.header {

	.nav-sections {
		height: $ts-sticky-headbar-height;
	}

	.navigation {
		// height: $ts-sticky-headbar-height;

		.submenu {
			display: none;
		}

		a {
			text-decoration: $ts-headbar-main-menu-anchor-underline;
		}

		> ul {
			> li {
				> ul {
					display: none;
				}
			}
		}

		ul,
		.ui-menu {
			@include menu-base;
			@include menu-icons;
			@include menu-direction(horizontal);
			@include lsn;
			outline: none;

			@include breakpoint(xxlarge) {
				margin-top: 0;
			}

			> li,
			> .ui-menu-item {
				position: relative;

				&.is-featured {
					> a {
						font-family: $ts-headbar-main-menu-featured-font-family;
						font-weight: $ts-headbar-main-menu-featured-font-weight;
						font-style: $ts-headbar-main-menu-featured-font-style;
						color: $ts-headbar-main-menu-featured-font-color;
						background-color: $ts-headbar-main-menu-featured-bg-color;

						&:hover,
						&:focus {
							color: $ts-headbar-main-menu-featured-font-color;
						}
					}
				}

				> a {
					color: $ts-headbar-main-menu-anchor-color;
					@include header-font-family($ts-headbar-main-menu-font-family);
					font-weight: $ts-headbar-main-menu-font-weight;
					outline: none;

					@each $tmp-mquery, $tmp-values in $ts-headbar-main-menu-font-size {
						@include breakpoint($tmp-mquery) {
							@each $tmp-value in $tmp-values {
								font-size : #{$tmp-value};
							} // @each
						} // @media
					} // @each

					@each $tmp-mquery, $tmp-properties in $menu-item-padding-hz {
						@include breakpoint($tmp-mquery) {
							@each $tmp-property, $tmp-value in $tmp-properties {
								#{$tmp-property} : #{$tmp-value};
							} // @each
						} // @media
					} // @each
					@each $tmp-mquery, $tmp-properties in $menu-item-padding-vt {
						@include breakpoint($tmp-mquery) {
							@each $tmp-property, $tmp-value in $tmp-properties {
								#{$tmp-property} : #{$tmp-value};
							} // @each
						} // @media
					} // @each
				}// a

				&.parent {

					.submenu-wrapper {
						overflow: hidden;
						min-width: map_get($ts-headbar-main-menu-width, xlarge);
						position: relative;
						background: $ts-headbar-main-menu-bg;
						@if ($ts-header-menu-dropdown-box-shadow != none) {
							box-shadow: $ts-header-menu-dropdown-box-shadow;
						}

						@include breakpoint(xlarge) {
							min-width: map_get($ts-headbar-main-menu-width, xlarge);
						}

						@include breakpoint(xxlarge) {
							min-width: map_get($ts-headbar-main-menu-width, xxlarge);
						}

						&:before {
							width: $ts-header-menu-dropdown-category-right-width;
							display:block;
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							background: $ts-header-menu-dropdown-right-background-color;
							z-index: 0;
						}// :before

						.left-categories {
							float: left;
							width: $ts-header-menu-dropdown-category-left-width;
							padding: $ts-header-menu-dropdown-category-left-spacing;
						}// .left-categories

						.right-categories {
							float: left;
							width: $ts-header-menu-dropdown-category-right-width;
							padding: $ts-header-menu-dropdown-category-right-spacing;
							position: relative;
							border-right: $ts-header-menu-dropdown-category-right-border;
							background: $ts-header-menu-dropdown-right-background-color;
							z-index: 1;

							@if $ts-header-menu-dropdown-category-right-list-inline {
								.category-list {
									display: flex;
								}// .category-list

								.category-item {
									flex-basis: 100%;
									max-width: 33.33333%;
									@include get-gutter(.25,'padding-left');
									@include get-gutter(.25,'padding-right');
								}
							}


							.category-item {
								text-align: $ts-header-menu-dropdown-category-right-talign;
								margin-bottom: $ts-header-menu-dropdown-category-right-indent;

								&:nth-child(2n+1) {
									clear:right;
								}

								a {
									display: block;
									text-decoration: none;

									&:hover,
									&:active {
										text-decoration: underline;
									}
								}

								@if $ts-header-menu-dropdown-category-menu-image-show {
									.category-menu-image {
										display: block;
										height: $ts-header-menu-dropdown-category-menu-image-height;
										margin-bottom: $ts-header-menu-dropdown-category-menu-image-bottom-spacing;

										img {
											max-height: 100%;
											max-width: 96%;
										}

										&:after {
											display:inline-block;
											vertical-align: bottom;
											content: '';
											height: 100%;
											margin-right: -.5em;
										}
									}// .category-menu-image
								} @else {
									.category-menu-image {
										display: none;
									}
								}


								.category-menu-title {
									display: block;
									line-height: $ts-header-menu-dropdown-category-right-line-height;
									font-weight: $ts-header-menu-dropdown-category-right-font-weight;
									font-size: $ts-header-menu-dropdown-category-right-font-size;
									@include header-font-family($ts-header-menu-dropdown-category-right-font-family);
									color: $ts-header-menu-dropdown-category-right-color;
									margin-bottom: $ts-header-menu-dropdown-category-right-title-indent;
									text-transform: $ts-header-menu-dropdown-category-right-title-transform;
								}// .category-menu-title

								.category-see-all {
									font-size: $ts-header-menu-dropdown-category-right-link-font-size;
									font-family: $ts-header-menu-dropdown-category-right-link-font-family;
									color: $ts-header-menu-dropdown-category-right-link-color;
									text-decoration: $ts-header-menu-dropdown-category-right-link-underline;
									text-transform: $ts-header-menu-dropdown-category-right-link-transform;

									&:hover {
										text-decoration: $ts-header-menu-dropdown-category-right-link-underline-hover;
									}
								}//.category-see-all

							}// .category-item

							.category-static-block {
								font-size: $ts-header-menu-dropdown-category-static-font-size;
								font-weight: $ts-header-menu-dropdown-category-static-font-weight;
								@include header-font-family($ts-header-menu-dropdown-category-static-font-family);
								color: $ts-header-menu-dropdown-category-static-color;
								border-top: $ts-header-menu-dropdown-category-static-border;
								padding-top: $ts-header-menu-dropdown-category-static-indent;
								text-align: $ts-header-menu-dropdown-category-static-talign;

								.convert-block {
									margin-bottom: 0;

									p {
										font-size: $ts-header-menu-dropdown-category-static-font-size;
										text-align: $ts-header-menu-dropdown-category-static-talign;
									}
								}

								a {
									display: inline-block;
									color:$anchor-color;
									font-weight: normal;
									position: relative;
									font-size: $ts-header-menu-dropdown-category-static-link-size;
									font-family: $ts-header-menu-dropdown-category-static-link-font-family;
									text-decoration: $ts-header-menu-dropdown-category-static-link-underline;
									margin-left: $ts-header-menu-dropdown-category-static-link-indent;

									@if $ts-header-menu-dropdown-category-static-link-arr {
										&:after {
											display: inline-block;
											content: '';
											width: $ts-header-menu-dropdown-category-static-link-arr-width;
											height: $ts-header-menu-dropdown-category-static-link-arr-height;
											margin-left: $ts-header-menu-dropdown-category-static-link-arr-indent;
											background: url("#{$ts-path-to-images}/#{$ts-header-menu-dropdown-category-static-link-arr-icon}.#{$ts-header-menu-dropdown-category-static-link-arr-type}") 0 0 no-repeat;
											background-size: 100% auto;
										}
									}

									&:hover,
									&:active {
										text-decoration: underline;
									}
								}// a
							}// .category-static-block

						}// .right-categories

					}// .submenu-wrapper

					&.has-submenu-wrapper {
						> .submenu {
							box-shadow: none;
							padding: 0;
							min-width: map_get($ts-headbar-main-menu-width, xlarge);

							li,
							.ui-menu-item {
								&.category-all {
									display: block;
									margin-bottom: $ts-header-menu-dropdown-link-all-bottom-spacing;
									a {
										font-size: $ts-header-menu-dropdown-link-all-font-size;
										font-weight: $ts-header-menu-dropdown-link-all-font-weight;
										@include header-font-family($ts-header-menu-dropdown-link-all-font-family);
										color:$ts-header-menu-dropdown-link-all-color;
										padding: $ts-header-menu-dropdown-link-all-padding;
										border-bottom: $ts-header-menu-dropdown-link-all-border;
										text-transform: $ts-header-menu-dropdown-link-all-transform;
									}// a
								}// &.category-all

								a {
									font-family: $ts-header-menu-dropdown-category-left-link-font-family;
									font-size: $ts-header-menu-dropdown-category-left-link-font-size;
									font-weight: $ts-header-menu-dropdown-category-left-link-font-weight;
									text-transform: $ts-header-menu-dropdown-category-left-link-transform;

									&:after {
										opacity: 1;
									}
								}
							}

							@include breakpoint(xlarge) {
								min-width: map_get($ts-headbar-main-menu-width, xlarge);
							}

							@include breakpoint(xxlarge) {
								min-width: map_get($ts-headbar-main-menu-width, xxlarge);
							}
						}
					}// &.has-submenu-wrapper

				}// .ui-menu-item.parent

				&.menu-type-subcategories {

					@if $ts-header-menu-dropdown-subcategory-has-arrow {
						> a {
							&:before {
								opacity: 0;
								display: block;
								content: '';
								position: absolute;
								bottom: 0;
								left: 50%;
								transform: translateX(-50%);
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 0 5px 6px 5px;
								border-color: transparent transparent $ts-header-menu-dropdown-background-color transparent;
								z-index: ($ts-header-menu-dropdown-z-index + 1);
								transition: opacity .3s .2s ease;
							}
							&.ui-state-focus,
							&.ui-state-active {
								&:before {
									opacity: 1;
								}
							}
						}
					}

					&.has-2subcategories {
						> .submenu {
							min-width: rem-calc(450);
							@include breakpoint(xxlarge) {
								min-width: rem-calc(460);
							}
						}
					}
					&.has-3subcategories {
						> .submenu {
							min-width: rem-calc(550);
							@include breakpoint(xxlarge) {
								min-width: rem-calc(645);
							}
						}
					}
					&.has-4subcategories {
						> .submenu {
							min-width: rem-calc(600);
							@include breakpoint(xxlarge) {
								min-width: rem-calc(850);
							}
						}
					}
					&.has-5subcategories {
						> .submenu {
							min-width: rem-calc(750);
							@include breakpoint(xxlarge) {
								min-width: rem-calc(1000);
							}
						}
					}
					&.has-6subcategories {
						> .submenu {
							min-width: rem-calc(600);
							.subcategories-over {
								flex-wrap: wrap;
								> .ui-menu-item {
									flex-basis: 33%;
									margin-bottom: 1rem;

									@if $ts-header-menu-dropdown-subcategory-has-separator {
										&:nth-child(3n) {
											&:before {
												display: none;
											}
										}
									}
								}
							}
							@include breakpoint(xxlarge) {
								min-width: rem-calc(1000);
								.subcategories-over {
									> .ui-menu-item {
										flex-basis: 16%;

										@if $ts-header-menu-dropdown-subcategory-has-separator {
											&:nth-child(3n) {
												&:before {
													display: block;
												}
											}
											&:nth-child(6n) {
												&:before {
													display: none;
												}
											}
										}
									}
								}
							}
						}
					}

					> .submenu {
						padding: $ts-header-menu-dropdown-subcategory-padding;
						margin:0;

						.subcategories-over {
							display: flex !important;

							> .ui-menu-item {
								flex-basis: 100%;
								-ms-flex-preferred-size: auto;
								position: relative;
								@include get-gutter(.25,'padding-left');
								@include get-gutter(.25,'padding-right');

								&.parent {
									+ .parent {
										margin-top: 0;
									}
								}

								@if $ts-header-menu-dropdown-subcategory-has-separator {
									@include get-gutter(.5,'padding-left');
									@include get-gutter(.5,'padding-right');
									padding-bottom: .5rem;

									&:before {
										display: block;
										content: '';
										position: absolute;
										top: 0;
										bottom: 0;
										right: 0;
										width: 1px;
										background-color: $ts-header-menu-dropdown-subcategory-separator-color;
									}

									&:last-child {
										&:before {
											display: none;
										}
									}
								}

								> .submenu {
									min-width: inherit;

									a {
										text-transform: $ts-header-menu-dropdown-subcategory-link-text-transform;
										padding: $ts-header-menu-dropdown-subcategory-link-inner-indent;
										font-size: ($ts-header-menu-dropdown-subcategory-link-font-size*.8);
										font-weight: $ts-header-menu-dropdown-subcategory-link-font-weight;
										font-family: $ts-header-menu-dropdown-subcategory-link-font-family;
										color: $ts-header-menu-dropdown-subcategory-link-font-color;

										@include breakpoint(xxlarge) {
											font-size: $ts-header-menu-dropdown-subcategory-link-font-size;
										}

										&:after {
											display: none;
										}
									}
								}

								a {
									white-space: normal;

									&:hover,
									&:active,
									&:focus {
										color: $ts-header-menu-dropdown-subcategory-link-font-color-hover;
										text-decoration: $ts-header-menu-dropdown-subcategory-link-font-hover-underline;
									}
								}

								> a {
									font-size: ($ts-header-menu-dropdown-subcategory-link-font-size-title*.8);
									text-transform: $ts-header-menu-dropdown-subcategory-link-transform-title;
									padding-bottom: $ts-header-menu-dropdown-subcategory-link-inner-indent-title;
									margin-bottom: $ts-header-menu-dropdown-subcategory-link-outer-indent-title;
									font-weight: $ts-header-menu-dropdown-subcategory-link-font-weight-title;
									font-family: $ts-header-menu-dropdown-subcategory-link-font-family-title;
									font-style: $ts-header-menu-dropdown-subcategory-link-font-style-title;
									padding-right: 0;

									@include breakpoint(xxlarge) {
										font-size: $ts-header-menu-dropdown-subcategory-link-font-size-title;
									}

									&:after {
										display: none;
									}
								}
							}
						}

					}
				}

				// HOYERCARE-332
				&.has-brand-section {
					> .submenu {
						padding: 0;

						.submenu-wrapper {
							&:before {
								width: 50%;
							}
							.left-categories,
							.right-categories {
								width: 50%;
								padding: $ts-header-menu-dropdown-category-left-spacing;

								.submenu {
									.subcategories-wrap {
										.subcategories-over {
											.ui-menu-item {
												padding: 0;
											}
										}
									}
								}
							}
							.left-categories {
								padding-right: 1rem;
								h6 {
									@include at;
								}
							}
							.category-static-block {
								.category-brands-title {
									font-size: ($ts-header-menu-dropdown-subcategory-link-font-size-title*.8);
									text-transform: $ts-header-menu-dropdown-subcategory-link-transform-title;
									margin-bottom: $ts-header-menu-dropdown-subcategory-link-outer-indent-title;
									font-weight: $ts-header-menu-dropdown-subcategory-link-font-weight-title;
									font-family: $ts-header-menu-dropdown-subcategory-link-font-family-title;
									font-style: $ts-header-menu-dropdown-subcategory-link-font-style-title;
									padding: $ts-header-menu-dropdown-subcategory-link-inner-indent;
									padding-bottom: $ts-header-menu-dropdown-subcategory-link-inner-indent-title;
									padding-right: 0;
									color: $ts-header-menu-dropdown-subcategory-link-font-color;

									@include breakpoint(xxlarge) {
										font-size: $ts-header-menu-dropdown-subcategory-link-font-size-title;
									}
								}
								.submenu {
									.ui-menu-item {
										a {
											text-transform: $ts-header-menu-dropdown-subcategory-link-text-transform;
											padding: $ts-header-menu-dropdown-subcategory-link-inner-indent;
											font-size: ($ts-header-menu-dropdown-subcategory-link-font-size*.8);
											font-weight: $ts-header-menu-dropdown-subcategory-link-font-weight;
											font-family: $ts-header-menu-dropdown-subcategory-link-font-family;
											color: $ts-header-menu-dropdown-subcategory-link-font-color;

											@include breakpoint(xxlarge) {
												font-size: $ts-header-menu-dropdown-subcategory-link-font-size;
											}
										}
									}
								}
							}
						}
					}
				}

				// temporary styles
				> ul,
				> .submenu {
					min-width: rem-calc(300);
					top: 100%;
					right: 0;

					.ui-menu {
						min-width: inherit;
					}

					@include breakpoint(xxlarge) {
						right: auto;
						top: auto;
					}
				}

				> ul,
				> .submenu {
					@include menu-base;
					margin-left: 0;
					position: absolute !important;
					padding: $ts-header-menu-dropdown-padding;
					background: $ts-header-menu-dropdown-background-color;
					@if ($ts-header-menu-dropdown-box-shadow != none) {
						box-shadow: $ts-header-menu-dropdown-box-shadow;
					}
					z-index: $ts-header-menu-dropdown-z-index;

					// temporary styles
					ul,
					.submenu {
						display: block !important;
						box-shadow: none !important;
						position: relative !important;
						left: auto !important;
						top: auto  !important;
						margin: 0 !important;
						padding: 0;
					}

					li,
					.ui-menu-item {
						display: block;

						&.parent {
							// + .parent {
							//  margin-top: .5rem;
							// }

							.ui-menu-item {
								&.parent {
									> a {
										border-bottom: 0;
									}

									+ .parent {
										margin-top: 0;
									}
								}
							}
						}

						> a {
							display: block;
							font-size: $ts-header-menu-dropdown-link-font-size;
							font-weight: $ts-header-menu-dropdown-link-font-weight;
							font-style: $ts-header-menu-dropdown-link-font-style;
							@include header-font-family($ts-header-menu-dropdown-link-font-family);
							color:$ts-header-menu-dropdown-link-color;
							padding: $ts-header-menu-dropdown-link-padding;
							text-transform: $ts-header-menu-dropdown-link-transform;
							position: relative;
							cursor: pointer;

							@if ( $ts-header-menu-dropdown-link-image != false ) {
								&:after {
									display: inline-block;
									content: '';
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: 0;
									opacity: $ts-header-menu-dropdown-link-image-opacity;
									width: $ts-header-menu-dropdown-link-image-width;
									height: $ts-header-menu-dropdown-link-image-height;
									background: url("#{$ts-path-to-images}/#{$ts-header-menu-dropdown-link-image}.#{$ts-header-menu-dropdown-link-image-type}") no-repeat;
									background-size: 100% auto;
								}
							}

							&:hover,
							&:active,
							&:focus,
							&.ui-state-focus {
								color: $ts-header-menu-dropdown-link-color-hover;
								text-decoration: $ts-header-menu-dropdown-link-color-hover-underline;
								background: transparent;
							}

							&.ui-state-focus {
								text-decoration: $ts-header-menu-dropdown-link-color-hover-underline;
							}
						}// > a

						&.category-all {
							display: none;
						}// &.category-all
					}
				}// > ul, > .submenu

			}// > .ui-menu-item

		}// ul, .ui-menu


		> ul,
		> .ui-menu {
			display: inline-block;
			vertical-align: middle;

			> li,
			> .ui-menu-item {
				> a {
					text-transform: $ts-headbar-main-menu-text-transform;
					background: transparent;
					transition: background .2s ease;

					span {
						display: inline-block;
					}

					&:hover,
					&:active,
					&:focus,
					&.ui-state-focus,
					&.ui-state-active {
						color: $menu-item-color-active;
						background: $menu-item-background-active;
					}
				}

				// &.active {
				//  &.parent {
				//      > a {
				//          @if $ts-primary-nav-dd {
				//              &:after {
				//                  background-image: url("#{$ts-path-to-images}/#{$ts-primary-nav-dd-icon-active}.#{$ts-primary-nav-dd-icon-type}");
				//              }
				//          }
				//      }
				//  }
				// }

				&.parent {

					> a {
						position: relative;
						white-space: nowrap;

						// &.ui-state-focus,
						//&:focus {
						//    + .submenu {
						//        display: block !important;
						//    }
						//}

						@if $ts-primary-nav-dd {
							&.ui-state-focus {
								&:after {
									transform: rotate(180deg);
									background-image: url("#{$ts-path-to-images}/#{$ts-primary-nav-dd-icon-active}.#{$ts-primary-nav-dd-icon-type}");
								}
							}

							&:after {
								display: inline-block;
								vertical-align: middle;
								margin-left: $ts-primary-nav-dd-icon-indent;
								content: '';
								width: $ts-primary-nav-dd-icon-width;
								height: $ts-primary-nav-dd-icon-height;
								background: url("#{$ts-path-to-images}/#{$ts-primary-nav-dd-icon}.#{$ts-primary-nav-dd-icon-type}") 0 0 no-repeat;
								background-size: 100% auto;
							}// > a:before
						}


					}// > a
				}
			}

			&.nav-extra {
				margin-left: .5rem;
				@include get-gutter(.25,'padding-left');
				@include border-line('left', 0,$ts-menu-extra-border-color);

				&:before {
					top: 6px;
					bottom: 6px;
				}

				@include breakpoint(xlarge) {
					@include get-gutter(.25,'margin-left');
				}

				> li,
				> .ui-menu-item {
					> a {
						font-weight: $ts-menu-extra-font-weight;
						color: $ts-menu-extra-color;
						background: transparent;
						transition: background .2s ease;

						&:hover,
						&:active,
						&:focus,
						&.ui-state-focus,
						&.ui-state-active {
							color: $menu-item-color-active;
							background: $menu-item-background-active;
						}
					}
				}
			}// .nav-extra
		} // > ul, > .ui-menu
	}

	.section-item-title {
		display: none;
	}

}// .header

.nav-sections-items {
	> div:last-child {
		display: none;
	}
}
