@if not $ts-amasty-filters-show {
	.filter-options-content {

		ol,
		ul {
			@include lsn;
			font-size: $ts-sidebar-main-filter-font-size;
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

			&.am_shopby_filter_items_attr_price {
				border-top: 0;
			}

			.item {
				border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

				&:last-child {
					border-bottom: 0;
				}

				a {
					display: block;
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;
					cursor: pointer;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}

					.label {
						cursor: inherit;
						text-decoration: $ts-sidebar-main-filter-anchor-underline;
					}

					&:hover {
						color: $ts-sidebar-main-filter-anchor-color-hover;
						background-color: $ts-sidebar-main-filter-anchor-bg-hover;
						text-decoration: none;

						.label {
							text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
						}
					}

					&.checked,
					&.active,
					&.selected,
					&.ais-refinement-list--item__active {
						color: $ts-sidebar-main-filter-anchor-color-active;
						background-color: $ts-sidebar-main-filter-anchor-bg-active;
						text-decoration: none;

						.label {
							padding-left: $ts-text-swatch-active-image-padding-left;
							font-weight: $ts-sidebar-main-filter-anchor-font-weight-active;
							text-decoration: $ts-sidebar-main-filter-anchor-underline-active;
							background: url("#{$ts-path-to-images}/#{$ts-text-swatch-active-image}.#{$ts-text-swatch-active-image-type}") $ts-text-swatch-active-image-position no-repeat;
							background-size: $ts-text-swatch-active-image-size;
						}
					}

				}// a


				> .label {
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;
					padding-right: 0;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}
				}

				.count {
					@if $ts-sidebar-main-filter-count-hide {
						@include at;
					} @else {
						display: inline-block;
						vertical-align: middle;
						color: $ts-sidebar-main-filter-count-color;
						font-size: $ts-sidebar-main-filter-count-font-size;

						&:before {
							content: '(';
						}

						&:after {
							content: ')';
						}

						.filter-count-label {
							@include at;
						}
					}
				}// .count
			}// li
		}// ol,ul

		.swatch-attribute.size {

			.swatch-option-link-layered,
			.ais-refinement-list--item {
				display: inline-block;
				vertical-align: top;
				min-width: $ts-size-swatch-width;
				margin: $ts-size-swatch-outer-indent;
				cursor: pointer;
				font-size: 0;

				&[href="javascript:void();"] {
					display: none;
				}
			}

			//.swatch-option {
			//	width: auto;
			//	min-width: $ts-size-swatch-width;
			//	height: $ts-size-swatch-height;
			//	padding: $ts-size-swatch-text-inner-indent;
			//	line-height: $ts-size-swatch-height;
			//	position: relative;
			//	text-align: center;
			//	cursor: pointer;
			//	font-size: $ts-size-swatch-font-size;
			//	font-weight: $ts-size-swatch-font-weight;
			//	font-family: $ts-size-swatch-font-family;
			//	box-shadow: $ts-size-swatch-box-shadow;
			//	background-color: $ts-size-swatch-bg-color;
			//	cursor: pointer;
			//	letter-spacing: $ts-size-swatch-letter-spacing;
			//	word-spacing: $ts-size-swatch-word-spacing;
            //
			//	&:before,
			//	&:after {
			//		display: none;
			//	}
			//	&.disabled {
			//		&:before,
			//		&:after {
			//			display: none;
			//		}
			//	}
            //
			//	&:hover,
			//	&:active,
			//	&.selected,
			//	&.checked {
			//		color: #fff;
			//		background-color: $primary-color;
			//	}
			//}
		}// &.has-text-swatch

		.swatch-attribute.color {
			padding-top: $ts-color-swatch-category-top-indent;
			padding-bottom: $ts-color-swatch-category-bottom-indent;

			@if $ts-color-swatch-category-top-border {
				border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
			}

			.swatch-option-link-layered,
			.ais-refinement-list--item {
				display: inline-block;
				vertical-align: top;
				border-radius: $ts-color-swatch-radius;
				margin: $ts-color-swatch-outer-indent;
				padding: $ts-color-swatch-inner-indent;
				position: relative;
				font-size: 0;

				&.checked,
				&.ais-refinement-list--item__active {
					box-shadow: 0 0 0 1px lighten($ts-color-swatch-border-shadows-basic-color, 67.0588);
					background:$white url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% calc(100% - 3px) no-repeat;
					background-size: $ts-color-swatch-active-image-width auto;
				}
			}// .swatch-option-link-layered

			.swatch-option {
				display: block;
				width: $ts-color-swatch-width;
				height: $ts-color-swatch-height;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				text-align: center;
				background-color: #fff;
				cursor: pointer;
				border-radius: $ts-color-swatch-radius;

				@if $ts-color-swatch-border {
					box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
				}

				&:hover,
				&:active {
					&:before {
						.touchevents & {
							display: block;
						}
					}
				}

				&:hover,
				&:active,
				&.selected,
				&.checked {
					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
						width: 100%;
						height: 100%;
						background: url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% no-repeat;
						background-size: $ts-color-swatch-active-image-width auto;
					}
				}

				.product-items & {
					width: $ts-color-swatch-product-list-width;
					height: $ts-color-swatch-product-list-height;
					border-radius: $ts-color-swatch-product-list-radius;
					vertical-align: top;
					margin: $ts-color-swatch-product-list-indent;
					margin-left: 0;

					&.selected {
						height: $ts-color-swatch-product-list-height;
						box-shadow: $ts-color-swatch-product-list-selected-shadow;

						&:after {
							display: none;
						}
					}
				}

				&.disabled {
					opacity: .25;
				}

				&[option-label="White"],
				&[option-label="Hvit"] {
					box-shadow: inset 0 0 0 1px rgba($black,.1);
				}
			}// .swatch-option
		}// &.has-images-labels

	}

	.filter-current {
		ol,
		ul {
			@include lsn;
		}
	}

	.swatch-attribute-label {
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
		@include label-colon;
		margin-bottom: .25rem;

		.product-info-main & {
			font-size: $ts-color-swatch-title-font-size;
			font-weight: $ts-color-swatch-title-font-weight;
			font-family: $ts-color-swatch-title-font-family;
			font-style: $ts-color-swatch-title-font-style;
			text-transform: $ts-color-swatch-title-text-transform;
		}

	}// .swatch-attribute-label

	.swatch-attribute-selected-option {
		margin-left: .5rem;
		display: inline-block;
		vertical-align: middle;
	}// .swatch-attribute-selected-option
}

.product-info-main {
	.swatch-attribute-options,
	.ais-refinement-list--list {
		width: 100%;
		display: block;

		.ais-refinement-list--item {
			display: inline-block;
			vertical-align: top;
			min-width: $ts-size-product-swatch-width;
			width: auto;
			border:0;
		}

		//.swatch-option {
		//	display: inline-block;
		//	vertical-align: middle;
		//	min-width: $ts-size-product-swatch-width;
		//	width: auto;
		//	height: $ts-size-product-swatch-height;
		//	padding: $ts-size-product-swatch-text-inner-indent;
		//	text-align: center;
		//	color: $ts-size-product-swatch-color;
		//	line-height: $ts-size-product-swatch-height;
		//	border-radius: $ts-size-swatch-radius;
		//	font-size: $ts-size-product-swatch-font-size;
		//	font-weight: $ts-size-product-swatch-font-weight;
		//	font-family: $ts-size-product-swatch-font-family;
		//	margin: $ts-size-product-swatch-margin;
		//	white-space: nowrap;
		//	box-shadow: none;
		//	background-color: $ts-size-product-swatch-bg;
		//	cursor: pointer;
        //
		//	@if not($ts-size-product-swatch-border == transparent) {
		//		border-left: 1px solid $ts-size-product-swatch-border;
		//	}
        //
        //
		//	&:first-child {
		//		border-left: 0;
		//	}
        //
		//	&:hover,
		//	&:active,
		//	&.selected {
		//		color: $ts-size-product-swatch-color-active;
		//		background-color: $ts-size-product-swatch-bg-active;
        //
		//		@if not($ts-size-product-swatch-border == transparent) {
		//			border-left-color: $ts-size-product-swatch-bg-active;
		//		}
        //
		//		@if $ts-size-product-swatch-has-full-border {
		//			box-shadow: $ts-size-product-swatch-full-border;
        //
		//		} @else {
		//			box-shadow: none;
		//		}
        //
		//		&:after {
		//			display: none;
		//		}
        //
		//		@if not($ts-size-product-swatch-border == transparent) {
		//			+ .swatch-option {
		//				border-left-color: $ts-size-product-swatch-bg-active;
        //
		//				&:hover {
		//					border-left-color: $ts-size-product-swatch-border-hover;
		//				}
		//			}
		//		}
		//	}
        //
		//	&.disabled {
		//		opacity: 1;
		//		position: relative;
		//		color: $ts-size-product-swatch-disabled-color;
        //
		//		&:hover,
		//		&:active {
		//			cursor: help;
		//			color: $ts-size-product-swatch-disabled-color;
		//			background: transparent;
		//			@if not($ts-size-product-swatch-border == transparent) {
		//				border-left-color: $ts-size-product-swatch-border;
		//				+ .swatch-option {
		//					border-left-color: $ts-size-product-swatch-border;
        //
		//					&:hover {
		//						border-left-color: $ts-size-product-swatch-border;
		//					}
		//				}
		//			}
        //
        //
		//			&:before,
		//			&:after {
		//				opacity: 1;
		//			}
		//		}
        //
		//		&:before {
		//			opacity: 0;
		//			display: block;
		//			content: attr(option-label);
		//			position: absolute;
		//			top: 100%;
		//			left: 50%;
		//			transform: translateX(-50%);
		//			padding: rem-calc(10);
		//			font-size: rem-calc(11);
		//			line-height: 0;
		//			margin: 0;
		//			color: $white;
		//			white-space: nowrap;
		//			background: $black;
		//			transition: opacity .3s ease;
		//			z-index: 1;
		//		}
		//		&:after {
		//			opacity: 0;
		//			content: '';
		//			width: 0;
		//			height: 0;
		//			display: block;
		//			border-style: solid;
		//			border-width: 0 6px 6px 6px;
		//			border-color: transparent transparent $black transparent;
		//			position: absolute;
		//			bottom: 0;
		//			left: 50%;
		//			transform: translateX(-50%);
		//			transition: opacity .3s ease;
		//		}
		//	}
		//}
	}// .swatch-attribute-options
}// .product-detail-swatch
