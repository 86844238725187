//
//  Shopping cart
//  ---------------------------------------------
.checkout-cart-index {
	.page-title-wrapper:not(.product) {
		@include breakpoint(medium down) {
			padding-bottom: 0;
			margin-bottom: 0;
			&:before {
				display: none;
			}
		}
	}
}

.cart-container {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.table-caption {
		display: none;
	}

	.page-title-wrapper {
		margin-bottom: .5rem;

		@include breakpoint(medium down) {
			padding-bottom: 0;

			.page-title {
				font-size: $ts-cart-title-mobile-font-size;
			}
			&:before {
				display: none;
			}
		}

		+ .checkout-methods-items {
			margin-top: .5rem;

			.item {
				margin-bottom: 0;
			}
		}
	}
}// .cart-container

.cart-form {
	@include grid-col;

	@if $ts-checkout-sidebar-wide {
		@include breakpoint(large) {
			@include grid-col-size(6);
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(7.9);
		}
	} @else {
		@include breakpoint(large) {
			@include grid-col-size(8);
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(9);
		}
	}
}// .cart-form

.cart-summary {
	@include grid-col;
	margin-bottom: 2rem;

	@if $ts-checkout-sidebar-wide {
		@include breakpoint(large) {
			@include grid-col-size(6);
			float: right;
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(4.1);
		}
	} @else {
		@include breakpoint(large) {
			@include grid-col-size(4);
			float: right;
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(3);
		}
	}


	.summary.title {
		display: none;
		font-size: $ts-cart-summary-title-font-size;
		font-weight: $ts-cart-summary-title-font-weight;
		@include header-font-family($ts-cart-summary-title-font-family);
		padding: $ts-cart-summary-title-spacing;

		@if $ts-cart-summary-title-visible {
			@include breakpoint(large) {
				display: block;
			}
		}
	}// .summary.title

	.message {
		margin-bottom: .5rem;
	}

	.methods {
		.item-title {
			display: block;
			font-size: $ts-cart-methods-title-font-size;
			font-weight: $ts-cart-methods-title-font-weight;
			@include header-font-family($ts-cart-methods-title-font-family);
			margin-bottom: .4rem;
		}
	}// .methods

	.field.choice {
		@include radio;
		margin-bottom: 0;
	}

	table {
		margin: 0;
		table-layout: fixed;

		tbody,
		thead,
		tfoot {
			border: 0;
			background: none;
		}
		td,
		th {
			border: 0;
			padding: 0 0 .5rem;
			line-height: $ts-cart-summary-table-line-height;
			font-weight: $ts-cart-summary-table-font-weight;
			text-align: left;
			vertical-align: middle;
			background: none;

			@include breakpoint(xxlarge) {
				padding-bottom: .9rem;
			}
		}

		td {
			padding-left: .5rem;
			text-align: right;

			&.amount {
				width: 100%;
			}
		}

		tr {
			&:last-child {
				td,
				th {
					padding-bottom: 0;
				}
			}
		}

		.totals {
			&.sub {
				td,
				th {
					font-size: ($ts-cart-summary-table-total-font-size*.81);
					text-transform: $ts-cart-summary-table-total-text-transform;
					@include header-font-family($ts-cart-summary-table-total-font-family);
					font-size: ($ts-cart-summary-table-total-font-size*.75);
					font-weight: $ts-cart-summary-table-total-amount-font-weight;

					@include breakpoint(xxlarge) {
						font-size: $ts-cart-summary-table-total-font-size;
					}
				}
			}

			&.grand {
				@include header-font-family($header-font-family);
				border-top: 1px solid $ts-cart-table-border-color;
				text-transform: $ts-cart-summary-table-grandtotal-text-transform;

				td,
				th {
					padding-top: .5rem;
					font-weight: $ts-cart-summary-table-grandtotal-font-weight;
					font-size: ($ts-cart-summary-table-total-font-size*.75);
					font-family: $ts-cart-summary-table-grandtotal-font-family;

					@include breakpoint(xxlarge) {
						font-size: $ts-cart-summary-table-grandtotal-font-size;
					}

					strong {
						font-weight: inherit;
					}
				}

				.amount {
					line-height: 1;
					@if $ts-checkout-sidebar-wide {
						font-size: ($ts-cart-summary-table-grandtotal-amount-font-size*.75);
					} @else {
						font-size: ($ts-cart-summary-table-total-font-size*.75);
					}
				}

				@include breakpoint(xxlarge) {
					td,
					th {
						padding-top: 1.25rem;
					}

					.amount {
						@if $ts-checkout-sidebar-wide {
							font-size: $ts-cart-summary-table-grandtotal-amount-font-size;
						} @else {
							font-size: $ts-cart-summary-table-total-font-size;
						}
					}
				}
			}
		}

		.shipping,
		.totals-tax {
			td,
			th {
				font-size: ($ts-cart-summary-table-font-size*.85);
				font-weight: $ts-cart-summary-table-font-weight;
				color: $ts-cart-summary-table-color;

				.label {
					color: $ts-cart-summary-table-color;
				}

				@include breakpoint(xxlarge) {
					font-size: $ts-cart-summary-table-font-size;
				}
			}
		}

		.price-label-free {
			display: inline-block;
			vertical-align: middle;

			@if ($ts-cart-summary-label-free == 'simple') {
				letter-spacing: normal;
				padding: 0;
				color: $ts-cart-summary-table-color;
				background: none;
			} @else {
				letter-spacing: -.02em;
				padding: .3rem .625rem;
				color: $white;
				background: $black;
			}

		}

		.shipping {
			.mark {
				.value {
					display: none;
				}
			}
		}

		.totals-tax {
			td,
			th {
				padding-bottom: .75rem;
				@include breakpoint(xxlarge) {
					padding-bottom: 1rem;
				}
			}
		}
	}// table

	.block {
		margin-bottom: .5rem;
		.title {
			color: $anchor-color;
			font-size: rem-calc(11);
			cursor: pointer;

			strong {
				border-bottom: 1px solid $ts-main-border-color;
			}

			&:hover,
			&:active {
				strong {
					border-bottom: 0;
				}
			}

			@include breakpoint(large) {
				font-size: rem-calc(13);
			}
		}// .title

		.content {
			margin-top: 1.25rem;
			padding: 1.25rem 1.75rem 1.75rem;
			line-height: 1.46;
			color: $black;
			position: relative;
			background: $ts-checkout-sidebar-bg;
			box-shadow: $ts-checkout-sidebar-shadow;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 14px 14px 14px;
				border-color: transparent transparent $ts-checkout-sidebar-bg transparent;
				position: absolute;
				bottom: 100%;
				left: 2rem;
			}

			.legend {
				display: block;
				font-size: $ts-cart-summary-table-total-amount-font-size;
				font-weight: $ts-cart-summary-table-total-amount-font-weight;
				@include header-font-family($ts-cart-summary-table-total-font-family);
				margin-bottom: .4rem;
			}

			.label {
				line-height: 1.46;
				span {
					@include label-colon;
				}
			}

			br {
				display: none;
			}

			p {
				line-height: 1.46;
				margin: 0 0 .625rem;
			}

			.input-text {
				margin-bottom: .625rem;
			}

			.action {
				@include button-expand;
				@include button-style($secondary-color, $button-background-secondary-hover);
			}

			.actions-toolbar {
				> div {
					margin-bottom: .25rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}// .content
	}// .block
}// .cart-summary

.cart-totals {
	padding: 1rem (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
	margin-left: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
	margin-right: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
	margin-bottom: 1rem;
	position: relative;
	box-shadow: $ts-checkout-sidebar-shadow;
	background: $ts-checkout-sidebar-bg;

	._block-content-loading,
	[data-bind="blockLoader: isLoading"] {
		position: static;
	}

	.loading-mask {
		background-color: rgba($white, .45);
	}

	@include breakpoint(large) {
		margin-left: 0;
		margin-right: 0;
	}

	@include breakpoint(xxlarge) {
		margin-bottom: 1.5rem;
		padding: 1.75rem 1.75rem 2rem;
	}
}// .cart-totals


.checkout-methods-items {
	@include lsn;
	margin-top: 1rem;

	@include breakpoint(xxlarge) {
		margin-top: 1.5rem;
	}

	.item {
		margin-bottom: .5rem;
	}

	button.action {
		@include button-expand;
		font-size: ($ts-checkout-main-button-font-size*.75);
		font-weight: $ts-checkout-main-button-font-weight;
		position: relative;
		padding-right: $ts-checkout-main-button-hz-indent;
		padding-left: $ts-checkout-main-button-hz-indent;
		padding-top: $ts-checkout-main-button-top-indent;
		padding-bottom: $ts-checkout-main-button-bottom-indent;
		text-transform: $ts-checkout-main-button-text-transform;

		@include breakpoint(xxlarge) {
			font-size: $ts-checkout-main-button-font-size;
		}

		@if $ts-checkout-main-button-has-arrow {
			&:after {
				display: block;
				content: '';
				width: $ts-checkout-main-button-arrow-width;
				height: $ts-checkout-main-button-arrow-height;
				position: absolute;
				top: 50%;
				right: 1rem;
				transform: translateY(-50%);
				background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg");
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
		}

	}
}// .checkout-methods-items

table {
	&.cart {
		margin-bottom: .5rem;

		@include breakpoint(large) {
			margin-bottom: 3rem;
		}

		&:not(.has-header) {
			thead {
				display: none;
			}
		}

		tbody,
		thead,
		tfoot {
			border: 0;
			background: none;
		}

		td,
		th {
			border: 0;
			padding: 10px 5px;
			vertical-align: middle;
			line-height: $ts-cart-table-cell-line-height;
			font-weight: $ts-cart-table-cell-font-weight;
			text-align: inherit;
			background: none;

			@include breakpoint(xxlarge) {
				padding: $ts-cart-table-cell-indent;
			}

			&:last-child {
				padding-right: 2.5rem;
			}

			.stock {
				color: rgba($body-font-color, .8);
			}

			@include breakpoint(large down) {
				padding: 0;
				vertical-align: top;
				display: block;
				float: left;

				&:last-child {
					padding-right: .25rem;
				}

				.product-item-details {
					margin-bottom: .75rem;
				}

				.stock {
					font-size: rem-calc(11);
					line-height: 1.5;
				}

				&.item-info,
				&.item-sku {
					float: none;
					clear: right;
					margin-left: rem-calc(60);
					padding-top: rem-calc(8);
					padding-right: rem-calc(30);

					.product-item-name {
						line-height: 1;
					}

					.item-options {
						font-size: rem-calc(11);
						line-height: 1.5;
					}
				}

				&.qty,
				&.addtobasket {
					margin-left: rem-calc(60);
					clear: left;
				}

				&.addtobasket {
					width: calc(100% - 3.75rem);
					padding-top: .5rem;
					padding-bottom: .5rem;
					border-top: 1px solid $ts-cart-table-border-color;
					border-bottom: 1px solid $ts-cart-table-border-color;
					margin-bottom: 1rem;
					margin-top: .5rem;
				}

				&.cart-actions {
					position: absolute;
					top: rem-calc(18);
					right: 0;
					line-height: rem-calc(30);

					@include breakpoint(xlarge) {
						float: right;
						position: static;
						line-height: $input-select-height;
					}
				}

			}// breakpoint(large down)

			@include breakpoint(xlarge) {
				&.item-info {
					width: 100%;
				}
				&.item-sku {
					width: auto;
					min-width: 100px;
				}
			}
			@include breakpoint(xxlarge) {
				&.item-sku {
					min-width: 200px;
				}
			}

		}// td th

		tbody {
			&.cart.item {
				border-bottom: 1px solid $ts-cart-table-border-color;

				&:last-child {
					border-bottom: 0;
				}

				@include breakpoint(large down) {
					tr {
						display: block;
						@include clearfix;
						padding-top: .75rem;
						padding-bottom: .75rem;
						position: relative;
					}
				}
			}
		}// tbody

		.item-message {
			td,
			th {
				padding-top: 0;
			}

			td {
				&:last-child {
					padding-right: 0;
				}
			}
		}// .item-message

		.item-image {
			width: rem-calc(50);
			margin-bottom: .25rem;
			white-space: nowrap;
			position: relative;

			.checkbox {
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				top: 100%;
				left: 0;
				margin: 6px 0 0 15px;
			}

			.product-item-photo {
				display: inline-block;
				vertical-align: middle;
			}

			.product-image-container {
				max-width: rem-calc(50);
			}
			.product-label,
			.convert-label-container {
				display: none;
			}

			@include breakpoint(xlarge) {
				width: 1%;
				margin-bottom: 0;
				.checkbox {
					position: static;
					margin: 0;
				}
				.product-image-container {
					max-width: rem-calc(50);
				}
				.product-label,
				.convert-label-container {
					display: block;
				}
			}
			@include breakpoint(xxlarge) {
				.product-image-container {
					max-width: rem-calc(110);
				}
			}
		}// .item-image

		.field.qty {
			white-space: nowrap;
			margin-bottom: 0;

			&:after {
				display: inline-block;
				vertical-align: top;
				content: '×';
				font-size: 1rem;
				line-height: rem-calc(30);
				color: rgba($black, .5);
				padding-left: .5rem;
				padding-right: .6rem;

				@include breakpoint(xlarge) {
					padding-left: 1rem;
					padding-right: 0;
					line-height: $input-select-height;

					.ie11 & {
						position: relative;
						top: 16px;
					}
				}

				@include breakpoint(xxlarge) {
					.ie11 & {
						top: auto;
					}
				}
			}
			.control {
				display: inline-block;
				position: relative;

				div.mage-error {
					position: absolute;
					top: 100%;
					left: 0;
					margin-bottom: 0;
					white-space: normal;
					width: rem-calc(200);
					line-height: 1;

					@include breakpoint(medium down) {
						margin-top: 0;
					}
				}
			}

			.action.update {
				position: absolute;
				top: 0;
				left: 100%;
				height: $input-select-height;

				@include breakpoint(large down) {
					height: rem-calc(30);
					padding-top: 0;
					padding-bottom: 0;
					line-height: rem-calc(30);
				}
			}
		}// .field.qty


		.input-text {
			width: rem-calc(50);
			padding-left: .25rem;
			padding-right: .25rem;
			margin: 0;
			text-align: center;
			-moz-appearance:textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
			    -webkit-appearance: none;
			    margin: 0;
			}

			@include breakpoint(large down) {
				width: rem-calc(40);
				height: rem-calc(30);
				padding-top: 0;
				padding-bottom: 0;
			}

			&.mage-error,
			&.mage-success,
			&.valid {
				background-image: none;
			}
		}// .input-text

		@if not ($ts-cart-table-subtotal__show) {
			.subtotal {
				display: none;
			}
		}

		@if not ($ts-cart-table-item-action__show) {
			.action-edit {
				display: none;
			}
		}

		.label {
			display: none;
		}

		.product-image-container {
			display: block;
			position: relative;
			overflow: hidden;

			@if $ts-product-photo-overlay {
				@extend %product-layer-overlay;
			}
		}// .product-image-container

		.product-item-name {
			display: block;
			font-size: map-get($ts-cart-table-product-title-font-size, small);
			font-weight: $ts-cart-table-product-title-font-weight;
			text-transform: $ts-cart-table-product-title-text-transform;
			@include header-font-family($ts-cart-table-product-title-font-family);
			margin: .25rem 0;

			a {
				color: $body-font-color;
				text-decoration: none;

				&:hover {
					color: $anchor-color;
				}
			}

			@include breakpoint(large) {
				font-size: map-get($ts-cart-table-product-title-font-size, large);
			}
		}// .product-item-name

		.price-including-tax,
		.price-excluding-tax,
		.wishlist-price .price-box {
			display: inline-block;
			font-size: $ts-cart-table-product-price-font-size;
			font-weight: $ts-cart-table-product-price-font-weight;
			color: $ts-cart-table-product-price-color;
			margin-top: -.25rem;
			line-height: rem-calc(30);
			@include header-font-family($ts-cart-table-product-price-font-family);

			.price-as-configured {
				font-weight: inherit;
				margin: 0;
			}

			@include breakpoint(xlarge) {
				margin-top: 0;
				line-height: $input-select-height;
			}
		}// .price-including-tax

		.addtocart-btn {
			display: inline;
			color: $primary-color;
			text-decoration: underline;
			font-weight: $global-weight-normal;
			padding: 0;
			border:0;
			box-shadow: none;
			background: transparent;
			cursor: pointer;
			white-space: nowrap;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}// .addtocart-btn
	}
} // table.cart

.cart.main.actions {
	margin-bottom: 1rem;

	.action,
	.continue,
	.clear {
		@if not ($ts-cart-table-actions__show) {
			display: none;
		}
	}
} // .cart.main.actions

.cart.items .item > .item-actions,
.cart.items .item-info .gift-options-cart-item,
.cart-actions .action-towishlist,
.cart-summary .block.giftcard .content,
.block.discount .content,
#block-shipping {
	display: none;
}
