// 00. Theme settings
// - - - - - - - - - - - - - - - - - - - - - - - - -
$ts-path-to-images                                           : "../images" !default;

// 00.1 Indents
$indent__base                                                : 20px !default;
$indent__xl                                                  : 40px !default;
$indent__l                                                   : 30px !default;
$indent__m                                                   : 25px !default;
$indent__s                                                   : 10px !default;
$indent__xs                                                  : 5px !default;
$indent__auto                                                : auto !default;

$ts-global-space-indent                                      : ( small : 6px, large : 20px) !default;

// 00.2 Loaders
$ts-loading-fallback                                         : true;
$ts-loader-icon-width                                        : 160px !default;
$ts-loader-icon-height                                       : 160px !default;
$ts-loader-icon-background-color                             : transparent !default;
$ts-loader-icon-background-image                             : url("#{$ts-path-to-images}/ripple.svg") !default;
$ts-loader-icon-background-image-fallback                    : url("#{$ts-path-to-images}/ripple.gif") !default;
$ts-loader-icon-background-position                          : 50% 50% !default;
$ts-loader-icon-border-radius                                : 5px !default;
$ts-loader-icon-padding                                      : inherit !default;
$ts-loader-text                                              : inherit !default;
$ts-loader-text-color                                        : $primary-color !default;
$ts-loader-text-font-size                                    : $global-font-size !default;
$ts-loader-text-font-family                                  : $body-font-family !default;
$ts-loader-text-font-weight                                  : 400 !default;
$ts-loader-text-font-style                                   : normal !default;
$ts-loader-text-padding                                      : 130px 0 0 !default;
$ts-loader-overlay-background-color                          : scale-color(white,$alpha: -50%) !default;
$ts-loading-background-color                                 : scale-color(white,$alpha: -70%) !default;
$ts-loader-overlay-z-index                                   : 9999 !default;
$ts-loading-background-image                                 : inherit !default;
$ts-loader-text                                              : true !default;

// 00.3 Borders
$ts-main-border-width                                        : 1px !default;
$ts-main-border-style                                        : solid !default;
$ts-main-border-color                                        : rgba($black, .1) !default;
$ts-main-dark-border-color                                   : rgba($black, .2) !default;
$ts-main-medium-border-color                                 : lighten($black, 85) !default;

// 00.4 Input Settings
$ts-input-height                                             : ($input-font-size + ($form-spacing * 1.5) - rem-calc(1)) !default;

// 00.5 Pro Block
$ts-pro-block-margin                                         : (2rem 0) !default;
$ts-pro-block-title-font-family                              : $header-font-family !default;
$ts-pro-block-title-font-weight                              : 300 !default;
$ts-pro-block-title-font-size                                : rem-calc(20) !default;
$ts-pro-block-title-font-style                               : normal !default;
$ts-pro-block-title-margin                                   : (0 0 1.5rem) !default;
$ts-pro-block-title-font-color                               : inherit !default;
$ts-pro-block-title-text-align                               : center !default;
$ts-pro-block-title-text-transform                           : uppercase !default;
$ts-pro-block-title-responsive                               : ( medium :  ( font-size : 1.3 * $ts-pro-block-title-font-size),
                                                                 xxlarge : ( font-size : 1.4 * $ts-pro-block-title-font-size),
                                                                 xxxlarge : ( font-size : 1.6 * $ts-pro-block-title-font-size)) !default;
$ts-pro-block-title-side-lines-show                          : true !default;
$ts-pro-block-title-side-lines-width                         : 1px !default;
$ts-pro-block-title-side-lines-color                         : $ts-main-border-color !default;
$ts-pro-block-title-side-lines-margin-bottom                 : 3px !default;
$ts-pro-block-title-side-lines-gap                           : 0.7rem !default;

// 00.6 Load More button
$ts-loadmore-btn-view                                        : button !default; // can be circle or button
$ts-loadmore-btn-border-style                                : solid !default;
$ts-loadmore-btn-border-size                                 : 2px !default;
$ts-loadmore-btn-width                                       : rem-calc(120) !default;
$ts-loadmore-btn-height                                      : $ts-loadmore-btn-width !default;
$ts-loadmore-btn-spinner-size                                : rem-calc(15) !default;
$ts-loadmore-btn-font-family                                 : $header-font-family !default;

// 00.7 Messages
$ts-main-message-style                                       : simple !default; // possible values: fixed, simple

$ts-main-message-text-color-error                            : $body-font-color !default;
$ts-main-message-bg-error                                    : $white !default;
$ts-main-message-text-color-success                          : $body-font-color !default;
$ts-main-message-bg-success                                  : $white !default;
$ts-main-message-text-color-warning                          : $white !default;
$ts-main-message-bg-warning                                  : map-get($foundation-palette, alert) !default;
$ts-main-message-text-color-info                             : $white !default;
$ts-main-message-bg-info                                     : map-get($foundation-palette, primary) !default;

$ts-main-message-border-style                                : 1px solid !default;
$ts-main-message-border-color-error                          : map-get($foundation-palette, alert) !default;
$ts-main-message-border-color-success                        : map-get($foundation-palette, success) !default;
$ts-main-message-border-color-warning                        : map-get($foundation-palette, alert) !default;
$ts-main-message-border-color-info                           : map-get($foundation-palette, primary) !default;

$ts-main-message-icon-error                                  : 'icon-error' !default;
$ts-main-message-icon-error-type                             : 'svg' !default;
$ts-main-message-icon-error-size                             : 14px 14px !default;
$ts-main-message-icon-success                                : 'icon-success' !default;
$ts-main-message-icon-success-type                           : 'svg' !default;
$ts-main-message-icon-success-size                           : 19px 14px !default;
$ts-main-message-icon-warning                                : 'icon-error-white' !default;
$ts-main-message-icon-warning-type                           : 'svg' !default;
$ts-main-message-icon-warning-size                           : 14px 14px !default;
$ts-main-message-icon-info                                   : 'icon-info' !default;
$ts-main-message-icon-info-type                              : 'svg' !default;
$ts-main-message-icon-info-size                              : 14px 14px !default;

$ts-main-message-info-close-icon                             : 'icon-close-white' !default;
$ts-main-message-info-close-icon-type                        : svg !default; // possible values: svg, png, none
$ts-main-message-success-close-icon                          : 'icon-close' !default;
$ts-main-message-success-close-icon-type                     : svg !default; // possible values: svg, png, none
$ts-main-message-error-close-icon                            : 'icon-close-white' !default;
$ts-main-message-error-close-icon-type                       : svg !default; // possible values: svg, png, none
$ts-main-message-warning-close-icon                          : 'icon-close-white' !default;
$ts-main-message-warning-close-icon-type                     : svg !default; // possible values: svg, png, none

$ts-main-message-box-shadow                                  : 0 0 100px 2px $black !default;
$ts-main-message-zindex                                      : 999 !default;
$ts-main-message-font-family                                 : $header-font-family !default;
$ts-main-message-font-size                                   : rem-calc(17) !default;
$ts-main-message-font-weight                                 : 400 !default;
$ts-main-message-line-height                                 : 1.2 !default;
$ts-main-message-main-padding                                : 1.05rem 1.2rem 1.25rem 3rem !default;
$ts-main-message-main-icon-position                          : 1.25rem 1.4rem !default;
$ts-main-message-text-align                                  : center !default;
$ts-main-message-icon-position                               : 0 4px !default;
$ts-main-message-close-size                                  : 2rem !default;
$ts-main-message-close-icon-size                             : 1rem !default;
$ts-main-message-close-icon-position                         : 50% !default;
$ts-main-message-close-icon-position-top                     : -.25rem !default;
$ts-main-message-text-padding                                : 1.75rem !default;

//00.8 Main Title settings
$ts-product-detail-title-text-align                          : center !default;

// 00.9 Product Photo settings
$ts-product-photo-overlay                                    : true !default;
$ts-product-photo-overlay-opacity                            : 1 !default;
$ts-product-photo-overlay-color                              : rgba($white, 0.03) !default;

// 00.10 Stock label
$ts-stock-label-font-size                                    : rem-calc(13) !default;
$ts-stock-label-font-family                                  : $header-font-family !default;
$ts-stock-label-font-weight                                  : inherit !default;
$ts-stock-label-uppercase                                    : none !default;
$ts-stock-label-font-color                                   : rgba($body-font-color, .9) !default;
$ts-stock-label-icon-bg-size                                 : 13px auto !default;
$ts-stock-label-icon-left-indent                             : 20px !default;
$ts-stock-label-icon-vt-spacing                              : .25rem 0 !default;

$ts-stock-label-icon-available                               : 'icon-valid-black' !default;
$ts-stock-label-icon-available-type                          : svg !default;
$ts-stock-label-icon-available-bg-size                       : 14px auto !default;
$ts-stock-label-icon-available-color                         : $ts-stock-label-font-color !default;

$ts-stock-label-icon-unavailable                             : 'icon-unavailable' !default;
$ts-stock-label-icon-unavailable-type                        : svg !default;
$ts-stock-label-icon-unavailable-bg-size                     : 14px auto !default;
$ts-stock-label-icon-unavailable-color                       : lighten($body-font-color, 50) !default;

$ts-stock-label-icon-backorder                               : 'icon-stock-delivery' !default;
$ts-stock-label-icon-backorder-type                          : png !default;
$ts-stock-label-icon-backorder-bg-size                       : 17px 17px !default;
$ts-stock-label-icon-backorder-color                         : $ts-stock-label-font-color !default;

//00.11 Lists
$ts-custom-ordered-lists                                     : true !default;
$ts-custom-unordered-lists                                   : true !default;
$ts-custom-unordered-lists-bullet                            : '○' !default;

//00.12 Titles
$ts-common-titles-border-bottom                              : true !default;
$ts-common-titles-padding-bottom                             : (small: .75rem,  large: .75rem) !default;
$ts-common-titles-margin-bottom                              : (small: 1.75rem, large: 2.75rem) !default;
$ts-common-titles-margin-top                                 : (small: 1.5rem,  large: 0) !default;
$ts-common-titles-product-padding-bottom                     : (small: .75rem,  large: .25rem) !default;

// 01. Footer settings
$ts-footer-view                                              : half-width !default;  // possible values: half-width or full-width
$ts-footer-full-width-block-alignment                        : uniform !default;  // possible values: uniform or non-uniform, work only with 'full-width' variable
$ts-footer-bg-has-image                                      : true !default;
$ts-footer-bg-custom-set                                     : false !default;  // possible values: true, false, true - disable $ts-footer-bg-position
$ts-footer-bg-src                                            : ( small : 'footer_bg', xlarge : 'footer_bg') !default;
$ts-footer-bg-type                                           : ( small : jpg, xlarge : jpg) !default; // possible values: svg, jpg, png, none
$ts-footer-bg-size                                           : ( small : cover, xlarge : cover) !default;
$ts-footer-bg-position                                       : ( small : 70% 50%, xlarge : 50% 100%) !default;
$ts-footer-bg-color                                          : $black !default;
$ts-footer-indents-vt                                        : ( small : 1, medium: .75, xxlarge : 1.9) !default;
$ts-footer-block-indents-vt                                  : ( small : 1.25, medium: 1, xxlarge : 1.1) !default;
$ts-footer-bottom-block-indents-vt                           : ( small : .75, medium: .8, xxlarge : 1, xxlarge-top : 3.25) !default;
$ts-footer-bottom-bg-color                                   : transparent !default;
$ts-footer-font-size                                         : rem-calc(12) !default;
$ts-footer-font-line-height                                  : 1.2 !default;
$ts-footer-font-color                                        : $white !default;
$ts-footer-font-family                                       : inherit !default;

$ts-footer-bottom-border                                    : true !default; // can be true or false
$ts-footer-bottom-border-height                             : 12px !default;
$ts-footer-bottom-border-height-mobile                      : 6px !default;
$ts-footer-bottom-border-bg                                 : linear-gradient(90deg, #ce3d95,#0dc1c9) !default;

$ts-footer-header-font-size                                  : rem-calc(20) !default;
$ts-footer-header-font-color                                 : $ts-footer-font-color !default;
$ts-footer-header-font-weight                                : 300 !default;
$ts-footer-header-text-transform                             : uppercase !default;
$ts-footer-header-font-family                                : $header-font-family !default;
$ts-footer-header-font-style                                 : normal !default;
$ts-footer-header-margin-bottom                              : .5rem !default;
$ts-footer-header-last-decrease                              : true !default;

$ts-footer-border                                            : true !default;
$ts-footer-border-width                                      : 1px !default;
$ts-footer-border-style                                      : dashed !default;
$ts-footer-border-color                                      : scale-color($white, $alpha: -85%) !default;

// 01.1 Footer Navigation settings
$ts-footer-nav-text-transform                                : none !default;
$ts-footer-nav-anchor-color                                  : $white !default;
$ts-footer-nav-font-family                                   : inherit !default;
$ts-footer-nav-font-weight                                   : 300 !default;
$ts-footer-nav-anchor-color-hover                            : $white !default;
$ts-footer-nav-anchor-underline                              : none !default;
$ts-footer-nav-anchor-underline-hover                        : underline !default;
$ts-footer-nav-anchor-padding                                : (.1rem 0 .1rem) !default;

// 01.2 Footer Cookies settings
$ts-footer-cookies-text-align                                : right !default;
$ts-footer-cookies-color                                     : rgba($white, .4) !default;
$ts-footer-cookies-anchor-color                              : rgba($white, .4) !default;
$ts-footer-cookies-magento-anchor-color                      : $ts-footer-cookies-color !default;
$ts-footer-cookies-font-size                                 : rem-calc(11) !default;
$ts-footer-cookies-icon-type                                 : none !default; // possible values: svg, png, none. If set to none, no icon will be shown at all.
$ts-footer-cookies-icon-size                                 : rem-calc(16) !default;
$ts-footer-cookies-icon-bg-size                              : $ts-footer-cookies-icon-size !default;
$ts-footer-cookies-anchor-text-decoration                    : underline !default;
$ts-footer-cookies-anchor-text-decoration-hover              : none !default;

// 01.3 Footer Subscribe settings
$ts-footer-suscribe-text-align                               : left !default;
$ts-footer-input-place                                       : inline !default; // possible inline or block
$ts-footer-input-border                                      : only-bottom !default; // possible only-bottom or true
$ts-footer-desc-bottom-indent                                : .5rem !default;
$ts-footer-desc-font-size                                    : inherit !default;
$ts-footer-desc-font-weight                                  : inherit !default;
$ts-footer-desc-font-family                                  : inherit !default;
$ts-footer-desc-line-height                                  : inherit !default;
$ts-footer-subscribe-max-width                               : rem-calc(350) !default;
$ts-footer-subscribe-input-height                            : ( small : rem-calc(42), xlarge : rem-calc(48)) !default;
$ts-footer-subscribe-input-spacing                           : .75rem 1rem !default;
$ts-footer-subscribe-input-bottom-indent                     : 1rem !default;
$ts-footer-subscribe-input-bg                                : transparent !default;
$ts-footer-subscribe-input-color                             : $white !default;
$ts-footer-subscribe-input-placeholder-color                 : $white !default;
$ts-footer-subscribe-input-border-color                      : rgba($white, .5) !default;
$ts-footer-subscribe-input-border-bottom-color               : $medium-gray !default;
$ts-footer-subscribe-input-font-size                         : rem-calc(12) !default;
$ts-footer-subscribe-input-font-weight                       : 300 !default;
$ts-footer-subscribe-input-font-family                       : $body-font-family !default;
$ts-footer-subscribe-input-text-align                        : left !default;
$ts-footer-subscribe-input-text-transform                    : none !default;
$ts-footer-subscribe-center                                  : false !default;

$ts-terms-checkbox-position                                  : 2px !default;

$ts-terms-label-color                                        : $ts-footer-font-color !default;
$ts-terms-label-font-size                                    : $ts-footer-desc-font-size !default;
$ts-terms-label-font-weight                                  : 400 !default;
$ts-terms-label-line-height                                  : inherit !default;
$ts-terms-label-cursor                                       : default !default;

$ts-terms-link-color                                         : $ts-footer-font-color !default;
$ts-terms-link-decoration                                    : none !default;
$ts-terms-link-decoration-hover                              : underline !default;

$ts-footer-subscribe-input-focus-color                       : $white !default;
$ts-footer-subscribe-input-focus-bg                          : rgba($white, .1) !default;

$ts-footer-button-view                                       : icon !default; // possible icon or text for inline form view, or with-icon for block form view
$ts-footer-button-bg-view                                    : hollow !default;
$ts-footer-button-color                                      : inherit !default;
$ts-footer-button-color-hover                                : inherit !default;
$ts-footer-button-font-size                                  : inherit !default;
$ts-footer-button-font-weight                                : inherit !default;
$ts-footer-button-font-style                                 : inherit !default;
$ts-footer-button-text-transform                             : none !default;
$ts-footer-button-border                                     : 1px solid $ts-main-dark-border-color !default;
$ts-footer-button-bg                                         : transparent !default;
$ts-footer-button-bg-hover                                   : transparent !default;
$ts-footer-button-icon                                       : "icon-subscribe-arrow" !default;
$ts-footer-button-icon-type                                  : svg !default;
$ts-footer-button-icon-width                                 : rem-calc(28) !default;
$ts-footer-button-icon-height                                : rem-calc(10) !default;
$ts-footer-button-icon-indent                                : rem-calc(14) !default;

// 01.4 Footer Contact settings
$ts-footer-contact-tel-font-size                             : rem-calc(18) !default;
$ts-footer-contact-tel-font-weight                           : 700 !default;
$ts-footer-contact-tel-font-family                           : $header-font-family !default;
$ts-footer-contact-tel-color                                 : $white !default;
$ts-footer-contact-tel-icon                                  : 'icon-phone-white' !default;
$ts-footer-contact-tel-icon-height                           : 14px !default;
$ts-footer-contact-tel-icon-type                             : svg !default; // possible values: svg, png, none. If set to none, no icon will be shown at all.
$ts-footer-contact-tel-icon-position                         : 0 50% !default;
$ts-footer-contact-tel-padding-left                          : rem-calc(22) !default;
$ts-footer-contact-email-color                               : $white !default;
$ts-footer-contact-email-text-decoration                     : underline !default;
$ts-footer-contact-email-text-decoration-hover               : none !default;
$ts-footer-contact-email-font-weight                         : 300 !default;

// 01.5 Footer Social settings
$ts-footer-social-text-align                                 : left !default;
$ts-footer-social-anchor-color                               : #322b34 !default;
$ts-footer-social-anchor-color-hover                         : $black !default;
$ts-footer-social-icon-type                                  : svg !default; // possible values: svg, png, none. If set to none, no icons will be shown at all.
$ts-footer-social-icon-networks                              : (facebook, instagram, twitter, pinterest, youtube, tiktok) !default;
$ts-footer-social-icon-bg-color                              : transparent !default;
$ts-footer-social-icon-bg-color-hover                        : transparent !default;
$ts-footer-social-right-indent                               : 1rem !default;
$ts-footer-social-icon-opacity                               : .75  !default;
$ts-footer-social-icon-opacity-hover                         : 1  !default;
$ts-footer-social-icon-size                                  : rem-calc(42) !default;
$ts-footer-social-icon-bg-size                               : 100% !default;
$ts-footer-social-icon-shape                                 : round !default; // possible values: round, rounded. Any other value will result in no border-radius set.
$ts-footer-social-icon-border-width                          : 0 !default;
$ts-footer-social-icon-border-style                          : solid !default;
$ts-footer-social-icon-border-color                          : $light-gray !default;

// 01.6 Footer Payment settings
$ts-footer-payment-text-align-small                          : center !default;

// 01.7 Footer Copyright settings
$ts-footer-copyright-color                                   : rgba($white, .4) !default;
$ts-footer-copyright-font-size                               : rem-calc(11) !default;

// 01.8 Footer Response settings
$ts-footer-response-bg                                       : transparent !default;
$ts-footer-response-bg-error                                 : $white !default;
$ts-footer-response-color                                    : $white !default;
$ts-footer-response-font-size                                : rem-calc(12) !default;
$ts-footer-response-border-color                             : $white !default;
$ts-footer-response-icon                                     : "icon-valid-white" !default;
$ts-footer-response-icon-type                                : svg !default;

$ts-footer-response-error-border-color                       : map-get($foundation-palette, alert) !default;
$ts-footer-response-error-icon                               : "icon-invalid" !default;
$ts-footer-response-error-icon-type                          : svg !default;

// 01.8 USP section
$ts-usp-font-color                                           : rgba($body-font-color,.75) !default;
$ts-usp-font-size                                            : rem-calc(18) !default;
$ts-usp-font-family                                          : $header-font-family !default;
$ts-usp-font-weight                                          : 300 !default;
$ts-usp-item-bottom-spacing                                  : .75rem !default;
$ts-usp-item-text-align                                      : default !default; // if value == default, property will not be rendered
$ts-usp-item-icon-indent                                     : .75rem !default;
$ts-usp-item-icon-top-indent                                 : .5rem !default;
$ts-usp-item-icon                                            : 'icon-usp' !default;
$ts-usp-item-icon-type                                       : svg !default;  // possible values: svg, png, none. If set to none, no icons will be shown at all.
$ts-usp-item-icon-width                                      : rem-calc(17) !default;
$ts-usp-item-icon-height                                     : rem-calc(12) !default;
$ts-usp-item-description-offset                              : 0 !default;
$ts-usp-item-description-font-size                           : $ts-usp-font-size !default;


// 02. Header settings
$ts-headbar-style                                            : logo-left !default; // can be logo-left and logo-center
$ts-headbar-style-extended                                   : false !default; // only for logo-left

$ts-headbar-top-border                                       : true !default; // can be true or false
$ts-headbar-top-border-height                                : rem-calc(6) !default;
$ts-headbar-top-border-height-mobile                         : rem-calc(3) !default;
$ts-headbar-top-border-height-stuck                          : rem-calc(2) !default;
$ts-headbar-top-border-bg                                    : linear-gradient(90deg, #ce3d95,#0dc1c9) !default;
$ts-headbar-height                                           : ( small : rem-calc(66),
                                                                 large : rem-calc(140),
                                                                 xlarge : rem-calc(140),
                                                                 xxlarge : rem-calc(110),
                                                                 xxxlarge : rem-calc(140) ) !default;
$ts-headbar-bg-color                                         : $white !default;
$ts-headbar-bg-image                                         : none !default;
$ts-headbar-bg-image-type                                    : none !default; // png, jpg, none
$ts-headbar-bg-repeat                                        : no-repeat !default;
$ts-headbar-bg-position                                      : 0 !default;
$ts-headbar-bg-size                                          : auto !default;
$ts-headbar-zindex                                           : 899 !default;
$ts-headbar-shadow-on-fixed                                  : none !default;
$ts-headbar-text-color                                       : scale-color($body-font-color, $alpha: -50%) !default;

$ts-headbar-logo-width                                       : 180px !default;
$ts-headbar-logo-mobile-height                               : auto !default; // size, auto
$ts-headbar-logo-align                                       : left !default;
$ts-headbar-logo-width-top                                   : 1.75rem !default;

// 02.0 Sticky Header
$ts-sticky-headbar-height                                    : rem-calc(55) !default;
$ts-sticky-headbar-box-shadow                                : 0 0 5px rgba(#000, .3), 0 0 15px rgba(#000, .15) !default; // none or value
$ts-sticky-scroll-headbar-box-shadow                         : 0 0 5px rgba(#000, .3), 0 0 15px rgba(#000, .15) !default; // none or value
$ts-sticky-headbar-height-spacing-top                        : rem-calc(7) !default;
$ts-sticky-headbar-height-spacing-bottom                     : rem-calc(5) !default;
$ts-sticky-headbar-height-spacing                            : (($ts-sticky-headbar-height-spacing-top + $ts-sticky-headbar-height-spacing-bottom)/2) !default;

$ts-sticky-headbar-tablet-search-width                       : 100% !default;
$ts-sticky-headbar-tablet-search-spacing                     : rem-calc(10 14 11) !default;
$ts-sticky-headbar-tablet-search-bg                          : #f0f0f0 !default;

// 02.1 Top links
$ts-headbar-anchor-color                                     : scale-color($body-font-color, $alpha: -20%) !default;
$ts-headbar-anchor-size                                      : rem-calc(12) !default;
$ts-headbar-anchor-font-weight                               : 300 !default;
$ts-top-links-bottom-indent                                  : ( large : .9rem,
                                                                 xlarge : .9rem,
                                                                 xxlarge : -.6rem,
                                                                 xxxlarge : .75rem ) !default;
$ts-top-links-anchor-font-size                               : rem-calc(12) !default;
$ts-top-links-anchor-font-family                             : $body-font-family !default;
$ts-top-links-anchor-font-weight                             : inherit !default;
$ts-top-links-anchor-line-height                             : 1.6 !default;
$ts-top-links-anchor-text-transform                          : none !default;
$ts-top-links-anchor-text-decoration                         : none !default;
$ts-top-links-anchor-text-decoration-hover                   : underline !default;
$ts-top-links-anchor-text-shadow                             : none !default;
$ts-top-links-anchor-padding                                 : rem-calc(10) !default;
$ts-top-links-placement                                      : right !default; //can be normal or right

$ts-top-links-user-icon-width                                : 15px !default;
$ts-top-links-user-icon-height                               : 13px !default;
$ts-top-links-user-icon                                      : "icon-user-account" !default;
$ts-top-links-user-icon-type                                 : svg !default;

// 02.2 Header Search
$ts-header-search-mobile-parent                              : "sticky-mm" !default; // can be 'sticky-mm' or 'mobile-search-wrapper'
$ts-header-search-mobile-sticky                              : false !default;
$ts-header-search-mobile-sticky-top                          : rem-calc(8) !default;
$ts-header-search-mobile-sticky-left                         : rem-calc(60) !default;
$ts-header-search-mobile-sticky-right                        : rem-calc(64) !default;
$ts-header-search-mobile-sticky-hide-from                    : "large" !default;

$ts-header-search-form-padding-top                           : ( large : 1.45rem,
                                                                 xlarge : 1.45rem,
                                                                 xxlarge : 1rem,
                                                                 xxxlarge : .5rem ) !default;
$ts-header-search-form-medium-margin-top                     : ( large : -7rem,
                                                                 xlarge : -6.75rem,
                                                                 xxlarge : -4.2rem,
                                                                 xxxlarge : 0 ) !default;

$ts-header-search-input-indent                               : 3rem !default;
$ts-header-search-input-left-indent                          : 1rem !default;
$ts-header-search-input-left-indent-mobile                   : 1rem !default;
$ts-header-search-font-family                                : $header-font-family !default;
$ts-header-search-font-size                                  : rem-calc(13) !default;
$ts-header-search-font-weight                                : 300 !default;
$ts-header-search-input-focus-border                         : 1px solid $ts-main-border-color !default;
$ts-header-search-input-background-color                     : lighten(#f0f0f0, 2.7451) !default;
$ts-header-search-input-border-bottom-color                  : lighten(#f0f0f0, 2.7451) !default;
$ts-header-search-input-focus-one-line-border                : true !default;
$ts-header-search-input-focus-border-color                   : $primary-color !default;
$ts-header-search-input-focus-border-bottom-color            : $primary-color !default;
$ts-header-search-input-height                               : rem-calc(42) !default;
$ts-header-search-input-color                                : $body-font-color !default;
$ts-header-search-input-text-transform                       : uppercase !default;
$ts-header-search-input-icon                                 : 'icon-search' !default;
$ts-header-search-input-icon-type                            : svg !default;
$ts-header-search-input-icon-opacity                         : 1 !default;
$ts-header-search-input-icon-width                           : rem-calc(22) !default;
$ts-header-search-input-icon-btn-bg-position                 : 0 50% !default;
$ts-header-search-input-icon-right-indent                    : .5rem !default;
$ts-header-search-input-icon-padding-indent                  : rem-calc(5) !default;
$ts-header-search-input-icon-bg-position                     : $input-state-icon-position !default;

// 02.3 Header Cart
// 02.3.1 Header Cart Button
$ts-header-cart-dropdown-showcart-type                       : image-text !default; // can be image-only, image-text
$ts-header-cart-dropdown-showcart-icon                       : 'icon-cart'  !default;
$ts-header-cart-dropdown-showcart-icon-active                : 'icon-cart-active'  !default;
$ts-header-cart-dropdown-showcart-icon-hover                 : 'icon-cart-active'  !default;
$ts-header-cart-dropdown-showcart-icon-type                  : svg !default;
$ts-header-cart-dropdown-showcart-icon-width                 : rem-calc(24)  !default;
$ts-header-cart-dropdown-showcart-icon-height                : rem-calc(34)  !default;
$ts-header-cart-dropdown-showcart-icon-spacing               : 0 1.5rem 0 1.75rem  !default;

$ts-header-cart-dropdown-showcart-text-color                 : $body-font-color !default;
$ts-header-cart-dropdown-showcart-text-size                  : inherit !default;
$ts-header-cart-dropdown-showcart-text-weight                : inherit !default;
$ts-header-cart-dropdown-showcart-text-family                : inherit !default;
$ts-header-cart-dropdown-showcart-text-transform             : uppercase !default;
$ts-header-cart-dropdown-showcart-text-line-height           : rem-calc(42) !default;
$ts-header-cart-dropdown-showcart-text-indent-cart-left      : rem-calc(16) !default;
$ts-header-cart-dropdown-showcart-text-indent-cart-right     : rem-calc(16) !default;
$ts-header-cart-dropdown-showcart-text-padding-right         : rem-calc(22) !default;
$ts-header-cart-dropdown-showcart-bg-color                   : $primary-color !default;
$ts-header-cart-dropdown-showcart-bg-color-hover             : $primary-color !default;
$ts-header-cart-dropdown-showcart-stuck-bg-color             : inherit !default;
$ts-header-cart-dropdown-showcart-stuck-bg-color-hover       : inherit !default;
$ts-header-cart-dropdown-showcart-text-hover-color           : $white !default;
$ts-header-cart-dropdown-showcart-has-border                 : false !default;
$ts-header-cart-dropdown-showcart-border                     : 1px solid $ts-main-border-color !default;
$ts-header-cart-dropdown-showcart-border-hover               : 1px solid $ts-main-border-color !default;

$ts-header-cart-button-count-size                            : rem-calc(14) !default;
$ts-header-cart-button-count-shape                           : round !default; // Round, rounded
$ts-header-cart-button-count-font-size                       : rem-calc(11) !default;
$ts-header-cart-button-count-font-weight                     : 400 !default;
$ts-header-cart-button-count-font-family                     : inherit !default;
$ts-header-cart-button-count-bg-color                        : $black !default;
$ts-header-cart-button-count-color                           : $white !default;
$ts-header-cart-button-count-box-shadow                      : 0 0 0 2px $white !default;
$ts-header-cart-dropdown-showcart-icon-image-sticky-bottom   : 10px !default;

// 02.3.2 Header Cart Dropdown
$ts-header-cart-dropdown-width                               : rem-calc(400) !default;
$ts-header-cart-dropdown-z-index                             : 20 !default;
$ts-header-cart-dropdown-bg-color                            : white !default;
$ts-header-cart-dropdown-box-shadow                          : (0 0 25px rgba($black,0.15)) !default; // Value or none
$ts-header-cart-dropdown-position-top                        : 100% !default;
$ts-header-cart-dropdown-position-top-indent                 : rem-calc(10) !default;
$ts-header-cart-dropdown-position-right                      : 0 !default;

$ts-header-cart-dropdown-subtitle-empty-size                 : 1rem !default;
$ts-header-cart-dropdown-subtitle-empty-color                : $primary-color !default;
$ts-header-cart-dropdown-subtitle-empty-weight               : 300 !default;

$ts-header-cart-dropdown-close-position-right                : rem-calc(10) !default;
$ts-header-cart-dropdown-close-position-top                  : rem-calc(7) !default;
$ts-header-cart-dropdown-close-font-size                     : 1rem !default;
$ts-header-cart-dropdown-close-color                         : $secondary-color !default;

// 02.3.3 Header AddToCart Popup
$ts-addtocart-popup-view                                     : 'default' !default;
$ts-addtocart-popup-width                                    : ( large : rem-calc(700), xxlarge : rem-calc(770) ) !default;
$ts-addtocart-popup-padding                                  : ( small : 1.25rem, large : 2.5rem 3rem 8rem ) !default;
$ts-addtocart-popup-mssg-font-family                         : $header-font-family !default;
$ts-addtocart-popup-mssg-font-weight                         : 300 !default;
$ts-addtocart-popup-mssg-line-height                         : 1 !default;
$ts-addtocart-popup-mssg-font-size                           : ( small : rem-calc(20), xxlarge : rem-calc(32)) !default;
$ts-addtocart-popup-mssg-letter-spacing                      : $header-letter-spacing !default;
$ts-addtocart-popup-mssg-bottom-indent                       : 1.5rem !default;
$ts-addtocart-popup-mssg-img-maxwidth                        : rem-calc(100) !default;
$ts-addtocart-popup-footer-bg                                : $box-color !default;
$ts-addtocart-popup-footer-padding                           : 2rem 2.5rem !default;

$ts-addtocart-popup-footer-btn-arrow                         : "icon-arrow-next-white" !default;
$ts-addtocart-popup-footer-btn-arrow-type                    : svg !default;
$ts-addtocart-popup-footer-btn-arrow-width                   : 7px !default;
$ts-addtocart-popup-footer-btn-arrow-height                  : 12px !default;

// 02.4 Top Bar Navigation
// 02.4.1 Top Bar Nav Home link
$ts-primary-nav-height                                       : inherit !important !default;
$ts-primary-nav-home-url-icon-replace                        : hide !default; // Home link text is replaced with home icon, can be hide or show
$ts-primary-nav-home-url-icon                                : "icon-home-dark" !default;
$ts-primary-nav-home-url-icon-width                          : rem-calc(12px) !default;
$ts-primary-nav-home-url-icon-height                         : rem-calc(14px) !default;
$ts-primary-nav-home-url-icon-type                           : svg !default; // Available types: svg, png
$ts-primary-nav-home-canvas-url-icon                         : "icon-home-white" !default;
$ts-primary-nav-home-canvas-url-icon-type                    : svg !default;

$ts-headbar-main-menu-anchor-color                           : $body-font-color !default;
$ts-headbar-main-menu-anchor-underline                       : none !default;
$ts-headbar-main-menu-font-family                            : $header-font-family !default;
$ts-headbar-main-menu-font-size                              : ( medium : rem-calc(13), xlarge : rem-calc(13), xxlarge : rem-calc(13) ) !default;
$ts-headbar-main-menu-font-weight                            : 600 !default;
$ts-headbar-main-menu-text-transform                         : uppercase !default;

$ts-headbar-main-menu-featured-font-family                   : $ts-headbar-main-menu-font-family;
$ts-headbar-main-menu-featured-font-weight                   : $ts-headbar-main-menu-font-weight;
$ts-headbar-main-menu-featured-font-style                    : inherit;
$ts-headbar-main-menu-featured-font-color                    : $menu-item-color-active;
$ts-headbar-main-menu-featured-bg-color                      : $menu-item-background-active;

$ts-primary-nav-dd                                           : true !default;
$ts-primary-nav-dd-icon                                      : "icon-submenu-down" !default;
$ts-primary-nav-dd-icon-active                               : "icon-submenu-down-active" !default;
$ts-primary-nav-dd-icon-width                                : 7px !default;
$ts-primary-nav-dd-icon-height                               : 5px !default;
$ts-primary-nav-dd-icon-type                                 : svg !default; // Available types: svg, png
$ts-primary-nav-dd-icon-indent                               : .25rem !default;

// 02.4.2 Dropdown
$ts-header-menu-dropdown-z-index                             : 10 !default;
$ts-header-menu-dropdown-box-shadow                          : 0 10px 15px rgba($black, .1) !default;
$ts-header-menu-dropdown-background-color                    : $white !default;
$ts-header-menu-dropdown-right-background-color              : #f0f0f0 !default;
$ts-header-menu-dropdown-padding                             : rem-calc(30 38 30 35) !default;

$ts-header-menu-dropdown-link-font-size                      : rem-calc(13) !default;
$ts-header-menu-dropdown-link-font-weight                    : 300 !default;
$ts-header-menu-dropdown-link-font-style                     : normal !default;
$ts-header-menu-dropdown-link-padding                        : rem-calc(7 35 7 3) !default;
$ts-header-menu-dropdown-link-transform                      : none !default;
$ts-header-menu-dropdown-link-all-bottom-spacing             : rem-calc(8) !default;
$ts-header-menu-dropdown-link-font-family                    : $body-font-family !default;
$ts-header-menu-dropdown-link-color                          : $body-font-color !default;
$ts-header-menu-dropdown-link-color-hover                    : darken(desaturate(adjust-hue($primary-color, -18), 27.79), 6.27) !default;
$ts-header-menu-dropdown-link-color-hover-underline          : underline !default;
$ts-header-menu-dropdown-link-image                          : false !default; // Possible values: icon-name, false
$ts-header-menu-dropdown-link-image-type                     : svg !default; // Possible values: png, svg
$ts-header-menu-dropdown-link-image-width                    : 6px !default;
$ts-header-menu-dropdown-link-image-height                   : 10px !default;
$ts-header-menu-dropdown-link-image-opacity                  : .5 !default;

$ts-header-menu-dropdown-link-all-font-size                  : rem-calc(13) !default;
$ts-header-menu-dropdown-link-all-font-weight                : 300 !default;
$ts-header-menu-dropdown-link-all-padding                    : rem-calc(14 35 14 0) !default;
$ts-header-menu-dropdown-link-all-font-family                : $header-font-family !default;
$ts-header-menu-dropdown-link-all-color                      : $body-font-color !default;
$ts-header-menu-dropdown-link-all-border                     : 1px dashed $medium-gray !default;
$ts-header-menu-dropdown-link-all-transform                  : none !default;

$ts-headbar-main-menu-width                                  : ( medium : 600px, xlarge : 550px, xxlarge : 680px ) !default; // 680
$ts-headbar-main-menu-bg                                     : $white !default;

$ts-header-menu-dropdown-category-left-width                 : 38.5% !default;
$ts-header-menu-dropdown-category-left-spacing               : rem-calc(23 46 35 42) !default;
$ts-header-menu-dropdown-category-left-link-font-family      : inherit !default;
$ts-header-menu-dropdown-category-left-link-font-size        : rem-calc(14) !default;
$ts-header-menu-dropdown-category-left-link-font-weight      : 300 !default;
$ts-header-menu-dropdown-category-left-link-transform        : none !default;

$ts-header-menu-dropdown-category-static-font-size           : rem-calc(17) !default;
$ts-header-menu-dropdown-category-static-font-family         : $header-font-family !default;
$ts-header-menu-dropdown-category-static-font-weight         : 300 !default;
$ts-header-menu-dropdown-category-static-color               : $body-font-color !default;
$ts-header-menu-dropdown-category-static-border              : 1px dashed $medium-gray !default;
$ts-header-menu-dropdown-category-static-indent              : 1rem !default;
$ts-header-menu-dropdown-category-static-talign              : center !default;
$ts-header-menu-dropdown-category-static-link-size           : rem-calc(14) !default;
$ts-header-menu-dropdown-category-static-link-underline      : underline !default;
$ts-header-menu-dropdown-category-static-link-font-family    : $body-font-family !default;
$ts-header-menu-dropdown-category-static-link-indent         : 1rem !default;
$ts-header-menu-dropdown-category-static-link-arr            : true !default;
$ts-header-menu-dropdown-category-static-link-arr-icon       : "icon-arrow-next-gray" !default;
$ts-header-menu-dropdown-category-static-link-arr-type       : svg !default; // Possible values: png, svg
$ts-header-menu-dropdown-category-static-link-arr-width      : 6px !default;
$ts-header-menu-dropdown-category-static-link-arr-height     : 10px !default;
$ts-header-menu-dropdown-category-static-link-arr-indent     : 5px !default;

$ts-header-menu-dropdown-category-right-list-inline          : true !default;
$ts-header-menu-dropdown-category-right-width                : 61.5% !default;
$ts-header-menu-dropdown-category-right-spacing              : rem-calc(40 30) !default;
$ts-header-menu-dropdown-category-right-font-size            : rem-calc(17) !default;
$ts-header-menu-dropdown-category-right-font-family          : $header-font-family !default;
$ts-header-menu-dropdown-category-right-font-weight          : 300 !default;
$ts-header-menu-dropdown-category-right-color                : $body-font-color !default;
$ts-header-menu-dropdown-category-right-indent               : 1.5rem !default;
$ts-header-menu-dropdown-category-right-talign               : center !default;
$ts-header-menu-dropdown-category-right-title-indent         : rem-calc(3) !default;
$ts-header-menu-dropdown-category-right-title-transform      : none !default;
$ts-header-menu-dropdown-category-right-line-height          : 1.2 !default;
$ts-header-menu-dropdown-category-right-link-font-size       : rem-calc(14) !default;
$ts-header-menu-dropdown-category-right-link-font-family     : inherit !default;
$ts-header-menu-dropdown-category-right-link-color           : $anchor-color !default;
$ts-header-menu-dropdown-category-right-link-underline       : underline !default;
$ts-header-menu-dropdown-category-right-link-underline-hover : none !default;
$ts-header-menu-dropdown-category-right-border               : 1px solid scale-color($black, $alpha: -98%) !default;
$ts-header-menu-dropdown-category-right-link-transform       : none !default;

$ts-header-menu-dropdown-category-menu-image-show            : true !default;
$ts-header-menu-dropdown-category-menu-image-height          : 7rem !default;
$ts-header-menu-dropdown-category-menu-image-bottom-spacing  : 1.25rem !default;

$ts-header-menu-dropdown-subcategory-has-separator           : false !default;
$ts-header-menu-dropdown-subcategory-has-arrow               : false !default;
$ts-header-menu-dropdown-subcategory-separator-color         : rgba($ts-main-border-color, .1) !default;
$ts-header-menu-dropdown-subcategory-padding                 : 2.25rem 1.25rem !default;
$ts-header-menu-dropdown-subcategory-link-font-size-title    : rem-calc(13) !default;
$ts-header-menu-dropdown-subcategory-link-font-weight-title  : 600 !default;
$ts-header-menu-dropdown-subcategory-link-font-family-title  : $header-font-family !default;
$ts-header-menu-dropdown-subcategory-link-font-style-title   : normal !default;
$ts-header-menu-dropdown-subcategory-link-transform-title    : uppercase !default;
$ts-header-menu-dropdown-subcategory-link-inner-indent-title : 0 !default;
$ts-header-menu-dropdown-subcategory-link-outer-indent-title : .75rem !default;
$ts-header-menu-dropdown-subcategory-link-font-size          : rem-calc(13) !default;
$ts-header-menu-dropdown-subcategory-link-font-weight        : 300 !default;
$ts-header-menu-dropdown-subcategory-link-font-family        : inherit !default;
$ts-header-menu-dropdown-subcategory-link-text-transform     : none !default;
$ts-header-menu-dropdown-subcategory-link-inner-indent       : .3rem 0 .3rem 0 !default;
$ts-header-menu-dropdown-subcategory-link-font-color         : rgba($body-font-color, .8) !default;
$ts-header-menu-dropdown-subcategory-link-font-color-hover   : darken(desaturate(adjust-hue($primary-color, -18), 27.79), 6.27) !default;
$ts-header-menu-dropdown-subcategory-link-font-hover-underline   : underline !default;

// 02.4.3 Navbar Extra
$ts-menu-extra-border-color                                  : scale-color($body-font-color, $alpha: -85%) !default;
$ts-menu-extra-font-weight                                   : 300 !default;
$ts-menu-extra-color                                         : scale-color($body-font-color, $alpha: -30%) !default;

// 02.5 Breadcrumbs
$ts-breadcrumb-icon-img                                      : "icon-arrow-next-gray" !default;
$ts-breadcrumb-icon-type                                     : svg !default; // Possible values: png, svg
$ts-breadcrumb-link-underline                                : none !default;
$ts-breadcrumb-link-hover-underline                          : underline !default;
$ts-breadcrumb-text-transform                                : none !default;
$ts-shipping-bar-top-indent                                  : 1.6rem !default;
$ts-shipping-bar-bottom-indent                               : ( small : 1rem, large : 1.5rem, xxlarge : 1.5rem) !default;
$ts-shipping-bar-bottom-indent-filter                        : ( small : 0, large : 1.5rem, xxlarge : 1.5rem) !default;

// 02.6 Snowdog Menu
// 02.6.1 Snowdog Menu Animations
$ts-snowdog-menu-transition-duration                         : 0.4s !default;
$ts-snowdog-menu-transition-function                         : ease !default;

// 02.6.2 Snowdog Menu Colors
$ts-snowdog-menu-background-color                            : darken(desaturate(adjust-hue($box-color, 30), 5.43), 4.12) !default;
$ts-snowdog-menu-border-color                                : $ts-main-border-color !default;
$ts-snowdog-menu-dimmed-text-color                           : $body-font-color !default;
$ts-snowdog-menu-common-emphasized-background-color          : rgba($white, 0) !default;
$ts-snowdog-menu-emphasized-text-color                       : $white !default;
$ts-snowdog-menu-emphasized-background-color                 : $primary-color !default;
$ts-snowdog-menu-emphasized-arrow-color                      : $white !default;
$ts-snowdog-menu-highlighted-text-color                      : inherit !default;
$ts-snowdog-menu-highlighted-background-color                : inherit !default;
$ts-snowdog-menu-highlighted-arrow-color                     : $ts-snowdog-menu-dimmed-text-color !default;
$ts-snowdog-menu-navbar-color                                : $body-font-color !default;
$ts-snowdog-menu-text-color                                  : $primary-color !default;

// 02.6.3 Snowdog Menu Positioning
$ts-snowdog-menu-offset-top                                  : 0 !default;
$ts-snowdog-menu-offset-right                                : 0 !default;
$ts-snowdog-menu-offset-bottom                               : 0 !default;
$ts-snowdog-menu-offset-left                                 : 0 !default;

// 02.6.4 Snowdog Menu Sizes
$ts-snowdog-menu-listitem-size                               : 52px !default;
$ts-snowdog-menu-btn-size                                    : 100% !default;
$ts-snowdog-menu-padding                                     : 10px !default;
$ts-snowdog-menu-line-height                                 : 24px !default;
$ts-snowdog-menu-panel-bottom-indent                         : 35px !default;

// 02.6.5 Snowdog Menu ListItem
$ts-snowdog-menu-listitem-font-size                          : 20px !default;
$ts-snowdog-menu-listitem-weight                             : normal !default;
$ts-snowdog-menu-listitem-style                              : normal !default;
$ts-snowdog-menu-listitem-font-family                        : $header-font-family !default;
$ts-snowdog-menu-listitem-line-height                        : normal !default;
$ts-snowdog-menu-listitem-indent                             : 28px !default;
$ts-snowdog-menu-navbar-size                                 : $ts-snowdog-menu-listitem-size * 2 !default;
$ts-snowdog-menu-panel-padding                               : $ts-snowdog-menu-padding * 2 !default;
$ts-snowdog-menu-subopen-width                               : $ts-snowdog-menu-btn-size !default;
$ts-snowdog-menu-subpanel-offset                             : 0% !default;

// 02.6.6 Snowdog Menu ListItem Secondary
$ts-snowdog-menu-listitem-secondary-font-size                : 17px !default;

// 02.6.7 Snowdog Menu Next-Prev Arrows
$ts-snowdog-menu-arrow-thick                                 : 2px !default;
$ts-snowdog-menu-arrow-width                                 : 8px !default;
$ts-snowdog-menu-arrow-height                                : 8px !default;
$ts-snowdog-menu-arrow-opacity                               : 0.5 !default;
$ts-snowdog-menu-arrow-indent                                : $ts-snowdog-menu-listitem-indent + 7 !default;
$ts-snowdog-menu-prev-width                                  : 50px !default;
$ts-snowdog-menu-prev-top-shift                              : 0 !default;
$ts-snowdog-menu-arrow-prev-width                            : 15px !default;
$ts-snowdog-menu-arrow-prev-height                           : 15px !default;
$ts-snowdog-menu-arrow-prev-color                            : $primary-color !default;
$ts-snowdog-menu-arrow-prev-opacity                          : 1 !default;
$ts-snowdog-menu-menu-width                                  : 0.8 !default;
$ts-snowdog-menu-menu-min-width                              : 240px !default;
$ts-snowdog-menu-menu-max-width                              : 440px !default;
$ts-snowdog-menu-menu-height                                 : 0.8 !default;
$ts-snowdog-menu-menu-min-height                             : 140px !default;
$ts-snowdog-menu-menu-max-height                             : 880px !default;
$ts-snowdog-menu-opt-screenreader	                         : true !default;
$ts-snowdog-menu-iconpanel-size                              : $ts-snowdog-menu-btn-size !default;
$ts-snowdog-menu-fullscreen-full                             : 1 !default;
$ts-snowdog-menu-fullscreen-min                              : 140px !default;
$ts-snowdog-menu-fullscreen-max                              : 10000px !default;

// 02.6.8 Snowdog Menu Top Panel Main Title
$ts-snowdog-menu-panel-title-size                            : 26px !default;
$ts-snowdog-menu-panel-title-weight                          : normal !default;
$ts-snowdog-menu-panel-title-style                           : normal !default;
$ts-snowdog-menu-panel-title-font-family                     : $header-font-family !default;
$ts-snowdog-menu-panel-title-color                           : $body-font-color !default;
$ts-snowdog-menu-panel-title-line-height                     : normal !default;
$ts-snowdog-menu-panel-title-top-shift                       : 0 !default;

// 02.6.9 Snowdog Menu Close Button
$ts-snowdog-menu-path-to-images                              : "../images" !default;
$ts-snowdog-menu-close-width                                 : 61px !default;
$ts-snowdog-menu-close-height                                : $ts-snowdog-menu-navbar-size !default;
$ts-snowdog-menu-close-icon-width                            : 21px !default;
$ts-snowdog-menu-close-icon                                  : "icon-close" !default;
$ts-snowdog-menu-close-icon-position                         : 14px 50% !default;
$ts-snowdog-menu-close-padding                               : 0 !default;
$ts-snowdog-menu-close-top                                   : 0 !default;
$ts-snowdog-menu-close-right                                 : 0 !default;
$ts-snowdog-menu-close-text-hide                             : true !default;
$ts-snowdog-menu-close-text-size                             : 13px !default;
$ts-snowdog-menu-close-text-weight                           : normal !default;
$ts-snowdog-menu-close-text-font-family                      : inherit !default;
$ts-snowdog-menu-close-text-line                             : normal !default;

// 02.6.10 Snowdog Menu Offcanvas Links
$ts-snowdog-menu-links-menu-inner-spacing                    : 0 0 0 28px !default;
$ts-snowdog-menu-links-menu-border                           : none !default;
$ts-snowdog-menu-links-menu-title-font-size                  : 24px !default;
$ts-snowdog-menu-links-menu-title-font-family                : inherit !default;
$ts-snowdog-menu-links-menu-title-font-weight                : inherit !default;
$ts-snowdog-menu-links-menu-title-color                      : inherit !default;
$ts-snowdog-menu-links-menu-title-margin-bottom              : 10px !default;
$ts-snowdog-menu-links-menu-title-transform                  : none !default;
$ts-snowdog-menu-links-menu-title-hide                       : true !default;
$ts-snowdog-menu-links-menu-item-font-size                   : 15px !default;
$ts-snowdog-menu-links-menu-item-font-family                 : $header-font-family !default;
$ts-snowdog-menu-links-menu-item-fon-weight                  : normal !default;
$ts-snowdog-menu-links-menu-item-color                       : inherit !default;
$ts-snowdog-menu-links-menu-item-padding                     : 0 0 17px !default;
$ts-snowdog-menu-links-menu-item-underline                   : none !default;
$ts-snowdog-menu-links-menu-item-transform                   : none !default;
$ts-snowdog-menu-links-menu-item-active-color                : inherit !default;

// 02.6.11 Snowdog Menu Includes
$ts-snowdog-menu-include-rtl: true !default;
$ts-snowdog-menu-include-vertical                            : true !default;
$ts-snowdog-menu-ie11-fallbacks                              : true !default;
$ts-snowdog-menu-include-offcanvas                           : true !default;
$ts-snowdog-menu-include-offcanvas_blocker                   : $ts-snowdog-menu-include-offcanvas !default;
$ts-snowdog-menu-include-fullscreen                          : true !default;

// 03. Mobile header
$ts-tabbar-menu-bg-color                                     : $ts-headbar-bg-color !default;
$ts-mobile-header-border-bottom                              : false !default;
$ts-mobile-header-border-bottom-width                        : 1px !default;
$ts-mobile-header-border-bottom-style                        : solid !default;
$ts-mobile-header-border-bottom-color                        : $ts-main-dark-border-color !default;

// 03.1 Off-canvas Mobile Navigation
$ts-foundation-offcanvas                                     : true !default;
$ts-offcanvas-zindex                                         : 1000 !default;
$ts-offcanvas-spacing-top                                    : rem-calc(30) !default;
$ts-offcanvas-spacing-side                                   : rem-calc(25) !default;
$ts-offcanvas-outer-spacing                                  : 1.5rem !default;

// 03.1.1 Offcanvas Main Title
$ts-offcanvas-title-font-size                                : rem-calc(40) !default;
$ts-offcanvas-title-font-family                              : $header-font-family !default;
$ts-offcanvas-title-font-weight                              : 300 !default;
$ts-offcanvas-title-font-style                               : normal !default;
$ts-offcanvas-title-bottom-indent                            : 1rem !default;
$ts-offcanvas-title-text-transform                           : none !default;
$ts-offcanvas-title-text-color                               : inherit !default;

// 03.1.2 Offcanvas Main Categories Menu
$ts-offcanvas-main-menu-item-border                          : 1px solid rgba($black, .1) !default;
$ts-offcanvas-main-menu-item-border-first                    : false !default;
$ts-offcanvas-main-menu-item-border-last                     : false !default;
$ts-offcanvas-main-menu-item-font-size                       : rem-calc(17) !default;
$ts-offcanvas-main-menu-item-font-family                     : $header-font-family !default;
$ts-offcanvas-main-menu-item-font-weight                     : 300 !default;
$ts-offcanvas-main-menu-item-link-color                      : $black !default;
$ts-offcanvas-main-menu-item-link-active-bg                  : $box-color !default;
$ts-offcanvas-main-menu-item-link-active-color               : $black !default;
$ts-offcanvas-main-menu-item-link-active-font-weight         : inherit !default;
$ts-offcanvas-main-menu-item-link-spacing                    : rem-calc(11) $ts-offcanvas-spacing-side !default;
$ts-offcanvas-main-menu-item-link-underline                  : none !default;
$ts-offcanvas-main-menu-item-link-arr-icon                   : "icon-arrow-next-gray" !default;
$ts-offcanvas-main-menu-item-link-arr-width                  : rem-calc(6) !default;
$ts-offcanvas-main-menu-item-link-arr-height                 : rem-calc(10) !default;
$ts-offcanvas-main-menu-item-link-arr-type                   : svg !default; // Possible values: png, svg, none
$ts-offcanvas-main-menu-item-link-arr-position               : ($ts-offcanvas-spacing-side - $ts-offcanvas-main-menu-item-link-arr-width) !default;
$ts-offcanvas-main-menu-item-text-transform                  : none !default;

// 03.1.3 Offcanvas Submenu of Main Categories Menu
$ts-offcanvas-main-submenu-spacing                           : rem-calc(7) $ts-offcanvas-spacing-side rem-calc(17) !default;
$ts-offcanvas-main-submenu-item-font-size                    : rem-calc(13) !default;
$ts-offcanvas-main-submenu-item-font-family                  : $body-font-family !default;
$ts-offcanvas-main-submenu-item-font-weight                  : 300 !default;
$ts-offcanvas-main-submenu-item-link-color                   : $black !default;
$ts-offcanvas-main-submenu-item-link-active-color            : $primary-color !default;
$ts-offcanvas-main-submenu-item-link-spacing                 : rem-calc(8 10 8 25) !default;
$ts-offcanvas-main-submenu-item-link-underline               : none !default;
$ts-offcanvas-main-submenu-item-arr-show                     : true !default;
$ts-offcanvas-main-submenu-item-link-arr-size                : 5px auto !default;
$ts-offcanvas-main-submenu-item-link-arr-position            : 9px 50% !default;
$ts-offcanvas-main-submenu-item-text-transform               : none !default;
$ts-offcanvas-main-submenu-item-bold-font-family             : $header-font-family !default;
$ts-offcanvas-main-submenu-item-bold-font-weight             : 400 !default;
$ts-offcanvas-main-submenu-item-bold-font-size               : rem-calc(17) !default;
$ts-offcanvas-main-submenu-item-bold-color                   : $ts-offcanvas-main-submenu-item-link-color !default;
$ts-offcanvas-main-submenu-item-bold-uppercase               : none !default;

// 03.1.4 Offcanvas Secondary Menu
$ts-offcanvas-secondary-menu-outer-spacing                   : rem-calc(23) !default;
$ts-offcanvas-secondary-menu-inner-spacing                   : $ts-offcanvas-secondary-menu-outer-spacing $ts-offcanvas-spacing-side rem-calc(20) !default;
$ts-offcanvas-secondary-menu-border                          : 1px solid scale-color($black, $alpha: -90%) !default;
$ts-offcanvas-secondary-menu-item-font-size                  : rem-calc(13) !default;
$ts-offcanvas-secondary-menu-item-font-family                : $body-font-family !default;
$ts-offcanvas-secondary-menu-item-font-weight                : 300 !default;
$ts-offcanvas-secondary-menu-item-link-color                 : lighten($body-font-color, 25) !default;
$ts-offcanvas-secondary-menu-item-link-active-color          : $primary-color !default;
$ts-offcanvas-secondary-menu-item-link-spacing               : rem-calc(2 0) !default;
$ts-offcanvas-secondary-menu-item-link-underline             : none !default;
$ts-offcanvas-secondary-menu-title-hide                      : false !default;
$ts-offcanvas-secondary-menu-title-font-size                 : rem-calc(18) !default;
$ts-offcanvas-secondary-menu-title-font-family               : $header-font-family !default;
$ts-offcanvas-secondary-menu-title-font-weight               : 300 !default;
$ts-offcanvas-secondary-menu-title-link-color                : darken($body-font-color, 9.4118) !default;
$ts-offcanvas-secondary-menu-title-indent                    : .5rem !default;
$ts-offcanvas-secondary-menu-item-text-transform             : none !default;
$ts-offcanvas-secondary-menu-title-text-transform            : none !default;
$ts-offcanvas-close-color                                    : rgba(darken($body-font-color, 9.4118),.7) !default;
$ts-offcanvas-close-text-transform                           : uppercase !default;
$ts-offcanvas-close-font-size                                : 1.5rem !default;
$ts-offcanvas-close-indent-left                              : 1.5rem !default;
$ts-offcanvas-close-indent-bottom                            : 2.5rem !default;

// 03.2 Mobile search
// 03.2.1 Mobile search bar
$ts-mobile-search-bar-box-shadow                             : (0 0 5px lighten($ts-headbar-bg-color, 50)) !default;
$ts-mobile-search-bar-bg-color                               : lighten($ts-headbar-bg-color, 88.2353) !default;

// 03.2.2 Mobile search bar field
$ts-mobile-search-field-height                               : rem-calc(47) !default;
$ts-mobile-search-field-width                                : auto !default;
$ts-mobile-search-field-color                                : $input-color;
$ts-mobile-search-field-font-size                            : 1rem !default;
$ts-mobile-search-field-border-width                         : 1px !default;
$ts-mobile-search-field-border-style                         : solid !default;
$ts-mobile-search-field-border-color                         : darken($medium-gray, 23.1373) !default;
$ts-mobile-search-field-margin                               : 0 !default;
$ts-mobile-search-field-padding                              : (0.5rem 3rem 0.5rem 1rem) !default;
$ts-mobile-search-field-bg                                   : $input-background !default;
$ts-mobile-search-field-placeholder-color                    : rgba($black, .5) !default;
$ts-mobile-search-field-text-transform                       : none !default;

// 03.2.3 Mobile search button
$ts-mobile-search-button-type                                : image !default; // Possible values: image, normal
$ts-mobile-search-button-image                               : "icon-search" !default;
$ts-mobile-search-button-image-type                          : svg !default; // Possible values: png, svg
$ts-mobile-search-button-image-position                      : 50% !default;
$ts-mobile-search-button-active-opacity                      : .5 !default;
$ts-mobile-search-button-width                               : rem-calc(27) !default;
$ts-mobile-search-button-image-size                          : $ts-mobile-search-button-width !default;

$ts-mobile-search-button-position                            : inside !default; // Possible values: inside, normal
$ts-mobile-search-button-position-top                        : 0 !default; // Applies if button-position == inside
$ts-mobile-search-button-position-right                      : 0 !default; // Applies if button-position == inside

// 03.3 Mobile toggle button
$ts-mobile-toggle-button-type                                : svg !default;
$ts-mobile-toggle-button-image                               : "icon-toggle" !default;
$ts-mobile-toggle-button-width                               : rem-calc(18) !default;

$ts-mobile-search-toggle-button-type                         : svg !default;
$ts-mobile-search-toggle-button-image                        : "icon-search" !default;
$ts-mobile-search-toggle-button-image-size                   : rem-calc(26) !default;

// 03.4 Tablet search button
$ts-tablet-search-toggle-button-type                         : svg !default;
$ts-tablet-search-toggle-button-image                        : "icon-search" !default;
$ts-tablet-search-toggle-button-image-size                   : rem-calc(26) !default;


// 04. Magento Modal
// 04.1 Magento Modal Basic
$ts-modal-zindex                                             : 900 !default;
$ts-modal-overlay-bg                                         : rgba($black, .2) radial-gradient(ellipse at center, rgba($black,0) 0%, rgba($black,.6) 100%) !default;
$ts-modal-overlay-zindex                                     : $ts-modal-zindex - 1 !default;
$ts-modal-background-color                                   : $white !default;
$ts-modal-box-shadow                                         : 0 0 12px 2px rgba($black, 0.35) !default;
$ts-modal-popup-indent-vertical                              : 80px !default;
$ts-modal-popup-padding                                      : 48px !default;
$ts-modal-popup-width                                        : 75% !default;
$ts-modal-popup-width-mobile                                 : 90% !default;
$ts-modal-popup-zindex                                       : $ts-modal-zindex !default;
$ts-modal-slide-first-indent-left                            : 236px !default;
$ts-modal-slide-indent-left                                  : 72px !default;
$ts-modal-slide-padding                                      : 41px !default;
$ts-modal-slide-zindex                                       : $ts-modal-zindex !default;
$ts-modal-slide-header-padding-vertical                      : 33px !default;
$ts-modal-popup-confirm-width                                : 800px !default;
$ts-modal-popup-image-box-border-color                       : $medium-gray !default;
$ts-modal-popup-image-box-max-width                          : 1248px !default;
$ts-modal-popup-image-box-preview-image-max-height           : 864px !default;
$ts-modal-popup-image-box-preview-max-width                  : $ts-modal-popup-image-box-preview-image-max-height + (2 * (map-get($grid-column-gutter, medium))) !default;

// 04.2 Magento Modal Extend (Custom, Popup, Slide)
$ts-modal-title-color                                        : $body-font-color !default;
$ts-modal-title-border                                       : 1px solid $light-gray !default;
$ts-modal-popup-title-font-size                              : 26px !default;
$ts-modal-popup-title-mobile-font-size                       : 24px !default;
$ts-modal-slide-first-indent-left                            : 44px !default;
$ts-modal-slide-mobile-background-color                      : $light-gray !default;
$ts-modal-overlay-background-color                           : scale-color($black, $alpha: -25%) !default;
$ts-modal-action-close-color                                 : $body-font-color !default;
$ts-modal-action-close-font-size                             : 3rem !default;
$ts-modal-action-close-font-weight                           : 300 !default;
$ts-modal-action-close-hover-color                           : $primary-color !default;
$ts-modal-slide-action-close-padding                         : $ts-modal-slide-header-padding-vertical - 16px $ts-modal-popup-padding - 16px !default;


// 05. Category Page
// 05.0 Category Title
$ts-category-main-title-extended                             : false !default;
$ts-category-main-title-align                                : center !default; // can be center or left
$ts-category-main-title-border                               : true !default;
$ts-category-main-title-size                                 : normal !default; // can be big or normal
$ts-category-main-title-size-big-qty                         : 10px !default; // in pixels

$ts-category-content-top-indent                              : -11px !default; // in pixels

// 05.1 Category Sidebar
$ts-amasty-filters-show                                      : true !default;

$ts-sidebar-additional-hide                                  : true !default;
$ts-sidebar-main-padding                                     : rem-calc(0 0 43) !default;
$ts-sidebar-main-top-indent                                  : 0 !default;
$ts-sidebar-main-bottom-indent                               : 1rem !default;
$ts-sidebar-main-background-color                            : transparent !default;

$ts-sidebar-main-filter-title-font-size                      : rem-calc(22) !default;
$ts-sidebar-main-filter-title-font-color                     : $body-font-color !default;
$ts-sidebar-main-filter-title-font-weight                    : 300 !default;
$ts-sidebar-main-filter-title-text-transform                 : none !default;
$ts-sidebar-main-filter-title-font-family                    : $header-font-family !default;
$ts-sidebar-main-filter-title-indent                         : .75rem !default;
$ts-sidebar-main-filter-title-indent-left                    : 0 !default;

$ts-sidebar-main-filter-clear-font-size                      : rem-calc(11) !default;
$ts-sidebar-main-filter-clear-font-color                     : $anchor-color !default;
$ts-sidebar-main-filter-clear-font-weight                    : 400 !default;
$ts-sidebar-main-filter-clear-font-family                    : $body-font-family !default;
$ts-sidebar-main-filter-clear-border                         : 1px solid lighten($anchor-color, 30) !default;

$ts-sidebar-main-filter-content-indent                       : .5rem !default;
$ts-sidebar-main-filter-border-width                         : 1px !default;
$ts-sidebar-main-filter-border-style                         : solid !default;
$ts-sidebar-main-filter-border-color                         : $medium-gray !default;
$ts-sidebar-main-filter-text-transform                       : none !default;
$ts-sidebar-main-filter-anchor-color                         : rgba($black,0.9) !default;
$ts-sidebar-main-filter-font-size                            : rem-calc(14) !default;
$ts-sidebar-main-filter-font-family                          : inherit !default;
$ts-sidebar-main-filter-font-weight                          : inherit !default;
$ts-sidebar-main-filter-anchor-bg-hover                      : transparent !default;
$ts-sidebar-main-filter-anchor-color-hover                   : $anchor-color !default;
$ts-sidebar-main-filter-anchor-underline                     : none !default;
$ts-sidebar-main-filter-anchor-underline-hover               : underline !default;
$ts-sidebar-main-filter-anchor-padding                       : (.35rem 1.8rem .35rem .2rem) !default;
$ts-sidebar-main-filter-count-hide                           : false !default;
$ts-sidebar-main-filter-count-color                          : lighten($body-font-color,45) !default;
$ts-sidebar-main-filter-count-font-size                      : 85% !default;

$ts-sidebar-main-filter-subcategory-left-indent              : 1rem !default;

$ts-sidebar-main-filter-anchor-bg-active                     : transparent  !default;
$ts-sidebar-main-filter-anchor-color-active                  : $anchor-color !default;
$ts-sidebar-main-filter-anchor-underline-active              : none !default;
$ts-sidebar-main-filter-anchor-font-weight-active            : 700 !default;
$ts-text-swatch-active-image                                 : "icon-valid-black" !default; // Possible values: icon-name, false
$ts-text-swatch-active-image-type                            : svg !default; // Possible values: png, svg
$ts-text-swatch-active-image-position                        : 0 50% !default;
$ts-text-swatch-active-image-size                            : 12px auto !default;
$ts-text-swatch-active-image-padding-left                    : rem-calc(16) !default;

$ts-sidebar-additional-filter-font-size                      : rem-calc(14) !default;
$ts-sidebar-additional-filter-font-weight                    : 400 !default;
$ts-sidebar-additional-filter-title-font-size                : rem-calc(18) !default;
$ts-sidebar-additional-filter-title-font-color               : $ts-footer-font-color !default;
$ts-sidebar-additional-filter-title-font-weight              : 400 !default;
$ts-sidebar-additional-filter-title-text-transform           : none !default;
$ts-sidebar-additional-filter-title-font-family              : inherit !default;
$ts-sidebar-additional-filter-title-indent                   : 0 !default;

// 05.1.1 Sidebar search
$ts-sidebar-search-input-indent                               : 3rem !default;
$ts-sidebar-search-input-left-indent                          : 1rem !default;
$ts-sidebar-search-input-left-indent-mobile                   : 1rem !default;
$ts-sidebar-search-font-family                                : $header-font-family !default;
$ts-sidebar-search-font-size                                  : rem-calc(13) !default;
$ts-sidebar-search-font-weight                                : 300 !default;
$ts-sidebar-search-input-border                               : 1px solid $ts-main-border-color !default;
$ts-sidebar-search-input-focus-border                         : 1px solid $ts-main-border-color !default;
$ts-sidebar-search-input-background-color                     : $white !default;
$ts-sidebar-search-input-border-bottom-color                  : $ts-main-border-color !default;
$ts-sidebar-search-input-focus-one-line-border                : false !default;
$ts-sidebar-search-input-focus-border-color                   : $black !default;
$ts-sidebar-search-input-focus-border-bottom-color            : $primary-color !default;
$ts-sidebar-search-input-height                               : rem-calc(42) !default;
$ts-sidebar-search-input-color                                : $body-font-color !default;
$ts-sidebar-search-input-text-transform                       : uppercase !default;
$ts-sidebar-search-input-icon                                 : 'icon-search' !default;
$ts-sidebar-search-input-icon-type                            : svg !default;
$ts-sidebar-search-input-icon-opacity                         : 1 !default;
$ts-sidebar-search-input-icon-width                           : rem-calc(22) !default;
$ts-sidebar-search-input-icon-btn-bg-position                 : 0 50% !default;
$ts-sidebar-search-input-icon-right-indent                    : .5rem !default;
$ts-sidebar-search-input-icon-padding-indent                  : rem-calc(5) !default;
$ts-sidebar-search-input-icon-bg-position                     : $input-state-icon-position !default;

// 05.2 Category Mobile Filter
$ts-sidebar-mobile-category-font-size                        : rem-calc(15) !default;
$ts-sidebar-mobile-category-font-weight                      : 400 !default;
$ts-sidebar-mobile-category-padding                          : rem-calc(8 35 8 8) !default;
$ts-sidebar-mobile-category-font-family                      : inherit !default;
$ts-sidebar-mobile-category-color                            : $body-font-color !default;
$ts-sidebar-mobile-category-image                            : "icon-arrow-next-gray" !default; // Possible values: icon-name, false
$ts-sidebar-mobile-category-image-type                       : svg !default; // Possible values: png, svg
$ts-sidebar-mobile-category-image-position                   : calc(100% - 8px) 50% !default;
$ts-sidebar-mobile-category-image-size                       : 6px auto !default;
$ts-sidebar-mobile-category-active-state                     : 700 !default;

$ts-mobile-filter-image                                      : "icon-check" !default; // Possible values: icon-name, false
$ts-mobile-filter-image-type                                 : svg !default; // Possible values: png, svg
$ts-mobile-filter-image-position                             : 0 !default;
$ts-mobile-filter-image-width                                : 16px !default;
$ts-mobile-filter-image-height                               : 13px !default;
$ts-mobile-sorting-left-indent                               : 1.5rem !default;
$ts-mobile-filter-active-font-weight                         : 700 !default;

$ts-mobile-filter-title-font-size                            : rem-calc(14) !default;
$ts-mobile-filter-title-font-color                           : $body-font-color !default;
$ts-mobile-filter-title-font-weight                          : 400 !default;
$ts-mobile-filter-title-text-transform                       : none !default;
$ts-mobile-filter-title-font-family                          : inherit !default;
$ts-mobile-filter-title-indent                               : 0 !default;

$ts-mobile-filter-subtitle-font-size                         : rem-calc(24) !default;
$ts-mobile-filter-subtitle-font-color                        : $body-font-color !default;
$ts-mobile-filter-subtitle-font-weight                       : 300 !default;
$ts-mobile-filter-subtitle-text-transform                    : none !default;
$ts-mobile-filter-subtitle-font-family                       : $header-font-family !default;
$ts-mobile-filter-subtitle-bottom-spacing                    : 1rem !default;

// 05.2.1 Algolia Mobile Filter/Sort buttons
$ts-algolia-mobile-filters-btn-view                           : 'primary' !default; // can be primary, secondary, hollow
$ts-algolia-mobile-filters-btn-font-size                      : map-get($button-sizes, default) !default;
$ts-algolia-mobile-filter-btn-icn-right-indent                : .5rem !default;

$ts-algolia-mobile-filter-btn-icn                            : 'icon-filter' !default;
$ts-algolia-mobile-filter-btn-icn-width                      : 15px !default;
$ts-algolia-mobile-filter-btn-icn-height                     : 10px !default;

$ts-algolia-mobile-sort-btn-icn                              : 'icon-sorter' !default;
$ts-algolia-mobile-sort-btn-icn-width                        : 22px !default;
$ts-algolia-mobile-sort-btn-icn-height                       : 13px !default;

// 05.2.2 Algolia Mobile Category filters
$ts-algolia-mobile-category-filter-title-show                : true !default;
$ts-algolia-mobile-category-filter-bottom-indent             : 1rem !default;

// 05.2.3 Algolia Collapsible Category filters
$ts-algolia-collaps-filter-header-padding                    : 1rem 2rem 1rem 1rem !default;
$ts-algolia-collaps-filter-body-padding                      : 0 1rem 1rem !default;
$ts-algolia-collaps-slider-body-bottom-indent                : 2rem !default;
$ts-algolia-collaps-filter-border                            : 1px solid lighten($black,90) !default;
$ts-algolia-collaps-filter-icn-closed                        : "icon-plus" !default;
$ts-algolia-collaps-filter-icn-opened                        : "icon-minus" !default;
$ts-algolia-collaps-filter-icn-type                          : svg !default;
$ts-algolia-collaps-filter-icn-width                         : 10px !default;
$ts-algolia-collaps-filter-icn-height                        : 10px !default;
$ts-algolia-uncollaps-filter-icn-width                       : 10px !default;
$ts-algolia-uncollaps-filter-icn-height                      : 3px !default;
$ts-algolia-collaps-filter-icn-position                      : calc(100% - 16px) 50% !default;

// 05.2.4 Algolia Show More Category filters
$ts-algolia-show-more-padding                                : rem-calc(3 13 3 3) !default;
$ts-algolia-show-more-margin                                 : rem-calc(5 0 0) !default;
$ts-algolia-show-more-font-size                              : 13px !default;
$ts-algolia-show-more-font-weight                            : 400 !default;
$ts-algolia-show-more-font-family                            : $header-font-family !default;
$ts-algolia-show-more-color                                  : $anchor-color !default;
$ts-algolia-show-more-text-decoration                        : underline !default;
$ts-algolia-show-more-hover-text-decoration                  : none !default;
$ts-algolia-show-more-icn-img                                : "icon-down-bold" !default;
$ts-algolia-show-more-icn-type                               : svg !default;
$ts-algolia-show-more-icn-width                              : 9px !default;
$ts-algolia-show-more-icn-height                             : 6px !default;
$ts-algolia-show-more-icn-pos-right                          : 0 !default;

$ts-algolia-show-more-fade                                   : false !default;
$ts-algolia-show-more-height                                 : 54px !default;
$ts-algolia-show-more-top-indent                             : 0 !default;

// 05.2.5 Algolia From-To Category filters
$ts-algolia-from-to-replace-separator                        : true !default;
$ts-algolia-from-to-replace-separator-content                : '-' !default;
$ts-algolia-from-to-input-height                             : 47px !default;
$ts-algolia-from-to-input-padding                            : 8px !default;
$ts-algolia-from-to-input-font-size                          : 13px !default;
$ts-algolia-from-to-input-font-family                        : $body-font-family !default;
$ts-algolia-from-to-input-font-weight                        : 400 !default;
$ts-algolia-from-to-input-color                              : $body-font-color !default;
$ts-algolia-from-to-input-placeholder-color                  : $body-font-color !default;
$ts-algolia-from-to-input-text-align                         : center !default;
$ts-algolia-from-to-items-indent                             : 8px !default;
$ts-algolia-from-to-btn-color                                : $button-color !default;
$ts-algolia-from-to-btn-bg-color                             : $button-background !default;
$ts-algolia-from-to-btn-bg-color-hover                       : $button-background-hover !default;
$ts-algolia-from-to-btn-font-size                            : 1rem !default;
$ts-algolia-from-to-btn-font-weight                          : $button-weight !default;
$ts-algolia-from-to-btn-font-family                          : $button-font-family !default;
$ts-algolia-from-to-btn-padding                              : 8px !default;

// 05.2.6 Algolia Label Filters with checkbox
$ts-label-has-checkbox                                       : false !default;
$ts-label-has-checkbox-simple-bg                             : true !default;
$ts-checkbox-label-swatch-height                             : 20px !default;
$ts-checkbox-label-swatch-width                              : 20px !default;
$ts-checkbox-swatch-border                                   : inset 0 0 0 1px rgba($black,.1) !default;
$ts-checkbox-swatch-bg-color                                 : $white !default;
$ts-checkbox-label-swatch-left-indent                        : 25px !default;
$ts-checkbox-label-swatch-top                                : 6px !default;
$ts-checkbox-label-swatch-left                               : 0 !default;
$ts-checkbox-label-swatch-height-active                      : 16px !default;
$ts-checkbox-label-swatch-width-active                       : 16px !default;
$ts-checkbox-label-swatch-bg-active                          : $secondary-color !default;
$ts-checkbox-label-swatch-bg-image-active                    : "icon-check" !default;
$ts-checkbox-label-swatch-bg-image-active-type               : svg !default;
$ts-checkbox-label-swatch-bg-image-active-size               : 16px auto !default;
$ts-checkbox-label-swatch-top-active                         : 8px !default;
$ts-checkbox-label-swatch-left-active                        : 2px !default;

// 05.2.7 Emulate checkboxes for Category filters
$ts-sidebar-category-has-checkbox                             : false !default;



// 05.3 Category Sorting Toolbar
$ts-category-sorting-toolbar-position                        : 'center-title' !default; // can be left-title and center-title
$ts-category-sorting-toolbar-height                          : rem-calc(40) !default;
$ts-category-sorting-toolbar-bottom-outer-indent             : 0 !default;
$ts-category-sorting-toolbar-bottom-indent                   : 1.25rem !default;
$ts-category-sorting-toolbar-bottom-border                   : false !default;
$ts-category-sorting-bottom-toolbar-border                   : false !default;
$ts-category-sorting-toolbar-font-size                       : rem-calc(14) !default;
$ts-category-sorting-toolbar-font-family                     : $body-font-family !default;
$ts-category-sorting-toolbar-color                           : lighten($body-font-color, 19.2157) !default;
$ts-category-sorting-toolbar-font-weight                     : 400 !default;
$ts-category-sorting-toolbar-link-underline                  : underline !default;
$ts-category-sorting-toolbar-select-spacing                  : 0.5rem !default;
$ts-category-sorting-toolbar-label-width                     : 50% !default;
$ts-category-sorting-toolbar-select-width                    : 50% !default;
$ts-category-sorting-toolbar-select-bg                       : $input-background !default;
$ts-category-sorting-toolbar-text-transform                  : none !default;

// 05.4 Category Swatches
$ts-additional-swatch-types                                  : (size) !default;
$ts-hide-all-swatch-tooltips                                 : true !default;

// IMAGE SWATCH
$ts-color-swatch-height                                      : 36px !default;
$ts-color-swatch-height-selected                             : 36px !default;
$ts-color-swatch-width                                       : 36px !default;
$ts-color-swatch-radius                                      : 0 !default;
$ts-color-swatch-border-shadows-basic-color                  : rgba($black, .07) !default;
$ts-color-swatch-border                                      : false !default;
$ts-color-swatch-border-color                                : transparent !default;
$ts-color-swatch-box-shadow                                  : 0 0 10px 2px $ts-color-swatch-border-shadows-basic-color !default;
$ts-color-swatch-letter-spacing                              : normal !default;
$ts-color-swatch-word-spacing                                : normal !default;
$ts-color-swatch-bg-color                                    : $white !default;
$ts-color-swatch-outer-indent                                : 0 2px 3px !default;
$ts-color-swatch-inner-indent                                : 0 !default;
$ts-color-swatch-text-inner-indent                           : 0 !default;
$ts-color-swatch-active-image                                : "icon-check-filter" !default;
$ts-color-swatch-active-image-type                           : svg !default;  // Possible values: png, svg, none
$ts-color-swatch-active-image-width                          : 16px !default;
$ts-color-swatch-tooltip-width                               : 157px !default;
$ts-color-swatch-tooltip-bg                                  : $white !default;
$ts-color-swatch-tooltip-image-height                        : 132px !default;
$ts-color-swatch-tooltip-title-height                        : 20px !default;
$ts-color-swatch-tooltip-corner-width                        : 20px !default;
$ts-color-swatch-tooltip-zindex                              : 999 !default;

// IMAGE+LABEL SWATCH
$ts-color-label-swatch-height                                : 1.5em !default;
$ts-color-label-swatch-width                                 : 1.5em !default;
$ts-color-label-swatch-indent                                : 0 0 0 4px !default;

// TEXT SWATCH
$ts-size-swatch-height                                      : 36px !default;
$ts-size-swatch-height-selected                             : 36px !default;
$ts-size-swatch-width                                       : 36px !default;
$ts-size-swatch-radius                                      : 0 !default;
$ts-size-swatch-color-text                                  : $black !default;
$ts-size-swatch-color-text-active                           : $white !default;
$ts-size-swatch-font-size                                   : rem-calc(15) !default;
$ts-size-swatch-font-weight                                 : 400 !default;
$ts-size-swatch-font-family                                 : inherit !default;
$ts-size-swatch-border-shadows-basic-color                  : rgba($black, .07) !default;
$ts-size-swatch-border                                      : false !default;
$ts-size-swatch-border-color                                : transparent !default;
$ts-size-swatch-box-shadow                                  : 0 0 10px 2px $ts-size-swatch-border-shadows-basic-color !default;
$ts-size-swatch-letter-spacing                              : normal !default;
$ts-size-swatch-word-spacing                                : normal !default;
$ts-size-swatch-bg-color                                    : $white !default;
$ts-size-swatch-bg-color-active                             : $primary-color !default;
$ts-size-swatch-outer-indent                                : 0 5px 3px 0 !default;
$ts-size-swatch-inner-indent                                : 0 !default;
$ts-size-swatch-text-inner-indent                           : 0 2px !default;

$ts-size-swatch-category-top-border                         : false !default;
$ts-size-swatch-category-top-indent                         : 0 !default;
$ts-size-swatch-category-bottom-indent                      : 1rem !default;

// Product detail - Color swatches title
$ts-color-swatch-title-font-size                             : 1rem !default;
$ts-color-swatch-title-font-weight                           : 300 !default;
$ts-color-swatch-title-font-family                           : $header-font-family !default;
$ts-color-swatch-title-font-style                            : normal !default;
$ts-color-swatch-title-text-transform                        : none !default;

// Category filters - Color's title
$ts-color-swatch-category-top-border                         : false !default;
$ts-color-swatch-category-top-indent                         : 0 !default;
$ts-color-swatch-category-bottom-indent                      : 1rem !default;

// Product list - Color swatches below the Product's photo
$ts-color-swatch-product-list-width                          : 10px !default;
$ts-color-swatch-product-list-height                         : 10px !default;
$ts-color-swatch-product-list-radius                         : 50% !default;
$ts-color-swatch-product-list-indent                         : .5rem !default;
$ts-color-swatch-product-list-selected-shadow                : 0 0 0 1px $anchor-color !default;

// Category filters - Color & Text swatches
$ts-ct-swatch-category-top-border                            : false !default;
$ts-ct-swatch-category-top-indent                            : 0 !default;
$ts-ct-swatch-category-bottom-indent                         : 1rem !default;

$ts-ct-swatch-outer-indent                                   : 0 2px 3px !default;
$ts-ct-swatch-inner-indent                                   : 0 !default;


// 05.5 Product detail Swatches
// IMAGE SWATCH
$ts-color-product-swatch-height                              : 2.25rem !default;
$ts-color-product-swatch-width                               : 3rem !default;
$ts-color-product-swatch-color                               : $body-font-color !default;
$ts-color-product-swatch-bg                                  : $white !default;
$ts-color-product-swatch-border                              : $ts-main-border-color !default;
$ts-color-product-swatch-border-hover                        : $ts-main-border-color !default;
$ts-color-product-swatch-font-size                           : rem-calc(15) !default;
$ts-color-product-swatch-font-weight                         : 700 !default;
$ts-color-product-swatch-font-family                         : inherit !default;
$ts-color-product-swatch-color-active                        : $white !default;
$ts-color-product-swatch-bg-active                           : $black !default;
$ts-color-product-swatch-disabled-color                      : lighten($body-font-color, 63.9216) !default;
$ts-color-product-swatch-margin                              : 0 !default;
$ts-color-product-swatch-has-full-border                     : false !default;
$ts-color-product-swatch-full-border                         : inset 0 0 0 1px rgba($black, .4) !default;

// TEXT SWATCH
$ts-size-product-swatch-height                              : 2.25rem !default;
$ts-size-product-swatch-width                               : 3rem !default;
$ts-size-product-swatch-color                               : $body-font-color !default;
$ts-size-product-swatch-bg                                  : $white !default;
$ts-size-product-swatch-border                              : $ts-main-border-color !default;
$ts-size-product-swatch-border-hover                        : $ts-main-border-color !default;
$ts-size-product-swatch-font-size                           : rem-calc(15) !default;
$ts-size-product-swatch-font-weight                         : 700 !default;
$ts-size-product-swatch-font-family                         : inherit !default;
$ts-size-product-swatch-color-active                        : $white !default;
$ts-size-product-swatch-bg-active                           : $black !default;
$ts-size-product-swatch-disabled-color                      : lighten($body-font-color, 63.9216) !default;
$ts-size-product-swatch-margin                              : 0 !default;
$ts-size-product-swatch-text-inner-indent                   : 0 2px !default;
$ts-size-product-swatch-has-full-border                     : false !default;
$ts-size-product-swatch-full-border                         : inset 0 0 0 1px rgba($black, .4) !default;


// 05.6 Category Price slider
$ts-sidebar-main-price-slider-point-width                    : 20px !default;
$ts-sidebar-main-price-slider-point-height                   : 20px !default;
$ts-sidebar-main-price-slider-point-bg                       : $secondary-color !default;
$ts-sidebar-main-price-slider-point-border                   : none !default;
$ts-sidebar-main-price-slider-line-width                     : 1px !default;
$ts-sidebar-main-price-slider-line-type                      : solid !default;
$ts-sidebar-main-price-slider-line-color                     : lighten(map-get($foundation-palette, secondary),40%) !default;
$ts-sidebar-main-price-slider-margin                         : rem-calc(8 17 10 0) !default;
$ts-sidebar-main-price-slider-font-size                      : 1rem !default;
$ts-sidebar-main-price-slider-font-weight                    : inherit !default;
$ts-sidebar-main-price-slider-color                          : inherit !default;


// 05.7 Pagination
$ts-pagination-enable                                        : true !default;
$ts-pagination-centered                                      : false !default;

$ts-pagination-outside-indent                                : ( small   : (2px 0 11px),
                                                                 xlarge  : (57px 0),
                                                                 xxlarge : (57px 0) ) !default;
$ts-pagination-inner-indent                                  : ( small   : (0 0 55px),
                                                                 xlarge  : (0 185px),
                                                                 xxlarge : (0 220px) ) !default;
$ts-pagination-text-align                                    : center !default;

$ts-pagination-item-outside-indent                           : ( small   : (0 5px 5px),
                                                                 xlarge  : (0 5px 5px),
                                                                 xxlarge : (0 5px 5px) ) !default;
$ts-pagination-item-width                                    : ( small   : 41px,
                                                                 xlarge  : 41px,
                                                                 xxlarge : 41px ) !default;
$ts-pagination-item-height                                   : ( small   : 41px,
                                                                 xlarge  : 41px,
                                                                 xxlarge : 41px ) !default;
$ts-pagination-item-line-height                              : ( small   : 41px,
                                                                 xlarge  : 41px,
                                                                 xxlarge : 41px ) !default;
$ts-pagination-item-font-size                                : 18px !default;
$ts-pagination-item-font-weight                              : 600 !default;
$ts-pagination-item-font-family                              : $header-font-family !default;
$ts-pagination-item-border-radius                            : 50% !default;
$ts-pagination-item-color                                    : $body-font-color !default;
$ts-pagination-item-border                                   : 1px solid rgba($black,.4) !default;
$ts-pagination-item-bg                                       : $white !default;
$ts-pagination-item-color-active                             : $body-font-color !default;
$ts-pagination-item-bg-active                                : lighten(saturate(adjust-hue($secondary-color, -1), 1.39), 17.25) !default;
$ts-pagination-item-border-color-active                      : lighten(saturate(adjust-hue($secondary-color, -1), 1.39), 17.25) !default;
$ts-pagination-item-box-shadow-active                        : 3px 3px 10px rgba($black,.1) !default;

$ts-pagination-item-next-prev-button                         : true !default;
$ts-pagination-item-next-prev-has-arrow                      : true !default;
$ts-pagination-item-next-prev-button-simple-mobile           : false !default;
$ts-pagination-item-next-prev-button-simple-mobile-until     : xlarge !default;

$ts-pagination-item-next-prev-button-font-size               : ( small   : 12px,
                                                                 xlarge  : 13px,
                                                                 xxlarge : 13px) !default;
$ts-pagination-item-next-prev-button-min-width               : ( small   : 160px,
                                                                 xlarge  : 180px,
                                                                 xxlarge : 215px) !default;
$ts-pagination-item-next-prev-button-padding-top             : ( small   : 1.1em,
                                                                 xlarge  : 1.1em,
                                                                 xxlarge : 1.1em ) !default;
$ts-pagination-item-next-prev-button-padding-bottom          : ( small   : 1em,
                                                                 xlarge  : 1em,
                                                                 xxlarge : 1em ) !default;
$ts-pagination-item-next-prev-button-min-width-iphone4       : 130px !default;
$ts-pagination-item-next-prev-button-font-size-iphone4       : 11px !default;
$ts-pagination-item-next-prev-button-hz-paddings-iphone4     : 1em !default;

$ts-pagination-item-next-prev-button-color                   : $button-color !default;
$ts-pagination-item-next-prev-button-letter-spacing          : 0.15em !default;
$ts-pagination-item-next-prev-button-text-transform          : $button-text-transform !default;
$ts-pagination-item-next-prev-button-bg-color                : $button-background !default;
$ts-pagination-item-next-prev-button-bg-color-hover          : $button-background-hover !default;
$ts-pagination-item-next-prev-button-bg-color-active         : $button-background-active !default;

$ts-pagination-item-next-prev-arrow-width                    : 5px !default;
$ts-pagination-item-next-prev-arrow-height                   : 8px !default;
$ts-pagination-item-next-prev-arrow-hz-indent                : 15px !default;

$ts-pagination-item-next-prev-button-arrow                   :'icon-arrow-next-gray' !default;
$ts-pagination-item-next-prev-button-arrow-prev              :'icon-arrow-prev-gray' !default;
$ts-pagination-item-next-prev-button-arrow-hover             :'icon-arrow-next-white' !default;
$ts-pagination-item-next-prev-button-arrow-prev-hover        :'icon-arrow-prev-white' !default;
$ts-pagination-item-next-prev-button-arrow-size              : 25% auto !default;

$ts-pagination-item-next-prev-opacity-disabled               : .25 !default;
$ts-pagination-item-next-prev-button-color-disabled          : $white !default;
$ts-pagination-item-next-prev-button-bg-color-disabled       : $box-color !default;


// 06. Product List Item
$ts-product-item-qty-line                                    : 3 !default; // can be 4 and 3
$ts-product-item-gap-size                                    : .25 !default; // only if $ts-product-item-collapse-grid and $ts-product-item-divider are false
$ts-product-item-mobile-two-inline                           : false !default; // true or false, use only if $ts-product-item-collapse-grid and $ts-product-item-divider are false
$ts-widget-grid-product-item-center-in-line                  : false !default;

$ts-product-item-divider                                     : false !default;
$ts-product-item-divider-width                               : 1px !default;
$ts-product-item-divider-style                               : solid !default;
$ts-product-item-divider-color                               : $light-gray !default;

$ts-product-item-bg                                          : $white !default;
$ts-product-item-inner-indent                                : ( small : rem-calc(30) rem-calc(25) rem-calc(25),
                                                                 large : rem-calc(30) rem-calc(25) rem-calc(25),
                                                                 xlarge : rem-calc(30) rem-calc(25) rem-calc(25),
                                                                 xxlarge : rem-calc(40) rem-calc(35) rem-calc(35)) !default;
$ts-product-item-text-align                                  : center !default;
$ts-product-item-info-align                                  : left !default;
$ts-product-item-info-indent                                 : 0 !default;
$ts-product-item-photo-bottom-indent                         : 1rem !default;
$ts-product-item-nameprice-align                             : inline !default; // inline or block
$ts-product-item-bottom-indent                               : 0 !default;
$ts-product-item-stock-price__layout                         : horizontal !default; // horizontal or vertical

$ts-product-item-collapse-grid                               : false !default;
$ts-product-item-collapse-grid-product-spacing-coeff         : .25 !default;
$ts-product-item-hover-bg                                    : $white !default;
$ts-product-item-hover-shadow                                : 0 0 30px rgba($black, 0.1) !default;

$ts-product-item-absolute-image                              : true !default;
$ts-product-item-absolute-image-ratio                        : 100% !default;

$ts-product-item-hide-btn                                    : true !default; // true or false
$ts-product-item-stock__hide                                 : true !default; // true, false
$ts-product-item-stock-over                                  : true !default; // true or false
$ts-product-item-stock__show--hover                          : true !default;

$ts-product-brand-font-size                                  : rem-calc(11) !default;
$ts-product-brand-font-weight                                : 400 !default;
$ts-product-brand-font-family                                : $header-font-family !default;
$ts-product-brand-line-height                                : 1 !default;
$ts-product-brand-text-transform                             : uppercase !default;
$ts-product-brand-color                                      : rgba($body-font-color, .5) !default;
$ts-product-brand-padding                                     : (0 0 0.25rem) !default;

$ts-product-item-product-name-font-size                      : ( small : rem-calc(13), large : rem-calc(13), xlarge : rem-calc(13), xxlarge : rem-calc(13)) !default;
$ts-product-item-product-name-font-family                    : $header-font-family !default;
$ts-product-item-product-name-font-weight                    : ( small : 300, large : 300, xlarge : 300) !default;
$ts-product-item-product-name-line-height                    : ( small : 1.27, large : 1.27, xlarge : 1.24, xxlarge : 1.44) !default;
$ts-product-item-product-name-color                          : $body-font-color !default;
$ts-product-item-product-name-text-transform                 : uppercase !default;
$ts-product-item-product-name-margin                         : 0 !default;

$ts-product-item-product-stock-weight                        : 300 !default;
$ts-product-item-product-stock__font-size                    : rem-calc(17) !default;
$ts-product-item-product-stock__line-height                  : false !default;

$ts-product-item-product-price-fra-show                      : false !default;
$ts-product-item-product-price-font-family                   : $header-font-family !default;
$ts-product-item-product-price-font-size                     : ( small : rem-calc(20), xlarge : rem-calc(24), xxlarge : rem-calc(25)) !default;
$ts-product-item-product-price-font-weight                   : 700 !default;
$ts-product-item-product-price-font-color                    : $body-font-color !default;

$ts-product-item-product-price-special-color                 : $primary-color !default;
$ts-product-item-product-price-special-spacing               : 0 !default;
$ts-product-item-product-price-special-right-spacing         : .5rem !default;
$ts-product-item-product-price-special-background            : transparent   !default;

$ts-product-item-product-price-old-color                     : rgba($black,.5) !default;
$ts-product-item-product-price-old-font-size                 : rem-calc(11) !default;
$ts-product-item-product-price-old-font-family               : inherit !default;
$ts-product-item-product-price-old-text-decoration           : line-through !default;
$ts-product-item-product-price-old-font-weight               : inherit !default;
$ts-product-item-product-price-currency-show                 : false !default;

$ts-product-item-product-attr-color                          : rgba($black,.7) !default;
$ts-product-item-product-attr-dot-color                      : darken(#f0f0f0, 6.2745) !default;
$ts-product-item-product-attr-font-size                      : ( small : rem-calc(11), xlarge : rem-calc(13)) !default;
$ts-product-item-product-attr-line-height                    : 1.2 !default;

// 06.1 Product Additional List
$ts-additional-cart-product-title-size                       : rem-calc(15) !default;
$ts-additional-cart-product-title-weight                     : 400 !default;
$ts-additional-cart-product-title-bottom-indent              : .75rem !default;
$ts-additional-cart-product-desc-font-size                   : rem-calc(13) !default;

// 06.2 Product Price Tier
$ts-product-price-tier__default-styling                      : true !default;

// 07. Product Detail
// 07.1 Product Detail Fotorama Gallery
$ts-size-fotorama-enable                                     : true !default;
$ts-size-fotorama-bg                                         : $white !default;
$ts-size-fotorama-block                                      : 80px !default;
$ts-fotorama-arw-size                                        : 80px !default;
$ts-fotorama-duration-time                                   : 0.3s !default;
$ts-fotorama-fullscreen-zoom-time                            : 0.3s !default;
$ts-fotorama-inner-box-shadow                                : 3px !default;
$ts-fotorama-focus__box-shadow                               : 3px !default;
$ts-fotorama-spinner-size                                    : 64px !default;
$ts-fotorama-thumb-arrow                                     : 30px !default;
$ts-fotorama_close_button                                    : 80px !default;
$ts-fotorama-z-index-10                                      : 1000 !default;
$ts-fotorama-z-index-9                                       : 900 !default;
$ts-fotorama-z-index-8                                       : 800 !default;
$ts-fotorama-z-index-1                                       : 100 !default;

$ts-fotorama-default-sprite-icon                             : 'gallery' !default;
$ts-fotorama-default-sprite-icon-type                        : png !default;

$ts-fotorama-default-zoom-logo-placement                     : left-top !default; // can be center-bottom and left-top
$ts-fotorama-default-zoom-logo-text                          : false;

$ts-fotorama-close-fullscreen-icon                           : 'icon-close' !default;
$ts-fotorama-close-fullscreen-icon-type                      : svg !default;
$ts-fotorama-close-fullscreen-icon-pos                       : calc(100% - 25px) 50% !default;
$ts-fotorama-close-fullscreen-icon-size                      : 30px !default;
$ts-fotorama-close-fullscreen-text-color                     : rgba($body-font-color, .7) !default;
$ts-fotorama-close-fullscreen-text-font-family               : $header-font-family !default;
$ts-fotorama-close-fullscreen-text-font-size                 : ( small : rem-calc(13), xlarge : rem-calc(16), xxlarge : rem-calc(18)) !default;
$ts-fotorama-close-fullscreen-text-font-weight               : inherit !default;

$ts-fotorama-lens-icon                                       : 'icon-lens' !default;
$ts-fotorama-lens-icon-type                                  : svg !default;
$ts-fotorama-lens-icon-width                                 : 25px !default;
$ts-fotorama-lens-icon-height                                : 25px !default;

$ts-fotorama-horiz-arrows-icon                               : ( small : 'icon-arrow-next-gray' , xlarge : 'icon-big-next-arrow') !default;
$ts-fotorama-horiz-arrows-icon-type                          : ( small : svg , xlarge : svg) !default;
$ts-fotorama-horiz-arrows-icon-size                          : ( small : 14px , xlarge : 25px) !default;
$ts-fotorama-horiz-arrows-icon-opacity                       : .9 !default;

$ts-fotorama-mobile-dots-size                                : 7px !default;
$ts-fotorama-mobile-dots-radius                              : 50% !default;
$ts-fotorama-mobile-dots-spacing                             : 0 3px !default;
$ts-fotorama-mobile-dots-bg                                  : lighten($black, 80) !default;
$ts-fotorama-mobile-dots-bg-active                           : $black !default;

$ts-fotorama-header-fullscreen-text-weight                    : 300 !default;
$ts-fotorama-header-fullscreen-text-color                     : $body-font-color !default;
$ts-fotorama-header-fullscreen-text-font-family               : $header-font-family !default;
$ts-fotorama-header-fullscreen-text-font-size                 : ( small : rem-calc(13), xlarge : rem-calc(18), xxlarge : rem-calc(22)) !default;

$ts-size-fotorama-vertical-thumbs                             : false !default;


// 07.2 Product Detail Top Panel
$ts-product-detail-title-font-size                           : ( small : rem-calc(20), large : rem-calc(30), xxlarge : rem-calc(45)) !default;
$ts-product-detail-title-font-weight                         : 300 !default;
$ts-product-detail-title-font-family                         : $header-font-family !default;
$ts-product-detail-title-font-style                          : normal !default;
$ts-product-detail-title-line-height                         : 1.1 !default;
$ts-product-detail-title-color                               : $body-font-color !default;
$ts-product-detail-title-text-transform                      : uppercase !default;
$ts-product-detail-title-text-align                          : center !default;
$ts-product-detail-title-bottom-indent                       : 1rem !default;
$ts-product-detail-title-bottom-inner-indent-coeff           : 1 !default;
$ts-product-detail-title-border-bottom                       : false !default;

$ts-product-detail-show-brand-title                          : true !default;
$ts-product-detail-brand-title-font-size                     : rem-calc(13) !default;
$ts-product-detail-brand-title-font-family                   : inherit !default;
$ts-product-detail-brand-title-font-weight                   : inherit !default;
$ts-product-detail-brand-title-font-color                    : rgba($body-font-color, .5) !default;
$ts-product-detail-brand-title-uppercase                     : uppercase !default;
$ts-product-detail-brand-title-indent                        : .75rem !default;
$ts-product-detail-brand-title-has-border                    : true !default;
$ts-product-detail-brand-title-border                        : 1px solid rgba($body-font-color, .25) !default;

$ts-product-detail-price-font-size                           : rem-calc(30) !default;
$ts-product-detail-price-font-weight                         : 700 !default;
$ts-product-detail-price-font-weight-mobile                  : 700 !default;
$ts-product-detail-price-font-family                         : $header-font-family !default;
$ts-product-detail-price-line-height                         : 1.4 !default;
$ts-product-detail-price-line-height-mobile                  : 1.4 !default;
$ts-product-detail-price-line-top-indent                     : 0 !default;
$ts-product-detail-price-color                               : $body-font-color !default;
$ts-product-detail-price-text-transform                      : none !default;

$ts-product-detail-old-price-font-size                       : rem-calc(15) !default;
$ts-product-detail-old-price-font-weight                     : 400 !default;
$ts-product-detail-old-price-font-family                     : $header-font-family !default;
$ts-product-detail-old-price-line-height                     : 1.2 !default;
$ts-product-detail-old-price-color                           : rgba($body-font-color,.5) !default;
$ts-product-detail-old-price-decoration                      : $ts-product-item-product-price-old-text-decoration !default;

$ts-product-size-guide-control-appearance                    : true !default;

$ts-product-detail-show-sku                                  : false !default;

// 07.3 Product Detail Info Right Sidebar
$ts-product-info-sidebar-view                                : ferner-view !default; // can be eple-view || ferner-view || hoyer-view || center-view
$ts-product-info-sidebar-indent                              : (small: 1.5rem 0, large: 1.6rem 2rem, xlarge: 2.75rem 2.1rem 2.1rem, xxlarge: 2.75rem 2.1rem 2.1rem) !default;
$ts-product-info-sidebar-bottom-indent                       : (small: 2rem, large: 2.5rem, xlarge: 2.75rem, xxlarge: 2.75rem) !default;
$ts-product-info-sidebar-background-color                    : $white !default;
$ts-product-info-sidebar-action-font-size                    : rem-calc(14) !default;
$ts-product-info-sidebar-action-color                        : $dark-gray !default;
$ts-product-info-sidebar-action-transform                    : none !default;
$ts-product-info-sidebar-action-hover-color                  : $primary-color !default;

// 07.4 Product Detail Add to Wishlist
$ts-addtowishlist-icon                                       : "icon-wishlist" !default; // Possible values: icon-name, false
$ts-addtowishlist-remove-icon                                : "icon-wishlist-remove" !default; // Possible values: icon-name, false
$ts-addtowishlist-icon-width                                 : rem-calc(25) !default;
$ts-addtowishlist-icon-height                                : rem-calc(23) !default;
$ts-addtowishlist-icon-type                                  : svg !default;  // Possible values: png, svg, none
$ts-addtowishlist-icon-active                                : "icon-wishlist-active" !default; // Possible values: icon-name, false
$ts-addtowishlist-text-size                                  : rem-calc(11) !default;
$ts-addtowishlist-text-weight                                : inherit !default;
$ts-addtowishlist-text-family                                : inherit !default;
$ts-addtowishlist-multiple-icon-active                       : "icon-wishlist-active" !default; // Possible values: icon-name, false
$ts-addtowishlist-multiple-icon-type                         : svg !default; // Possible values: icon-name, false

// 07.5 Product Label
$ts-product-label-small-from                                 : xlarge !default;
$ts-product-label-position-top                               : 0 !default;
$ts-product-label-position-right                             : 0 !default;
$ts-product-label-position-top-small                         : 0 !default;
$ts-product-label-position-right-small                       : 0 !default;
$ts-product-label-position-top-detail                        : 0 !default;
$ts-product-label-position-right-detail                      : ( small: (map-get($grid-column-gutter, small)/2), medium: (map-get($grid-column-gutter, medium)/2) ) !default;
$ts-product-label-font-family                                : $header-font-family !default;
$ts-product-label-font-size                                  : ( small : rem-calc(14), xxlarge : rem-calc(14)) !default;
$ts-product-label-font-weight                                : 700 !default;
$ts-product-label-color                                      : $white !default;
$ts-product-label-view                                       : none !default; // can be square or none
$ts-product-label-size                                       : ( small : rem-calc(80), xlarge : rem-calc(80), xxlarge : rem-calc(80)) !default;
$ts-product-label-size-height                                : ( small : rem-calc(40), xlarge : rem-calc(40), xxlarge : rem-calc(40)) !default;
$ts-product-label-checkout-size                              : rem-calc(50) !default;
$ts-product-label-checkout-size-height                       : rem-calc(50) !default;
$ts-product-label-checkout-font-size                         : rem-calc(20) !default;
$ts-product-label-radius                                     : 0 !default;
$ts-product-label-background-color                           : $primary-color !default;
$ts-product-label-text-dash                                  : false !default;
$ts-product-label-text-dash-color                            : 1px solid rgba($ts-product-label-color,.5) !default;

$ts-product-label-new-text-color                             : $ts-product-label-color !default;
$ts-product-label-new-text-transform                         : none !default;
$ts-product-label-new-background-color                       : $black !default;
$ts-product-label-new-notice-text-show                       : false !default;
$ts-product-label-new-notice-color                           : inherit !default;
$ts-product-label-new-notice-font-family                     : $body-font-family !default;
$ts-product-label-new-notice-font-size                       : rem-calc(13) !default;
$ts-product-label-new-notice-font-weight                     : 400 !default;
$ts-product-label-new-notice-text-transform                  : none !default;

$ts-product-label-new-number-font-family                     : $header-font-family !default;
$ts-product-label-new-number-font-size                       : ( small : rem-calc(18), xxlarge : rem-calc(18)) !default;
$ts-product-label-new-number-font-weight                     : 700 !default;

// 07.6 Product Detail AddtoCart Button
$ts-product-addtocart-qty__show                              : false !default;
$ts-product-addtocart-btn-font-size                          : rem-calc(14) !default;
$ts-product-addtocart-btn-font-weight                        : 700 !default;
$ts-product-addtocart-btn-font-family                        : $header-font-family !default;
$ts-product-addtocart-text-transform                         : uppercase !default;
$ts-product-addtocart-btn-padding                            : 1rem !default;
$ts-product-addtocart-btn-icon-view                          : false !default;
$ts-product-addtocart-btn-icon                               : $ts-header-cart-dropdown-showcart-icon-hover !default;
$ts-product-addtocart-btn-icon-type                          : $ts-header-cart-dropdown-showcart-icon-type !default;
$ts-product-addtocart-btn-icon-size                          : 20px auto !default;
$ts-product-addtocart-btn-icon-pos                           : 18px 50% !default;

// 07.7 Product Detail Info Text
$ts-product-detail-info-text-toggled                         : false !default;
$ts-product-detail-info-text-styled                          : true !default;

// 07.8 Product Detail Grouped Product
$ts-grouped-product-name-font-size                           : 1rem !default;
$ts-grouped-product-name-font-weight                         : 300 !default;
$ts-grouped-product-price-font-size                          : 1rem !default;
$ts-grouped-product-price-font-weight                        : 600 !default;

// 07.9 Product Detail Bundle Product
$ts-product-bundle-price-label-font-size                     : rem-calc(18) !default;
$ts-product-bundle-price-label-font-weight                   : 400 !default;
$ts-product-bundle-price-label-font-color                    : scale-color($black, $alpha: -30%) !default;
$ts-product-bundle-price-font-size                           : rem-calc(30) !default;
$ts-product-bundle-price-font-weight                         : 700 !default;
$ts-product-bundle-price-font-color                          : $body-font-color !default;

$ts-product-bundle-option-container-border                   : 1px $medium-gray solid !default;
$ts-product-bundle-option-label-font-size                    : rem-calc(15) !default;
$ts-product-bundle-option-price-font-size                    : rem-calc(14) !default;
$ts-product-bundle-option-price-font-weight                  : 600 !default;

$ts-product-bundle-product-form__show                        : false !default;

// 07.10 Product stock
$ts-product-detail-stock__font-size                          : ($ts-stock-label-font-size - 0.0625) !default;
$ts-product-detail-stock__line-height                        : $ts-product-detail-price-font-size !default;

// 08. Customer Section
$ts-underlined-title-font-family                             : $header-font-family !default;
$ts-underlined-title-font-size                               : rem-calc(24) !default;
$ts-underlined-title-font-weight                             : 300 !default;
$ts-underlined-title-line-height                             : 1.2 !default;

$ts-subtitle-font-family                                     : $header-font-family !default;
$ts-subtitle-font-size                                       : rem-calc(30) !default;
$ts-subtitle-font-weight                                     : 300 !default;
$ts-subtitle-action-font-weight                              : 400 !default;
$ts-subtitle-action-font-family                              : $body-font-family !default;
$ts-subtitle-action-font-size                                : rem-calc(13) !default;
$ts-subtitle-line-height                                     : 1 !default;
$ts-boxtitle-font-family                                     : $header-font-family !default;
$ts-boxtitle-font-size                                       : rem-calc(22) !default;
$ts-boxtitle-font-weight                                     : 300 !default;

$ts-customer-sidebar-vertical-top                            : false !default;
$ts-customer-sidebar-hz-indent-coeff                         : .75 !default;
$ts-customer-sidebar-background-color                        : $box-color !default;
$ts-customer-sidebar-box-shadow                              : 0 0 15px rgba($black, .1) !default;
$ts-customer-sidebar-font-size                               : rem-calc(13) !default;
$ts-customer-sidebar-font-weight                             : 400 !default;
$ts-customer-sidebar-font-weight-active                      : 400 !default;
$ts-customer-sidebar-active-bg                               : lighten($secondary-color, 71.7647) !default;
$ts-customer-sidebar-active-underline                        : none !default;
$ts-customer-sidebar-line-height                             : 1.2 !default;
$ts-customer-sidebar-item-spacing-top                        : rem-calc(10) !default;
$ts-customer-sidebar-item-spacing-side                       : rem-calc(25) !default;
$ts-customer-sidebar-item-color                              : $body-font-color !default;
$ts-customer-sidebar-item-active-color                       : $white !default;
$ts-customer-sidebar-item-underline                          : none !default;
$ts-customer-sidebar-item-border                             : true !default;
$ts-customer-sidebar-item-border-color                       : lighten($secondary-color, 63.5294) !default;

$ts-goback-button-icon                                       : "icon-arrow-next-light-blue" !default; // Possible values: icon-name, false
$ts-goback-button-icon-width                                 : rem-calc(8) !default;
$ts-goback-button-icon-height                                : rem-calc(12) !default;
$ts-goback-button-icon-rotate                                : true !default;
$ts-goback-button-icon-type                                  : svg !default;  // Possible values: png, svg, none

// 08.1 Login Page
$ts-login-show-page-title                                    : false !default;
$ts-login-main-title-font-family                             : $header-font-family !default;
$ts-login-main-title-font-weight                             : 300 !default;
$ts-login-main-title-text-transform                          : $header-text-transform !default;
$ts-login-section-bg                                         : $box-color !default;
$ts-login-section-box-shadow                                 : 0 0 10px rgba($black,.1) !default;
$ts-login-section-padding                                    : ( small : 1.25rem 1.5rem, xlarge : 2.25rem 2.5rem) !default;

// Password Strong
$ts-password-height                                          : $ts-input-height/2.1 !default;
$ts-password-padding                                         : 0 .5rem !default;
$ts-password-font-size                                       : rem-calc(10) !default;
$ts-password-default                                         : $light-gray !default;
$ts-password-weak                                            : #ffafae !default;
$ts-password-medium                                          : #ffd6b3 !default;
$ts-password-strong                                          : #c5eeac !default;
$ts-password-very-strong                                     : #81b562 !default;


// 09. Brands Page
$ts-brands-navbar-bg                                         : $white !default;
$ts-brands-navbar-box-shadow                                 : 0 0 10px rgba($black,.2) !default;
$ts-brands-navbar-padding                                    : .25rem 0 .5rem !default;
$ts-brands-navbar-label-font-family                          : $header-font-family !default;
$ts-brands-navbar-label-font-size                            : rem-calc(22) !default;
$ts-brands-navbar-label-font-weight                          : 300 !default;
$ts-brands-navbar-alphabet-font-family                       : $header-font-family !default;
$ts-brands-navbar-alphabet-font-size                         : rem-calc(22) !default;
$ts-brands-navbar-alphabet-font-weight                       : 300 !default;
$ts-brands-navbar-alphabet-color                             : $anchor-color !default;

$ts-brands-section-bg                                        : $box-color !default;
$ts-brands-section-padding                                   : 1.2rem !default;
$ts-brands-section-capital-letter-font-family                : $header-font-family !default;
$ts-brands-section-capital-letter-font-size                  : rem-calc(50) !default;
$ts-brands-section-capital-letter-font-weight                : 300 !default;
$ts-brands-section-capital-letter-color                      : $body-font-color !default;
$ts-brands-section-text-color                                : $anchor-color !default;


// 09. Account Section
$ts-account-register-subtitle-size                           : rem-calc(22) !default;
$ts-account-register-subtitle-weight                         : 500 !default;
$ts-account-register-subtitle-family                         : $header-font-family !default;
$ts-account-register-subtitle-indent                         : 1.25rem !default;

$ts-account-register-block-alignment                         : center !default;

// 11. Checkout Cart
$ts-checkout-sidebar-wide                                    : true !default;
$ts-checkout-sidebar-bg                                      : $box-color !default;
$ts-checkout-sidebar-shadow                                  : 0 0 15px rgba($black, .1) !default;
$ts-delete-icon-width                                        : 19px !default;
$ts-delete-icon-height                                       : 21px !default;
$ts-delete-icon                                              : "icon-delete" !default;
$ts-delete-icon-type                                         : svg !default;

$ts-cart-title-mobile-font-size                              : rem-calc(28) !default;
$ts-cart-summary-title-visible                               : false !default;
$ts-cart-summary-label-free                                  : simple !default; // simple or styled
$ts-cart-summary-title-font-size                             : rem-calc(32) !default;
$ts-cart-summary-title-font-weight                           : 600 !default;
$ts-cart-summary-title-font-family                           : $header-font-family !default;
$ts-cart-summary-title-spacing                               : .65rem 0 .55rem !default;
$ts-cart-methods-title-font-size                             : rem-calc(22) !default;
$ts-cart-methods-title-font-weight                           : 600 !default;
$ts-cart-methods-title-font-family                           : $header-font-family !default;

$ts-cart-summary-table-font-size                             : rem-calc(13) !default;
$ts-cart-summary-table-font-weight                           : 400 !default;
$ts-cart-summary-table-color                                 : $body-font-color !default;

$ts-cart-summary-table-line-height                           : 1.3 !default;
$ts-cart-summary-table-font-weight                           : 400 !default;

$ts-cart-summary-table-total-font-size                       : rem-calc(17) !default;
$ts-cart-summary-table-grandtotal-font-size                  : rem-calc(20) !default;
$ts-cart-summary-table-total-font-family                     : $header-font-family !default;
$ts-cart-summary-table-total-amount-font-size                : rem-calc(17) !default;
$ts-cart-summary-table-grandtotal-amount-font-size           : rem-calc(20) !default;
$ts-cart-summary-table-total-amount-font-weight              : 300 !default;
$ts-cart-summary-table-grandtotal-font-weight                : 400 !default;
$ts-cart-summary-table-total-text-transform                  : uppercase !default;
$ts-cart-summary-table-grandtotal-text-transform             : uppercase !default;
$ts-cart-summary-table-grandtotal-font-family                : $header-font-family !default;

$ts-cart-table-border-color                                  : lighten($black, 90);
$ts-cart-table-cell-indent                                   : 1rem !default;
$ts-cart-table-cell-line-height                              : 1.3 !default;
$ts-cart-table-cell-font-weight                              : 400 !default;

$ts-cart-table-product-price-font-size                       : rem-calc(24) !default;
$ts-cart-table-product-price-font-family                     : $header-font-family !default;
$ts-cart-table-product-price-font-weight                     : 300 !default;
$ts-cart-table-product-price-color                           : inherit !default;

$ts-cart-table-product-title-font-size                       : ( small    : rem-calc(13),
                                                                 large   : rem-calc(17)) !default;
$ts-cart-table-product-title-font-family                     : $header-font-family !default;
$ts-cart-table-product-title-font-weight                     : 300 !default;
$ts-cart-table-product-title-text-transform                  : uppercase !default;

$ts-cart-table-subtotal__show                                : false !default;
$ts-cart-table-actions__show                                 : false !default;
$ts-cart-table-item-action__show                             : false !default;

// 11.1 Checkout
$ts-checkout-headbar-height                                  : ( small : rem-calc(62), normal : rem-calc(131), tall : rem-calc(131) ) !default;
$ts-checkout-header-border-bottom                            : 1px solid $ts-main-dark-border-color !default;
$ts-checkout-headbar-info-color                              : $body-font-color !default;
$ts-checkout-headbar-link-color                              : $anchor-color !default;
$ts-checkout-headbar-info-font-size                          : rem-calc(13) !default;

$ts-checkout-headbar-total-color                             : $body-font-color !default;

$ts-checkout-headbar-total-font-family                       : $header-font-family !default;
$ts-checkout-headbar-total-font-weight                       : 300 !default;
$ts-checkout-headbar-total-font-size                         : rem-calc(28) !default;
$ts-checkout-headbar-total-title-transform                   : none !default;

$ts-checkout-headbar-contact-font-family                     : $header-font-family !default;
$ts-checkout-headbar-contact-font-weight                     : 300 !default;
$ts-checkout-headbar-contact-font-size                       : rem-calc(28) !default;
$ts-checkout-headbar-contact-title-transform                 : uppercase !default;
$ts-checkout-headbar-contact-tel-color                       : $body-font-color !default;
$ts-checkout-headbar-contact-tel-icon                        : 'icon-phone' !default;
$ts-checkout-headbar-contact-tel-icon-width                  : 23px !default;
$ts-checkout-headbar-contact-tel-icon-height                 : 23px !default;
$ts-checkout-headbar-contact-tel-icon-type                   : svg !default; // possible values: svg, png, none. If set to none, no icon will be shown at all.
$ts-checkout-headbar-contact-tel-icon-position               : 50% !default;
$ts-checkout-headbar-contact-tel-padding-left                : rem-calc(32) !default;

$ts-checkout-headbar-goback-button-icon                      : "icon-arrow-next-gray" !default; // Possible values: icon-name, false
$ts-checkout-headbar-goback-button-icon-width                : rem-calc(8) !default;
$ts-checkout-headbar-goback-button-icon-height               : rem-calc(12) !default;
$ts-checkout-headbar-goback-button-icon-rotate               : true !default;
$ts-checkout-headbar-goback-button-icon-type                 : svg !default;  // Possible values: png, svg, none

$ts-checkout-authmodal-title-font-weight                     : 300 !default;
$ts-checkout-authmodal-title-font-size                       : rem-calc(24) !default;
$ts-checkout-authmodal-title-text-transform                  : uppercase !default;
$ts-checkout-authmodal-text-color                            : rgba($body-font-color, .8) !default;

$ts-checkout-title__show                                     : false !default;
$ts-checkout-h1-font-weight                                  : 300 !default;
$ts-checkout-h1-text-align                                   : center !default;

$ts-checkout-box-bg-color                                    : $box-color !default;
$ts-checkout-box-shadow                                      : 0 0 15px rgba($black, .1) !default;
$ts-checkout-box-spacing                                     : ( small : 1.5rem, xlarge : 3.75rem ) !default;
$ts-checkout-box-title-font-family                           : $header-font-family !default;
$ts-checkout-box-title-font-weight                           : 300 !default;
$ts-checkout-box-title-font-size                             : rem-calc(32) !default;
$ts-checkout-box-title-title-transform                       : none !default;
$ts-checkout-box-title-title-color                           : $body-font-color !default;

$ts-checkout-comment-line-title-decoration                   : underline !default;
$ts-checkout-comment-line-title-color                        : $anchor-color !default;

$ts-checkout-address-country__show                           : false !default;

$ts-checkout-shipping-list-border                            : 1px solid $ts-main-border-color !default;
$ts-checkout-shipping-list-spacing-out                       : 1.25rem !default;
$ts-checkout-shipping-list-spacing-in                        : 1.5rem !default;
$ts-checkout-shipping-list-title-font-family                 : $header-font-family !default;
$ts-checkout-shipping-list-title-font-weight                 : 400 !default;
$ts-checkout-shipping-list-title-font-size                   : rem-calc(16) !default;
$ts-checkout-shipping-list-price-font-size                   : rem-calc(18) !default;
$ts-checkout-shipping-list-title-title-transform             : none !default;

$ts-checkout-order-total-font-family                         : $header-font-family !default;
$ts-checkout-order-total-font-weight                         : 300 !default;
$ts-checkout-order-total-font-size                           : rem-calc(32) !default;
$ts-checkout-order-total-title-transform                     : none !default;

$ts-checkout-payment-method-title-font-family                : $header-font-family !default;
$ts-checkout-payment-method-title-font-weight                : 400 !default;
$ts-checkout-payment-method-title-font-size                  : rem-calc(16) !default;
$ts-checkout-payment-method-title-transform                  : none !default;

$ts-checkout-agreement-title-transform                       : none !default;
$ts-checkout-agreement-title-border                          : 1px solid $ts-main-border-color !default;
$ts-checkout-agreement-title-spacing                         : 1rem !default;

$ts-checkout-main-button-font-size                           : rem-calc(22) !default;
$ts-checkout-main-button-font-weight                         : 300 !default;
$ts-checkout-main-button-top-indent                          : 1rem !default;
$ts-checkout-main-button-bottom-indent                       : 1rem !default;
$ts-checkout-main-button-hz-indent                           : 1.5em !default;
$ts-checkout-main-button-text-transform                      : none !default;
$ts-checkout-main-button-has-arrow                           : true !default;
$ts-checkout-main-button-arrow-width                         : 7px !default;
$ts-checkout-main-button-arrow-height                        : 12px !default;

$ts-checkout-minicart-font-size                              : rem-calc(13) !default;
$ts-checkout-minicart-border-color                           : $ts-main-dark-border-color !default;
$ts-checkout-minicart-title-font-family                      : $header-font-family !default;
$ts-checkout-minicart-subtotal-font-family                   : $header-font-family !default;
$ts-checkout-minicart-qty-font-family                        : $header-font-family !default;
$ts-checkout-minicart-title-font-weight                      : 500 !default;
$ts-checkout-minicart-subtotal-font-weight                   : 500 !default;
$ts-checkout-minicart-title-transform                        : none !default;
$ts-checkout-minicart-qty-color                              : rgba($body-font-color, .5) !default;
