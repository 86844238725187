.customer-account-create {
    .b-account-register {
        .field-row {
            .field--small {
                margin-bottom: rem-calc(10);
                @include breakpoint(xlarge) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
