.mm-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    position: absolute;
    top: $mm_offsetTop;
    right: $mm_offsetRight;
    bottom: $mm_offsetBottom;
    left: $mm_offsetLeft;
    z-index: 0;

    background: $mm_backgroundColor;
    border-color: $mm_borderColor;
    color: $mm_textColor;
    line-height: $mm_lineHeight;

    -webkit-tap-highlight-color: $mm_common_emphasizedBackgroundColor;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a,
    a:link,
    a:active,
    a:visited,
    a:hover {
        text-decoration: none;
        // color: inherit;
    }
}
