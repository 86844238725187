//	Animations
$mm_transitionDuration: 0.4s !default;
$mm_transitionDelay: 0.4s !default;
$mm_transitionFunction: ease !default;

//	Colors
$mm_backgroundColor: $primary-color !default;
$mm_borderColor: rgba($white, .1) !default;
$mm_dimmedTextColor: $white !default;
$mm_common_emphasizedBackgroundColor: rgba($white, 0) !default;
$mm_emphasizedTextColor: $white !default;
$mm_emphasizedBackgroundColor: $primary-color !default;
$mm_emphasizedArrowColor: $white !default;
$mm_highlightedTextColor: inherit !default;
$mm_highlightedBackgroundColor: inherit !default;
$mm_highlightedArrowColor: $mm_dimmedTextColor !default;
$mm_navbarColor: $body-font-color !default;
$mm_textColor: $white!important !default;

//	Positioning
$mm_offsetTop: 0 !default;
$mm_offsetRight: 0 !default;
$mm_offsetBottom: 0 !default;
$mm_offsetLeft: 0 !default;

//	Sizes
$mm_listitemSize: 52px !default;
$mm_btnSize: 100% !default;
$mm_padding: 0 !default;
$mm_lineHeight: 24px !default;
$mm_panelBottomIndent: 35px !default;

// ListItem
$mm_listitemFontSize: 18px !default;
$mm_listitemWeight: 400 !default;
$mm_listitemStyle: normal !default;
$mm_listitemFamily: $header-font-family !default;
$mm_listitemLineHeight: normal !default;

$mm_listitemIndent: 0px !default;
$mm_navbarSize: $mm_listitemSize * 2 !default;
$mm_panelPadding: $mm_padding * 2 !default;
$mm_subopenWidth: $mm_btnSize !default;
$mm_subpanelOffset: 0% !default;

// Next-Prev Arrows
$mm_arrowThick: 1px !default;
$mm_arrowWidth: 10px !default;
$mm_arrowHeight: 10px !default;
$mm_arrowOpacity: 0.5 !default;
$mm_arrowIndent: 0 !default;

$mm_prevWidth: 50px !default;
$mm_prevTopShift: 0 !default;
$mm_arrowPrevWidth: 15px !default;
$mm_arrowPrevHeight: 15px !default;
$mm_arrowPrevColor: $primary-color !default;
$mm_arrowPrevOpacity: 1 !default;

$mm_menuWidth: 0.8 !default;
$mm_menuMinWidth: 240px !default;
$mm_menuMaxWidth: 440px !default;
$mm_menuHeight: 0.8 !default;
$mm_menuMinHeight: 140px !default;
$mm_menuMaxHeight: 880px !default;

$mm_opt_screenreader	: true !default;

$mm_iconpanelSize: $mm_btnSize !default;

$mm_fullscreen_full: 1 !default;
$mm_fullscreen_min: 140px !default;
$mm_fullscreen_max: 100vw !default;

// Top Panel Main Title
$mm_panelTitleSize: 15px !default;
$mm_panelTitleWeight: normal !default;
$mm_panelTitleStyle: normal !default;
$mm_panelTitleFamily: $header-font-family !default;
$mm_panelTitleColor: $white !default;
$mm_panelTitleLineHeight: normal !default;
$mm_panelTitleTopShift: 0 !default;

// Close Button
$mmPathToImages: "../images" !default;
$mm_closeWidth: 61px !default;
$mm_closeHeight: $mm_navbarSize !default;
$mm_closeIconWidth: 21px !default;
$mm_closeIcon: "icon-close-white" !default;
$mm_closeIconPosition: 14px 50% !default;
$mm_closePadding: 0 !default;
$mm_closeTop: 0 !default;
$mm_closeRight: 0 !default;
$mm_closeTextHide: true !default;

$mm_close_textSize: 13px !default;
$mm_close_textWeight: normal !default;
$mm_close_textFamily: inherit !default;
$mm_close_textLine: normal !default;

// Offcanvas Links
$mm_LinksMenu_inner-spacing: 0 0 0 28px !default;
$mm_LinksMenu_border: none !default;
$mm_LinksMenu_titleFontSize: 24px !default;
$mm_LinksMenu_titleFontFamily: inherit !default;
$mm_LinksMenu_titleFontWeight: inherit !default;
$mm_LinksMenu_titleColor: inherit !default;
$mm_LinksMenu_titleMarginBottom: 10px !default;
$mm_LinksMenu_titleTransform: none !default;
$mm_LinksMenu_titleHide : true !default;
$mm_LinksMenu_itemFontSize: 15px !default;
$mm_LinksMenu_itemFontFamily: $header-font-family !default;
$mm_LinksMenu_itemFontWeight: normal !default;
$mm_LinksMenu_itemColor: inherit !default;
$mm_LinksMenu_itemPadding: 0 0 17px !default;
$mm_LinksMenu_itemUnderline: none !default;
$mm_LinksMenu_itemTransform: none !default;
$mm_LinksMenu_itemActiveColor: inherit !default;
