$mm_module: '.mm-listitem';

.mm-listview {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}

#{$mm_module} {
    color: $mm_textColor;
    border-color: $mm_borderColor;

    list-style: none;
    display: block;
    padding: 0;
    margin: 0;

    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        border-color: inherit;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background: none;
        border-radius: 0;
    }

    a,
    a:hover {
        text-decoration: none;
    }
}

%mm_listitem_content {
    display: block;
    padding: (($mm_listitemSize - $mm_lineHeight) / 2);
    padding-left: 0;
    padding-right: 0;
    font-size: $mm_listitemFontSize;
    font-weight: $mm_listitemWeight;
    font-style: $mm_listitemStyle;
    font-family: $mm_listitemFamily;
    line-height: $mm_listitemLineHeight;
}

#{$mm_module}__text {
    @extend %mm_listitem_content;
    @include mm_ellipsis;

    padding-left: $mm_listitemIndent;
    padding-right: $mm_padding;
    flex-grow: 1;
    // flex-basis: 10%;
}

#{$mm_module}__btn {
    @extend %mm_listitem_content;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // padding-right: $mm_subopenWidth;
}

#{$mm_module} {
    &:active,
    &.active:active,
    &_selected {
        > #{$mm_module}__text,
        > #{$mm_module}__text:visited {
            color: $mm_emphasizedTextColor;
            background: $mm_emphasizedBackgroundColor;
        }
        > #{$mm_module}__btn {
            &:after,
            &:before {
                border-color: $mm_emphasizedArrowColor;
            }
        }
    }

    &.active,
    &_opened {
        > #{$mm_module}__text {
            color: $mm_highlightedTextColor;
            background: $mm_highlightedBackgroundColor;
        }
        > #{$mm_module}__btn {
            &:after,
            &:before {
                border-color: $mm_highlightedArrowColor;
            }
        }
    }
}
