.hr-grid.hr-products-container, .hr-search-overlay-product {
     .tier-price {
        color: #F7465B;
        font-weight: 600;
        font-size: 1.25rem;
        vertical-align: middle;
    }

    .old-price.sly-old-price.no-display {
        margin-right: 5px;
    }
}

.hr-overlay-search .hr-results .hr-products.initialcontent .hr-search-overlay-product {
    flex-basis: 236px !important;
    max-width: 236px !important;
}
