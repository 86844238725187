//
//  Typography
//  _____________________________________________
h1, .as-h1,
h2, .as-h2,
h3, .as-h3,
h4, .as-h4,
h5, .as-h5,
h6, .as-h6 {
	letter-spacing: $header-letter-spacing;
	text-transform: $header-text-transform;
	&.bold {
		font-weight: $global-title-weight;
	}

	a {
		color: $header-color;
		text-decoration: none;
		cursor: pointer;
	}

	strong,
	b {
		font-weight: inherit;
	}
}

h1,
.as-h1 {
	@include get-gutter(.75, 'margin-bottom');
}
h2,
.as-h2 {
	@include get-gutter(.5, 'margin-bottom');
}
h3,
.as-h3 {
	@include get-gutter(.25, 'margin-bottom');
}
h4,
.as-h4 {
	@include get-gutter(.15, 'margin-bottom');
}

small {
	color: $header-small-font-color;
}

p {
	font-weight: $paragraph-font-weight;
}

.switch {
	color: $switch-color;
	font-weight: $switch-weight;
}

strong,
b {
	font-weight: $global-weight-bold;
}

.txt-nowrap {
	white-space: nowrap;
}

//  Item Options
.item-options {
    overflow: hidden;
    color: rgba($body-font-color, .8);

    dt {
        float: left;
        clear: left;
        font-weight: normal;
        padding-right: .5rem;
        @include label-colon;
    }
    dd {
        float: left;
    }
}

// MVA label for B2B user
.mva_label {
	color: $ts-product-detail-old-price-color;
	font-size: ($ts-product-detail-old-price-font-size * .75);
	font-weight: $ts-product-detail-old-price-font-weight;
	letter-spacing: normal;
}// .mva_label