.footer-social {
	li {
		display: inline-block;
		margin-right: $ts-footer-social-right-indent;
		font-size: 0;

		&:last-child {
			margin-right: 0;
		}
	} // li

	a {
		display: inline-block;
		color: $ts-footer-social-anchor-color;
		opacity: $ts-footer-social-icon-opacity;

		&:hover {
			color: $ts-footer-social-anchor-color-hover;
			opacity: $ts-footer-social-icon-opacity-hover;

			@if ($ts-footer-social-icon-type != none) {
				&:before {
					background-color: $ts-footer-social-icon-bg-color-hover;
				}
			} // @if
		}

		@if ($ts-footer-social-icon-type != none) {
			position: relative;
			line-height: $ts-footer-social-icon-size;

			&:before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: $ts-footer-social-icon-size;
				height: $ts-footer-social-icon-size;
				background-color: $ts-footer-social-icon-bg-color;
				background-position: 50%;
				background-repeat: no-repeat;
				background-size: $ts-footer-social-icon-bg-size auto;
				transition: background-color .5s ease;

				@if ( $ts-footer-social-icon-shape == round ) {
					border-radius: 100%;
				} @else if ( $ts-footer-social-icon-shape == rounded ) {
					border-radius: $global-radius;
				} // @if

				@if ( $ts-footer-social-icon-border-width > 0) {
					border: $ts-footer-social-icon-border-width $ts-footer-social-icon-border-style $ts-footer-social-icon-border-color;
				}
			}
		} // @if
	} // a

	@if ($ts-footer-social-icon-type != none) {

		@each $tmp-network in $ts-footer-social-icon-networks {
			.icon-#{$tmp-network} {
				&:before {
					background-image: url("#{$ts-path-to-images}/icon-#{$tmp-network}.#{$ts-footer-social-icon-type}");
				}
			}
		} // @each
	} // @if
} // .footer-social

