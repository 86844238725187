.header {

	.minicart-wrapper {
		border: 0;
		display: inline-block;
		line-height: 1;
		position: relative;
		background: none;

		@include breakpoint(xlarge down) {
			margin-top: .5rem;
		}


		@if ($ts-header-cart-dropdown-showcart-type == image-only) {
			&:after {
				display: block;
				content: '';
				width: $ts-header-cart-dropdown-showcart-icon-width;
				height: $ts-header-cart-dropdown-showcart-icon-height;
			}
			.action.showcart {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				@include breakpoint(medium down) {
					position: relative;
					left: auto;
					top: auto;
					transform: scale(.85) translateY(0);
					margin: 0;
				}

				@include breakpoint(xxlarge) {
					left: 0;
					right: auto;
					margin: $ts-header-cart-dropdown-showcart-icon-spacing;
				}

			}// .action.showcart
		}

		.mage-dropdown-dialog {
			display: block;
			position: absolute;
			right: $ts-header-cart-dropdown-position-right;
			top: $ts-header-cart-dropdown-position-top;
			margin-top: $ts-header-cart-dropdown-position-top-indent;
			width: $ts-header-cart-dropdown-width;
			@include get-gutter(0.5,'padding');
			z-index: $ts-header-cart-dropdown-z-index;
			background-color: $ts-header-cart-dropdown-bg-color;
			@if ( $ts-header-cart-dropdown-box-shadow != none ) {
				box-shadow: $ts-header-cart-dropdown-box-shadow;
			}

			.block-title,
			.subtitle:not(.empty) {
				@include at;
			}

			.subtitle {
				&.empty {
					font-size: $ts-header-cart-dropdown-subtitle-empty-size;
					color: $ts-header-cart-dropdown-subtitle-empty-color;
					font-weight: $ts-header-cart-dropdown-subtitle-empty-weight;
				}
			}// .subtitle

			.action.close {
				display: inline-block;
				position: absolute;
				right: $ts-header-cart-dropdown-close-position-right;
				top: $ts-header-cart-dropdown-close-position-top;
				font-size: $ts-header-cart-dropdown-close-font-size;
				color:$ts-header-cart-dropdown-close-color;

				span {
					@include at;
				}
			}// .action.close

        }// .mage-dropdown-dialog
    }  // .minicart-wrapper
}// .content.minicart-items
