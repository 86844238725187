.checkout-klarna-index {
    .klarna-wrapper {
        #checkoutSteps {
            .delivery-step {
                .ingrid-checkout-ko {
                    float: none;
                    width: 90%;
                    margin: auto auto 50px;
                    max-width: 86.25rem;
                }
            }
        }
    }
}
