.page-with-filter {
	.shipping-bar-container {
		display: none;

		@include breakpoint(medium) {
			display: block;
		}
	}
}// .page-with-filter


.breadcrumbs {
	@include grid-col;
	@include grid-col-end;

	@include breakpoint(medium) {
		@include grid-col-size(7);
	}

	+ .vat-label {
		bottom: auto;
		top: .25rem;
	}

	.items {
		@include breadcrumbs-container;
		@include lsn;
		margin-bottom: 0;

		.disabled {
		  color: $breadcrumbs-item-color-disabled;
		  cursor: not-allowed;
		}

		li {
			margin-#{$global-right}: $breadcrumbs-item-margin;
			font-weight: $breadcrumbs-font-weight;
			font-family: $breadcrumbs-font-family;
			&:not(:last-child)::after {
				width: auto;
				height: auto;
				content: $breadcrumbs-item-slash-view;
				display: inline-block;
				vertical-align: baseline;
				position: relative;
				top: auto;
				left: auto;
				font-size: $breadcrumbs-item-font-size;
				color: $breadcrumbs-item-color;
				background-color: transparent;
			}

			@if not $breadcrumbs-item-slash {
				$slash: '';

				&:not(:last-child)::after {
					content: $slash;
					width: 5px;
					height: 8px;
					margin: 0 $breadcrumbs-item-margin;
					background: url("#{$ts-path-to-images}/icon-breadcrumb.#{$ts-breadcrumb-icon-type}") no-repeat;
					background-size: 100% auto;
					background-color: transparent;
				}
			}

			strong {
				font-weight: inherit;
			}

			a {
				text-decoration: $ts-breadcrumb-link-underline;

				&:hover {
					text-decoration: $ts-breadcrumb-link-hover-underline;
				}
			}
		}// li

	}// .items
}// .breadcrumbs

.shipping-bar {

	> div {
		@include clearfix;
	}

	.progress-text {
		font-size: $breadcrumbs-item-font-size;
		font-family: $breadcrumbs-font-family;
		text-align: right;
		line-height: $progress-height;
		margin-bottom: .25rem;
		font-weight: $breadcrumbs-font-weight;
		color: $breadcrumbs-item-color;

		@if $breadcrumbs-item-uppercase {
			text-transform: uppercase;
		}

		@include breakpoint(medium) {
			width: 50%;
			float: left;
			margin-bottom: 0;
			@include get-gutter(.5,'padding-right');
		}

		@include breakpoint(xxlarge) {
			width: 80%;
		}
	}// .progress-text

	.progress-graph {
		@include progress-container;
		border: $progress-border;
		padding: $progress-padding;

		&.small-shipping-bar {
			height: ($progress-height / 2);
			padding: 0;
		}

		@include breakpoint(medium) {
			width: 50%;
			float: left;
		}

		@include breakpoint(xxlarge) {
			width: 20%;
		}

		.progress-bar {
			@include progress-meter;
		}
	}// .progress-graph
}// .shipping-bar

.top-controls-wrapper {
	@include grid-row;
	position: relative;
	z-index: 1;
	margin-top: $ts-shipping-bar-top-indent;
	margin-bottom: map-get($ts-shipping-bar-bottom-indent, small);

	.algolia-instant-selector-results & {
		.cms-index-index & {
			display: block;
		}
	}

	.shipping-bar-wrapper {
		@include clearfix;
	}

	.cms-index-index & {
		display: none;
	}

	.page-with-filter &,
	.algolia-instant-selector-results & {
		@include breakpoint(large) {
			margin-bottom: map-get($ts-shipping-bar-bottom-indent-filter, large);
		}
		@include breakpoint(xxlarge) {
			margin-bottom: map-get($ts-shipping-bar-bottom-indent-filter, xxlarge);
		}
		@include breakpoint(xxlarge) {
			margin-bottom: map-get($ts-shipping-bar-bottom-indent-filter, xxlarge);
		}
	}

	.category-header.has-bg & {
		@include breakpoint(large) {
			margin-bottom: 2.75rem;
		}
	}

	.category-header.has-bg.has-desc & {
		@include breakpoint(xxlarge) {
			margin-bottom: 5rem;
		}
	}

	.category-header.has-bg.has-image & {
		@include breakpoint(xxlarge) {
			margin-bottom: 4.5rem;
		}
	}

	.shipping-bar-container {
		display: none;
		position: relative;
		@include grid-col;

		@include breakpoint(large) {
			display: block;
			@include grid-col-size(5);

			.shipping-bar-container {
				width: 100%;
				float: none;
				@include get-gutter(0,'padding-left');
				@include get-gutter(0,'padding-right');
			}
		}
	}// .shipping-bar-container

	.vat-label {
		position: absolute;
		bottom: 0;
		@include get-gutter(.5,'right');
		font-size: $breadcrumbs-item-font-size;
		line-height: $progress-height;
		color: $breadcrumbs-item-color;

		@include breakpoint(medium down) {
			@include at;
		}
	}

}// .top-controls-wrapper
