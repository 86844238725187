// Hide cookie message; bug in Magento v2.4.3
.cookie-status-message {
    display: none;
}

i,
em {
}

sup {
    font-size: 50%;
    top: -0.6em;
}

//  Lists
//  ---------------------------------------------
ul {
    > li {
        padding: 0 0 0.8em 1.5em;
        @include breakpoint(large) {
            padding: 0 0 0.4em 1.5em;
        }

        &:after {
            content: none;
        }
    }
}

// Buttons
//  ---------------------------------------------
.button,
button.action {
    border-radius: $button-radius;

    &.primary {
        @include button-style($button-background);
        box-shadow: $ts-button-box-shadow;

        &.disabled,
        &[disabled] {
            &:hover,
            &:focus,
            &:active {
                background-color: $button-background;
            }
        }
    }

    // .primary
    &.secondary {
        background-color: $button-background-secondary;
        @include button-hollow-style(map-get($button-palette, primary), 1);
        box-shadow: $ts-button-box-shadow;

        &:hover,
        &:focus,
        &:active {
            background-color: $button-background-secondary-hover;
        }

        &.disabled,
        &[disabled] {
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

    // default

    // Default Hollow
    &.convert-button {
        padding-left: 1rem;
        padding-right: 1rem;

        &.hollow {
            border-radius: 0;
            border: 5px solid #f1f1f1;
            box-shadow: none;
            margin: 0.5rem 0;
        }
    }

    //inactive
    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}

// button

// Checkboxes
//  ---------------------------------------------
.checkbox-wrapper {
    input {
        display: none;

        & + label {
            position: relative;
            margin: 0 0 17px 0;
            padding-left: 32px;
            font-size: 14px;
            line-height: 24px;

            &:before {
                content: "";
                position: absolute;
                display: block;
                left: 0;
                top: 3px;
                background-color: #ededed;
                height: 20px;
                width: 20px;
                box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
            }
        }

        &:checked + label {
            &:before {
                background-color: $primary-color;
                background-image: url("#{$ts-path-to-images}/icon-tick.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 60% auto;
            }
        }
    }
}

// Modal
//  ---------------------------------------------
body._has-modal {
    position: absolute; // block scrolling to top of a page
}

// Breadcrumbs
//  ---------------------------------------------
.top-controls-wrapper {
    .breadcrumbs {
        .items {
            @include breakpoint(large) {
                font-size: 14px;
                line-height: 24px;

                a {
                    color: rgba($white, 0.5);
                }

                li {
                    color: rgba($white, 0.5);

                    &:not(:last-child):after {
                        color: rgba($white, 0.5);
                    }
                }
            }
        }
    }

    .shipping-bar {
        .progress-text {
            color: $white;
            font-size: 12px;
        }
    }
}

// top-controls-wrapper

// Search
//  ---------------------------------------------
.mobile-search-wrapper {
    transition: none;

    .mobile-search-bar {
        padding-top: 0;

        .minisearch {
            .input-text {
                margin-bottom: 3px;
            }
        }
    }

    &.in-scroll {
        z-index: $ts-headbar-zindex + 2;

        .mobile-search-bar {
            padding: 6px 0 0 !important;
            border-bottom: 0;
        }
    }
}

// mobile-search-wrapper

// Showcart
a.action.showcart {
    .counter {
        bottom: 35px;
        right: -10px;
        line-height: 16px;
        background-color: #f7465b;
        border: 2px solid #f7465b;
        @include breakpoint(large) {
            right: 9px;
        }
    }
}

body:not(.is-sticky) .add-to-cart-popup .add-popup-showcart {
    @include breakpoint(large) {
        margin: 6px 2px 0 0;
    }
}

//MMenu
//  ---------------------------------------------
.mm-menu {
    .mm-navbar {
        border: 0;
    }

    .mm-navbar__title {
        color: $primary-color;
        justify-content: flex-start;
    }

    .mm-btn_prev {
        + .mm-navbar__title {
            color: $black;
            font-size: rem-calc(18);
            justify-content: center;
        }
    }

    .mm-btn_prev {
        &:before {
            left: 25px;
        }
    }

    .mm-listview {
        padding-left: 25px;
        padding-right: 25px;
    }

    .mm-listitem {
        &:last-child {
            &:after {
                content: none;
            }
        }
    }

    .top-links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        li {
            line-height: 50px;
            width: 50%;

            .item {
                display: block;
                padding-left: 34px;
                background-position: left center;
                background-repeat: no-repeat;

                &.icon-location {
                    background-image: url("#{$ts-path-to-images}/icon-location.svg");
                    background-position: 4px center;
                    padding-right: 7px;
                }

                &.icon-info {
                    background-image: url("#{$ts-path-to-images}/icon-info.svg");
                    padding-right: 7px;
                }

                &.icon-return {
                    background-image: url("#{$ts-path-to-images}/icon-return.svg");
                }

                &.icon-star {
                    background-image: url("#{$ts-path-to-images}/icon-star.svg");
                }
            }
        }
    }
}

.mm-offcanvas-links {
    margin-top: 15px;

    .offcanvas-links:not(.top.links) {
        display: none;
    }
}

.mm-panels > .mm-panel {
    display: flex;
    flex-direction: column;

    &:after {
        content: "";
        display: block;
        flex: 1;
        background-image: url("#{$ts-path-to-images}/menu-background.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding-bottom: 35%;
        pointer-events: none;
    }
}

//HEADER
//  ---------------------------------------------
.page-header {
    padding: 0;
    @include breakpoint(large) {
        height: auto;
    }

    .is-sticky &,
    .in-scroll-main & {
        z-index: $ts-headbar-zindex + 2;
    }

    .header-top-panel {
        border-bottom: 1px solid rgba($white, 0.15);
        @include breakpoint(medium down) {
            display: none;
        }

        .header-top-wrapper {
            @include grid-row($cf: false);
            padding: 0 (map-get($grid-column-gutter, medium) / 4);
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 56px;
        }

        .top-links,
        .links:not(.usp-section) {
            @include lsn;
            display: none;
            font-size: 0;

            li {
                display: inline-block;
                vertical-align: middle;
                font-size: $ts-headbar-anchor-size;
                color: $ts-headbar-anchor-color;
                font-weight: $ts-headbar-anchor-font-weight;

                a {
                    display: block;
                    font-size: $ts-top-links-anchor-font-size;
                    color: $ts-headbar-anchor-color;
                    line-height: $ts-top-links-anchor-line-height;
                    text-transform: $ts-top-links-anchor-text-transform;
                    padding: 0 ($ts-top-links-anchor-padding);
                    position: relative;
                    font-family: $ts-top-links-anchor-font-family;
                    font-weight: $ts-top-links-anchor-font-weight;
                    text-decoration: $ts-top-links-anchor-text-decoration;

                    @if ($ts-top-links-anchor-text-shadow != none) {
                        text-shadow: $ts-top-links-anchor-text-shadow;
                    }

                    &:hover {
                        text-decoration: $ts-top-links-anchor-text-decoration-hover;
                    }
                }

                // a
            }

            // li

            @include breakpoint(xlarge) {
                display: block;
            }
        }
    }

    .header {
        // Search
        .minisearch {
            .actions {
                right: 8px;
            }
        }

        // search

        .sticky-mm {
            box-shadow: none;

            &.in-scroll {
                z-index: $ts-headbar-zindex + 2;

                &:after {
                    display: none;
                }
            }
        }

        // sticky-mm

        // Mobile
        .tab-bar {
            display: flex;
            padding: 0;
            justify-content: space-between;

            .nav-toggle {
                background-size: auto;
            }

            .logo {
                display: block;
                padding-left: 0;

                img {
                    width: 156px;
                }
            }

            .left-side {
                display: flex;
                align-items: center;
                width: auto;
            }

            .right-side {
                display: flex;
                width: auto;
                padding-left: 0;
                margin-left: auto;

                .minicart-wrapper {
                    opacity: 1;
                }
            }
        }

        // tab-bar

        .minicart-wrapper {
            height: $ts-header-search-input-height;
            margin: 0 0 0 11px;

            .action.showcart {
                display: flex;
                align-items: flex-end;
                background-position: center 3px;
                background-size: 26px 21px;
                height: 100%;
                position: static;
                transform: none;
                width: auto;
                @include breakpoint(medium down) {
                    background-position: center;
                    width: 26px;
                }

                @include breakpoint(large) {
                    .text {
                        font-size: 12px;
                        line-height: 1.6;
                        @include element-invisible-off;
                    }
                }
            }
        }

        .header-row {
            .usp-section {
                ul {
                    @include grid-col(12);

                    > li {
                        display: flex;
                        align-items: center;
                        padding: 0 0 0 31px;
                    }
                }

                //ul
            }

            //.usp-section

            // Logo
            .logo {
                line-height: normal;
                @include grid-col-size(3);
                min-width: 240px;

                &:before {
                    display: none;
                }

                img {
                    width: $ts-headbar-logo-width;
                }
            }

            // logo

            // Top Header Panel

            .sticky-header-navigation {
                @include grid-row();
                margin-top: 16px;
                padding: 0 4px;

                .navigation {
                    position: relative;
                    padding-right: 0;
                }
            }

            .sticky-header-top {
                display: flex;
                margin-top: 18px;
                align-items: center;
                @include grid-row();

                .panel.wrapper {
                    @include element-invisible;
                    position: absolute;
                    top: 0;
                    right: 0;
                    justify-content: flex-end;
                }

                .top-search-wrapper {
                    @include grid-col-size(7);
                    flex: 1;
                    text-align: right;
                    margin-top: 1px;
                    @include breakpoint(xxxlarge) {
                        text-align: center;
                    }

                    .block-search {
                        height: auto;
                        display: inline-block;
                        background: transparent;
                        box-shadow: none;
                        width: 320px;
                        @include breakpoint(xxlarge) {
                            width: 444px;
                        }

                        @include breakpoint(xxxlarge) {
                            width: 599px;
                        }
                    }
                }

                .header-control-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @include grid-col;
                    width: auto;
                    @include breakpoint(xxlarge) {
                        @include grid-col-size(2);
                    }
                }

                .panel.header {
                    margin: 0;
                }
            }

            &.is-stuck {
                position: static !important;

                @include breakpoint(medium) {
                    .sticky-header-row {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        // .header-row

        .links {
            display: flex;
            margin: 0;

            li.link {
                text-align: center;
                padding: 0;

                a {
                    display: block;
                }

                &.authorization-link,
                &.customer-section {
                    order: 4;

                    a {
                        display: flex;
                        align-items: flex-end;
                        font-size: 0;
                        height: $ts-header-search-input-height;
                        background: url("#{$ts-path-to-images}/icon-user.svg") center no-repeat;
                        padding: 0;

                        &:before,
                        &:after {
                            display: none;
                        }

                        @include breakpoint(medium down) {
                            height: 100%;
                            width: 30px;
                        }
                        @include breakpoint(large) {
                            font-size: 12px;
                            background-position: center 3px;
                        }
                    }
                }

                &.wishlist {
                    a {
                        margin-right: 10px;
                        display: flex;
                        align-items: flex-end;
                        font-size: 0;
                        height: $ts-header-search-input-height;
                        background: url("#{$ts-path-to-images}/icon-wishlist-link.svg") center no-repeat;
                        padding: 0;

                        &:before,
                        &:after {
                            display: none;
                        }

                        @include breakpoint(medium down) {
                            height: 100%;
                            width: 30px;
                        }
                        @include breakpoint(large) {
                            font-size: 12px;
                            background-position: center 3px;
                        }
                    }
                }

                &.authorization-link {
                    @include breakpoint(large) {
                        &:not(.loggedout) {
                            display: none;
                        }
                    }
                }
            }
        }

        // .links
        .nav-sections {
            border: 0;
            background-color: $primary-color;
            @include breakpoint(xxxlarge) {
                width: 100%;
            }

            .navigation {
                @include breakpoint(large) {
                    > ul {
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 15px;
                        position: relative;

                        > li {
                            &.parent {
                                > a {
                                    &:before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: 0;
                                        height: 0;
                                        border-left: 12px solid transparent;
                                        border-right: 12px solid transparent;
                                        border-bottom: 12px solid $white;
                                        left: 50%;
                                        bottom: -10px;
                                        transform: translateX(-50%);
                                        opacity: 0;
                                        transition-property: opacity;
                                        transition-duration: 0s;
                                        transition-delay: 0s;
                                    }

                                    &.ui-state-active {
                                        &:before {
                                            opacity: 1;
                                            transition-delay: 300ms;
                                        }
                                    }
                                }
                            }
                        }

                        ul.level0 {
                            padding: 44px 0;
                            left: 6px !important;
                            bottom: auto !important;
                            min-width: 267px;
                            transform: translateY(10px);
                            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.15);
                            //@include grid-row();

                            > li {
                                &.level1 {
                                    &.category-all {
                                        display: block;

                                        a {
                                            color: $black;
                                            font-size: 18px;
                                            font-weight: $ts-top-links-anchor-font-weight;
                                            margin-bottom: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .level1 {
                            li.level2 {
                                a {
                                    padding-top: 8px;
                                    padding-bottom: 8px;
                                }
                            }
                        }

                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .navigation {
            > .ui-menu {
                > .ui-menu-item {
                    &.menu-type-subcategories {
                        position: static;

                        > .submenu {
                            @include breakpoint(large) {
                                padding: 44px 0;
                                left: 50% !important;
                                right: 0;
                                margin-top: 10px;
                                top: auto;
                                transform: translateX(-50%);
                                width: 100vw;
                                box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.15);

                                .subcategories-wrap {
                                    @include grid-row();
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }

                                .subcategories-over {
                                    > .ui-menu-item {
                                        flex-basis: content;
                                        padding-right: rem-calc(40);

                                        > a {
                                            color: $black;
                                            font-size: 18px;
                                            font-weight: $ts-top-links-anchor-font-weight;
                                            line-height: 23px;
                                            margin-bottom: 16px;
                                            padding-left: 0;
                                            text-transform: none;
                                        }

                                        > .submenu {
                                            a {
                                                font-size: 16px;
                                                font-weight: 400;
                                                line-height: 30px;
                                                padding: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            @include breakpoint(xxxlarge) {
                                .subcategories-over {
                                    > .ui-menu-item {
                                        padding-right: rem-calc(160);
                                    }
                                }

                                .subcategories-wrap {
                                    padding-left: 30px;
                                    padding-right: 30px;
                                }
                            }
                        }
                    }

                    &:not(.menu-type-subcategories) {
                        .level-top {
                            &:before {
                                bottom: -10px;
                            }
                        }
                    }
                }
            }
        }
    }

    // header
}

// page-header

// FOOTER
//  ---------------------------------------------
footer.page-footer {
    @include clearfix;
    overflow: visible;
    @include breakpoint(medium down) {
        @include grid-col(12);
    }

    .usp-section {
        background-color: $light-background;
        margin-left: -(map-get($grid-column-gutter, small) / 2);
        margin-right: -(map-get($grid-column-gutter, small) / 2);
        margin-bottom: -30%;
        padding: map-get($grid-column-gutter, small) map-get($grid-column-gutter, small) / 2 14%;
        @include breakpoint(medium) {
            display: none;
        }

        ul {
            color: #555555;

            li {
                background-image: url("#{$ts-path-to-images}/icon-circle-mark.svg");
                background-position: left center;
                background-size: 22px;
                font-size: 16px;
                font-weight: 400;
                margin-left: 9px;
                padding: 7px 0 3px 30px;
            }
        }
    }

    .footer-background-image {
        background-color: $white;
        overflow: hidden;
        margin-left: -(map-get($grid-column-gutter, small) / 2);
        margin-right: -(map-get($grid-column-gutter, small) / 2);
        @include breakpoint(small down) {
            background-color: transparent;
        }

        @include breakpoint(medium) {
            margin-left: -21px;
            margin-right: -22px;
        }

        @include breakpoint(large) {
            margin-left: -1px;
            margin-right: -2px;
        }

        img {
            width: 100%;
        }
    }

    h3 {
        font-size: 26px;
        @include breakpoint(large) {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 26px;
        }
        @include breakpoint(xlarge) {
            font-size: $ts-footer-header-font-size;
        }
    }

    .footer-contacts {
        a {
            color: $white;
            font-size: 20px;

            &[href^="tel"] {
                display: inline-block;
                padding-left: 26px;
                background: url("#{$ts-path-to-images}/icon-phone.svg") 2px center no-repeat;
                background-size: 11px;
            }

            &[href^="mail"] {
                display: inline-block;
                padding-left: 26px;
                background: url("#{$ts-path-to-images}/icon-mail.svg") left center no-repeat;
                background-size: 15px;
            }
        }

        p {
            margin-bottom: 20px;
        }
    }

    .newsletter {
        .form.subscribe {
            input {
                &:hover,
                &:active,
                &:focus {
                    color: $ts-footer-subscribe-input-color;
                    background-color: $ts-footer-subscribe-input-bg;
                }
            }

            .actions {
                .action {
                    &:hover,
                    &:focus {
                        border-color: #09b8ae;
                        color: $white;
                    }
                }
            }
        }
    }

    // newsletter

    .footer-bottom {
        padding-bottom: 40px;
        margin-top: 46px;
        @include breakpoint(large) {
            margin-top: 54px;
        }

        .footer-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: auto;

            .footer-block {
                padding-top: 0 !important;
            }

            .footer-payment {
                padding-bottom: 5px;
                text-align: center;
                order: 2;
                margin-top: 15px;
                @include grid-col(12);
            }

            .social {
                @include grid-col(12);
                order: 1;
                text-align: center !important;
            }

            .footer-copyright-cookies {
                text-align: center !important;
                order: 3;
                @include grid-col(12);

                .copyright,
                .cookies {
                    display: inline;
                    text-align: center;

                    > a {
                        border-bottom: 1px solid rgba($white, 0.6);
                    }
                }

                .cookies-magento {
                    display: none;
                }
            }
        }
    }

    // footer-bottom

    .footer-content {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(medium down) {
            padding-top: 2rem;
        }

        .footer-list {
            .footer-block {
                @include breakpoint(large only) {
                    @include grid-column-size(6);
                    padding-left: 50px;
                    padding-right: 50px;

                    &:nth-child(1),
                    &:nth-child(2) {
                        padding-top: 0;
                    }

                    &:nth-child(3) {
                        clear: both;
                    }

                    &:nth-child(4) {
                        width: 50%;
                    }
                }

                @include breakpoint(xlarge) {
                    @include grid-column-size(3);
                    padding-top: 0;
                    padding-bottom: 18px;
                }
            }
        }

        @include breakpoint(medium down) {
            .footer-list {
                .footer-mobile-accordion {
                    float: none !important;
                    width: auto !important;
                    padding: 0;
                    border-top: 1px solid rgba($white, 0.1);

                    &:first-child {
                        border-top: none;
                    }

                    .footer-title {
                        font-size: rem-calc(17);
                        padding: 16px (map-get($ts-global-space-indent, small) + 47px 15px 0);
                        margin-bottom: 0;
                        position: relative;

                        &:before {
                            display: block;
                            content: "";
                            width: 13px;
                            height: 13px;
                            position: absolute;
                            top: 22px;
                            right: (map-get($ts-global-space-indent, small));
                            background-image: url("#{$ts-path-to-images}/icon-arrow-down-white.svg");
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            background-position: 0 0;
                        }
                    }

                    .footer-content-wrapper {
                        display: none;
                        padding: 15px 0;
                    }

                    &.is-active {
                        .footer-title {
                            &:before {
                                top: 17px;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                // footer-mobile-accordion
            }
        }
        // mobile
    }

    .footer-social {
        a {
            &:before {
                background-position: -3px -3px;
            }
        }
    }
}

// footer

// USP SECTION
//  ---------------------------------------------
.usp-section {
    ul {
        line-height: $ts-usp-font-line-height;

        li {
            display: flex;
            align-items: center;
            margin: 0 0 0 20px;
            background-size: $ts-usp-item-icon-width $ts-usp-item-icon-height;
            background-position: $ts-usp-item-icon-indent 55%;
            white-space: nowrap;

            &:first-child {
                margin: 0;
            }

            @include breakpoint(xlarge) {
                padding: 6px 20px 5px 30px;
                width: 100%;
                background-position: $ts-usp-item-icon-indent 55%;
            }
        }
    }
}

// PRODUCT DETAIL
//  ---------------------------------------------
@if $ts-product-photo-overlay {
    .fotorama__stage {
        &:before {
            @extend %product-layer-overlay;

            .fotorama--fullscreen & {
                display: none;
            }
        }
    }
}

.fotorama-item {
    padding-bottom: 0;
}

.fotorama__stage__frame {
    .fotorama__img {
        max-width: 90%;
        max-height: 90%;
    }
}

.page-title-wrapper.product {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: rem-calc(9);
    width: 100% !important;
    @include breakpoint(medium down) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .page-title {
        color: $black;
        font-size: 20px;
        line-height: 25px;
        width: 100%;
        @include breakpoint(medium down) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(large) {
            font-size: 40px;
            line-height: 51px;
        }
    }
}

// .page-title-wrapper.product

.product-info-wrapper {
    .product.media {
        width: auto;
        padding: 0;
        float: none;
        margin-bottom: 0;

        .fotorama-item {
            padding-bottom: 20px;

            .fotorama__stage {
                margin-bottom: 0;
            }

            .fotorama__nav-wrap--horizontal {
                padding-left: 0;
                padding-right: 0;
                @include breakpoint(medium down) {
                    background-color: rgba(110, 74, 40, 0.04);
                    padding: 0 0 10px;
                }

                .fotorama__thumb__arr--left,
                .fotorama__thumb__arr--right {
                    @include element-invisible;
                }

                .fotorama__nav__shaft {
                    @include breakpoint(medium) {
                        display: block;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            @include grid-col;
            @include grid-col-size(5);
            margin-left: 0;
            margin-right: 0;
        }
        @include breakpoint(large) {
            @include grid-col-size(7.1);
        }
    }

    // .product.media

    .product-info-main {
        display: flex;
        flex-wrap: wrap;
        padding-left: (map-get($grid-column-gutter, small) / 2) + 8px;
        padding-right: (map-get($grid-column-gutter, small) / 2) + 8px;
        @include breakpoint(medium) {
            padding-left: (map-get($grid-column-gutter, medium) / 2);
            padding-right: (map-get($grid-column-gutter, medium) / 2);
        }

        .product-title-brand {
            line-height: 28px;
            margin: 5px 0;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            width: inherit;
            @include breakpoint(large) {
                margin-left: 2px;
                margin-top: 0;
                width: 100%;
            }

            .lipscore-rating {
                display: flex;
                white-space: nowrap;
                flex-direction: row-reverse;
                align-items: center;
                width: fit-content;

                .lipscore-compact-rating-wrapper {
                    margin-right: 2px;
                }

                .lipscore-compact-rating-value {
                    display: none;
                }

                .lipscore-rating-slider-stars-wrapper .lipscore-rating-star {
                    cursor: pointer;
                    display: inline-block;
                    height: 16px;
                    width: fit-content;
                }
            }

        }

        > .prices-tier {
            width: 100%;
            @include breakpoint(large) {
                margin-top: -15px;
            }

            li {
                font-size: 17px;
            }
        }

        > .short_description {
            order: 4;
            @include breakpoint(large) {
                order: 1;
            }
        }

        > .product-info-sidebar {
            order: 3;
            width: 100%;
            @include breakpoint(medium down) {
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }

        > .product.attribute {
            display: none;
        }

        > .product-detail-accordion {
            order: 6;
        }

        .product-info-form-wrapper {
            .price-over-box {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: flex-end;
            }

            .product-info-form-row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                @include breakpoint(large) {
                    flex-direction: column;
                }

                > .product-options-wrapper {
                    @include breakpoint(medium down) {
                        order: 3;
                    }

                    + .price-revert-wrapper {
                        @include breakpoint(large) {
                            margin-bottom: 2px;
                        }
                    }
                }

                .price-revert-wrapper {
                    width: 100%;
                }

                .special-price-discount {
                    display: none;
                    color: map-get($foundation-palette, alert);
                    font-size: 12px;
                    padding-right: 2%;

                    @include breakpoint(large) {
                        font-size: 14px;
                    }
                }

                .special-price {
                    color: map-get($foundation-palette, alert);
                    font-size: 24px;
                    font-weight: 700;
                    padding-right: 18px;

                    @include breakpoint(large) {
                        font-size: 32px;
                    }

                    & + .old-price {
                        color: $black;
                        font-size: 14px;
                        margin-top: 0;
                        order: 3;
                        //text-decoration: none;

                        .price-label {
                            display: none;
                        }
                    }
                }

                .box-tocart {
                    width: 100%;
                    margin-top: 10px;
                }

                @include breakpoint(xlarge) {
                    .box-tocart {
                        margin-top: 0;
                    }
                }
            }

            .box-tocart {
                .fieldset .actions {
                    display: flex;
                    align-items: stretch;
                    padding-top: 1.3rem;

                    .product-quantity-control {
                        width: 100%;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: flex-start;
                    }
                }

                .field.qty {
                    @include element-invisible;
                }

                .action.tocart {
                    display: flex;
                    width: 100%;
                    height: 40px;
                    box-shadow: 0 4px 10px rgba(34, 34, 34, 0.15);
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 15px;

                    @include breakpoint(xxlarge) {
                        min-width: 300px;
                    }
                }
            }
        }

        .stock.available span {
            background-image: url("#{$ts-path-to-images}/icon-tick-green.svg");
            background-size: 13px auto;
        }

        .aw-cus__customstockstatus {
            margin-bottom: 6px;
            display: flex;
            align-items: baseline;

            .icon {
                padding-right: 3px;

                > img {
                    min-width: 13px;
                    max-width: 13px;
                }
            }
        }

        .stock > span,
        .aw-cus__customstockstatus .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 6px;

        }

        .unavailable span {
            background-color: #f7465b;
            border-radius: 50%;
            color: $black;
            height: 18px;
            width: 18px;
        }

        .stock.order-item {
            span {
                padding-left: $ts-stock-label-icon-backorder-bg-size + 2px;
                background: url("#{$ts-path-to-images}/icon-stock-delivery.svg") 0 50% no-repeat;
                background-size: $ts-stock-label-icon-backorder-bg-size;
            }
        }

        .price-revert-wrapper {
            margin-bottom: 2px;
        }

        .porterbuddy-availability,
        .shipping-message-wrapper {
            margin-bottom: 17px;
        }

        .porterbuddy-availability {
            padding: 18px 20px 13px;
            border-radius: 30px;
        }

        .product-description-wrapper {
            margin-top: 17px;
            margin-bottom: 28px;
            @include breakpoint(large) {
                margin-top: 37px;
                margin-bottom: 41px;
                padding-left: 5px;
            }

            .overview {
                margin-bottom: 30px;
                @include breakpoint(large) {
                    margin-bottom: 35px;
                }

                p {
                    font-size: 17px;
                    line-height: 1.7;
                    @include breakpoint(large) {
                        font-size: 22px;
                        line-height: 1.54;
                    }
                }
            }

            .description {
                ul,
                ol {
                    font-size: 15px;
                    line-height: 1.4;
                    @include breakpoint(large) {
                        font-size: 17px;
                        > li {
                            padding-left: 2.2em;
                        }
                    }

                    &:after {
                        top: 0.55em;
                    }
                }
            }
        }
    }

    // product-info-main
    .price-box {
        > .price-container {
            font-size: rem-calc(24);

            @include breakpoint(xxlarge) {
                font-size: rem-calc(28);
            }
        }

        & > .price-container.superdeals-price {
            color: map-get($foundation-palette, alert);
        }
    }
}

// .product-info-wrapper

.block.related {
    padding: 12px 0 26px 0;

    .block-title {
        strong {
            color: $body-font-color-alt;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: left;
            @include breakpoint(large) {
                color: $black;
                font-weight: 600;
                line-height: 28px;
            }

            &:before,
            &:after {
                content: none;
            }
        }
    }
}

.related-products-container {
    width: 100%;

    .products-related {
        .product-items {
            @include breakpoint(large) {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:before,
                &:after {
                    content: none;
                }
            }
        }

        .product-item.item {
            width: 100%;
            z-index: 0;
            @include breakpoint(xlarge) {
                margin: 2px 0;
                width: 45%;

                &:hover {
                    box-shadow: none;
                    background-color: transparent;
                }
            }

            .product-item-info {
                display: flex;
                align-items: center;

                .product-item-photo {
                    flex-shrink: 0;
                    margin-bottom: 0;
                    width: 71px;
                }
            }

            .convert-label-container {
                display: none;
            }

            .product-item-details {
                flex: 1;
                padding-left: 14px;
                padding-right: 40px;

                .product-item-name {
                    font-size: 14px;
                    font-weight: 400;
                    height: auto !important;
                    margin-bottom: 3px;

                    a {
                        color: $body-font-color-alt;
                    }
                }

                .product-price-stock {
                    height: auto !important;
                }

                .price-box {
                    .price {
                        color: $black;
                        font-size: 18px;
                    }

                    .special-price {
                        .price {
                            color: map-get($foundation-palette, alert);
                        }

                        &:after {
                            content: none;
                        }
                    }

                    .old-price {
                        .price {
                            font-size: 12px;
                        }
                    }
                }

                .field.choice.related {
                    position: absolute;
                    display: inline-block;
                    width: 34px;
                    height: 12px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;

                        &:checked + label {
                            background-color: $primary-color;

                            &:before {
                                background-color: $primary-color;
                            }
                        }

                        &:checked + label:before {
                            transform: translateX(26px);
                        }
                    }

                    label {
                        font-size: 0;
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #d0ccc8;
                        border-radius: 50px;
                        min-height: auto;
                        transition: 0.4s;

                        &:before {
                            position: absolute;
                            content: "";
                            height: 29px;
                            width: 29px;
                            left: -8px;
                            top: -9px;
                            background-color: #d0ccc8;
                            border: 8px solid $white;
                            border-radius: 50%;
                            transition: 0.4s;
                            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
                        }
                    }
                }

                .stock.unavailable {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    margin: 0;
                    background-color: rgba($white, 0.6);

                    span {
                        color: $black;
                    }
                }
            }
        }
    }

    .related-show-all {
        display: none;
    }

    &.show-less {
        @include breakpoint(medium down) {
            .block.related {
                position: relative;

                .block-content {
                    &:before {
                        background: linear-gradient(rgba($white, 0) 58%, rgba($white, 1) 100%);
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        pointer-events: none;
                        z-index: 10;
                    }
                }
            }

            .related-show-all {
                //display: block;
                font-size: 12px;
                //text-align: center;
                text-decoration: underline;

                display: flex;
                flex-direction: row;

                &:before,
                &:after {
                    content: "";
                    flex: 1 1;
                    border-bottom: 1px solid;
                    margin: auto;
                    opacity: 0.1;
                }

                &:before {
                    margin-right: 10px;
                }

                &:after {
                    margin-left: 10px;
                }
            }

            .product-item {
                &:nth-child(n + 4) {
                    height: 1px !important;
                    overflow: hidden;
                    width: 1px !important;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                }
            }
        }
    }
}

// .related-products-container

.page-product-bundle .bundle-options-container .product-add-form #product_addtocart_form {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.page-product-bundle .related-products-container {
    width: 48%;
    padding-right: 10px;
    order: 2;

    .slick-list.draggable {
        width: 100%;
        min-height: 260px;
    }

    @include breakpoint(medium down) {
        width: 95%;
        margin: 0 auto;
        padding-right: 0;
    }

    .products-related .product-item.item {
        min-height: 120px;
        max-height: 120px;
    }
}

.product-detail-accordion, .product.media {
    margin-bottom: 17px;
    width: 100%;

    .accordion-item {
        padding: 0;
        border-bottom: 1px solid rgba($black, 0.1);
        list-style-type: none;

        @include breakpoint(large) {
            margin-bottom: 4px;
        }

        &:after {
            display: none;
        }

        .accordion-title {
            border: 0;
            text-decoration: none;
            font-weight: normal;
            font-family: $header-font-family;
            padding: 16px 0 15px;

            &:hover {
                background-color: transparent;
                color: $black;
            }

            h3 {
                font-size: rem-calc(16);
                font-weight: 500;
                line-height: 28px;
                color: inherit;
                margin: 0;
            }

            &:after {
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-image: url("#{$ts-path-to-images}/icon-arrow-down-gray.svg");
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: 50%;
            }
        }

        &.is-active {
            .accordion-title:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    .accordion-content {
        border: 0 !important;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border-radius: 0 0 30px 30px;

        p,
        ul,
        ol {
            font-size: 17px;
            line-height: 2;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .product-attributes-list,
        .ingredients-list {
            margin-left: 0;

            li {
                padding: 0;

                &:after {
                    display: none;
                }
            }
        }
    }

    .show-more-btn {
        color: black;
        cursor: pointer;
        display: block;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 20px;
        text-decoration: underline;
    }
}

// .accordion

.convert-label-container {
    .image-label,
    .convert-label {
        margin-bottom: 10px;
    }
}

//.convert-label-container

.product.media {
    .convert-label-container {
        .convert-label-discount {
            background-color: map-get($foundation-palette, alert);
            border-radius: 50%;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            height: 57px;
            width: 57px;
            position: absolute;
            right: 43px;
            top: 20px;
            z-index: 100;

            @include breakpoint(medium) {
                right: 30px;
                top: 10px;
            }

            @include breakpoint(large) {
                font-size: 26px;
                height: 85px;
                top: 22px;
                width: 85px;
                right: 43px;
            }
        }

        .convert-label-red,
        .convert-label-black,
        .convert-label-green {
            background-color: map-get($foundation-palette, alert);
            border-radius: 3px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            height: 25px;
            left: 27px;
            position: absolute;
            padding: 5px 15px;
            top: 20px;
            z-index: 100;

            @include breakpoint(medium) {
                font-size: 14px;
                height: 28px;
                left: 133px;
                top: -7px;

                &:before {
                    bottom: -6px;
                    content: "";
                    display: block;
                    left: 5px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 5px solid #ae001e;
                    transform: rotate(-225deg);
                }
            }
        }

        .convert-label-transparent {
            position: absolute;
            top: 22px;
            left: 27px;
            z-index: 100;
            font-weight: 600;
            font-size: 1.5rem;

            @include breakpoint(medium) {
                left: 175px;
            }
        }

        .convert-label-black {
            background-color: $black;

            @include breakpoint(medium) {
                &:before {
                    border-right-color: $black;
                }
            }
        }

        .convert-label-green {
            background-color: $primary-color;

            @include breakpoint(medium) {
                &:before {
                    border-right-color: #07aaa1;
                }
            }
        }
    }
}

// product.media

.product-attr-list {
    margin-left: 0;
    margin-top: 20px;

    li {
        padding-left: 0;
    }

    .attr-label {
        font-weight: 600;

        &:after {
            content: ":";
        }
    }

    .attr-label,
    .attr-value {
        font-size: 16px;
    }
}

.cms-page-view {
    .breadcrumbs {
        @include breakpoint(large) {
            padding-left: 0;
        }

        .items {
            @include breakpoint(large) {
                font-size: 14px;
                line-height: 24px;

                a {
                    font-size: inherit;
                    color: rgba($black, 0.5);
                }

                li {
                    font-size: inherit;
                    color: rgba($black, 0.5);

                    &:not(:last-child):after {
                        color: rgba($black, 0.5);
                    }
                }
            }
        }
    }

    .top-controls-wrapper {
        .shipping-bar-container {
            @include breakpoint(large) {
                padding-right: 0;
            }
        }

        .shipping-bar {
            .progress-text {
                color: #282827;
            }

            .progress-graph {
                background: rgba(119, 86, 2, 0.07);
            }

            .progress-bar {
                border-radius: 50px;
            }
        }
    }
}

// PING MODAL
//  ---------------------------------------------
.modal-popup {
    &.ping-modal {
        color: $black;
        overflow-y: visible;

        .modal-inner-wrap {
            height: 100%;
            max-height: 100%;
            width: 100%;
            margin: 0 auto;
            @include breakpoint(large) {
                width: 90%;
                max-width: 843px;
                height: auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .action-close {
            display: none;
        }

        .modal-header {
            background-image: url("#{$ts-path-to-images}/ping-logo.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 64px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
            @include breakpoint(large) {
                display: none;
            }
        }

        .modal-content {
            height: 100%;
            @include breakpoint(large) {
                padding: 0;
            }
        }

        .modal-title {
            @include element-invisible;
        }

        .ping-modal-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 64px);
            @include breakpoint(large) {
                flex-direction: row;
                height: 100%;
            }
        }

        .ping-modal-content-left {
            overflow-y: scroll;
            padding-top: 34px;
            padding-bottom: 60px;
            @include breakpoint(large) {
                background-color: $light-background;
                overflow-y: auto;
                padding: 45px;
                width: 62%;
            }

            h3 {
                color: inherit;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 18px;

                &.section-header {
                    font-weight: 700;
                    margin-bottom: 0;
                }
            }
        }

        .ping-modal-content-right {
            position: relative;
            padding: 0 0 27px;
            @include breakpoint(large) {
                padding: 26px;
                width: 38%;
            }

            &:before {
                position: absolute;
                top: -119px;
                left: 0;
                content: "";
                display: block;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 9.11%, #ffffff 60%);
                height: 120px;
                width: 100%;
                pointer-events: none;
                @include breakpoint(large) {
                    content: none;
                }
            }

            .ping-right-title {
                display: none;
                @include breakpoint(large) {
                    background-image: url("#{$ts-path-to-images}/ping-logo.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: block;
                    height: 81px;
                    font-size: 0;
                    margin-bottom: 40px;
                }
            }

            button {
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}

//.ping-right-title {
//    position: fixed;
//}

// ADD TO CART POPUP
//  ---------------------------------------------
.modal-popup {
    &.add-to-cart-modal {
        @include breakpoint(medium down) {
            background-color: $white;
            pointer-events: auto;
        }

        .modal-inner-wrap {
            height: 100%;
            max-height: 100%;
            width: 100%;
            margin: 0 auto;
            @include breakpoint(medium down) {
                box-shadow: none;
            }
            @include breakpoint(large) {
                width: 90%;
                max-width: 843px;
                height: auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .modal-header {
            display: none;
        }

        .modal-content {
            padding: 0;

            @include breakpoint(medium down) {
                padding-bottom: 140px;
            }
        }

        .mobile-header {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            color: $white;
            font-size: 18px;
            min-height: 61px;
            position: relative;
            @include breakpoint(large) {
                display: none;
            }

            &:before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                left: 5%;
                background-image: url("#{$ts-path-to-images}/icon-arrow-left-white.svg");
                background-repeat: no-repeat;
                background-size: 8px auto;
                background-position: center;
            }
        }

        .desktop-header {
            display: none;
        }

        .animation-wrapper {
            @include breakpoint(large up) {
                padding-bottom: 30px;
            }
        }

        .shipping-bar {
            @include breakpoint(medium down) {
                margin-top: 15px;
            }
            @include breakpoint(large) {
                margin-bottom: 30px;
            }

            > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .progress-text {
                color: $black;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                margin-bottom: 15px;
                text-align: center;
                letter-spacing: -0.02em;
                padding-right: 0;
                width: 100%;
                @include breakpoint(large) {
                    font-size: 18px;
                }
            }

            .progress-graph {
                border-radius: 50px;
                margin-left: auto;
                margin-right: auto;
                width: 266px;
                @include breakpoint(large) {
                    width: 362px;
                }

                .progress-bar {
                    border-radius: 50px;
                }
            }
        }

        .hello-retail-see-also {
            padding: ($ts-modal-popup-padding / 2);
            text-align: left;
            @include breakpoint(large) {
                text-align: center;
            }

            .title {
                font-size: 18px;
                font-weight: 500;
                @include breakpoint(large) {
                    font-size: 24px;
                }
            }
        }

        #hr-upsell-box-1 {
            padding-left: 70px;
            padding-right: 70px;
            padding-bottom: 50px;
            background: white;
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;

            @include breakpoint(large up) {
                padding-left: 100px;
                padding-right: 100px;
            }

            @include breakpoint(medium down) {
                display: block;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 9px 23px 23px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
                z-index: 900;
            }

            button {
                width: 100%;
                @include breakpoint(large) {
                    position: relative;
                    width: 50%;
                }

                &.modal-continue-shopping {
                    margin-bottom: 10px;

                    @include breakpoint(large) {
                        margin-bottom: 0;
                        margin-right: 7px;

                        &:before {
                            content: "";
                            display: block;
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%) rotate(180deg);
                            left: 5%;
                            background-image: url("#{$ts-path-to-images}/icon-arrow-right-green.svg");
                            background-repeat: no-repeat;
                            background-size: 8px auto;
                            background-position: center;
                        }
                    }
                }

                &.modal-proceed-to-checkout {
                    @include breakpoint(large) {
                        margin-left: 8px;
                        &:before {
                            content: "";
                            display: block;
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 5%;
                            background-image: url("#{$ts-path-to-images}/icon-arrow-left-white.svg");
                            background-repeat: no-repeat;
                            background-size: 8px auto;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }
}

// HOME PAGE
//  ---------------------------------------------
.cms-index-index {
    @include breakpoint(large) {
        .page-container {
            position: relative;
        }

        .top-controls-wrapper {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            .shipping-bar-container {
                display: none;
            }
        }
    }
}

// CATEGORY PAGE
//  ---------------------------------------------
.catalog-category-view {
    .columns {
        > .sidebar-main {
            display: none;
        }

        .column.main {
            width: 100%;

            videoly-tape {
                display: none;
            }
        }
    }
}

.page-title-sorting-wrapper {
    .page-sorting-categories-wrapper {
        display: flex;
        flex-direction: column;
        @include breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .toolbar-sorter.sorter {
            @include breakpoint(medium) {
                display: block !important;
            }
        }
    }

    .page-title-wrapper {
        width: 100%;
        padding: 0 50px;
        margin: 0 0 10px;
        position: relative;
        @include breakpoint(large) {
            width: auto;
            text-align: center;
            margin-bottom: 0;
            padding: 0;
            @include get-gutter(0.5, "padding-left");
        }

        .page-title {
            text-align: center;
            padding: 0;

            span {
                &:before {
                    display: none;
                }
            }
        }

        .category-back-link {
            @include hide-for(large);
            @include get-gutter(0.5, "left");
            position: absolute;
            top: 0;
            font-size: 0;
            margin-bottom: 0;

            a {
                width: 34px;
                height: 34px;
                padding: 0;

                &:before {
                    width: 12px;
                    height: 20px;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0) rotate(180deg);
                }
            }
        }
    }

    .toolbar.toolbar-products {
        width: 100%;
        margin: 0;
        @include breakpoint(medium) {
            width: auto;
            margin-left: auto;
        }

        .toolbar-amount {
            @include hide-for(medium);
            text-align: center;
            padding: 0;
            @include breakpoint(medium only) {
                text-align: left;
                line-height: 1.6;
            }
        }

        .toolbar-sorter {
            width: 273px;
            padding: 0;
        }
    }
}

// page-title-sorting-wrapper
.category-back-link {
    margin-bottom: 20px;

    a {
        display: inline-block;
        font-family: $header-font-family;
        line-height: 1;
        text-decoration: none;
        color: $body-font-color;
        position: relative;
        padding-left: 15px;

        &:hover,
        &:active {
            text-decoration: underline;
        }

        &:before {
            display: block;
            content: "";
            width: 7px;
            height: 14px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(180deg);
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: 50%;
            background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray.svg");
            // opacity: .75;
        }
    }
}

// category-back-link

.convert-paging {
    .pages-items .item .page {
        box-shadow: 3px 3px 6px rgba($black, 0.1);
    }
}

// convert-paging

.category-header-mobile .filter-content,
.block.filter .filter-options {
    .filter-options-content {
        .count,
        .filter-count-label {
            @include element-invisible;
        }

        .am-show-more {
            margin-top: 0;

            &:after {
                margin-top: 2px;
            }

            &[data-is-hide="false"] {
                &:after {
                    margin-top: 0;
                }
            }
        }

        .am-filter-items-attr_category_ids {
            @include breakpoint(large) {
                border-bottom: 1px solid $ts-main-border-color;
            }

            .item {
                a {
                    font-size: 17px;
                    font-family: $header-font-family;
                    font-weight: normal;
                    padding: 6px 25px 6px 0;
                    position: relative;
                    border-top: 1px solid $ts-main-border-color;

                    &:before {
                        @include element-invisible;
                    }

                    &:after {
                        display: block;
                        content: "";
                        width: 10px;
                        height: 20px;
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%);
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray.svg");
                        opacity: 0.75;
                    }

                    &.am_shopby_link_selected {
                        &:after {
                            width: 15px;
                            height: 15px;
                            background-image: url("#{$ts-path-to-images}/icon-valid-black.svg");
                            opacity: 1;
                        }

                        .label {
                            padding-left: 0;
                            font-weight: normal;
                            background: none;
                        }
                    }
                }
            }
        }

        &.has-images-labels {
            .am-swatch-options {
                .item {
                    .swatch-option {
                        border-radius: 2px;
                        font-size: 0;
                        margin-right: 0;
                        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);

                        &:after {
                            display: block;
                            content: "";
                            position: absolute;
                            top: $ts-checkbox-label-swatch-top-active;
                            left: $ts-checkbox-label-swatch-left-active;
                            width: $ts-checkbox-label-swatch-width-active;
                            height: $ts-checkbox-label-swatch-height-active;
                            background-color: $ts-checkbox-label-swatch-bg-active;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            transform: none;
                            opacity: 0;
                        }

                        &:hover,
                        &:active,
                        &.selected,
                        &.checked {
                            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);

                            &:before {
                                display: block;
                                content: "";
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                border-radius: 2px;
                                background-color: rgba($primary-color, 0.8);
                                top: 2px;
                                left: 2px;
                            }

                            &:after {
                                opacity: 1;
                                background-image: none;
                            }
                        }

                        &:hover {
                            &:before {
                                background-color: rgba($primary-color, 0.6);
                            }
                        }
                    }

                    .am-shopby-swatch-label {
                        display: inline-block;
                        margin-left: 0;
                        max-width: 100%;

                        .label {
                            margin-left: 9px;
                        }
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        .swatch-option {
                            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);

                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        // &.has-images-labels

        &.has-ui-slider {
            padding-top: 0;
            padding-bottom: 95px;

            .amshopby-slider-tooltip {
                display: none;
            }

            .ui-slider {
                .ui-slider-handle {
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
                    top: -10px;
                }
            }
        }

        //&.has-ui-slider
    }
}

// .block.filter

.block.filter {
    .action.clear {
        @include button-style($button-background);
        font-size: 15px;
    }

    .filter-options {
        .am-swatch-wrapper {
            margin: 0 !important;
        }

        .am-swatch-options > .item {
            padding-bottom: 3px;
        }

        .items .items-children.-folding {
            padding-left: 0;

            .item {
                margin: 0;

                a {
                    padding-left: 20px;
                }
            }
        }
    }
}

// .block.filter

aside.modal-popup {
    pointer-events: none;

    &.filters {
        background-color: $panel-color;

        .action-close {
            width: 35px;
            height: 35px;
            font-size: 0;
            top: 27px;
            right: 20px;
            padding: 0;
            background-image: url("#{$ts-path-to-images}/icon-close.svg");
            background-size: 20px auto;
            background-repeat: no-repeat;
            background-position: 50%;

            &:before {
                display: none;
            }

            + .close-filter-modal {
                @include element-invisible;
            }
        }

        .modal-inner-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            min-height: 100%;
            margin: 0;
            box-shadow: none;
            background: $panel-color;
        }

        .modal-header {
            padding: 32px 60px 41px;
            text-align: center;
        }

        .modal-title {
            font-size: 28px;
            font-weight: normal;
            font-family: $header-font-family;
        }

        .modal-sub-title {
            @include element-invisible;
        }

        .modal-content {
            padding: 0 28px 30px;

            .block.filter {
                .filter-options {
                    .filter-options-title,
                    .filter-current-subtitle {
                        border-bottom: 0;
                    }

                    .filter-options-content .item {
                        margin-bottom: 2px;

                        .label {
                            font-size: rem-calc(15);
                        }
                    }
                }
            }

            .nav-toolbar-sorter-mobile {
                margin: 0 -28px;

                ol.items {
                    border-top: 1px solid $ts-main-border-color;

                    .item {
                        border-bottom-width: 1px;

                        a {
                            font-size: 19px;
                            padding: 11px 40px 9px 28px;
                            color: $primary-color;
                        }

                        &.active {
                            a {
                                background: none;

                                &:after {
                                    left: auto;
                                    right: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            padding: 0 32px 20px;

            button:not(.action-close) {
                @include button-style($button-background);
            }
        }
    }
}

// .modal-popup.filters

.category-header-mobile {
    width: 100%;
    overflow: hidden;

    .filter-content .filter-options-content .item a {
        background: none;
    }
}

// category-header-mobile

.mobile-controls {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 !important;

    .mobile-filter-btn {
        width: 49%;
        @include button-style($button-background);
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        font-size: rem-calc(14);
        font-weight: 600;
    }
}

// mobile-controls

.category-view {
    background-color: $white;
    margin-bottom: 20px;
    padding: 5px (map-get($grid-column-gutter, small) / 2);
    position: relative;

    @include breakpoint(large) {
        border-radius: 10px 10px 0 0;
        padding: 40px 38px 48px;
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.11);
        width: 91.5%;
    }
    @include breakpoint(xlarge) {
        padding: 55px 74px 48px;
    }

    .category-cms {
        margin-bottom: 20px;
    }
}

.golfshopen-subcategories-links {
    @include breakpoint(large) {
        text-align: center;
    }

    .golfshopen-subcategory-link {
        border-bottom: 1px solid $primary-color;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 12px;
        margin-right: 10px;
        text-decoration: none;
        @include breakpoint(large) {
            @include button;
            margin-bottom: 22px;
            margin-right: 9px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.product-items {
    .product-item {
        .product-item-info {
            opacity: 1;

            .midlertidig-utsolgt {
                height: 32px;
                width: 100%;
                color: #000000;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 30px;
                text-align: center;
                background-color: rgba(241, 240, 235, 0.5);
                position: absolute;
                z-index: 99;
                margin-top: -50px;
                display: inherit;
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            &:hover .midlertidig-utsolgt,
            &:active .midlertidig-utsolgt {
                opacity: 1;
            }

            &:hover .product-item-details.soldout,
            &:active .product-item-details.soldout {
                opacity: 0.5;
            }
        }

        @include breakpoint(small only) {
            &:hover,
            &:active,
            &:focus {
                box-shadow: none !important;
            }
            .product-item-info {
                .product-item-photo {
                    margin-bottom: 10px;
                }
            }
        }
        // small-only

        .touchevents & {
            .product-item-info {
                .product-item-details {
                    .product-price-stock {
                        align-items: center;
                    }

                    .product-price-cta-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .product-item-inner,
                    .product-item-actions {
                        @include element-invisible-off;
                    }

                    .product-addtocart-quantity-control,
                    .product-item-actions .action.tocart {
                        opacity: 1;
                        z-index: 4;
                    }

                    .product-addtocart-quantity-control {
                        .product-addtocart-quantity-control-wrapper {
                            width: 99px;
                            height: 36px;
                            line-height: 36px;
                            padding: 3px;
                            border-radius: 26px;
                            justify-content: space-between;

                            .decrease,
                            .increase {
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }

        // touchevents

        .product-item-details {
            .product-item-name {
                margin: 0 0 8px;
            }

            // .product-item-manufacturer {
            //     &:empty {
            //         display: block;
            //     }
            // }

            .product-price-stock {
                margin-bottom: 0;
            }

            .product-attr-list {
                @include element-invisible;
            }

            .price-box {
                .special-price {
                    vertical-align: middle;
                    position: relative;

                    &:after {
                        display: block;
                        content: "";
                        width: 100%;
                        bottom: -6px;
                        position: absolute;
                        left: 0;
                        height: 3px;
                        background-color: $alt-color;
                    }
                }
            }

            .product-item-inner {
                margin-top: 0;
            }
        }
    }

    &.widget-product-grid {
        .product-item {
            .product-image-container .product-image-wrapper {
                padding: 10px !important;
                @include breakpoint(large) {
                    padding: 20px !important;
                }
            }
        }

        // APOT-118
        .product-item-manufacturer {
            &.-ingredient {
                @include element-invisible;
            }
        }
    }
}

// product-items

.widget-product-grid {
    &.product-items {
        .product-item {
            @include breakpoint(medium) {
                .product-item-photo {
                    margin-bottom: 20px;
                }
                .product-item-details {
                    .product-item-manufacturer {
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }
}

// .widget-product-grid

// Shipping message
//  ---------------------------------------------
.shipping-message {
    padding: 17px 17px 17px 60px;
    font-size: 15px;
    border-radius: 24px;
    background-color: lighten(desaturate(adjust-hue($alert-color, 356), 21.36), 43.14);
    background-image: url("#{$ts-path-to-images}/icon-clock.svg");
    background-repeat: no-repeat;
    background-position: 20px 18px;
    background-size: 27px auto;
    @include breakpoint(large) {
        font-size: 17px;
        padding: 20px 20px 20px 60px;
        border-radius: 30px;
    }

    strong {
        font-weight: normal;
    }
}

// CTA QTY CONTROL
//  ---------------------------------------------

// Product Lists
.product-items {
    .product-item {
        margin: 0.5rem 0;
        @include breakpoint(large) {
            margin: 1rem 0;
        }

        .convert-label-container {
            .touchevents & {
                top: 43px !important;
                @include breakpoint(medium) {
                    top: 30px !important;
                }
            }
        }

        .product-item-details {
            position: static;

            // CTA
            .product-item-actions .action.tocart,
            .product-addtocart-quantity-control {
                position: relative;
                margin: -18px -6px 0 0;
                top: 0;
                right: 0;
                @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
                    @include get-gutter(-1 * $ts-product-item-gap-size, "top");
                    @include get-gutter(-1 * $ts-product-item-gap-size, "right");
                }
                z-index: 4;
            }

            // cta controls

            // Default CTA
            .product-item-actions .action.tocart {
                display: block;
                width: 48px;
                height: 48px;
                padding: 0;
                font-size: 0;
                border: 0;
                border-radius: 50%;
                box-shadow: none;
                background-color: $primary-color;
                background-image: url("#{$ts-path-to-images}/icon-cart.svg");
                background-size: 24px auto;
                background-position: 10px center;
                background-repeat: no-repeat;
                @extend .no-outline;

                &:hover,
                &:active {
                    background-color: saturate($primary-color, 30);
                }

                .no-touchevents & {
                    position: absolute;
                }

                &.disabled {
                    &:after {
                        content: "";
                        @include loading-overlay;
                        display: block;
                        background-size: 30px auto;
                    }
                }
            }

            // Custom CTA
            // Product has been added to cart and is showing only Qty now
            .product-addtocart-quantity-control {
                .product-addtocart-quantity-control-wrapper {
                    width: 42px;
                    border-radius: 50%;
                    justify-content: center;
                    transition: all 0.3s ease;

                    .decrease,
                    .increase {
                        opacity: 0;
                        z-index: -1;
                        transition: all 0.3s ease;
                    }
                }
            }
        }

        // product-item-details

        &:hover,
        &:active {
            .product-addtocart-quantity-control {
                .product-addtocart-quantity-control-wrapper {
                    width: 115px;
                    border-radius: 26px;
                    justify-content: space-between;

                    .decrease,
                    .increase {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }

            .product-item-actions .action.tocart {
                .no-touchevents & {
                    z-index: 4;
                }
            }
        }
    }
}

// .product-items

// Product Detail
.product-info-main {
    // Custom CTA
    .product-addtocart-quantity-control {
        position: relative;

        &.is-shown {
            margin-right: 6px;
            display: flex;
            align-items: center;
        }

        .product-addtocart-quantity-control-wrapper {
            width: 100%;
            height: 42px;
            padding: 4px;
            box-shadow: 2px 2px 6px rgba($black, 0.1);
            @include breakpoint(xlarge) {
                width: 124px;
                height: 40px;
                padding: 6px;
                box-shadow: none;
            }
        }

        .quantity-value {
            font-size: 17px;
            font-family: $header-font-family;
            @include breakpoint(large) {
                font-size: 20px;
                font-family: $body-font-family;
            }
        }

        .decrease,
        .increase {
            width: 34px;
            height: 34px;
            line-height: 34px;
            font-size: 17px;
            @include breakpoint(xlarge) {
                width: 36px;
                height: 34px;
                font-size: 24px;
            }
        }

        .product-addtocart-quantity-control-desc {
            display: none;

            @include show-for(xxlarge);
            margin-left: 6px;
            font-size: 15px;
        }

        .quantity-value-txt {
            @include hide-for(xlarge);
        }

        // Product has been added to cart and is showing only Qty now
        &.is-addedtocart {
            .product-addtocart-quantity-control-wrapper {
                width: 53px;
            }
        }
    }

    .b-decription-toggle {
        .description-content {
            margin: 5px 0;
            @include breakpoint(large) {
                margin: 10px 0;
            }

            p {
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 0;
            }

            .read-more {
                color: black;
                cursor: pointer;
                display: block;
                font-size: 16px;
                margin-top: 5px;
                margin-bottom: 20px;
                text-decoration: underline;
            }
        }

        .more-info-trigger {
            text-decoration: underline;
            color: $anchor-color;

            &:hover,
            &:active {
                text-decoration: none;
                color: $anchor-color-hover;
            }
        }

        &.teaser {
            .product-content {
                max-height: 87px;
                overflow: hidden;
                position: relative;

                &:before {
                    background: linear-gradient(rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 100%);
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

// product-info-main

.product-info-usps {
    margin-bottom: 10px;

    .pagebuilder-column-group {
        @include breakpoint(medium down) {
            display: flex;
            flex-direction: row;
            background: #0000000A;
            margin-bottom: 12px;
            padding: 14px;
            border-radius: 15px;
            column-gap: 9px;
        }

        @include breakpoint(large) {
            display: flex;
            flex-direction: row;
            background: #0000000A;
            padding: 17px 1px 12px 8%;
            border-radius: 15px;
            column-gap: 9px;
        }

        .pagebuilder-column {
            width: 100% !important;
            @include breakpoint(large) {
                width: 50% !important;
            }
        }
            }

    p {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 6px;
        line-height: 20px;
        @include breakpoint(large) {
            font-size: 16px;
            line-height: 28px;
        }

        img {
            margin-right: 4px;
        }
    }
}

.aw-gc-product-add-form {
    .aw-gc-product-form-options {
        fieldset.fieldset {
            margin-bottom: 20px;

            .legend {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }

        label {
            &[for="aw_gc_amount"] {
                display: none;
            }
        }
    }

    .aw-gc-product-form-options__step-number {
        font-size: 13px;
    }

    .email.fromto {
        .fieldset {
            @include breakpoint(large) {
                display: flex;
            }
        }
    }

    .control.message {
        border: 0;
        background-image: none;
        padding: 0;

        textarea {
            border-radius: 30px;
        }
    }
}

// Shopping Cart
.table.cart {
    @include breakpoint(large) {
        border-top: 1px solid $ts-cart-table-border-color;
    }

    tbody {
        &.cart.item {
            &:last-child {
                border-bottom: 1px solid $ts-cart-table-border-color;
            }
        }
    }

    td {
        &.item-image {
            position: relative;
            width: auto;
            margin-bottom: 0;
            @include breakpoint(large) {
                padding-left: 0;
                padding-right: 0;
            }

            .product-image-container {
                max-width: none;
            }
        }

        &.item-info {
            margin-left: 114px;
            padding-top: 0;
            @include breakpoint(large) {
                padding-left: 20px;
            }
        }

        &.cart-actions {
            top: 11px;
            @include breakpoint(large) {
                padding-right: 10px;
            }
        }

        &.qty {
            float: none;
            clear: right;
            margin-left: 114px;
        }

        &.price {
            float: none;
            clear: right;
            margin-left: 114px;
            @include breakpoint(large) {
                text-align: right;
                white-space: nowrap;
            }

            .with-discount {
                color: map-get($foundation-palette, alert);
                line-height: 23px;
                @include breakpoint(large) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            .old-price {
                color: #555555;
                font-size: 12px;
                margin-left: 3px;
                @include breakpoint(large) {
                    font-size: 14px;
                }
            }
        }
    }

    .product-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100px;
    }

    .convert-label-container {
        .convert-label {
            display: none;

            &.convert-label-discount {
                background-color: map-get($foundation-palette, alert);
                border-radius: 4px;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                height: 26px;
                padding: 0 4px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 100;
                @include breakpoint(xlarge) {
                    top: 10px;
                }

                @include breakpoint(xxlarge) {
                    top: 18px;
                }
            }
        }
    }

    .product-item-name {
        a {
            color: $light-black;
            font-size: 15px;
            font-weight: normal;
            @include breakpoint(large) {
                font-size: 18px;
                font-weight: 500;
                line-height: 23px;
            }
        }
    }

    .cart-item-stock-status {
        display: none;
    }

    .product-addtocart-quantity-control {
        @include breakpoint(xlarge) {
            position: relative;
            padding-right: 25px;

            &:after {
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                top: 50%;
                right: 0;
                margin: 1px 0 0 11px;
                transform: translateY(-50%);
                background-image: url("#{$ts-path-to-images}/icon-cross-gray.svg");
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }

        @include breakpoint(xxlarge) {
            padding-right: 40px;
        }

        .product-addtocart-quantity-control-wrapper {
            width: 113px;
            height: 36px;
            line-height: 36px;
            border-radius: 36px;

            .decrease,
            .increase {
                width: 36px;
                height: 36px;
                line-height: 36px;
            }

            .quantity-value {
                font-size: 14px;
            }
        }
    }

    .price-including-tax,
    .price-excluding-tax {
        letter-spacing: -0.02em;
    }

    .old-price {
        font-size: rem-calc(13);
        font-weight: 400;
        letter-spacing: normal;
        color: $ts-product-item-product-price-old-color;
        line-height: 1.4;
        text-decoration: line-through;
    }

    .product-item-details {
        .product-item-manufacturer {
            display: none;
        }
    }
}

.amshopby-option-link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(large) {
        padding-left: 1.25rem;
    }
}

// table.cart

.catalog-product-view {
    .breadcrumbs {
        padding-left: 27px;

        .items {
            @include breakpoint(large) {
                font-size: 14px;
                line-height: 24px;

                a {
                    font-size: inherit;
                    color: rgba($black, 0.5);
                }

                li {
                    font-size: inherit;
                    color: rgba($black, 0.5);

                    &:not(:last-child):after {
                        color: rgba($black, 0.5);
                    }
                }
            }
        }
    }

    .top-controls-wrapper {
        .shipping-bar {
            .progress-text {
                color: #282827;
            }

            .progress-graph {
                background: rgba(119, 86, 2, 0.07);
            }

            .progress-bar {
                border-radius: 50px;
            }
        }
    }

    // AjaxCart Popup for Custom CTA
    &.is-reveal-open {
        .product-addtocart-quantity-control-wrapper {
            z-index: $reveal-zindex + 1;
        }

        .mm-slideout {
            z-index: unset;
            @include breakpoint(large) {
                z-index: 1;
            }
        }
    }

    .reveal-overlay {
        background: rgba($black, 0.2);
    }

    .add-popup-container {
        width: 100%;
        padding: 70px (map-get($grid-column-gutter, small) / 2) + map-get($ts-global-space-indent, small) 0;
        @include breakpoint(large) {
            padding: 0;
            width: 320px;
        }
        @include breakpoint(xlarge) {
            width: 450px;
        }
        @include breakpoint(xxlarge) {
            width: 620px;
        }
        @include breakpoint(xxxlarge) {
            width: 895px;
        }

        .add-popup-showcart {
            display: none;
        }

        .add-popup-block {
            border-radius: 8px;
            box-shadow: 4px 4px 8px rgba($black, 0.15);
            background: lighten(desaturate(adjust-hue($primary-color, -6), 21.52), 60.59);
        }

        .add-popup-message {
            border-radius: 8px 8px 0 0;
            padding: 50px 20px 30px;
            background: transparent;
            box-shadow: none;
            @include breakpoint(xxlarge) {
                padding-bottom: 23px;
            }

            .txt {
                width: 100%;
                font-size: 22px;
                text-transform: none;
                @include breakpoint(xxlarge) {
                    font-size: 28px;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }

            &:after {
                display: block;
                right: 50% !important;
                transform: translateX(50%) !important;
                border-color: transparent transparent lighten(desaturate(adjust-hue($primary-color, -6), 21.52), 60.59)
                    transparent;
            }
        }

        .add-popup-product-image {
            display: none;
        }

        .close-button {
            display: block;
            font-size: 0;
            width: 35px;
            height: 35px;
            font-size: 0;
            top: 15px;
            right: 18px;
            padding: 0;
            background-image: url("#{$ts-path-to-images}/icon-close.svg");
            background-size: 20px auto;
            background-repeat: no-repeat;
            background-position: 50%;
            z-index: 1;
            @extend .no-outline;
        }

        .add-popup-actions {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            padding: 0 36px 30px;
            background: transparent;
            @include breakpoint(xxlarge) {
                padding: 0 20px 32px;
                flex-wrap: nowrap;
                justify-content: center;
            }

            .btn-viewcart,
            .btn-continue {
                width: 100%;
                min-width: inherit;
                float: none;
                margin: 0 0 10px;
                padding: 0.75em 1em;
                @include breakpoint(xxlarge) {
                    min-width: 260px;
                    width: auto;
                    margin: 0 4px;
                }

                .countdown {
                    @include element-invisible;
                }
            }

            .btn-viewcart {
                order: 1;

                &:after {
                    display: none;
                }

                @include breakpoint(xxlarge) {
                    order: 2;
                }
            }

            .btn-continue {
                order: 2;
                border-color: $primary-color;
                color: $primary-color;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color !important;
                }

                @include breakpoint(xxlarge) {
                    order: 1;
                }
            }
        }

        .block.grid {
            padding: 27px 20px;
            border-radius: 0 0 8px 8px;
            background: $white;
            @include breakpoint(xxlarge) {
                padding: 35px 50px;
            }

            .block-title strong {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    .product-info-main {
        .product-info-top-skuprice {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .configurable-variation-qty {
            display: none;
        }

        .super-attribute-select,
        select.product-custom-option {
            border-color: transparent;
            border-radius: 100px;
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 11px;
            background: $light-background url("#{$ts-path-to-images}/icon-arrow-down-black.svg") calc(100% - 20px) 50%
                no-repeat;
            background-size: 12px;
            font-size: 14px;
            height: 52px;

            &:focus {
                box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
            }
        }

        .product-options-wrapper {
            width: 100%;

            > .fieldset {
                @include breakpoint(large) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    > .field {
                        width: calc(50% - 9px);
                    }
                }
                @include breakpoint(medium down) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .product-info-stock-sku {
                display: none;
            }

            .swatch-attribute-options {
                margin-left: -6px;
                margin-top: 6px;
                display: flex;
                flex-wrap: wrap;
            }

            .swatch-option.text.selected {
                background-color: #046C66;
                color: #fff;
            }

            .swatch-option.disabled {
                opacity: 1;
                position: relative;
                color: $ts-size-product-swatch-disabled-color;
                cursor: not-allowed;
            }

            //.swatch-option.text {
            //    box-shadow: $ts-size-product-swatch-full-border;
            //    margin: $ts-size-product-swatch-margin;
            //    font-size: $ts-size-product-swatch-font-size;
            //    height: 44px;
            //    padding: 5px 17px;
            //    border-radius: 50px;
            //    border-left: 0;
            //
            //    &:hover,
            //    &:active {
            //        box-shadow: $ts-size-product-swatch-border-hover;
            //    }
            //}
        }
    }
}

// catalog-product-view

// Defult CTA Qty control
.product-addtocart-quantity-control {
    margin-bottom: 14px;
    @include breakpoint(large) {
        margin-bottom: 0;
    }

    &.is-shown {
        display: block;
    }

    // Custom CTA
    .product-addtocart-quantity-control-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        width: 115px;
        height: 42px;
        text-align: center;
        border-radius: 26px;
        position: relative;
        background: $light-background;
    }

    .quantity-value {
        display: block;
        font-size: 14px;
        font-weight: normal;
        font-family: $header-font-family;
    }

    .decrease,
    .increase {
        display: block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        font-size: 20px;
        font-family: $body-font-family;
        font-weight: normal;
        border-radius: 50%;
        color: $black;
        background: $white;
        text-decoration: none;
        transition: background 0.3s ease;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

        &:hover,
        &:active {
            background: saturate($primary-color, 30);
        }
    }

    // Product is adding to cart now and smth loading
    &.is-loading {
        .product-addtocart-quantity-control-wrapper:after {
            content: "";
            @include loading-overlay;
            display: block;
            background-size: 30px auto;
        }
    }
}

// .product-addtocart-quantity-control

//
// Page Builder
//  ---------------------------------------------

// Native pagebuilder classes
[data-appearance="full-bleed"] {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.catalog-category-view {
    .page-container {
        .category-header--cms-block {
            max-width: none;
        }
    }

    [data-appearance="contained"] {
        max-width: rem-calc(1410);
        padding: 0 rem-calc(20);
        margin: auto;
    }
}

.pagebuilder-mobile-hidden {
    @include breakpoint(medium down) {
        display: none !important;
    }
}

.pagebuilder-mobile-only {
    @include breakpoint(large) {
        display: none !important;
    }
}

.pagebuilder-button-primary {
    @include button;
    @include button-hollow;
    @include button-hollow-style(map-get($button-palette, primary), 1);
    box-shadow: $ts-button-box-shadow;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.pagebuilder-button-secondary {
    @include button;
    @include button-style($primary-color);
    box-shadow: $ts-button-box-shadow;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        background-color: $primary-color;
    }
}

//
// Print Order
//  ---------------------------------------------
.page-print {
    .logo {
        max-width: 300px;
    }
}

.page-layout-checkout,
.catalog-product-view {
    .page-container {
        padding: 0;
    }
}

//Brand page
.ambrand-index-index {
    .column {
        &.main {
            width: 100%;
        }
    }

    .golfshopen-subcategories-links {
        display: none;
    }
}

.ambrand-index-index,
.page-products {
    .page-container {
        padding: 0;
    }

    .category-header {
        .category-view {
            max-width: 918px;
            @include breakpoint(medium down) {
                padding-top: 40px;
            }

            .page-title span {
                font-size: 28px;
                font-weight: 500;
                padding: 0;
                margin: 0;
                @include breakpoint(large) {
                    font-size: 36px;
                    text-align: center;
                }
            }
        }

        .page-title-sorting-wrapper {
            .page-title-wrapper {
                padding: 0;

                h1 {
                    @include breakpoint(large) {
                        margin-bottom: 20px;
                    }
                }
            }

            .category-description {
                margin: 0 -10px 2rem;
                padding: 0;
                float: none;
                display: block;
                width: calc(100% + 20px);
                @include breakpoint(large) {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                }

                p {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            .toolbar-products {
                @include breakpoint(medium) {
                    //width: 100%;
                }
            }
        }

        &.has-bg {
            background-repeat: no-repeat;
            background-position: center top !important;
            @include breakpoint(medium down) {
                background-image: none;
                padding-top: 0;
                position: relative;
            }

            @include breakpoint(large) {
                background-size: auto 223px !important;
            }
            @include breakpoint(xlarge) {
                background-size: auto 344px !important;
            }

            .top-controls-wrapper {
                @include breakpoint(large) {
                    height: 53px;
                }
                @include breakpoint(xlarge) {
                    height: 87px;
                }
            }
        }

        .brand-small-image {
            display: none;
        }

        .top-controls-wrapper {
            @include breakpoint(medium down) {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;

                .breadcrumbs .items a,
                .breadcrumbs .items li {
                    color: rgba($white, 0.5);

                    &:not(:last-child):after {
                        color: rgba($white, 0.5);
                    }
                }
            }
        }
    }
}

//GS-266
.catalog-category-view .category-header .top-controls-wrapper {
    @include breakpoint(medium down) {
        position: initial;
        margin-bottom: 0;
    }

    .breadcrumbs .items a,
    .breadcrumbs .items li {
        @include breakpoint(medium down) {
            color: rgba($black, 0.5);

            &:not(:last-child):after {
                color: rgba($black, 0.5);
            }
        }
    }
}

// Visibility classes
//  ---------------------------------------------

.mobile-hidden {
    @include breakpoint(medium down) {
        display: none !important;
    }
}

.tablet-hidden {
    @include breakpoint(large only) {
        display: none !important;
    }
    @include breakpoint(xlarge only) {
        display: none !important;
    }
}

.desktop-hidden {
    @include breakpoint(xxlarge) {
        display: none !important;
    }
}

.mobile-only {
    @include breakpoint(large) {
        display: none !important;
    }
}

.tablet-only {
    @include breakpoint(medium down) {
        display: none !important;
    }

    @include breakpoint(xxlarge) {
        display: none !important;
    }
}

.desktop-only {
    @include breakpoint(xlarge down) {
        display: none !important;
    }
}

// CLERK SEARCH
//  ---------------------------------------------
.clerk-instant-search {
    padding: 0 25px;
    position: relative;
    text-align: center;
    @include breakpoint(large only) {
        transform: translateX(-100px);
    }

    .clerk-instant-search-inner-wrapper {
        box-shadow: 9px 15px 15px rgba($black, 0.2);
        background-color: $white;
        border-radius: 0 0 8px 8px;
    }

    .clerk-instant-search-inner-wrapper-wrapper {
        padding: 20px 20px 20px 15px;
        position: relative;
        overflow: hidden;

        &:after {
            display: block;
            content: "";
            height: 1px;
            position: absolute;
            top: -1px;
            left: -20px;
            right: -20px;
            box-shadow:
                2px 2px 4px rgba($black, 0.2),
                $ap-global-shadow-active;
            z-index: 2;
        }

        @include breakpoint(large) {
            padding: 20px 15px 40px 15px;
        }
    }

    .clerk-instant-search-no-results {
        padding: 0 0 0 20px;
    }

    .clerk-instant-search-alternate-query {
        @include element-invisible;
    }

    .clerk-instant-search-products {
        text-align: left;

        > a {
            color: $body-font-color;
            text-decoration: none;

            &:hover,
            &:active {
                color: $primary-color;
            }
        }

        .clerk-instant-search-product {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 17px;
            margin-bottom: 10px;
            @include breakpoint(large) {
                margin-bottom: 0;
            }

            .clerk-instant-search-product-image {
                width: 30px;
                height: 30px;
                background-size: 60% auto;
                background-repeat: no-repeat;
                background-position: 50%;
                @include breakpoint(large) {
                    width: 60px;
                    height: 60px;
                }
            }

            .clerk-instant-search-product-name {
                padding: 0 15px;
                max-width: 200px;
                flex: 1;
                @include breakpoint(medium) {
                    max-width: 400px;
                }
                @include breakpoint(large) {
                    max-width: 200px;
                }
                @include breakpoint(xxlarge) {
                    max-width: 400px;
                }
            }

            .clerk-instant-search-product-prices {
                color: $primary-color;
                font-family: $header-font-family;
                text-align: right;
                margin-left: auto;
                @include breakpoint(xlarge) {
                    padding-right: 25px;
                }

                .clerk-instant-search-product-list-price {
                    font-size: 13px;
                    color: rgba($body-font-color, 0.5);
                    text-decoration: line-through;
                    font-family: $body-font-family;
                    margin-top: -17px;
                    line-height: 1;
                }
            }
        }

        .clerk-instant-search-more-results {
            margin-top: 25px;
            text-align: center;

            .button {
                min-width: 260px;
                font-size: 1em;
                padding: 0.8em 1em;
                margin-bottom: 0;
            }
        }
    }
}

// .clerk-instant-search

// QUESTIONNAIRE
//  ---------------------------------------------
.modal-popup {
    &.questionnaire-modal {
        .modal-inner-wrap {
            max-width: 894px;
            border-radius: 8px;
            box-shadow: $ap-global-shadow-medium;
        }

        .action-close {
            font-size: 34px;
            top: 10px;
            right: 19px;
        }

        .modal-header {
            padding: 50px 25px 16px;
            border-radius: 8px 8px 0 0;
            background: lighten(desaturate(adjust-hue($primary-color, -6), 21.52), 60.59);
            @include breakpoint(xlarge) {
                padding: 65px 73px 32px;
            }
        }

        .modal-title {
            text-align: center;
            border-bottom: 0;
            padding: 0;
            font-size: 21px;
            @include breakpoint(xlarge) {
                font-size: 28px;
            }
        }

        .modal-content {
            padding: 0 25px 25px;
            @include breakpoint(xlarge) {
                padding: 0 73px 55px;
            }
        }

        .quest-intro {
            padding: 0 25px 30px;
            margin: 0 -25px 30px;
            font-size: 20px;
            line-height: 1.5;
            background: lighten(desaturate(adjust-hue($primary-color, -6), 21.52), 60.59);
            @include breakpoint(xlarge) {
                padding: 0 73px 56px;
                margin: 0 -73px 65px;
            }
        }

        .quest-row {
            @include grid-row;
            @include grid-row-nest;
        }

        .quest-col {
            @include grid-col;
            margin-bottom: 25px;
            @include breakpoint(xlarge) {
                @include grid-col-end;
                @include grid-col-size(6);
                margin-bottom: 54px;
            }

            &:nth-child(odd) {
                clear: both;
            }

            .quest-form-message,
            .mage-error {
                margin-top: 20px;
            }
        }

        .links {
            margin-bottom: 20px;
        }

        .quest-title,
        .quest-group-title {
            line-height: 1.2;
            font-size: 17px;
            font-family: $header-font-family;
            margin-bottom: 10px;
            @include breakpoint(xlarge) {
                margin-bottom: 16px;
            }
        }

        .quest-group-container {
            margin-bottom: 25px;
            @include breakpoint(xlarge) {
                margin-bottom: 55px;
                padding-right: 66px;
            }

            .quest-title {
                font-family: $body-font-family;
                margin: 0;
                flex: 1;
            }

            .quest-answers {
                @include lsn;

                > li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 6px 0;
                    border-top: 1px solid $ts-main-border-color;
                }
            }

            .quest-options {
                white-space: nowrap;

                li {
                    margin: 0 0 0 15px;
                    @include breakpoint(xlarge) {
                        margin: 0 0 0 30px;
                    }
                }
            }
        }

        .quest-options {
            @include lsn;

            li {
                display: inline-block;
                vertical-align: middle;
                margin: 0 15px 0 0;
                @include breakpoint(xlarge) {
                    margin: 0 30px 0 0;
                }
            }
        }

        .checkbox input[type="checkbox"] {
            + .label {
                display: inline-block;
                font-size: 17px;
                font-family: $body-font-family;

                &:before {
                    top: 2px;
                }
            }
        }

        .radio input[type="radio"] {
            + label {
                font-size: 17px;
                font-family: $header-font-family;
                padding-left: 32px;

                &:before {
                    width: 24px;
                    height: 24px;
                    border-color: lighten($black, 59.22);
                    top: -2px;
                }

                &:after {
                    width: 16px;
                    height: 16px;
                    top: 2px;
                    left: 4px;
                }
            }
        }

        .questionnaire-action-container {
            text-align: center;
            margin-top: 25px;
            @include breakpoint(xlarge) {
                margin-top: 45px;
            }

            .action {
                width: 100%;
                font-size: 1em;
                padding: 0.8em 1em;
                margin: 0 0 10px;
                @include breakpoint(large) {
                    width: auto;
                    min-width: 200px;
                    margin: 0 5px;
                }
                @include breakpoint(xlarge) {
                    min-width: 260px;
                }
            }
        }

        .quest-form-message,
        .mage-error {
            font-size: 15px;
            line-height: 1.67;
            padding: 17px 22px 22px 24px;
            border-radius: 8px;
            color: $body-font-color;
            background: lighten(desaturate(adjust-hue($primary-color, -6), 21.52), 60.59);

            &.error {
                background: lighten(desaturate(adjust-hue($alert-color, 356), 21.36), 43.14);
            }
        }

        .mage-error {
            padding-bottom: 17px;
            background: lighten(desaturate(adjust-hue($alert-color, 356), 21.36), 43.14);
        }
    }
}

.checkout-klarna-success {
    .page-title,
    .top-controls-wrapper,
    .kco-checkout-success,
    .kco-checkout-registration {
        display: none;
    }

    .checkout-success {
        text-align: center;

        .action.primary {
            @include button;
            @include button-style($primary-color);
            box-shadow: $ts-button-box-shadow;
            font-weight: 500;
            text-decoration: none;
        }
    }
}

.account {
    .actions-toolbar {
        margin-bottom: 20px;
    }

    .table-wrapper {
        overflow-x: auto;
    }

    @include breakpoint(large) {
        .page-title {
            margin-bottom: 60px;
        }
        .actions-toolbar {
            margin-bottom: 50px;
        }
    }
}

#trustpilot-widget-trustbox-1-wrapper {
    z-index: 1 !important;
    position: relative;
}

.legal-logo {
    display: inline-block;
}

.children-category-container {
    .wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .slide {
        margin: 0 16px 19px 0;
        border-bottom: 1px solid $primary-color;
        line-height: 0.9;
        font-size: rem-calc(17);
        text-decoration: none;
        @include breakpoint(large) {
            margin: 9px 35px 10px 0;
            font-size: rem-calc(20);
        }

        .category-item-title {
            max-width: none !important;
        }
    }

    //.slide
}

//.children-category-container

.ais-pagination,
.ais-Pagination-list,
.convert-paging .pages-items {
    .ais-pagination--item,
    .ais-Pagination-item,
    .item {
        &.ais-pagination--item__previous,
        &.ais-Pagination-item--previousPage,
        &.ais-pagination--item__next,
        &.ais-Pagination-item--nextPage,
        &.pages-item-next,
        &.pages-item-previous {
            @include breakpoint(medium down) {
                &:before {
                    content: "";
                    width: $ts-pagination-item-next-prev-arrow-width;
                    height: $ts-pagination-item-next-prev-arrow-height;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) rotate(180deg);
                    left: $ts-pagination-item-next-prev-arrow-hz-indent;
                    background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                }
            }

            &.ais-Pagination-item--disabled {
                display: none;
            }

            .ais-pagination--link,
            .ais-Pagination-link,
            .link,
            .action {
                font-size: map-get($ts-pagination-item-next-prev-button-font-size, small);
                font-weight: 600;
            }
        }

        &.ais-Pagination-item--previousPage,
        &.ais-Pagination-item--nextPage {
            .ais-Pagination-link {
                &:hover,
                &:focus {
                    box-shadow: none;
                }
            }
        }

        &.ais-Pagination-item--previousPage {
            .ais-Pagination-link {
                padding-left: 20px;
                padding-right: 10px;
            }
        }

        &.ais-Pagination-item--nextPage {
            .ais-Pagination-link {
                padding-left: 10px;
                padding-right: 20px;
            }
        }

        &.ais-pagination--item__next,
        &.ais-Pagination-item--nextPage,
        &.pages-item-next {
            left: auto;
            right: 0;
            @if $ts-pagination-item-next-prev-has-arrow {
                &:before {
                    transform: translateY(-50%);
                    left: auto;
                    right: $ts-pagination-item-next-prev-arrow-hz-indent;
                }
            }
        }
    }
}

.b-page-checkout {
    .page-header {
        height: map-get($ts-checkout-headbar-height, small);
        @include breakpoint(large) {
            margin-bottom: 0;
            height: map-get($ts-checkout-headbar-height, normal);
        }

        .showcart {
            .text {
                color: $white;
            }
        }
    }

    .header {
        .header-row {
            .logo {
                img {
                    max-width: 100%;
                }
            }
        }

        .tab-bar {
            .nav-toggle {
                display: inline-block;
            }
        }
    }

    .sticky-header-row {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .top-controls-wrapper {
        margin-bottom: 0;

        .breadcrumbs {
            .items {
                a,
                li,
                li:not(:last-child):after {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .shipping-bar-container {
            display: none;
        }
    }

    .page-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
        @include breakpoint(large) {
            margin-bottom: 20px;
        }

        .page-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 1.5rem;
            text-align: center;

            @include breakpoint(large) {
                font-size: 36px;
            }
        }
    }

    .title-additional-wrapper {
        @include breakpoint(large) {
            display: flex;
        }
    }

    .cart-collapse-block-wrapper {
        display: flex;
        margin: 25px 0 50px;
        justify-content: center;

        .cart-collapse-block {
            .content {
                display: none;
                width: 300px;
                position: absolute;
                top: 100%;
                margin-top: 0;
                padding: 20px;
                text-align: left;
                background: $white;
                box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
                z-index: 560;

                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 12px;
                    border-color: transparent transparent $white;
                }

                .legend {
                    @include element-invisible;
                }

                .label {
                    font-size: 15px;
                    line-height: normal;
                    margin-bottom: 10px;
                }

                .input-text {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .cart-collapse-block .button.secondary {
        @include breakpoint(large down) {
            padding: 17px;
        }
    }

    .block.aw-giftcard {
        position: relative;
        padding-right: 15px;
        @include breakpoint(large) {
            padding-bottom: 0;
        }

        .content {
            left: 2px;

            &:before {
                left: 40px;
            }
        }

        .actions-toolbar {
            padding: 0;

            button {
                width: 100%;

                &.primary {
                    margin-bottom: 10px;
                }
            }
        }

        .action.apply {
            width: 100%;
        }
    }

    .block.discount {
        position: relative;
        @include breakpoint(large) {
            padding-bottom: 0;
        }

        .content {
            right: 2px;

            &:before {
                right: 40px;
            }
        }

        .actions-toolbar {
            padding: 0;
        }

        .action.apply {
            width: 100%;
        }
    }

    .checkout-methods-items {
        margin: 0;
    }

    .checkout-container {
        @include grid-col(12);
    }
}

.b-page-kco-checkout {
    ul.klarna-content {
        li {
            padding-left: 0;
        }

        .step-title {
            font-size: 18px;
            text-align: center;
            @include breakpoint(large) {
                font-size: 36px;
            }
        }
    }
}

.form-edit-account {
    .choice {
        #tooltip-label {
            display: none;
        }

        .field-tooltip-content {
            margin: 5px 0 0;
            font-size: rem-calc(13);
        }
    }

    .additional_info {
        @include grid-col-size(12);
    }
}

.field-recaptcha {
    .form-login & {
        @include grid-col(12);
        clear: both;
        margin: 0 0 20px;
    }

    .password.forget & {
        margin: 0 0 20px;
    }
}

.field.captcha {
    margin: 0 0 20px;

    .captcha-reload {
        margin: 0 0 0 10px;
    }
}

.page-product-configurable {
    .product-info-main {
        .product-info-top-skuprice {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .configurable-variation-qty {
            display: none;
        }

        .super-attribute-select {
            border-color: transparent;
            border-radius: 100px;
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 11px;
            background: #f5f4f3 url("#{$ts-path-to-images}/icon-arrow-down-black.svg") calc(100% - 20px) 50% no-repeat;
            background-size: 12px;
            font-size: 14px;
            height: 52px;
        }

        .product-options-wrapper {
            width: 100%;

            > .fieldset {
                @include breakpoint(large) {
                    > .field {
                        width: calc(50% - 9px);
                    }
                }

                .swatch-opt {
                    width: 100%;
                }
            }

            .swatch-attribute-label {
                display: block;
                font-size: 1rem;
                font-weight: 600;
                width: 100%;
            }

            .swatch-attribute-options {
                margin-left: -6px;
                margin-top: 6px;
            }

            .swatch-option.text {
                margin: $ts-size-product-swatch-margin;
                font-size: rem-calc(14);
                border-radius: 14px;
                font-weight: 600;
                background: #F5F4F3;
                width: rem-calc(63);
                height: rem-calc(38);
                display: flex;
                justify-content: center;
                align-items: center;

                @include breakpoint(large) {
                    width: rem-calc(72);
                    height: rem-calc(43);
                }
            }
        }

        .swatch-attribute.fatning_gloves .swatch-option.text {
            width: rem-calc(120);
        }
    }
}


// hiding shipping bar on user account pages
body {
    &.account,
    &.customer-account-login,
    &.customer-account-logoutsuccess {
        .shipping-bar-container {
            display: none;
        }
    }
}

.catalogsearch-result-index {
    .top-controls-wrapper {
        display: none;
    }
}
.page-title-wrapper.product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 1.5rem;

    .amshopby-option-link { grid-area: 1 / 1 / 2 / 2; }
    #lipscore-rating { grid-area: 1 / 2 / 2 / 3; }
    .page-title { grid-area: 2 / 1 / 3 / 3; }

    .multiple-wishlist-dropdown {
        display: none;
    }

    .action.towishlist,
    .action.wishlist-remove-product {
        margin-top: 10px;
        height: 30px;
        width: 30px;
    }
}

body.wishlist-index-index {
    .block-content.padding-info {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .form-wishlist-items {
        .actions-toolbar .primary {
            display: flex;

            .action.share {
                order: 2;
            }

            .action.tocart {
                order: 1;
            }
        }
    }

    .wishlist-list-and-create {
        display: flex;
        justify-content: space-between;

        #wishlists-select,
        .item.wishlist-add {
            width: 48%;
        }

        #wishlists-select {
            height: 51px;
            background-color: #f9f7f6;
        }
    }

    .wishlist-title.box-title {
        overflow: visible;

        .wrapper {
            display: flex;
        }

        .text,
        .links {
            width: 50%;
        }

        .links {
            display: flex;
            justify-content: space-between;
        }

        .wishlist-toolbar-actions {
            display: flex;
        }

        button,
        .action-box {
            font-size: 14px;
            color: #046c66;
            font-weight: 700;
            text-decoration: underline;
            width: auto;
            cursor: pointer;
        }

        .action {
            font-weight: 700;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 1405px) {
    body.wishlist-index-index {
        .wishlist-title.box-title {
            .text {
                width: 30%;
            }

            .links {
                width: 70%;
            }
        }

        .wishlist-list-and-create {
            flex-direction: column;

            #wishlists-select,
            .item.wishlist-add {
                width: 227px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    body.wishlist-index-index {
        .wishlist-title.box-title {
            .text {
                width: 20%;
            }

            .links {
                width: 80%;
            }

            button,
            .action-box {
                font-size: 12px;
                font-weight: 600;
            }

            .action {
                font-weight: 600;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    body.wishlist-index-index {
        .wishlist-title.box-title {
            .wrapper {
                flex-direction: column;

                .text {
                    width: 100%;
                }

                .links {
                    width: 100%;
                }
            }
        }
    }
}

.customer-account-login {
    .login-container {
        display: flex;
        flex-direction: column;
        .login-vipps {
            text-align: center;
            margin-bottom: 30px;
        }

        .or-wrapper {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #b2b2b2;
            line-height: 0.1em;
            margin: 10px 0 30px;
        }

        .or {
            background: #fff;
            padding: 0 10px;
            color: #444;
        }
        .block-new-customer {
            order: 1;
            float: none;
            width: 100%;
            p {
                margin-bottom: 30px;
            }
            .block-content {
                max-width: 650px;
                text-align: center;
                margin: 0 auto;
                padding: 0;
                .actions-toolbar {
                    max-width: 285px;
                    margin: 0 auto;
                    .primary {
                        text-align: center;
                    }
                }
            }
        }
        .account-sign-in.my-account {
            order: 2;
        }
        .block-customer-login {
            order: 3;
            float: none;
            width: 100%;
            margin-top: 80px;
            .login-vipps {
                max-width: 285px;
                margin: 30px auto;
            }
            .form.form-login {
                max-width: 285px;
                padding: 0;
                margin: 0 auto;
                background: #fff;
                box-shadow: none;
                .fieldset {
                    margin: 0;
                    .label {
                        height: 28px;
                    }
                }
                .field {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 0px;
                }
                .actions-toolbar {
                    display: flex;
                    div.primary,
                    div.secondary {
                        float: none;
                        width: 50%;
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 455px) {
    body.wishlist-index-index {
        .wishlist-title.box-title {
            button,
            .action-box {
                font-size: 14px;
                font-weight: 700;
            }

            .action {
                font-weight: 700;
                font-size: 14px;
            }
            .links {
                flex-direction: column;
            }
        }
        .form-wishlist-items .actions-toolbar .primary {
            flex-direction: column;
        }
        .wishlist-list-and-create {
            #wishlists-select,
            .item.wishlist-add {
                width: 100%;
            }
            #wishlist-create-button {
                display: block;
            }
        }
    }
}

.customer-account-create {
    .login-vipps-holder {
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }
    .or-wrapper {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #b2b2b2;
        line-height: 0.1em;
        margin: 10px 0 30px;
    }

    .or {
        background: #fff;
        padding: 0 10px;
        color: #444;
    }
}

button.action.primary.vipps-btn {
    background: #ff5b24;
    margin-bottom: 30px;
    .icon-vipps {
        background: url("#{$ts-path-to-images}/vipps-logo.svg");
        width: 65px;
        display: inline-block;
        height: 0;
        padding: 8px 0;
        background-repeat: no-repeat;
        margin-bottom: -5px;
    }
}

@media screen and (max-width: 768px) {
    .page-title-wrapper.product {
        .action.towishlist,
        .action.wishlist-remove-product {
            margin-top: 0px;
        }
    }

    .customer-account-create {
        .login-vipps-holder {
            margin-bottom: 50px;
        }
    }
}

#videoly-videobox-placeholder {
    @include breakpoint(large) {
        padding-left: 120px;
    }

    videoly-slider {
        margin: 0 0 24px 0;
    }
}

.related-product-price {
    font-size: 18px;
    display: flex;
    align-items: baseline;
    flex-direction: column;

    .related-special-price {
        color: #F7465B;
        font-size: 18px;
        font-weight: 600;
    }

    .related-normal-price {
        display: inline;
        font-weight: 600;
    }
}

.related-product-price.has-special .related-normal-price {
    opacity: 0.7;
    text-decoration: line-through;
    font-size: 12px;
}

.product-info-price a img {
    @include breakpoint(medium down) {
        height: 26px;
    }
}

.hr-buy-form .product-addtocart-quantity-control {
    display: none;
}

#hr-product-box-1, #hr-product-box-2 {
    min-height: 460px;
}

@media only screen and (max-width: 600px) {
    #hr-product-box-1, #hr-product-box-2 {
        min-height: 400px;
    }
}
@media only screen and (max-width: 720px) {
    #hr-product-box-1, #hr-product-box-2 {
        min-height: 430px;
    }
}
@media only screen and (max-width: 1080px) {
    #hr-product-box-1, #hr-product-box-2 {
        min-height: 450px;
    }
}

.validation-advice.error-message {
    color: #F7465B;
    font-size: 0.875rem;
    margin-top: 5px;
    margin-bottom: 16px;
    font-weight: 600;
}

.action.tocart {
    transition: background-color 0.3s ease;
}

.button-error {
    background-color: #F7465B !important;
}

@media only screen and (max-width: 1024px) {
    .description-flex {
        .pagebuilder-column-line {
            flex-direction: column;

            .pagebuilder-column {
                width: 100% !important;
            }
        }
    }
}

.description-block-table {
    overflow-x: scroll;

    td {
        padding: 10px;
        white-space: nowrap;
    }
}
