//
// Customer/account/create
//  ---------------------------------------------
.form-create-account {
	margin-bottom: 3rem;

	.actions-toolbar {
		text-align: center;
		margin:2rem 0 2.5rem;

		.submit {
			min-width: rem-calc(230);
		}

		.secondary {
			display: none;
		}
	}

	.check-toolbar {
		margin-top: 1rem;
		margin-bottom: 2.5rem;

		@include breakpoint(large) {
			margin-top: 0;
		}

		.choice {
			@include checkbox;

			label {
				&:after {
					display: none;
				}
			}
		}
	}

	.fieldset.create {
		.check-toolbar {
			display: none;
		}
	}
	.field.choice {
		&.consent,
		&.newsletter {
			.newsletter-terms {
				margin-top: .5rem;
			}

			div.mage-error {
				margin: .15rem 0 .5rem 1.4rem;
			}
		}
	}
}// .form-create-account

.customer-account-create,
.company-account-create {
	.page-title-wrapper {
		margin-bottom: 1rem;

		.page-title {
			text-align: center;
		}

		&:before {
			display: none;
		}

		@include breakpoint(large) {
			margin-bottom: .65rem;
		}
	}
	.page.messages {
		margin-bottom: 0;
	}
}// .customer-account-create

.b-account-register {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.account-type-sidebar,
	.account-type-fields,
	.account-register-help {
		@include grid-col;

		.title {
			@include header-font-family($ts-account-register-subtitle-family);
			font-size: $ts-account-register-subtitle-size;
			font-weight: $ts-account-register-subtitle-weight;
			margin-bottom: $ts-account-register-subtitle-indent;
		}
	}

	@if ($ts-account-register-block-alignment == 'center') {
		.account-type-fields {
			margin-top: 0;
			@include grid-column-position(center);
			.label {
				&:after {
					display: none;
				}
			}
		}
	}

	.account-type-sidebar  {

		@include breakpoint(large) {
			@include grid-col-size(3.5);
		}

		@include breakpoint(xlarge) {
			margin-top: 2rem;
			@include grid-col-size(3);
		}

		.title {
			margin-bottom: .25rem;
		}

		.link {
			line-height: 1.3;
		}

		.fieldset {
			position: relative;
			padding: 1.5rem (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small) 1.9rem;
			margin-left: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
			margin-right: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
			margin-bottom: 1.75rem;
			background-color: $box-color;

			@include breakpoint(medium) {
				margin-left: 0;
				margin-right: 0;
			}

			@include breakpoint(xxlarge) {
				padding: 1.5rem 2rem 2.25rem;
				margin-bottom: 2rem;
			}
		}// .fieldset

		.field-list-account-type {
			margin: 1.25rem 0 0;
			position: relative;

			@include breakpoint(xlarge) {
				margin-left: .25rem;
			}
		}

		.field-account-type {
			@include radio;
			margin-bottom: 1rem;

			.label {
				span {
					&:after {
						display: none;
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}// .account-type-sidebar

	.account-type-fields  {

		@include breakpoint(large) {
			padding-top: 1.5rem;
			@include grid-col-size(5);
		}
		@include breakpoint(xlarge) {
			margin-top: 2rem;
			@include grid-col-size(6);
		}

		.label {
			@include label-colon;
		}

		.field-row {
			@include breakpoint(xlarge) {
				margin-bottom: .85rem;
			}
		}

		.fieldset-list {
			.fieldset {
				padding-bottom: 1.25rem;
				margin-bottom: 1.5rem;
				border-bottom: 1px solid $ts-main-dark-border-color;

				&:last-child {
					border-bottom: 0;
					padding-bottom: 0;
					margin-bottom: .5rem;
				}
			}// .fieldset
		}

	}// .account-type-fields

	.account-register-help  {
		@include breakpoint(large) {
			@include grid-col-size(3.5);
		}

		@include breakpoint(xlarge) {
			@include grid-col-size(3);
		}

		.customer-form-after {
			text-align: center;
			position: relative;
			margin-left: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
			margin-right: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
			margin-bottom: 1.75rem;
			background-color: $box-color;

			@include breakpoint(medium) {
				margin-left: 0;
				margin-right: 0;
			}

			.help-block {
				padding: 1.5rem (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small) 1.9rem;

				@include breakpoint(large) {
					padding: 1.5rem 1.25rem 1.75rem;
				}

				@include breakpoint(xlarge) {
					padding: 1.5rem 1.75rem 2.25rem;
				}
			}

			h2,
			.title {
				@include header-font-family($header-font-family);
				font-size: rem-calc(24);
				font-weight: 700;
				margin-bottom: .75rem;

				@include breakpoint(xlarge) {
					font-size: rem-calc(28);
				}
			}

			p {
				margin-bottom: 0;
				line-height: 1.46;
				color: rgba($black, .7);
			}
		}// .customer-form-after
	}// .account-register-help

	.field-row {
		@include grid-row;
		@include grid-row-nest;

		.field {
			@include grid-col;

			@include breakpoint(xlarge) {
				@include grid-col-size(6);

				&.telephone.short {
					@include grid-col-size(4.5);
				}

				&.zip {
					@include grid-col-size(4);
				}
				&.city {
					@include grid-col-size(8);
				}
			}
		}// .field
	}// .field-row

}// .b-account-register

.customer-dob {
	position: relative;

	input {
		width: calc(100% - 50px);
	}

	.v-middle {
		position: absolute;
		width: 50px;
		right: 0;
		top:0;
		height: $input-select-height;
		border:$input-border;
		border-left: 0;
		background:$light-gray url("#{$ts-path-to-images}/icon-calendar.svg") 50% no-repeat;

		span {
			@include at;
		}
	}
}// .customer-dob

.ui-datepicker {
	.ui-datepicker-next,
	.ui-datepicker-prev {
		top: 50%;
		transform: translateY(-50%);
		span {
			margin: 0;
			top: auto;
			left: auto;
			right: 0;
			border-width: 10px 0 10px 16px;
			border-color: transparent transparent transparent $primary-color;
		}
	}
	.ui-datepicker-prev {
		span {
			right: auto;
			left: 0;
			border-width: 10px 16px 10px 0;
			border-color: transparent $primary-color transparent transparent;
		}
	}

	.ui-datepicker-header {
		min-width: 250px;
	}

	.ui-datepicker-calendar {
		margin-bottom: 0;
		th {
			padding: .25rem;
		}
		td {
			padding: 0;

			.ui-state-hover  {
				color: $white;
				background: $primary-color;
			}
		}

		.ui-state-active {
			color: $white;
			background: $primary-color;
		}
	}
}// .ui-datepicker

.field-account-type-info {
	display: inline-block;
	vertical-align: middle;
	margin-left: .2rem;

	@include breakpoint(large) {
		position: relative;
	}

	&:hover,
	&:active {
		.field-account-text {
			display: block;
			z-index: 1;
		}
	}

	.field-account-text {
		display: none;
		width: rem-calc(280);
		position: absolute;
		bottom: 100%;
		left: 0;
		padding: .5rem;
		margin-bottom: .5rem;
		box-shadow: 0 0 3px rgba($black, .5);
		font-size: rem-calc(11);
		line-height: 1.1;
		background: $white;

		@include breakpoint(large) {
			left: 100%;
			margin-bottom: 0;
		}
	}
}

//
//  Password Strength Meter
//  ---------------------------------------------
.field.new.password {
	.control {
		display: flex;
		flex-direction: column;
		.mage-error {
			order: 2;
			margin-bottom: ($form-spacing / 3);
		}
		.input-text {
			order: 0;
		}
	}
}// .field.password

.password-strength-meter {
	background-color: $ts-password-default;
	height: $ts-password-height;
	line-height: $ts-password-height;
	padding: $ts-password-padding;
	position: relative;
	font-size: $ts-password-font-size;
	margin-top: -($form-spacing / 5);
	margin-bottom: .25rem;
	z-index: 1;

	&:before {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		z-index: -1;
	}

	.password-strength-meter-0 & {
		&:before {
			background-color: $ts-password-default;
			width: 100%;
		}
	}

	.password-strength-meter-1 & {
		&:before {
			background-color: $ts-password-weak;
			width: 25%;
		}
	}

	.password-strength-meter-2 & {
		&:before {
			background-color: $ts-password-medium;
			width: 50%;
		}
	}

	.password-strength-meter-3 & {
		&:before {
			background-color: $ts-password-strong;
			width: 75%;
		}
	}

	.password-strength-meter-4 & {
		&:before {
			background-color: $ts-password-very-strong;
			width: 100%;
		}
	}
} //.password-strength-meter
