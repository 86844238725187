.header {
	.navigation {

		> .ui-menu {
			> .ui-menu-item {

				&.menu-type-static-block {
					> .submenu {
						> .ui-menu-item {
							> .convert-block {
								padding: 20px 25px;
								margin:0 -25px 20px;
								background-color: lighten(desaturate(adjust-hue($secondary-color, 3), 2.90), 23.92);

								@include breakpoint(xxlarge) {
									padding: 42px 59px 35px;
									margin:0 -59px 40px;
								}

								p {
									font-size: 17px;
									line-height: 1.35;
									@include breakpoint(xxxlarge) {
										font-size: 20px;
									}
								}
							}
							.convert-grid-row {
								@include grid-row-nest;
								text-align: center;

								.column {
									@include grid-col-end;
								}

								.button {
									min-width: 320px;
									letter-spacing: .2em;
								}

								.convert-wysiwyg {
									text-align: left;
									margin-bottom: 20px;
									@include breakpoint(xxlarge) {
										margin-bottom: 40px;
									}

									h3,
									h4,
									h5 {
										font-size: 20px;
										margin-bottom: 15px;
									}
									p {
										color: rgba($body-font-color,.75);
										font-size: 13px;
										line-height: 1.65;
										&:not(:last-child) {
											margin-bottom: 5px;
										}
									}
									a {
										color: $anchor-color;
										text-decoration: underline;
										&:hover,
										&:active,
										&:focus {
											text-decoration: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
