.similar-products-wrapper {
    .product-options-wrapper {
        margin-top: rem-calc(26);
        margin-bottom: rem-calc(26);
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(6) rem-calc(15);

        .swatch-attribute-label {
            &.similar__title {
                display: block;
                font-size: rem-calc(16);
                font-weight: 600;
                width: 100%;
            }
        }

        @include breakpoint(medium) {
            gap: rem-calc(10);
            width: 100%;
        }
    }

    .product, .current-product {
        font-size: rem-calc(10);
        text-align: center;
        transition: .3s ease-in-out;

        &:hover {
            box-shadow: $ts-button-box-shadow;
        }

        .convert-label-container {
            display: none;
        }

        .convert-label {
            margin-bottom: rem-calc(5);
        }

        .convert-label-text {
            color: $black;
            font-family: $body-font-family;
        }

        .convert-label-discount {
            .convert-label-text {
                color: map-get($foundation-palette, alert);
            }
        }

        .similar-product-price-container {
            background: #F7465B;
            color: #fff;
            position: relative;
            bottom: 14%;
            border-radius: 10px;

            .similar-product-price {
                padding: rem-calc(3.5) 0;
                font-size: 10px;
                font-weight: 600;

                @include breakpoint(large) {
                    font-size: 12px;
                }
            }
        }
    }

    .product-image-photo {
        background: rgba($light-background, .7);
        height: 6em;
        object-fit: contain;
        width: 6em;
        border-radius: 50px;

        @include breakpoint(medium) {
            height: rem-calc(76);
            width: rem-calc(76);
        }
    }

    .product__info {
        text-decoration: none;
    }

    .similar-color-name {
        margin: 0;
        position: relative;
        bottom: 5%;
    }

    &-mobile {
        order: 5;
    }

    .current-product .product-image-photo {
        border: 1px solid #046C66;
    }
}
