// GS-275
#lipscore-reviews {
    .lipscore-review-form-wrapper {
        .lipscore-review-form {
            display: block;
        }
    }
}
// GS-275

.product-detail-accordion {
    .accordion-item {
        .accordion-title {
            .lipscore-rating-star {
                fill: $primary-color;
                height: 16px;
                width: 16px;

                &.lipscore-star-incomplete .lipscore-star-last-half,
                &.lipscore-rating-star-inactive {
                    fill: $light-background;
                }
            }
        }

        .accordion-content {
            .lipscore-review-list-footer-container {
                display: none;
            }
        }

        #js-lipscore-reviews-tab {
            align-items: center;
            display: flex;
            gap: rem-calc(10);
        }

        #lipscore-rating,
        #js-lipscore-reviews-tab-count {
            line-height: 1;
        }

        .lipscore-compact-rating-wrapper,
        .lipscore-rating-review-link,
        #lipscore-review-post {
            display: none;
        }
    }
}

.hr-item {
    .lipscore-rating-star {
        height: rem-calc(16);
        width: rem-calc(20);
    }
}

.lipscore-service-review-badge-small-short {
    padding-top: rem-calc(40);

    text {
        fill: rgb(255, 255, 255) !important;
    }

    .lipscore-service-review-logo {
        polygon, path {
            fill: rgb(255, 255, 255) !important;
        }
    }

    .lipscore-rating-star {
        fill: rgb(255, 255, 255);
    }
}

.lipscore-rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-title-wrapper .lipscore-rating {
    font-size: 14px;
    text-align: right;

    .lipscore-rating-star {
        fill: $primary-color;
        height: 16px;
        width: 16px;

        &.lipscore-star-incomplete .lipscore-star-last-half,
        &.lipscore-rating-star-inactive {
            fill: $light-background;
        }
    }
}

.page-title-wrapper #lipscore-rating .lipscore-rating-slider {
    pointer-events: auto;
}


@media screen and (max-width: 769px) {
    .lipscore-rating-distribution {
        display: none;
    }

    .lipscore-rating-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}
