.fotorama-video-container {
    &:after {
        background: url(../Magento_ProductVideo/images/gallery-sprite.png) bottom right;
        background-size: 108px 145px;
        height: 70px;
        width: 70px;
        bottom: 0;
        content: '';
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 12px;
        cursor: pointer;
        @include breakpoint(large){
            height: 100px;
            width: 100px;
            background-size: auto;
        }
        transition: transform linear .2s;
    }

    .magnify-lens {
        display: none !important;
    }

    &.video-unplayed {

        &:hover {
            img {
                opacity:.6;
            }

            &:after {
                transform: scale(1.25);
            }
        }
    }
}//.fotorama-video-container

.video-thumb-icon:after {
    background: none;
    bottom: 0;
    content: '';
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 49px;
    @include breakpoint(large){
        background: url(../Magento_ProductVideo/images/gallery-sprite.png) bottom left;
    }
}

.product-video {
    bottom: 0;
    height: 75%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
}

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px;
}

.fotorama__product-video--loading {
    &:after {
        visibility: hidden;
    }
}
