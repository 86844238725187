//
//  Sidebars
//  _____________________________________________
.sidebar-main {
	margin-top: $ts-sidebar-main-top-indent;
	margin-bottom: $ts-sidebar-main-bottom-indent;
}

.sidebar-main,
.sidebar-additional {
	@include grid-col;

	.page-layout-2columns-right & {
		@extend .float-right;
	}

	.page-layout-3columns & {
		@include breakpoint(medium) {
			@include grid-col-size(4);
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(3);
		}
	}

	@include breakpoint(medium) {
		@include grid-col-size(4);
	}
	@include breakpoint(xlarge) {
		@include grid-col-size(3);
	}
}// sidebars

@if $ts-sidebar-additional-hide {
	.sidebar-additional {
		display: none !important;
	}
} @else {
	.sidebar-additional {
		clear: left;

		.page-layout-2columns-left & {
			float: left !important;
		}
		.page-layout-2columns-right & {
			clear: right;
		}
		.page-layout-3columns & {
			@extend .float-right;
			clear: none;
		}

		.actions-toolbar {
			a.action {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}// .sidebar-additional
}
