.header {
	.navigation {
		> ul,
		> .ui-menu {
			> li,
			> .ui-menu-item {

				&.menu-type-image-desc {

					> a.level-top {
						&:hover,
						&:active,
						&:focus,
						&.ui-state-focus,
						&.ui-state-active {
							box-shadow: $ts-header-menu-dropdown-box-shadow;

							&:before {
								display: block;
								content: '';
								position: absolute;
								top: 100%;
								left: 0;
								right: 0;
								height: 6px;
								background-color: $menu-item-background-active;
								z-index: 11;
							}
						}
					}

					> .submenu {
						// display: block !important;
						margin-top: 6px;
						min-width: rem-calc(550);
						@include breakpoint(xxlarge) {
							min-width: rem-calc(700);
						}
						@include breakpoint(xxxlarge) {
							min-width: rem-calc(910);
						}

						.submenu-wrapper {
							min-width: inherit;
							padding: rem-calc(3 0) !important;
							box-shadow: none !important;

							&:before {
								display: none;
							}

							.left-categories {
								width: 100%;
								float: none;
								padding: 0;

								h6 {
									@include at;
								}

								.submenu {
									display: flex !important;
									flex-wrap: wrap;
									margin-left: -.5*(map-get($grid-column-gutter,medium)) !important;
									margin-right: -.5*(map-get($grid-column-gutter,medium)) !important;

									li {
										@include grid-col;
										@include grid-col-size(4);
										padding-top: rem-calc(10);
										padding-bottom: rem-calc(26);
										text-align: center;
										position: relative;

										&:after {
											display: block;
											content: '';
											width: 0;
											border-right: 1px solid $ts-main-border-color;
											position: absolute;
											top: 0;
											right: 0;
											left: auto;
											bottom: 0;
										}

										&:nth-child(3n) {
											&:after {
												display: none;
											}
										}

										a {
											padding: 0;
											text-decoration: none !important;
										}

										.icon-text {
											.submenu-image {
												display: block;
												border-radius: 50%;
												overflow: hidden;
												width: rem-calc(100);
												height: rem-calc(100);
												margin: 0 auto rem-calc(30);

												@include breakpoint(xxlarge) {
													width: rem-calc(133);
													height: rem-calc(133);
													margin-bottom: rem-calc(40);
												}

												img {
													margin: 0;
												}
											}

											> span {
												display: block;
												font-size: rem-calc(20);
												font-family: $header-font-family;
												font-weight: 400;
												margin-bottom: rem-calc(10);
											}
											p {
												font-size: rem-calc(13);
												font-weight: 300;
												line-height: 1.46;
												color: rgba($black, .7);
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}
					}

					.category-static-block {
						text-align: center;
						margin-top: rem-calc(37);

						.column {
							@include get-gutter(0,'padding');
						}

						.convert-wysiwyg {
							p {
								text-align: center;
							}
							a {
								color: $secondary-color;

								&:hover,
								&:active {
									text-decoration: underline;
								}
							}
						}
					}
				}

			}
		}

	}
}
