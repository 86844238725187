//
//  Lists
//  _____________________________________________
ul {
	margin-bottom: 1rem;

	@if $ts-custom-unordered-lists {
		list-style: none;
		margin-left: 0;
	}

	> li {
		padding: .3em 0 .2em 0.2em;

		@if $ts-custom-unordered-lists {
			position: relative;
			padding-left: 1.3em;

			&:after {
				display: block;
				position: absolute;
				content: $ts-custom-unordered-lists-bullet;
				top: .55em;
				left: 0;
				font-size: 1.05em;
				line-height: 1;
				color: lighten($black, 50);
			}
		}// @if
	}
}

ol {
	margin-bottom: 1rem;

	@if $ts-custom-ordered-lists {
		list-style: none;
		margin-left: 0;
	}

	> li {
		padding: .3em 0 .4em 0.2em;

		@if $ts-custom-ordered-lists {
			position: relative;
			counter-increment: step-counter;
			padding-left: 1em;

			&:after {
				font-weight: 700;
				position: absolute;
				top: 0;
				left: 0;
				content: counter(step-counter)".";
			}
		}// @if
	}
}