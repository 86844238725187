@font-face {
  font-family: 'magento-icons';
  src: url('../Magento_Company/fonts/Magento-Icons.woff2') format('woff2'), url('../Magento_Company/fonts/Magento-Icons.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

.jstree ul > li::after {
  content: "";
}

.jstree > ul ul li {
  background: none;
  border-left: 1px dashed #a6a6a6;
  margin-left: 2.4rem;
  padding: .5rem 0;
  position: relative;
}
.jstree > ul ul li.jstree-last {
  background: none;
  border-left: none;
  left: .1rem;
  padding-bottom: 0;
  position: relative;
}
.jstree > ul ul li.jstree-last:after {
  border-left: 1px dashed #a6a6a6;
  content: '';
  height: 2.3rem;
  left: -.1rem;
  position: absolute;
  top: 0;
}
.jstree > ul ul li.jstree-leaf > .jstree-icon.jstree-ocl {
  background: none;
  display: inline;
  padding-right: 2.5rem;
  width: 2.5rem;
}
.jstree > ul ul li.jstree-leaf > .jstree-icon.jstree-ocl:after {
  right: 0;
  top: 1.7rem;
  width: 100%;
}
.jstree > ul ul li > .jstree-icon.jstree-ocl {
  position: relative;
}
.jstree > ul ul li > .jstree-icon.jstree-ocl:after {
  border-top: 1px dashed #a6a6a6;
  content: '';
  height: .1rem;
  position: absolute;
  right: 1.7rem;
  top: 1.1rem;
  width: 1.3rem;
}
.jstree li > .jstree-icon {
  padding-right: 1.5rem;
}
.jstree li a {
  height: 2.2rem;
  line-height: 2.2rem;
  padding: .4rem 1rem .4rem .2rem;
}
.jstree li a > .jstree-themeicon-custom {
  margin-left: 10px;
  margin-right: .7rem;
}
.jstree li a > .jstree-icon.icon-customer:before {
  content: '\e907';
}
.jstree li a > .jstree-icon.icon-customer:before {
  font-size: 1.8rem;
}
.jstree li a > .jstree-icon.icon-company:before {
  content: '\e906';
}
.jstree li a > .jstree-icon.icon-company:before {
  font-size: 1.7rem;
}
.jstree li a > .field-tooltip {
  color: #000000;
  left: 100%;
  margin-left: 5px;
  top: .4rem;
}
.jstree li a .field-tooltip-content {
  white-space: normal;
}
.jstree li:not(.jstree-leaf) > .jstree-icon {
  display: inline-block;
  text-decoration: none;
  left: -.1rem;
  position: relative;
  top: 0.6rem;
  z-index: 5;
}
.jstree li:not(.jstree-leaf) > .jstree-icon > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.jstree li:not(.jstree-leaf) > .jstree-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.9rem;
  line-height: 1.1;
  color: #575757;
  content: '\e909';
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.jstree li:not(.jstree-leaf) > .jstree-icon:before {
  cursor: pointer;
  text-align: center;
}
.jstree li.jstree-open > .jstree-icon:before {
  content: '\e90a';
}
.jstree li.jstree-open > .jstree-icon:before {
  color: #c2c2c2;
  font-size: 1.6rem;
  left: 1.8rem;
  position: relative;
}
.jstree li.jstree-closed > .jstree-icon:before {
  content: '\e909';
}
.jstree li.jstree-closed > .jstree-icon:before {
  color: #c2c2c2;
  font-size: 1.6rem;
  left: 1.8rem;
  position: relative;
}
.jstree > ul > li {
  background: none;
}
.jstree > ul > li > a.company-admin {
  margin-left: -.2rem;
  padding-bottom: 1.3rem;
}
.jstree > ul > li > a.company-admin:before {
  display: none;
}
.jstree > ul > li > a.company-admin > .jstree-icon {
  margin-right: 10px;
  position: relative;
  z-index: 5;
}
.jstree > ul > li > a.company-admin > .jstree-icon:before {
  content: '\e908';
}
.jstree > ul > li > a.company-admin > .jstree-icon:before {
  font-size: 3rem;
}
.jstree > ul > li:not(.root-collapsible) > .jstree-icon.jstree-ocl {
  display: none;
}
.jstree .jstree-leaf > .jstree-icon.jstree-ocl {
  display: none;
}
.jstree a > .jstree-icon {
  display: inline-block;
  text-decoration: none;
  background: none;
}
.jstree a > .jstree-icon > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.jstree a > .jstree-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.8rem;
  line-height: 1.1;
  color: #858585;
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.jstree.jstree-draggable a {
  display: inline-block;
  text-decoration: none;
}
.jstree.jstree-draggable a > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.jstree.jstree-draggable a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.8rem;
  line-height: 1.1;
  color: #c2c2c2;
  content: '\e90b';
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.jstree.jstree-draggable a:before {
  height: 1.8rem;
  margin-left: -.4rem;
  overflow: hidden;
  width: 1.9rem;
}
.jstree.jstree-draggable a.jstree-clicked:before {
  color: #ffffff;
}
.jstree i {
  font-style: normal;
}
.block-dashboard-company .jstree .jstree-clicked {
  background-color: #006bb4;
  color: #ffffff;
}
.block-dashboard-company .jstree .jstree-clicked > .jstree-icon:before {
  color: #ffffff;
}
.jstree .jstree-anchor .jstree-checkbox {
  background-color: transparent;
  background-image: url(../Magento_Company/images/icon-tree.png);
  background-position: top right;
  background-repeat: no-repeat;
  height: 16px;
  margin: 5px 8px -2px 4px;
  position: relative;
  width: 16px;
}
.jstree .jstree-anchor.jstree-clicked > .jstree-checkbox {
  background-position: top center;
}
.jstree .jstree-anchor > .jstree-checkbox.jstree-undetermined {
  background-position: top left;
}
.jstree-checkbox-selection .jstree-anchor {
  display: inline-block;
  text-decoration: none;
}
.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
.jstree-node {
  white-space: nowrap;
}
.jstree-anchor {
  color: black;
  display: inline-block;
  margin: 0;
  padding: 0 4px 0 1px;
  vertical-align: top;
  white-space: nowrap;
}
#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;
}
#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px;
}
#jstree-dnd .jstree-ok {
  background: green;
}
#jstree-dnd .jstree-er {
  background: red;
}
#jstree-dnd .jstree-copy {
  margin: 0 2px 0 2px;
}