.table.data.grouped {

  .table-caption {
    @include at;
  }

  .col {

    &.item{

      .product-item-name{
        font-weight: $ts-grouped-product-name-font-weight;
        font-size: $ts-grouped-product-name-font-size;
      }

      .price-box{
        margin: 10px 0 0;

        > .price-container{
          font-size: $ts-grouped-product-price-font-size;
          font-weight: $ts-grouped-product-price-font-weight;
        }
      }
    }//&.item

    &.qty {
      padding-right: 0;
      width: 10%;

      .control.qty {

        .input-text {
          text-align: center;
          -moz-appearance:textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }//.control.qty
    }//&.qty
  }//.col

  .price-box {
    display: block;
  }
}//.table.data.grouped