//00. Golfshopen Variables
$ap-global-shadow                                            : 0px 4px 10px rgba($black,.15);
$ap-global-shadow-medium                                     : 0px 4px 10px rgba($black,.2);
$ap-global-shadow-active                                     : 0px 4px 10px rgba($black,.3);


// 00.1 Indents
$ts-global-space-indent                                      : ( small : 3px, large : 20px);

// 00.3 Borders
$ts-main-border-color                                        : rgba($black, .15);

// 00.5 Pro Block
$ts-pro-block-margin                                         : (60px 0);
$ts-pro-block-title-font-weight                              : 500;
$ts-pro-block-title-font-size                                : rem-calc(28);
$ts-pro-block-title-margin                                   : (0 0 8px);
$ts-pro-block-title-text-transform                           : none;
$ts-pro-block-title-responsive                               : ( medium :  ( font-size : $ts-pro-block-title-font-size*.75),
                                                                 xxlarge : ( font-size : ($ts-pro-block-title-font-size)),
                                                                 xxxlarge : ( font-size : ($ts-pro-block-title-font-size)));
$ts-pro-block-title-side-lines-gap                           : 78px;

// 00.7 Messages
$ts-main-message-font-family                                 : $body-font-family;
$ts-main-message-text-color-error                            : $body-font-color;
$ts-main-message-bg-error                                    : $white;
$ts-main-message-text-color-success                          : $body-font-color;
$ts-main-message-bg-success                                  : $white;
$ts-main-message-text-color-warning                          : $body-font-color;
$ts-main-message-bg-warning                                  : $white;
$ts-main-message-text-color-info                             : $body-font-color;
$ts-main-message-bg-info                                     : $white;

$ts-main-message-icon-error                                  : 'icon-error';
$ts-main-message-icon-warning                                : 'icon-error';

$ts-main-message-main-icon-position                          : 1.25rem 1.25rem;
$ts-main-message-main-padding                                : 1.05rem 1.2rem 1rem 3rem;

//00.9 Product Photo settings
$ts-product-photo-overlay                                    : true;
$ts-product-photo-overlay-color                              : #6e4a28;
$ts-product-photo-overlay-opacity                            : 0.04;

// 00.10 Stock label
$ts-stock-label-font-size                                    : rem-calc(14);
$ts-stock-label-font-family                                  : $body-font-family;
$ts-stock-label-font-weight                                  : normal;
$ts-stock-label-font-color                                   : $body-font-color;
$ts-stock-label-icon-bg-size                                 : 11px auto;
$ts-stock-label-icon-left-indent                             : 16px;
$ts-stock-label-icon-vt-spacing                              : 0;

$ts-stock-label-icon-available                               : 'icon-valid-green';
$ts-stock-label-icon-available-bg-size                       : $ts-stock-label-icon-bg-size;
$ts-stock-label-icon-available-color                         : $ts-stock-label-font-color;
$ts-stock-label-icon-backorder-bg-size                       : 14px 14px;

// 01. Footer settings
$ts-footer-view                                              : full-width;  // possible values: half-width or full-width
$ts-footer-bg-has-image                                      : false;
$ts-footer-bg-custom-set                                     : true;  // possible values: true, false, true - disable $ts-footer-bg-position
$ts-footer-bg-src                                            : ( small : 'footer_bg', large : 'footer_bg_desktop');
$ts-footer-bg-type                                           : ( small : svg, large : svg); // possible values: svg, jpg, png, none
$ts-footer-bg-size                                           : ( small : calc(100% + 4px) auto, large : calc(100% + 1px) auto);
$ts-footer-bg-position                                       : ( small : 70% 0, large : 50% 0);
$ts-footer-bg-color                                          : $primary-color;
$ts-footer-indents-vt                                        : ( small : 0, medium: 0, xxlarge : 0);
$ts-footer-block-indents-vt                                  : ( small : 0, medium: 0.45, xxlarge : 1.35);
$ts-footer-bottom-block-indents-vt                           : ( small : 0, medium: 0, xxlarge : 0, xxlarge-top :1);
$ts-footer-bottom-bg-color                                   : transparent;
$ts-footer-font-color                                        : $white;
$ts-footer-font-size                                         : rem-calc(16);
$ts-footer-font-family                                       : $body-font-family;

$ts-footer-bottom-border                                     : false; // can be true or false

$ts-footer-header-font-size                                  : 28px;
$ts-footer-header-font-color                                 : $white;
$ts-footer-header-font-weight                                : 400;
$ts-footer-header-margin-bottom                              : 14px;
$ts-footer-header-text-transform                             : none;
$ts-footer-header-last-decrease                              : false;
$ts-footer-border-style                                      : none;

// 01.1 Footer Navigation settings
$ts-footer-nav-text-transform                                : none;
$ts-footer-nav-anchor-color                                  : $white;
$ts-footer-nav-anchor-color-hover                            : rgba($white, 0.75);
$ts-footer-nav-font-weight                                   : normal;
$ts-footer-nav-anchor-padding                                : (4px 0);

// 01.2 Footer Cookies settings
$ts-footer-cookies-text-align                                : left;
$ts-footer-cookies-font-size                                 : rem-calc(12);
$ts-footer-cookies-color                                     : rgba($ts-footer-font-color, 0.6);
$ts-footer-cookies-anchor-color                              : rgba($ts-footer-font-color, 0.6);
$ts-footer-cookies-magento-anchor-color                      : rgba($ts-footer-font-color, 0.6);
$ts-footer-cookies-anchor-text-decoration                    : none;
$ts-footer-cookies-anchor-text-decoration-hover              : underline;

// 01.3 Footer Subscribe settings
$ts-footer-input-place                                       : block;
$ts-footer-desc-font-size                                    : rem-calc(16);
$ts-footer-desc-line-height                                  : 1.8;
$ts-footer-desc-bottom-indent                                : 22px;
$ts-footer-subscribe-input-color                             : $black;
$ts-footer-subscribe-input-bg                                : #F0FFFE;
$ts-footer-subscribe-input-placeholder-color                 : #828282;
$ts-footer-subscribe-input-border-color                      : $white;
$ts-footer-subscribe-input-height                            : ( small : rem-calc(50), xlarge : rem-calc(50));
$ts-footer-subscribe-input-font-size                         : rem-calc(14);
$ts-footer-button-view                                       : text;
$ts-footer-button-icon                                       : "icon-arrow-left-white";
$ts-footer-button-icon-width                                 : 12px;
$ts-footer-button-icon-height                                : 20px;
$ts-footer-button-icon-indent                                : 18px;
$ts-footer-button-color                                      : $white;
$ts-footer-button-color-hover                                : $white;
$ts-footer-button-border                                     : 1px solid #09B8AE;


// 01.5 Footer Social settings
$ts-footer-social-icon-networks                              : (facebook, instagram, youtube, tiktok);
$ts-footer-social-icon-size                                  : rem-calc(44);
$ts-footer-social-right-indent                               : rem-calc(13);
$ts-footer-social-icon-shape                                 : none;
$ts-footer-social-icon-border-width                          : 0;
$ts-footer-social-icon-opacity                               : 1;
$ts-footer-social-icon-bg-size                               : 53px;

// 01.7 Footer Copyright settings
$ts-footer-copyright-font-size                               : rem-calc(12);
$ts-footer-copyright-color                                   : rgba($ts-footer-font-color, 0.6);

// 01.8 USP section
$ts-usp-font-color                                           : $white;
$ts-usp-font-size                                            : 14px;
$ts-usp-font-family                                          : $header-font-family;
$ts-usp-font-weight                                          : 500;
$ts-usp-font-line-height                                     : 24px;
$ts-usp-item-bottom-spacing                                  : 22px;
$ts-usp-item-icon-width                                      : 13px;
$ts-usp-item-icon-height                                     : 13px;
$ts-usp-item-icon-indent                                     : 6px;


// 02. Header settings
$ts-headbar-height                                           : ( small : rem-calc(71),
                                                                 large : rem-calc(204),
                                                                 xlarge : rem-calc(204),
                                                                 xxlarge : rem-calc(165),
                                                                 xxxlarge : rem-calc(165) );

$ts-headbar-top-border                                       : false;

$ts-headbar-text-color                                       : $primary-color;
$ts-headbar-logo-width                                       : 273px;
$ts-headbar-logo-width-top                                   : 0;
$ts-headbar-bg-color                                         : $primary-color;

// 02.0 Sticky Header
$ts-sticky-headbar-height                                    : auto;
$ts-sticky-headbar-height-spacing-top                        : 0;
$ts-sticky-headbar-height-spacing-bottom                     : 0;
$ts-sticky-headbar-height-spacing                            : 0;

$ts-sticky-headbar-tablet-search-spacing                     : rem-calc(0);

$ts-sticky-scroll-headbar-box-shadow                         : none;

// 02.1 Top links
$ts-headbar-anchor-size                                      : rem-calc(14);
$ts-headbar-anchor-color                                     : $white;
$ts-headbar-anchor-font-weight                               : normal;
$ts-top-links-anchor-font-size                               : rem-calc(14);
$ts-top-links-anchor-font-weight                             : 400;

// 02.2 Header Search
$ts-header-search-mobile-parent                              : 'mobile-search-wrapper';
$ts-header-search-mobile-sticky                              : true;
$ts-header-search-mobile-sticky-top                          : 6px;
$ts-header-search-mobile-sticky-left                         : map-get($grid-column-gutter, small) / 2;
$ts-header-search-mobile-sticky-right                        : 140px;

$ts-header-search-form-padding-top                           : ( large : 0,
                                                                 xlarge : 0,
                                                                 xxlarge : 0,
                                                                 xxxlarge : 0 );
$ts-header-search-form-medium-margin-top                     : ( large : 0,
                                                                 xlarge : 0,
                                                                 xxlarge : 0,
                                                                 xxxlarge : 0 );

$ts-header-search-input-height                               : rem-calc(50);
$ts-header-search-font-size                                  : rem-calc(16);
$ts-header-search-font-weight                                : normal;
$ts-header-search-font-family                                : $body-font-family;
$ts-header-search-input-text-transform                       : none;
$ts-header-search-input-indent                               : rem-calc(60);
$ts-header-search-input-left-indent                          : rem-calc(23);
$ts-header-search-input-background-color                     : $white;
$ts-header-search-input-border-bottom-color                  : none;
$ts-header-search-input-focus-border                         : none;
$ts-header-search-input-focus-one-line-border                : false;
$ts-header-search-input-focus-border-color                   : none;
$ts-header-search-input-focus-border-bottom-color            : none;
$ts-header-search-input-color                                : #555555;
$ts-header-search-input-icon-width                           : rem-calc(26);

// 02.3 Header Cart
// 02.3.1 Header Cart Button
$ts-header-cart-dropdown-showcart-type                       : image-only;
$ts-header-cart-dropdown-showcart-icon-active                : 'icon-cart';
$ts-header-cart-dropdown-showcart-icon-hover                 : 'icon-cart';
$ts-header-cart-dropdown-showcart-icon-spacing               : 0;
$ts-header-cart-dropdown-showcart-icon-width                 : 35px;
$ts-header-cart-dropdown-showcart-icon-height                : 32px;

$ts-header-cart-button-count-size                            : rem-calc(20);
$ts-header-cart-button-count-font-size                       : rem-calc(12);
$ts-header-cart-button-count-shape                           : rounded;
$ts-header-cart-button-count-font-weight                     : 600;
$ts-header-cart-button-count-box-shadow                      : none;
$ts-header-cart-button-count-bg-color                        : $primary-color;
$ts-header-cart-dropdown-showcart-icon-image-sticky-bottom   : auto;

// 02.4 Top Bar Navigation
// 02.4.1 Top Bar Nav Home link
$ts-primary-nav-height                                       : rem-calc(54);
$ts-headbar-main-menu-font-size                              : ( medium : rem-calc(15), xlarge : rem-calc(15), xxlarge : rem-calc(16) );
$ts-headbar-main-menu-anchor-color                           : $white;
$ts-headbar-main-menu-font-weight                            : 500;
$ts-headbar-main-menu-text-transform                         : none;

// 02.4.2 Dropdown
$ts-header-menu-dropdown-padding                             : 0;
$ts-header-menu-dropdown-box-shadow                          : none;
$ts-header-menu-dropdown-link-font-size                      : 16px;
$ts-header-menu-dropdown-link-font-weight                    : normal;
$ts-header-menu-dropdown-link-padding                        : 8px 45px 8px 36px;
$ts-header-menu-dropdown-link-color                          : #555555;
$ts-header-menu-dropdown-link-color-hover                    : $primary-color;
$ts-header-menu-dropdown-link-image                          : false;
$ts-primary-nav-dd                                           : false;

// 02.4.3 Navbar Extra
$ts-menu-extra-border-color                                  : rgba($white, .2);
$ts-menu-extra-font-weight                                   : 400;
$ts-menu-extra-color                                         : $white;

// 02.5 Breadcrumbs
$ts-shipping-bar-top-indent                                  : 14px;
$ts-shipping-bar-bottom-indent                               : ( small : 25px, large : 40px, xxlarge : 76px);
$ts-shipping-bar-bottom-indent-filter                        : ( small : 16px, large : 40px, xxlarge : 76px);


// 03. Offcanvas
$ts-foundation-offcanvas                                     : true;
$ts-offcanvas-spacing-top                                    : 20px;
$ts-tabbar-menu-bg-color                                     : $ts-headbar-bg-color;

// 03.1.1 Offcanvas Main Title
$ts-offcanvas-title-font-size                                : rem-calc(26);
$ts-offcanvas-title-text-color                               : $primary-color;
$ts-offcanvas-title-bottom-indent                            : rem-calc(20);

// 03.1.2 Offcanvas Main Categories Menu
$ts-offcanvas-main-menu-item-font-family                     : 'cerebri-sans';
$ts-offcanvas-main-menu-item-link-color                      : $primary-color;
$ts-offcanvas-main-menu-item-link-active-bg                  : transparent;
$ts-offcanvas-main-menu-item-link-active-color               : $ts-offcanvas-main-menu-item-link-color;
$ts-offcanvas-main-menu-item-link-spacing                    : rem-calc(16) 0;
$ts-offcanvas-main-menu-item-link-arr-width                  : rem-calc(12);
$ts-offcanvas-main-menu-item-link-arr-height                 : rem-calc(17);
$ts-offcanvas-main-menu-item-link-arr-position               : 0;
$ts-offcanvas-main-menu-item-link-arr-icon                   : 'icon-arrow-right-green';

$ts-offcanvas-close-font-size                                : 0;
$ts-offcanvas-close-indent-left                              : 0;
$ts-offcanvas-close-indent-bottom                            : 0;

$ts-offcanvas-main-submenu-item-bold-font-family             : $ts-offcanvas-main-menu-item-font-family;
$ts-offcanvas-main-submenu-item-bold-font-weight             : $ts-offcanvas-main-submenu-item-font-weight;
$ts-offcanvas-main-submenu-item-bold-font-size               : $ts-offcanvas-main-submenu-item-font-size;
$ts-offcanvas-main-submenu-item-bold-color                   : $ts-offcanvas-main-submenu-item-link-color;

$ts-offcanvas-secondary-menu-border                          : 0;
$ts-offcanvas-secondary-menu-item-font-size                  : rem-calc(14);
$ts-offcanvas-secondary-menu-item-font-weight                : normal;
$ts-offcanvas-secondary-menu-item-link-color                 : #555555;

// 03.2 Mobile search
// 03.2.1 Mobile search bar
$ts-mobile-search-bar-box-shadow                             : none;
$ts-mobile-search-bar-bg-color                               : $primary-color;

// 03.2.2 Mobile search bar field
$ts-mobile-search-field-bg                                   : $white;
$ts-mobile-search-field-color                                : $ts-header-search-input-color ;
$ts-mobile-search-field-placeholder-color                    : $ts-header-search-input-color ;
$ts-mobile-search-field-font-size                            : rem-calc(14);
$ts-mobile-search-field-border-width                         : 0;

// 03.2.3 Mobile search button
$ts-mobile-search-button-image-size                          : 21px;

// 03.3 Mobile toggle button
$ts-mobile-toggle-button-width                               : rem-calc(24);


// 04. Magento Modal
// 04.1 Magento Modal Basic
$ts-modal-popup-padding                                      : 50px;
$ts-modal-overlay-bg                                         : rgba($black, .2) radial-gradient(50% 50% at 50% 50%, rgba($black,0) 0%, rgba($black,.2) 100%);


// 05. Category Page
// 05.0 Category Title
$ts-category-main-title-align                                : left;

$ts-category-content-top-indent                              : 0;

$ts-sidebar-main-top-indent                                  : 24px;
$ts-sidebar-main-padding                                     : 0 0 52px;
$ts-sidebar-main-filter-content-indent                       : 0;
$ts-sidebar-main-filter-border-width                         : 0;
$ts-sidebar-main-filter-font-size                            : 15px;

$ts-sidebar-main-filter-anchor-color                         : $body-font-color;
$ts-sidebar-main-filter-anchor-color-hover                   : $black;
$ts-sidebar-main-filter-anchor-padding                       : 2px 0;
$ts-sidebar-main-filter-count-hide                           : true;
$ts-sidebar-main-filter-font-size                            : rem-calc(15);

// 05.1 Category Sidebar
$ts-sidebar-main-filter-title-font-size                      : 24px;
$ts-sidebar-main-filter-title-font-weight                    : 500;
$ts-sidebar-main-filter-title-font-family                    : $header-font-family;
$ts-sidebar-main-filter-title-indent                         : 13px;
$ts-sidebar-main-filter-title-font-color                     : $primary-color;

$ts-sidebar-main-filter-anchor-underline-hover               : none;
$ts-sidebar-main-filter-count-hide                           : true;

$ts-sidebar-main-padding                                     : 0 0 50px;
// $ts-sidebar-main-top-indent                                  : 0 !default;
// $ts-sidebar-main-bottom-indent                               : 1rem !default;
// $ts-sidebar-main-background-color                            : transparent !default;


// 05.1.1 Sidebar search
$ts-sidebar-search-font-family                                : $body-font-family;
$ts-sidebar-search-input-text-transform                       : none;
$ts-sidebar-search-input-icon                                 : 'icon-search-black';

// 05.2 Category Mobile Filter
$ts-sidebar-mobile-category-font-size                        : rem-calc(20);
$ts-sidebar-mobile-category-font-weight                      : normal;
$ts-sidebar-mobile-category-padding                          : rem-calc(18px 40px 18px 32px);
$ts-sidebar-mobile-category-font-family                      : $header-font-family;
$ts-sidebar-mobile-category-image-position                   : calc(100% - 32px) 50%;
$ts-sidebar-mobile-category-image-size                       : 8px auto;
$ts-sidebar-mobile-category-active-state                     : normal;
$ts-mobile-sorting-left-indent                               : 32px;
$ts-mobile-filter-active-font-weight                         : normal;

// 05.3 Category Sorting Toolbar
$ts-category-sorting-toolbar-position                        : 'left-title' !default;
$ts-category-sorting-toolbar-font-size                       : rem-calc(15);
$ts-category-sorting-toolbar-color                           : rgba($black, .5);
$ts-category-sorting-toolbar-font-weight                     : normal;
$ts-category-sorting-toolbar-link-underline                  : none;
$ts-category-sorting-toolbar-label-width                     : false;
$ts-category-sorting-toolbar-select-width                    : 100%;
$ts-category-sorting-toolbar-height                          : rem-calc(45);

// 05.4 Category Swatches
// IMAGE SWATCH
$ts-color-swatch-height                                      : 24px;
$ts-color-swatch-height-selected                             : 24px;
$ts-color-swatch-width                                       : 24px;
$ts-color-swatch-radius                                      : 50%;
$ts-color-swatch-border-shadows-basic-color                  : rgba($black, .1);
$ts-color-swatch-border                                      : true;
$ts-color-swatch-border-color                                : rgba($black, .3);
$ts-color-swatch-box-shadow                                  : none;
$ts-color-swatch-outer-indent                                : rem-calc(0 4 4 0);

// IMAGE+LABEL SWATCH
$ts-color-label-swatch-height                                : 14px;
$ts-color-label-swatch-width                                 : 14px;

// TEXT SWATCH
$ts-size-product-swatch-border-hover                         : inset 0 0 0 2px rgba(39, 197, 174, 1);
$ts-size-product-swatch-font-size                            : rem-calc(15);
$ts-size-product-swatch-color-active                         : $body-font-size;
$ts-size-product-swatch-bg-active                            : transparent;
$ts-size-product-swatch-margin                               : 6px;
$ts-size-product-swatch-full-border                          : inset 0 0 0 2px rgba(0, 0, 0, 0.05);
$ts-size-product-swatch-has-full-border                      : true;
$ts-size-swatch-height                                       : 30px;
$ts-size-swatch-height-selected                              : 30px;
$ts-size-swatch-width                                        : 30px;
$ts-size-swatch-box-shadow                                   : 0 0 0 1px rgba($black, .3);

// 05.6 Category Price slider
$ts-sidebar-main-price-slider-point-width                    : 17px;
$ts-sidebar-main-price-slider-point-height                   : 17px;
$ts-sidebar-main-price-slider-point-bg                       : $alt-color;
$ts-sidebar-main-price-slider-line-color                     : #e5e5e5;
$ts-sidebar-main-price-slider-font-size                      : 15px;
$ts-sidebar-main-price-slider-font-weight                    : normal;
$ts-sidebar-main-price-slider-line-width                     : 2px;

// 05.7 Pagination
$ts-pagination-item-font-size                                : 20px;
$ts-pagination-item-font-weight                              : 600;
$ts-pagination-item-font-family                              : $header-font-family;
$ts-pagination-item-border-radius                            : 50%;
$ts-pagination-item-border                                   : none;
$ts-pagination-item-bg                                       : $alt-color;
$ts-pagination-item-color                                    : $white;
$ts-pagination-item-color-active                             : $body-font-color;
$ts-pagination-item-bg-active                                : $white;
$ts-pagination-item-box-shadow-active                        : 3px 3px 6px $alt-color, 0 0 0 3px $alt-color;

$ts-pagination-outside-indent                                : ( small   : (2px 0 11px),
                                                                xlarge  : (84px 0 25px),
                                                                xxlarge : (84px 0 25px) );
$ts-pagination-item-outside-indent                           : ( small   : (0 3px 10px),
                                                                 xlarge  : (0 10px 10px),
                                                                 xxlarge : (0 10px 10px) );
$ts-pagination-inner-indent                                  : ( small   : (80px 0 70px),
                                                                 xlarge  : (0 185px),
                                                                 xxlarge : (0 220px) );
$ts-pagination-item-width                                    : ( small   : 45px,
                                                                 xlarge  : 55px,
                                                                 xxlarge : 55px );
$ts-pagination-item-height                                   : ( small   : 45px,
                                                                 xlarge  : 55px,
                                                                 xxlarge : 55px );
$ts-pagination-item-line-height                              : ( small   : 45px,
                                                                 xlarge  : 55px,
                                                                 xxlarge : 55px );
$ts-pagination-item-next-prev-button-font-size               : ( small   : 14px,
                                                                 xlarge  : 14px,
                                                                 xxlarge : 14px);
$ts-pagination-item-next-prev-button-min-width               : ( small   : 130px,
                                                                 xlarge  : 186px,
                                                                 xxlarge : 186px);
$ts-pagination-item-next-prev-button-padding-top             : ( small   : 18px,
                                                                 xlarge  : 18px,
                                                                 xxlarge : 18px );
$ts-pagination-item-next-prev-button-padding-bottom          : ( small   : 18px,
                                                                 xlarge  : 18px,
                                                                 xxlarge : 18px );
$ts-pagination-item-next-prev-button-min-width-iphone4       : 127px;

$ts-pagination-item-next-prev-button-color                   : $white;
$ts-pagination-item-next-prev-button-letter-spacing          : normal;
$ts-pagination-item-next-prev-button-bg-color                : map-get($button-palette, primary);
$ts-pagination-item-next-prev-button-bg-color-hover          : darken(map-get($button-palette, primary),10);
$ts-pagination-item-next-prev-button-bg-color-active         : darken(map-get($button-palette, primary),10);

$ts-pagination-item-next-prev-arrow-width                    : 8px;
$ts-pagination-item-next-prev-arrow-height                   : 16px;

$ts-pagination-item-next-prev-button-arrow                   :'icon-arrow-left-cyan';
$ts-pagination-item-next-prev-button-arrow-prev              :'icon-arrow-right-cyan';
$ts-pagination-item-next-prev-button-arrow-hover             :'icon-arrow-left-cyan';
$ts-pagination-item-next-prev-button-arrow-prev-hover        :'icon-arrow-right-cyan';
$ts-pagination-item-next-prev-button-arrow-size              : 100% auto;

$ts-pagination-item-next-prev-opacity-disabled               : .3;
$ts-pagination-item-next-prev-button-bg-color-disabled       : #333;

// 06. Product List Item
$ts-product-item-qty-line                                    : 3;
$ts-product-item-mobile-two-inline                           : false;
$ts-product-item-divider                                     : false;
$ts-product-item-collapse-grid                               : true;
$ts-product-item-collapse-grid-product-spacing-coeff         : .2;

$ts-product-item-inner-indent                                : ( small : 0 0 7px,
                                                                 large : 0 0 7px,
                                                                 xlarge : 0 0 7px,
                                                                 xxlarge : 0 0 7px);

$ts-product-item-product-name-color                          : $primary-color;
$ts-product-item-hide-btn                                    : false;

$ts-product-item-info-align                                  : left;
$ts-product-item-nameprice-align                             : block;
$ts-product-item-absolute-image-ratio                        : 100%;
$ts-product-item-photo-bottom-indent                         : 19px;
$ts-product-item-hide-btn                                    : false;
$ts-product-item-stock-over                                  : true;


$ts-product-item-product-name-font-size                      : ( small : rem-calc(15), large : rem-calc(17), xlarge : rem-calc(17), xxlarge : rem-calc(17));
$ts-product-item-product-name-font-family                    : $header-font-family;
$ts-product-item-product-name-font-weight                    : ( small : 500, large : 500, xlarge : 500);
$ts-product-item-product-name-line-height                    : ( small : 1.33, large : 1.22, xlarge : 1.22, xxlarge : 1.22);
$ts-product-item-product-name-text-transform                 : none;
$ts-product-item-product-name-margin                         : (0 0 10px);

$ts-product-item-product-price-font-weight                   : 600;
$ts-product-item-product-price-font-size                     : ( small : rem-calc(20), xlarge : rem-calc(20), xxlarge : rem-calc(20));
$ts-product-item-product-price-font-color                    : $primary-color;

$ts-product-item-product-price-special-color                 : $primary-color;

$ts-product-item-product-price-old-color                     : $body-font-color;
$ts-product-item-product-price-old-font-family               : $body-font-family;
$ts-product-item-product-price-old-font-weight               : normal;
$ts-product-item-product-price-old-font-size                 : rem-calc(15);

$ts-product-brand-font-size                                  : rem-calc(15);
$ts-product-brand-font-weight                                : normal;
$ts-product-brand-font-family                                : $body-font-family;
$ts-product-brand-line-height                                : 1.2;
$ts-product-brand-text-transform                             : none;
$ts-product-brand-color                                      : $body-font-color;
$ts-product-brand-padding                                     : (0 0 8px);

// 07. Product Detail
//07.1 Fotorama
$ts-fotorama-mobile-dots-size                                : 10px;
$ts-fotorama-mobile-dots-spacing                             : 0 5px;
$ts-fotorama-mobile-dots-bg                                  : rgba($black, .25);
$ts-fotorama-mobile-dots-bg-active                           : lighten($black, 29.0196);

// 07.2 Product Detail Top Panel
$ts-product-detail-title-font-size                           : ( small : rem-calc(24), large : rem-calc(30), xxlarge : rem-calc(40));
$ts-product-detail-title-font-weight                         : 500;
$ts-product-detail-title-text-transform                      : normal;
$ts-product-detail-title-text-align                          : left;
$ts-product-detail-title-bottom-indent                       : 30px;
$ts-product-detail-title-bottom-inner-indent-coeff           : 0;

$ts-product-detail-show-brand-title                          : true;
$ts-product-size-guide-control-appearance                    : false;

$ts-product-detail-brand-title-font-size                     : rem-calc(16);
$ts-product-detail-brand-title-font-color                    : #555555;
$ts-product-detail-brand-title-uppercase                     : none;
$ts-product-detail-brand-title-indent                        : 10px;
$ts-product-detail-brand-title-has-border                    : false;

$ts-product-detail-price-font-size                           : rem-calc(28);
$ts-product-detail-price-color                               : $black;
$ts-product-detail-price-font-weight                         : 600;
$ts-product-detail-price-font-weight-mobile                  : 600;
$ts-product-detail-price-font-family                         : $header-font-family;

$ts-product-detail-show-sku                                  : true;

// 07.3 Product Detail Info Right Sidebar
$ts-product-info-sidebar-view                                : false;
$ts-product-info-sidebar-indent                              : (small: 0, large: 0, xlarge: 0, xxlarge: 0);
$ts-product-info-sidebar-bottom-indent                       : (small: 12px, large:  0, xlarge:  0, xxlarge: 0);

// 07.5 Product Label
$ts-product-label-small-from                                 : large;
$ts-product-label-position-top                               : 14px;
$ts-product-label-position-right                             : 14px;
$ts-product-label-position-top-small                         : -6px;
$ts-product-label-position-right-small                       : -6px;
$ts-product-label-position-top-detail                        : 16px;
$ts-product-label-position-right-detail                      : 38px;
$ts-product-label-view                                       : square;
$ts-product-label-radius                                     : 50%;
$ts-product-label-font-size                                  : ( small : rem-calc(14), xlarge-new : rem-calc(20));
$ts-product-label-color                                      : $white;
$ts-product-label-size                                       : ( small : rem-calc(38), xlarge : rem-calc(69), xlarge-new : rem-calc(69));
$ts-product-label-size-height                                : ( small : rem-calc(38), xlarge : rem-calc(69), xlarge-new : rem-calc(69));
$ts-product-label-background-color                           : $alt-color;
$ts-product-label-font-weight                                : 600;

$ts-product-label-new-text-color                             : $white;
$ts-product-label-new-text-transform                         : none;
$ts-product-label-new-background-color                       : #252525;
$ts-product-label-new-number-font-size                       : ( small : rem-calc(14), xxlarge : rem-calc(20));

// 07.6 Product Detail AddtoCart Button
$ts-product-addtocart-btn-icon-view                          : true;

// 07.7 Product Detail Info Text
$ts-product-detail-info-text-toggled                         : true;
$ts-product-detail-info-text-styled                          : false;


// 08. Customer Section
$ts-customer-sidebar-box-shadow                              : none;
$ts-customer-sidebar-active-bg                               : $body-font-color;

// 08.1 Login Page
$ts-login-main-title-font-weight                             : normal;

// 11. Checkout Cart
$ts-checkout-sidebar-bg                                      : transparent;
$ts-checkout-sidebar-shadow                                  : none;

$ts-cart-table-border-color                                  : lighten($black, 85);
$ts-cart-table-cell-indent                                   : 18px;
$ts-cart-table-cell-line-height                              : 1.2;
$ts-cart-table-product-price-font-size                       : rem-calc(18);
$ts-cart-table-product-price-font-weight                     : 700;
$ts-cart-table-product-price-color                           : $black;

$ts-delete-icon-width                                        : 20px;
$ts-delete-icon-height                                       : 20px;

$ts-cart-summary-table-grandtotal-font-weight                : 500;
$ts-cart-summary-table-grandtotal-text-transform             : none;

$ts-cart-table-border-color                                  : lighten($black, 85);

$ts-cart-table-product-title-font-size                       : ( small    : rem-calc(15),
                                                                 large   : rem-calc(15));
$ts-cart-table-product-title-font-weight                     : 500;
$ts-cart-table-product-title-text-transform                  : none;

$ts-checkout-shipping-list-border                            : 0;
$ts-checkout-shipping-list-spacing-out                       : 0;
$ts-checkout-shipping-list-spacing-in                        : 0;
$ts-checkout-shipping-list-title-font-size                   : rem-calc(20);
$ts-checkout-shipping-list-price-font-size                   : rem-calc(20);

// 11.1 Checkout
$ts-cart-table-border-color                                  : rgba($black, 0.1);

$ts-checkout-headbar-height                                  : ( small : rem-calc(71), normal : auto, tall : rem-calc(165) );


// 12 Buttons
$ts-button-box-shadow                                        : 2px 3px 10px rgba(0, 0, 0, 0.1);
