@if $ts-foundation-offcanvas {
	.off-canvas {
		overflow-x: hidden;
		padding-left: $ts-offcanvas-spacing-side;
		padding-right: $ts-offcanvas-spacing-side;
		padding-top: $ts-offcanvas-spacing-top;
		padding-bottom: $ts-offcanvas-spacing-top;
		z-index: $ts-offcanvas-zindex;
		-webkit-overflow-scrolling: touch;

		@if $ts-headbar-top-border {
			@include top-header-line($ts-headbar-top-border-height-mobile);
		}

		.navigation,
		.links {
			ul {
				@include lsn;

				li {
					display: block;
					a {
						display: block;
					}
				}
			}

		}// .navigation, .links

		.header.links {
			margin: 0 (-$ts-offcanvas-spacing-side);
			padding: $ts-offcanvas-secondary-menu-inner-spacing;
			border-bottom: $ts-offcanvas-secondary-menu-border;
			font-size: $ts-offcanvas-secondary-menu-item-font-size;
			font-family: $ts-offcanvas-secondary-menu-item-font-family;
			font-weight: $ts-offcanvas-secondary-menu-item-font-weight;

			li {
				> a,
				> span {
					display: block;
					color: $ts-offcanvas-secondary-menu-item-link-color;
					padding: $ts-offcanvas-secondary-menu-item-link-spacing;
					text-decoration: $ts-offcanvas-secondary-menu-item-link-underline;
					text-transform: $ts-offcanvas-secondary-menu-item-text-transform;

					&:hover {
						color: $ts-offcanvas-secondary-menu-item-link-active-color;
					}
				}

				strong {
					font-weight: normal;
				}

				&.current {
					color: $ts-offcanvas-secondary-menu-item-link-active-color;
				}
			}
		}// .header.links

		.navigation {
			> ul {
				&.menu {
					@include header-font-family($ts-offcanvas-main-menu-item-font-family);
					font-weight: $ts-offcanvas-main-menu-item-font-weight;
					font-size: $ts-offcanvas-main-menu-item-font-size;
					margin: 0 (-$ts-offcanvas-spacing-side);

					@if $ts-offcanvas-main-menu-item-border-last {
						margin-bottom: 1.5rem;
					}

					&.nav-extra {
						margin-bottom: $ts-offcanvas-outer-spacing;
					}

					.active {
						> a {
							color: $ts-offcanvas-main-menu-item-link-active-color;
							font-weight: $ts-offcanvas-main-menu-item-link-active-font-weight;
							background-color: $ts-offcanvas-main-menu-item-link-active-bg;
						}
					}

					.has-active {
						> a {
							color: $ts-offcanvas-main-menu-item-link-active-color;
							font-weight: $ts-offcanvas-main-menu-item-link-active-font-weight;
						}
					}

					> li {
						border-top: $ts-offcanvas-main-menu-item-border;

						@if $ts-offcanvas-main-menu-item-border-last {
							&:last-child {
								border-bottom: $ts-offcanvas-main-menu-item-border;
							}
						}

						@if ($ts-offcanvas-main-menu-item-border-first != true) {
							&.nav-2 {
								border-top: 0;
							}
						}


						> a {
							color: $ts-offcanvas-main-menu-item-link-color;
							text-decoration: $ts-offcanvas-main-menu-item-link-underline;
							padding: $ts-offcanvas-main-menu-item-link-spacing;
							position: relative;
							text-transform: $ts-offcanvas-main-menu-item-text-transform;

							&:after {
								display: block;
								content:'';
								position: absolute;
								top: 50%;
								right: $ts-offcanvas-main-menu-item-link-arr-position;
								width: $ts-offcanvas-main-menu-item-link-arr-width;
								height: $ts-offcanvas-main-menu-item-link-arr-height;
								transform: translateY(-50%);
								@if ( $ts-offcanvas-main-menu-item-link-arr-type != none ) {
									border:0;
									margin: 0;
									background: url("#{$ts-path-to-images}/#{$ts-offcanvas-main-menu-item-link-arr-icon}.#{$ts-offcanvas-main-menu-item-link-arr-type}")  no-repeat;
									background-size: 100% auto;
								}
							}

							&:hover,
							&:active {
								color: $ts-offcanvas-main-menu-item-link-active-color;
							}
						}// > a

						&.is-accordion-submenu-parent[aria-expanded="true"] {
							> a {
								&:after {
									transform: translateY(-50%) rotate(90deg);
								}
							}
						}
					}// > li

					ul {
						font-family: $ts-offcanvas-main-submenu-item-font-family;
						font-weight: $ts-offcanvas-main-submenu-item-font-weight;
						font-size: $ts-offcanvas-main-submenu-item-font-size;
						margin: $ts-offcanvas-main-submenu-spacing;

						li {
							a {
								color: $ts-offcanvas-main-submenu-item-link-color;
								text-decoration: $ts-offcanvas-main-submenu-item-link-underline;
								padding: $ts-offcanvas-main-submenu-item-link-spacing;
								text-decoration: $ts-offcanvas-main-submenu-item-link-underline;
								text-transform: $ts-offcanvas-main-submenu-item-text-transform;

								@if ( ($ts-offcanvas-main-menu-item-link-arr-type != none) and ($ts-offcanvas-main-submenu-item-arr-show) ) {
									background: url("#{$ts-path-to-images}/#{$ts-offcanvas-main-menu-item-link-arr-icon}.#{$ts-offcanvas-main-menu-item-link-arr-type}") $ts-offcanvas-main-submenu-item-link-arr-position no-repeat;
									background-size: $ts-offcanvas-main-submenu-item-link-arr-size;
								}

								&:hover,
								&:active {
									color: $ts-offcanvas-main-submenu-item-link-active-color;
								}
							}// a

							&.is-featured,
							&.category-all {
								@include header-font-family($ts-offcanvas-main-submenu-item-bold-font-family);
								font-weight: $ts-offcanvas-main-submenu-item-bold-font-weight;
								font-size: $ts-offcanvas-main-submenu-item-bold-font-size;

								a {
									color: $ts-offcanvas-main-submenu-item-bold-color;
									text-transform: $ts-offcanvas-main-submenu-item-bold-uppercase;
								}
							}
						}// li
					}// ul submenu

				}// .menu
			}//


			li.welcome {
				@include at;
			}

			.button-find-store {
				// text-align: left;
				margin-bottom: 1.5rem;

				.icn-map {
					margin-right: .75rem;
				}
			}// .button-find-store


			.dropdown.menu {

				.parent {

					.menu {
						display: none;
					}
				}
			}//.dropdown.menu
		}// .navigation

		.offcanvas-title {
			font-size: $ts-offcanvas-title-font-size;
			@include header-font-family($ts-offcanvas-title-font-family);
			font-weight: $ts-offcanvas-title-font-weight;
			font-style: $ts-offcanvas-title-font-style;
			margin-bottom: $ts-offcanvas-title-bottom-indent;
			text-transform: $ts-offcanvas-title-text-transform;
			color: $ts-offcanvas-title-text-color;
		}// .offcanvas-title

		.offcanvas-links {
			margin: 0 (-$ts-offcanvas-spacing-side);
			padding: $ts-offcanvas-secondary-menu-inner-spacing;
			border-bottom: $ts-offcanvas-secondary-menu-border;

			h3,
			.title {
				font-size: $ts-offcanvas-secondary-menu-title-font-size;
				@include header-font-family($ts-offcanvas-secondary-menu-title-font-family);
				font-weight: $ts-offcanvas-secondary-menu-title-font-weight;
				color: $ts-offcanvas-secondary-menu-title-link-color;
				margin-bottom: $ts-offcanvas-secondary-menu-title-indent;
				text-transform: $ts-offcanvas-secondary-menu-title-text-transform;

				@if $ts-offcanvas-secondary-menu-title-hide {
					display: none;
				}
			}// h3, .title

			ul:not(.footer-contacts) {
				font-size: $ts-offcanvas-secondary-menu-item-font-size;
				font-family: $ts-offcanvas-secondary-menu-item-font-family;
				font-weight: $ts-offcanvas-secondary-menu-item-font-weight;

				li {
					a {
						color: $ts-offcanvas-secondary-menu-item-link-color;
						padding: $ts-offcanvas-secondary-menu-item-link-spacing;
						text-decoration: $ts-offcanvas-secondary-menu-item-link-underline;
						text-transform: $ts-offcanvas-secondary-menu-item-text-transform;

						&:hover {
							color: $ts-offcanvas-secondary-menu-item-link-active-color;
						}
					}

					strong {
						font-weight: normal;
					}

					&.current {
						color: $ts-offcanvas-secondary-menu-item-link-active-color;
					}
				}
			}// ul:not(.footer-contacts)

			&:last-child {
				border-bottom: 0;
			}
		}// .offcanvas-links

		.menu-item-home {
			display: none !important;

			a {
				margin-right: 5rem;
				&:before {
					background-image: url("#{$ts-path-to-images}/#{$ts-primary-nav-home-canvas-url-icon}.#{$ts-primary-nav-home-canvas-url-icon-type}");
				}
			}
		}// .menu-item-home

		.close-button {
			@extend .no-outline;
			z-index: 1;
		}// .close-button

		.close-menu {
			position: relative;
			color: $ts-offcanvas-close-color;
			font-size: ($ts-offcanvas-close-font-size * .5);
			padding: 0 0 0 $ts-offcanvas-close-indent-left;
			margin-bottom: $ts-offcanvas-close-indent-bottom;
			@extend .no-outline;

			i {
				font-style: normal;
			}

			.close-button {
				font-style: normal;
				top: 50%;
				color: $closebutton-color;
				transform: translateY(-50%);
				left: 0;
				right: auto;
				text-transform: $ts-offcanvas-close-text-transform;
				font-size: $ts-offcanvas-close-font-size;
			}// .close-button

			.txt {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: 2px;
			}

			&:hover,
			&:active {
				color: $primary-color;
			}
		}// .close-menu

	}

	.js-off-canvas-overlay {
		&.is-visible {
			z-index: ($ts-offcanvas-zindex - 1);
		}
	}

}
