@import '../../mixins', '../../includes', '../../variables';

//	Animations
.mm-page {
    box-sizing: border-box;
    position: relative;

    > .mm-offcanvas-links {
        display: none !important;
    }
}

.mm-slideout {
    transition: transform $mm_transitionDuration $mm_transitionFunction;
    @include breakpoint(medium down) {
        z-index: 1;
    }
}

//	Wrapper
.mm-wrapper {
    &_opened {
        overflow-x: hidden;
        position: relative;

        .mm-page {
            min-height: 100vh;
        }
    }
    &_background {
        .mm-page {
            background: inherit;
        }
    }
}

//	Menu
.mm-menu_offcanvas {
    position: fixed;
    right: auto;
    z-index: 2;
}

#my-menu {
    display: none;
    &.mm-menu_opened {
        display: flex;
    }
}

@include mm_offcanvas_size;

@if ($mm_include_offcanvas_blocker) {
    .mm-wrapper__blocker {
        background: rgba(3, 2, 1, 0);
        overflow: hidden;
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .mm-wrapper_blocking {
        overflow: hidden;

        body {
            overflow: hidden;
        }

        .mm-wrapper__blocker {
            display: block;
        }
    }
}

.mm-offcanvas-close {
    width: $mm_closeWidth;
    height: $mm_closeHeight;
    background-image: url("#{$mmPathToImages}/#{$mm_closeIcon}.svg");
    background-size: $mm_closeIconWidth auto;
    background-repeat: no-repeat;
    background-position: $mm_closeIconPosition;
    padding: $mm_closePadding;
    position: absolute;
    top: $mm_closeTop;
    right: $mm_closeRight;
    z-index: 2;

    span {
        font-size: $mm_close_textSize;
        font-weight: $mm_close_textWeight;
        font-family: $mm_close_textFamily;
        line-height: $mm_close_textLine;
    }

    @if $mm_closeTextHide {
        span {
            display: none;
        }
    }
}

.mm-offcanvas-links {
    display: block !important;
    position: static !important;
    transform: translate3d(0%, 0, 0);
    margin-top: $mm_panelBottomIndent;

    .mm-navbar {
        display: none;
    }

    .offcanvas-links {
        padding: $mm_LinksMenu_inner-spacing;
        border-bottom: $mm_LinksMenu_border;

        h3,
        .title {
            font-size: $mm_LinksMenu_titleFontSize;
            font-family: $mm_LinksMenu_titleFontFamily;
            font-weight: $mm_LinksMenu_titleFontWeight;
            color: $mm_LinksMenu_titleColor;
            margin-bottom: $mm_LinksMenu_titleMarginBottom;
            text-transform: $mm_LinksMenu_titleTransform;

            @if $mm_LinksMenu_titleHide {
                display: none;
            }
        }// title

        ul {
            font-size: $mm_LinksMenu_itemFontSize;
            font-family: $mm_LinksMenu_itemFontFamily;
            font-weight: $mm_LinksMenu_itemFontWeight;
            @include lsn;

            li {
                padding: $mm_LinksMenu_itemPadding;
                a {
                    color: $mm_LinksMenu_itemColor;
                    text-decoration: $mm_LinksMenu_itemUnderline;
                    text-transform: $mm_LinksMenu_itemTransform;

                    &:hover {
                        color: $mm_LinksMenu_itemActiveColor;
                    }
                }

                strong {
                    font-weight: inherit;
                }

                &.current {
                    color: $mm_LinksMenu_itemActiveColor;
                }
            }
        }// ul
    }// links

    .top-links {
        display: flex;
        flex-direction: column-reverse;
    }
}


// .menu inheritance bugfix
.mm-menu {
    .menu {
        > li {
            display: block;

            > a {
                padding-left: $mm_listitemIndent;
                padding-right: $mm_padding;
                line-height: inherit;
            }
        }
        .active > a {
            background: $mm_highlightedBackgroundColor;
        }
    }
}
.mm-listitem:after {
    top: auto;
}

@import 'offcanvas.rtl';
