.header-row.checkout-index-index {
    .algolia-instant-selector-results {
        display: none !important;
    }
}

.b-page-checkout {

    @include breakpoint(medium down) {
        .column.main,
        .page-container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.is-sticky {
        .logo {
            img {
                margin-top: 0;
            }// img
        }
    }

    .header {
        .header-row {
            .logo {
                text-align: center;
                img {
                    max-width: $ts-headbar-logo-width;
                }// img
            }
        }// .header-row

        .tab-bar {
            .nav-toggle {
                display: none;
            }
            .middle-side {
                @include grid-col-size(4);
            }
            .left-side,
            .right-side {
                @include grid-col-size(4);
                line-height: map-get($ts-checkout-headbar-height, small);
            }
        }// .tab-bar

        a {
            color: $ts-checkout-headbar-link-color;
        }

        .button {
            color: $button-color;
            margin-bottom: 0;
        }

        .contact-header-checkout {
            color: $ts-checkout-headbar-contact-tel-color;
        }
    }// .header

    .page-header {
        height: map-get($ts-checkout-headbar-height, small);
        border-bottom: $ts-checkout-header-border-bottom;
        // overflow: hidden;

        @include breakpoint(large) {
            height: auto;
            margin-bottom: 1rem;
        }

        .header {
            .header-row {
                &.is-stuck {
                    .logo {
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: none;
                    }
                }
            }
        }
    }// .page-header

    .sticky-header-row {
        @include grid-row;
        @include get-gutter(.5,'padding-left');
        @include get-gutter(.5,'padding-right');
    }//.sticky-header-row

    .page-title-wrapper {
    	@if not ( $ts-checkout-title__show ) {
        	display: none;
        }
    }// .page-title-wrapper
}


.header-controls {
    @include grid-row;
    @include grid-row-nest;
    max-width: initial;
    width: auto;
    height: map-get($ts-checkout-headbar-height, small);

    &.center-header-top-panel {
        .is-stuck & {
            @include get-gutter(.5,'padding-right');
            @include get-gutter(.5,'padding-left');
        }
    }

    @include breakpoint(large) {
        height: map-get($ts-checkout-headbar-height, normal);
    }
    @include breakpoint(xxlarge) {
        height: map-get($ts-checkout-headbar-height, tall);
    }

    .header-row.is-stuck & {
        height: ($ts-sticky-headbar-height - $ts-sticky-headbar-height-spacing*2);
    }

    .header-totals,
    .header-logo,
    .header-info {
        @include grid-col;
        height: map-get($ts-checkout-headbar-height, small);
        line-height: map-get($ts-checkout-headbar-height, small);
        @include breakpoint(large) {
            height: map-get($ts-checkout-headbar-height, normal);
            line-height: map-get($ts-checkout-headbar-height, normal);
        }
        @include breakpoint(xxlarge) {
            height: map-get($ts-checkout-headbar-height, tall);
            line-height: map-get($ts-checkout-headbar-height, tall);
        }

        .header-row.is-stuck & {
            height: ($ts-sticky-headbar-height - $ts-sticky-headbar-height-spacing*2);
            line-height: ($ts-sticky-headbar-height - $ts-sticky-headbar-height-spacing*2);

            .logo {
                padding-left: 0;
                width: auto;
                display: block;
            }

            @include breakpoint(large) {
                .items-in-cart,
                .contact-header-checkout {
                    margin-top: .25rem;
                }

                .contact-header-worktime {
                    margin-top: -.25rem;
                    font-size: rem-calc(11);

                    @include breakpoint(xxlarge) {
                        margin-top: -.15rem;
                    }
                }
            }
        }
    }// .header-totals, .header-logo, .header-info

    .header-totals {
        @include grid-col-size(3.5);

        @include breakpoint(large) {
            @include grid-col-size(4.5);
        }
    }// .header-totals

    .header-logo {
        @include grid-col-size(5);
        @include get-gutter(0,'padding-right');
        @include get-gutter(0,'padding-left');
        text-align: center;

        @include breakpoint(large) {
            @include grid-col-size(3);
            @include get-gutter(.5,'padding-right');
            @include get-gutter(.5,'padding-left');
        }

        .logo {
            width: auto;
            margin-left: auto;
            margin-right: auto;
            float: none;
            text-align: center;
            @include get-gutter(0,'padding');

            &:before {
                display: none;
            }
        }
    }// .header-logo

    .header-info {
        @include grid-col-size(3.5);
        text-align: right;

        @include breakpoint(large) {
            @include grid-col-size(4.5);
        }
    }// .header-info
}// .header-controls

.header-total-controls {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    .total-line {
        display: none;
        color: $ts-checkout-headbar-total-color;
        font-size: ($ts-checkout-headbar-total-font-size*.64);
        font-weight: $ts-checkout-headbar-total-font-weight;
        @include header-font-family($ts-checkout-headbar-total-font-family);

        .title {
            text-transform: $ts-checkout-headbar-total-title-transform;
        }

        @include breakpoint(large) {
            display: block;
            font-size: ($ts-checkout-headbar-total-font-size*.75);

            &.total-line-excl {
                font-size: ($ts-checkout-headbar-total-font-size*.55);
            }
        }

        @include breakpoint(xxlarge) {
            font-size: $ts-checkout-headbar-total-font-size;

            .header-row.is-stuck & {
                font-size: ($ts-checkout-order-total-font-size*.75);
            }

            &.total-line-excl {
                font-size: ($ts-checkout-headbar-total-font-size*.8);
            }
        }
    }// .total-line
}// .header-total-controls

.checkout-header-info {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: $ts-checkout-headbar-info-color;
    font-size: ($ts-checkout-headbar-info-font-size*.85);

    .title,
    .contact-header-worktime {
        display: none;
    }

    @include breakpoint(large) {
        font-size: $ts-checkout-headbar-info-font-size;
        .title,
        .contact-header-worktime {
            display: block;
        }
    }
}// .checkout-header-info

.mobile-total-price {
    text-align: center;
    margin-bottom: 1rem;

    .mark {
        @include label-colon;
    }
    @include breakpoint(large) {
        display: none;
    }
}// .mobile-total-price

.contact-header-checkout {
    display: inline-block;
    width: 99%;
    font-size: 0;
    color: $ts-checkout-headbar-contact-tel-color;
    font-weight: $ts-checkout-headbar-contact-font-weight;
    text-transform: $ts-checkout-headbar-contact-title-transform;
    text-decoration: none;
    @include header-font-family($ts-checkout-headbar-contact-font-family);

    @include breakpoint(large) {
        white-space: nowrap;
        font-size: ($ts-checkout-headbar-contact-font-size*.75);
        margin-top: .75rem;
    }
    @include breakpoint(xxlarge) {
        font-size: $ts-checkout-headbar-contact-font-size;

        .header-row.is-stuck & {
            font-size: ($ts-checkout-headbar-contact-font-size*.75);
        }
    }

    &:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: $ts-checkout-headbar-contact-tel-icon-width;
        height: $ts-checkout-headbar-contact-tel-icon-width;
        background: url("#{$ts-path-to-images}/#{$ts-checkout-headbar-contact-tel-icon}.#{$ts-checkout-headbar-contact-tel-icon-type}") $ts-checkout-headbar-contact-tel-icon-position no-repeat;
        background-size: 100% auto;

        @include breakpoint(xxlarge) {
            margin-right: rem-calc(5);

            .header-row.is-stuck & {
                width: ($ts-checkout-headbar-contact-tel-icon-width*.6);
                height: ($ts-checkout-headbar-contact-tel-icon-height*.6);
            }
        }
    }
}// .contact-header-checkout

.items-in-cart {
    display: inline-block;
    position: relative;

    @include breakpoint(large) {
        margin-top: .75rem;

        &:hover,
        &:focus {
            .content.minicart-items {
                display: block;
            }
        }
    }

    .title {
        cursor: pointer;

        a {
            &:before {
                display: inline-block;
                vertical-align: middle;
                content: '';
                width: $ts-checkout-headbar-goback-button-icon-width;
                height: $ts-checkout-headbar-goback-button-icon-height;
                background-image: url("#{$ts-path-to-images}/#{$ts-checkout-headbar-goback-button-icon}.#{$ts-checkout-headbar-goback-button-icon-type}");
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: rem-calc(7);
                transform: scale(2);

                @if $ts-checkout-headbar-goback-button-icon-rotate {
                    transform: scale(2) rotate(180deg);
                }

                @include breakpoint(large) {
                    transform: scale(1);

                    @if $ts-checkout-headbar-goback-button-icon-rotate {
                        transform: scale(1) rotate(180deg);
                    }
                }
            }

            span {
                // display: none;

                @include breakpoint(large) {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: underline;
                }
            }
            &:hover {
                span {
                    text-decoration: none;
                }

                &:before {
                    text-decoration: none;
                }
            }
       }

    }
}// .items-in-cart


.checkout-container {
    position: relative;
    h1 {
        text-align: $ts-checkout-h1-text-align;
        font-weight: $ts-checkout-h1-font-weight;

        @include breakpoint(large) {
            margin-bottom: 2.75rem;
        }
    }
}// .checkout-container

.checkout-form-row {
    @include grid-row;
    @include grid-row-nest;
    max-width: initial;
    width: auto;

    .field {
        @include grid-col;
        margin-bottom: 0;

        .control {
            margin-bottom: .85rem;
        }

        @include breakpoint(xlarge) {
            &.field-city {
                @include grid-col-size(9);
            }
            &.field-postcode {
                @include grid-col-size(3);
                padding-right: 0;
            }
            &.field-telephone,
            &.field-customer-password {
                @include grid-col-size(6);
            }
        }
    }// .field

    &.shipping-postcode-city {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .85rem;

        .field {
            margin-bottom: 0;
        }

        .message,
        .mage-error {
            clear: none;
            margin-bottom: .5rem;
            @include get-gutter(.5,'margin-right');
            @include get-gutter(.5,'margin-left');

            @include breakpoint(xlarge) {
                order: 3;
            }
        }
    }// &.shipping-postcode-city

    &.shipping-company-number-name {
        .field {
            margin-bottom: 0;
            @include breakpoint(xlarge) {
                @include grid-col-size(6);
            }
        }
    }// &.shipping-company-number-name
} // .checkout-form-row

.checkout-steps {
    @include lsn;
    position: relative;
    z-index: 1;

    @include breakpoint(large) {
        @include grid-row;
        @include grid-row-nest;
        max-width: initial;
        width: auto;
    }

    .checkout-shipping-address,
    .checkout-shipping-method,
    .checkout-payment-method {
        position: relative;

        @include breakpoint(large) {
            @include grid-col;
            @include grid-col-size(6);
        }
    }

    .loading-mask {
        background-color: rgba($white, .45);
    }

    .checkout-box {
        padding: map-get($ts-checkout-box-spacing, small);
        margin-bottom: 1.25rem;
        position: relative;
        background: $ts-checkout-box-bg-color;
        box-shadow: $ts-checkout-box-shadow;

        @include breakpoint(medium down) {
            padding-left: (map-get($ts-checkout-box-spacing, small) + rem-calc(map-get($ts-global-space-indent, small)));
            padding-right: (map-get($ts-checkout-box-spacing, small) + rem-calc(map-get($ts-global-space-indent, small)));
        }
        @include breakpoint(xlarge) {
            padding: map-get($ts-checkout-box-spacing, xlarge);
        }

        .label {
            > span {
                @include label-colon;
            }
        }

        legend.label {
            @extend label.label;
        }
    }// .checkout-box

    .field-tooltip-content {
        @include at;
    }

    .step-title {
        text-transform: $ts-checkout-box-title-title-transform;
        font-size: ($ts-checkout-box-title-font-size*.75);
        position: relative;
        color: $ts-checkout-box-title-title-color;
        font-weight: $ts-checkout-box-title-font-weight;
        @include header-font-family($ts-checkout-box-title-font-family);

        @include breakpoint(xxlarge) {
            font-size: $ts-checkout-box-title-font-size;
            margin-bottom: 1rem;
        }
    }// .step-title

    .form-order-comment {
        .input-text {
            margin-top: .5rem;
            margin-bottom: 0;
            min-height: rem-calc(77);
        }
    }

    .form-order-comment,
    .new-address-form {
        > .field {
            > .label {
                display: inline-block;
                line-height: 1;
                text-decoration: $ts-checkout-comment-line-title-decoration;
                color: $ts-checkout-comment-line-title-color;
                cursor: pointer;
                @extend .no-outline;

                &[aria-expanded="true"] {
                    text-decoration: none;
                }

                > span {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .billing-address-same-as-shipping-block {
            margin-top: 1rem;
        }

        legend.label {
            @extend label.label;
        }

        .field-select-billing {
            .label {
                margin-top: 1rem;
                line-height: 1.2;
            }
        }
    }// .form-order-comment

    .field {
        margin-bottom: .85rem;

        &.addresses {
            margin-bottom: $ts-checkout-shipping-list-spacing-out;
            h3 {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        &.field-country_id {
        	@if not ( $ts-checkout-address-country__show ) {
            	display: none !important;
            }
        }
    }

    fieldset.field {
        margin-bottom: 0;
    }

    .has-customer {
        padding-bottom: 2rem;
        margin-bottom: 1.5rem;
        margin-top: -.65rem;
        border-bottom: $ts-checkout-shipping-list-border;
    }// .has-customer

    .checkout-shipping-list {
        .item {
            padding-bottom: $ts-checkout-shipping-list-spacing-in;
            margin-bottom: $ts-checkout-shipping-list-spacing-out;
            border-bottom: $ts-checkout-shipping-list-border;

            label {
                display: block;
                padding-left: 1.75rem;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        .col-row {
            @include grid-row;
            @include grid-row-nest;
            max-width: initial;
            width: auto;
            position: relative;
            top: -2px;

            .col {
                @include grid-col;
            }
            .col-title {
                @include grid-col-size(8);

                .title {
                    font-size: $ts-checkout-shipping-list-title-font-size;
                    font-weight: $ts-checkout-shipping-list-title-font-weight;
                    text-transform: $ts-checkout-shipping-list-title-title-transform;
                    line-height: 1;
                    @include header-font-family($ts-checkout-shipping-list-title-font-family);
                }
            }
            .col-price {
                @include grid-col-size(4);
                text-align: right;
                line-height: 1;
                font-size: $ts-checkout-shipping-list-price-font-size;
                text-transform: $ts-checkout-shipping-list-title-title-transform;
                font-weight: $ts-checkout-shipping-list-title-font-weight;
                @include header-font-family($ts-checkout-shipping-list-title-font-family);
            }

            .desc {
                color: rgba($body-font-color, .8);
            }
        }
    }// .checkout-shipping-list

    .checkout-payment-method {

        .step-title {
            @include grid-row;
            @include grid-row-nest;

            .txt {
                @include grid-col;
                white-space: nowrap;

                @include breakpoint(xlarge) {
                    @include grid-col-size(4);
                }
            }

            .order-total {
                @include grid-col;

                @include breakpoint(xlarge) {
                    @include grid-col-size(8);
                }
            }
        }

        .payment-group {
            .step-title {
                display: none;
            }
        }

        .b-place-order {
            button.action {
                @include button-expand;
                font-size: ($ts-checkout-main-button-font-size*.75);
                font-weight: $ts-checkout-main-button-font-weight;
                position: relative;
                padding-right: $ts-checkout-main-button-hz-indent;
                padding-left: $ts-checkout-main-button-hz-indent;
                padding-top: $ts-checkout-main-button-top-indent;
                padding-bottom: $ts-checkout-main-button-bottom-indent;
                text-transform: $ts-checkout-main-button-text-transform;

                @include breakpoint(xxlarge) {
                    font-size: $ts-checkout-main-button-font-size;
                }

				@if ( $ts-checkout-main-button-has-arrow ) {
	                &:after {
	                    display: block;
	                    content: '';
	                    width: $ts-checkout-main-button-arrow-width;
	                    height: $ts-checkout-main-button-arrow-height;
	                    position: absolute;
	                    top: 50%;
	                    right: 1rem;
	                    transform: translateY(-50%);
	                    background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg");
	                    background-repeat: no-repeat;
	                    background-size: 100% auto;
	                }
                }
            }
        }

        .actions-toolbar {
            display: none;
        }

        .order-total {
            font-size: ($ts-checkout-order-total-font-size*.75);
            font-weight: $ts-checkout-order-total-font-weight;
            line-height: 1;
            text-transform: $ts-checkout-order-total-title-transform;
            @include header-font-family($ts-checkout-order-total-font-family);
            margin-top: .75rem;

            @include breakpoint(xlarge) {
                text-align: right;
                margin-top: 0;
            }

            @include breakpoint(xxlarge) {
                font-size: $ts-checkout-order-total-font-size;
            }

            .order-total-incl-tax {
                .mark {
                    &:after {
                        content: ':';
                    }
                }
            }

            .note {
                color: rgba($body-font-color, .8);
                font-size: 11px;
                line-height: 1.4;
                letter-spacing: normal;
            }

            .order-total-excl-tax {
                font-weight: 500;
                font-size: 18px;
                line-height: 1.6;
                + .order-total-tax {
                    color: rgba($body-font-color, .8);
                    font-size: 11px;
                    line-height: 1.4;
                    + .order-total-incl-tax {
                        font-size: 22px;
                        line-height: 1.4;
                    }
                }
            }
        }

        .message {
            @extend .message.error;
            margin-top: 1rem;
            padding: .75rem .75rem .75rem 2rem;
            font-size: .75rem;
            box-shadow: none;
            background-position: .75rem .85rem;
        }
    }// .checkout-payment-method

    .payment-methods-list {
        .checkout-agreements-block {
            display: none;
        }

        .payment-group {
            border-top: $ts-checkout-shipping-list-border;
            padding-top: $ts-checkout-shipping-list-spacing-out;

            .payment-method {
                padding-bottom: ($ts-checkout-shipping-list-spacing-out*.75);
                margin-bottom: $ts-checkout-shipping-list-spacing-out;
                border-bottom: $ts-checkout-shipping-list-border;

                > label {
                    display: block;
                    padding-left: 1.75rem;

                    > span {
                        display: block;
                    }
                    .desc {
                        color: rgba($body-font-color, .8);
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                }

                .payment-method-content {
                    display: none;
                    padding-left: 1.4rem;
                    color: rgba($body-font-color, .8);
                }

                &._active {
                    .payment-method-content {
                        display: block;
                    }
                }
            }
        }


        .payment-method-title {
            @include radio;
            margin-bottom: 0;
            [class*="-logo"] {

                display: none !important;
            }

            input[type="radio"] + .label {
                min-height: 1.25rem;
            }

            span {
                font-size: $ts-checkout-payment-method-title-font-size;
                font-weight: $ts-checkout-payment-method-title-font-weight;
                @include header-font-family($ts-checkout-payment-method-title-font-family);
                position: relative;
                top: -2px;
                text-transform: $ts-checkout-payment-method-title-transform;
            }
        }

        .payment-method-content {
            [class*="-img"] {
                display: none !important;
            }
        }
    }// .payment-methods-list

    .checkout-agreements-block {
        padding-top: $ts-checkout-agreement-title-spacing;
        padding-bottom: $ts-checkout-agreement-title-spacing;
        text-transform: $ts-checkout-agreement-title-transform;
        border-top: $ts-checkout-agreement-title-border;

        .checkout-agreement {
            @include checkbox;
            margin-bottom: 0;

            a {
                color: $anchor-color;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }// .checkout-agreements-block

}// .checkout-steps

.checkout-step-form-login {
    .actions-toolbar {
        text-align: center;
        margin-top: 1rem;
        clear: both;
        @include get-gutter(.5,'padding-right');
        @include get-gutter(.5,'padding-left');

        > .primary {
            .action {
                @include button-expand;
                margin-bottom: .75rem;
            }
        }

        .remind {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
        }

        @include breakpoint(large) {
            text-align: left;
            @include grid-row;
            @include grid-row-nest;

            > .primary,
            > .secondary {
                @include grid-col;
                @include grid-col-size(6);
            }

            > .primary {

                .action {
                    margin-bottom: 0;
                }
            }
            > .secondary {
                line-height: $input-select-height;
            }
        }
    }// .actions-toolbar

    .control {
        position: relative;
    }

    .hidden-fields {
        @include grid-row;
        @include grid-row-nest;

        .field {
            @include grid-col;

            @include breakpoint(large) {
                @include grid-col-size(6);
            }
        }
    }

    .note {
        display: block;
        position: relative;
        text-align: left;
        padding: 1rem;
        line-height: 1.45;
        margin-top: 1rem;
        box-shadow: (0 0 15px rgba($black,0.25));
        background-color: $white;
        z-index: 1;

        @include breakpoint(large) {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 0;
            @include grid-col-size(6);
        }

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 1rem 1rem 1rem;
            border-color: transparent transparent $white transparent;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);

            @include breakpoint(large) {
                right: 2.5rem;
                left: auto;
                transform: translateX(0);
            }
        }
    }
}// .checkout-step-form-login

.authentication-wrapper {
    text-align: center;
    margin-top: -.75rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;

    @include breakpoint(medium) {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        position: absolute;
        left: 0;

        @if map-has-key($header-styles, medium) {
        top: rem-calc(map-deep-get($header-styles, medium, h1, font-size) * 1px) - $body-font-size;
    	}
    }

    @include breakpoint(xxlarge) {
    	@if map-has-key($header-styles, xlarge) {
        top: rem-calc(map-deep-get($header-styles, xlarge, h1, font-size) * 1px) - $body-font-size;
    	}
    }

    .authentication-dropdown {
        display: none;
        @extend .no-outline;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 2.5rem;
        margin-top: .75rem;
        box-shadow: (0 0 25px rgba($black,0.15));
        background-color: $white;
        z-index: 1;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 1rem 1rem 1rem;
            border-color: transparent transparent $white transparent;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &._show {
            display: block;
        }

        @include breakpoint(medium) {
            width: rem-calc(430);

            &:after {
                left: 1rem;
                transform: translateX(0);
            }
        }
    }// .authentication-dropdown

    .block-title {
        font-size: 18px;
        font-weight: 500;
        @include header-font-family($header-font-family);
        margin-bottom: 1rem;

        strong {
            font-weight: inherit;
        }
    }

    .actions-toolbar {
        text-align: center;
        margin-top: 1rem;

        > .primary {
            .action {
                @include button-expand;
                margin-bottom: .75rem;
            }
        }

        @include breakpoint(large) {
            text-align: left;
            @include grid-row;
            @include grid-row-nest;

            > .primary,
            > .secondary {
                @include grid-col;
                @include grid-col-size(6);
            }

            > .primary {
                float: right;
                text-align: right;

                .action {
                    display: inline-block;
                    width: auto;
                    margin-bottom: 0;
                }
            }
            > .secondary {
                line-height: $input-select-height;
            }
        }
    }// .actions-toolbar

    .action-close {
        font-size: 1.5rem;
    }
    .action-auth-toggle {
        display: inline-block;
        line-height: 1;
        vertical-align: top;
    }
}// .authentication-wrapper


.shipping-address-items {
    .shipping-address-item {
        padding-top: $ts-checkout-shipping-list-spacing-in;
        margin-top: $ts-checkout-shipping-list-spacing-out;
        border-top: $ts-checkout-shipping-list-border;

        &:first-child {
            margin-top: ($ts-checkout-shipping-list-spacing-out/2);
        }

        label {
            display: block;
            padding-left: 1.75rem;
        }

        .radio {
            margin-bottom: 0;
        }

        .col-row {
            @include grid-row;
            @include grid-row-nest;
            max-width: initial;
            width: auto;
            position: relative;
            top: -2px;

            .col {
                @include grid-col;
            }
            .shipping-address {
                margin-bottom: 0;
                line-height: 1.4;

                @include breakpoint(xlarge) {
                    @include grid-col-size(6);
                }

                strong {
                    @include header-font-family($header-font-family);
                    font-weight: 700;
                    font-size: ($body-font-size*1.2);
                    line-height: 1;
                }
            }
        }

        &.selected-item {
            label:after,
            .label:after {
                display: block;
            }
        }
    }// .shipping-address-item
}// .shipping-address-items

.shipping-address {
    .action {
        @extend label.label;
        display: inline-block;
        text-decoration: $ts-checkout-comment-line-title-decoration;
        color: $ts-checkout-comment-line-title-color;
        cursor: pointer;
        line-height: 1.6;
        margin-bottom: .85rem;
        @extend .no-outline;
    }
}// .shipping-address

.modal-add-adress {
    .street {
        legend.label {
            @extend label.label;
        }
    }
    label.label {
        span {
            @include label-colon;
        }
    }
}// .modal-add-adress

.account-types-list {
    @include grid-row;
    @include grid-row-nest;
    margin-bottom: 1rem;

    .field-column {
        @include grid-col;

        @include breakpoint(xlarge) {
            @include grid-col-size(6);
        }
    }
    .field-account-type.control {
        @include radio;
        position: relative;

        .field-account-type-info {
            position: static;
        }

        .label {
            display: inline-block;
            > span:after {
                display: none;
            }
        }

        .icon-q {
            position: absolute;
            left: 100%;
            top: 0;
        }
    }
}// .account-types-list

.opc-payment-additional {
    margin: 1rem 0 0;
    padding: 1rem 0 0;
    border-top: $ts-checkout-agreement-title-border;

    .payment-option-title {
        line-height: 1;
        text-decoration: $ts-checkout-comment-line-title-decoration;
        color: $ts-checkout-comment-line-title-color;
        cursor: pointer;
        @extend .no-outline;

        &[aria-expanded="true"] {
            text-decoration: none;
        }

        > span {
            &:after {
                display: none;
            }
        }
    }// .payment-option-title
}// .opc-payment-additional

.service-information-block {
    margin-top: 1rem;
    text-align: center;
}

.custom-file-input-wrapper {
    position: relative;
    margin: 0;

    @include breakpoint(xxlarge) {
        max-width: 75%;
    }

    .form-control {
        margin-bottom: 0;
        background: $input-background !important;
    }

    .input-group-button {
        padding-left: .5rem;

        .button {
            position: relative;
            overflow: hidden;
            height: $input-select-height;

            input {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                opacity: 0;
                height: auto;
                padding: inherit;
                font-size: initial;
                display: initial;
                width: auto;
                cursor: pointer;
            }

            &.primary {
                margin-left: .25rem;
            }
        }
    }
}// .custom-file-input-wrapper

// Collector module
.payment-method {
    .control {
        &.multi-control {
            .html-field {
                line-height: $input-select-height;
            }
        }

        &.single-checkbox-field {
            .option {
                @include checkbox;
            }
        }

        &.radio-field {
            .option {
                @include radio;

                input[type="radio"] + label {
                    font-weight: $global-title-weight;

                    b {
                        font-weight: $global-weight-bold;
                    }
                }
            }
        }
    }
}// .payment-method

.checkout-footer {
    overflow: hidden;
    padding: .75rem 0 1rem;
    position: relative;
    text-align: center;

    p {
        margin-bottom: 0;
    }

    &:before {
        content: '';
        display: block;
        visibility: visible;
        border-top: $ts-main-border-width $ts-main-border-style rgba($black, .15);
        position: absolute;
        top: 0;
        left: -50%;
        right: -50%;
    }
}// .checkout-footer




