//
// Customer/account
//  ---------------------------------------------
.account {
	.sidebar-main {
		margin-top: 0;
	}
	&.page-layout-2columns-left {
		.main.column {
			@include get-gutter(.5,'padding-left');
			@include get-gutter(.5,'padding-right');
		}
	}
	.main {
		.block-title,
		.page-subtitle {
			font-size: ($ts-subtitle-font-size * .75);
			@include header-font-family($ts-subtitle-font-family);
			font-weight: $ts-subtitle-font-weight;
			line-height: $ts-subtitle-line-height;
			margin-bottom: 1rem;

			strong {
				font-weight: inherit;
			}

			.action {
				font-weight: $ts-subtitle-action-font-weight;
				font-family: $ts-subtitle-action-font-family;
				font-size: $ts-subtitle-action-font-size;
				margin-left: 1rem;
			}

			@include breakpoint(large) {
				font-size: $ts-subtitle-font-size;
				margin-bottom: 2.25rem;
			}
		}
		.legend {
			@extend .box-title;

			+ br {
				display: none;
			}
		}
	}

	.page-title {
		padding-bottom: .75rem;
		@if $ts-common-titles-border-bottom {
			@include border-line('bottom', 0, $ts-main-dark-border-color);
		}

		@include breakpoint(xxlarge) {
			margin-bottom: 3.75rem;
		}
	}// .page-title

	.page-subtitle-wrapper {
		margin-bottom: 1rem;

		@include breakpoint(large) {
			margin-bottom: 2.25rem;
		}

		.page-subtitle,
		.view-all-orders,
		.order-status {
			display: inline-block;
			vertical-align: baseline;
		}
		.page-subtitle {
			margin-bottom: 0;
		}
		.view-all-orders {
			margin-left: .5rem;
		}

		.order-status {
			vertical-align: super;
			margin-left: 1.25rem;

			.label {
				@include at;
			}
			.value {
				display: inline-block;
				line-height: 1;
				border: 1px solid $ts-main-dark-border-color;
				padding: .3rem 1rem;
				background: $white;
			}
		}
	}// .page-subtitle-wrapper

	.box-title {
		position: relative;
		display: block;
		font-size: ($ts-boxtitle-font-size*.75);
		font-weight: $ts-boxtitle-font-weight;
		padding-bottom: .25rem;
		margin-bottom: 1rem;
		overflow: hidden;
		@include header-font-family($ts-boxtitle-font-family);
		@include border-line('bottom', 0, $ts-main-dark-border-color);

		&:before {
			@include get-gutter(0,'left');
			@include get-gutter(0,'right');
		}

		@include breakpoint(large) {
			font-size: $ts-boxtitle-font-size;
			line-height: 1;
		}

		@include breakpoint(xlarge) {
			line-height: 1.4;
		}


		.text {
			margin-right: .5rem;
			@include breakpoint(xlarge) {
				float: left;
				width: 60%;
				margin-right: 0;
			}
		}

		.action-box {
			white-space: nowrap;

			@include breakpoint(xlarge) {
				float: left;
				width: 40%;
				text-align: right;
			}
		}

		.action {
			font-weight: $ts-subtitle-action-font-weight;
			font-family: $ts-subtitle-action-font-family;
			font-size: $ts-subtitle-action-font-size;
		}
	}

	.field {
		margin-bottom: 1.15rem;
	}

	caption {
		display: none;
	}

	label.label {
		span {
			@include label-colon;
		}
	}

	.actions-toolbar {
		margin-top: 1rem;
		.action {
			@include breakpoint(medium down) {
				@include button-expand;
			}

			&.primary {
				min-width: rem-calc(200);
			}
			&.back {
				display: none;
			}

		}
	}// .actions-toolbar


	address {
		font-style: normal;
		line-height: 1.92;
	}

	.field-over {
		@include grid-row;
		@include grid-row-nest;
	}

	.field-row {
		.field-over {
			@include get-gutter(0,'margin-left');
			@include get-gutter(0,'margin-right');
		}
	}

}// .account

.field {
	&.choice {
		@include checkbox;

		label.label {
			span {
				&:after {
					display: none;
				}
			}
		}
		&.persistent {
			.remember-me-link {
				margin-left: 4px;
				font-size: .85em;
				color: $anchor-color;
			}
		}
	}
}// .field.choice

.box-actions,
.product-item-actions .actions-secondary,
.col.actions,
.order-actions-toolbar,
.addresses .item.actions,
.order-details-items .actions-toolbar,
.order-details-items .order-title {
	.action {
		display: block;
		position: relative;
		margin: .25rem 0;

		@include breakpoint(medium) {
			display: inline;
			margin: 0;

			&:after {
				display: inline-block;
				content: '|';
				color:$medium-gray;
				margin: 0 .5rem;
			}

			&:hover {
				color:$body-font-color;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}

	}
}// .box-actions


//
// Customer/account/index
//  ---------------------------------------------
div.account-nav,
.block-collapsible-nav {
	@include get-gutter($ts-customer-sidebar-hz-indent-coeff,'padding-top');
	@include get-gutter($ts-customer-sidebar-hz-indent-coeff,'padding-bottom');
	background-color: $ts-customer-sidebar-background-color;
	box-shadow: $ts-customer-sidebar-box-shadow;
	margin-bottom: 2rem;

	@if $ts-customer-sidebar-vertical-top {
		margin-top: -$ts-customer-sidebar-item-spacing-top;
	}

	.title {
		@include at;
	}
	.nav {
		@include lsn;
		font-size: $ts-customer-sidebar-font-size;
		font-weight: $ts-customer-sidebar-font-weight;
		line-height: $ts-customer-sidebar-line-height;

		.item {
			position: relative;

			@if $ts-customer-sidebar-item-border {
				@include border-line(top, 0, $ts-customer-sidebar-item-border-color);

				&:first-child {
					&:before {
						display: none;
					}
				}
			}

			&:before {
				left: $ts-customer-sidebar-item-spacing-side;
				right: $ts-customer-sidebar-item-spacing-side;
			}

			a {
				display: block;
				padding: $ts-customer-sidebar-item-spacing-top $ts-customer-sidebar-item-spacing-side;
				color:$ts-customer-sidebar-item-color;
				text-decoration: $ts-customer-sidebar-item-underline;
			}
			&:hover,
			&:focus {
				a {
					color:$ts-customer-sidebar-item-active-color;
					background: $ts-customer-sidebar-active-bg;
					text-decoration: $ts-customer-sidebar-active-underline;
				}
				@if $ts-customer-sidebar-item-border {
					&:before {
						opacity: 0;
					}

					+ .item {
						&:before {
							opacity: 0;
						}
					}
				}

				@if ($ts-customer-sidebar-active-bg == transparent or $ts-customer-sidebar-active-bg == none) {
					&:before {
						opacity: 1;
					}

					+ .item {
						&:before {
							opacity: 1;
						}
					}
				}
			}

			&.current {
				@if $ts-customer-sidebar-item-border {
					&:before {
						opacity: 0;
					}

					+ .item {
						&:before {
							opacity: 0;
						}
					}
				}
				@if ($ts-customer-sidebar-active-bg == transparent or $ts-customer-sidebar-active-bg == none) {
					&:before {
						opacity: 1;
					}

					+ .item {
						&:before {
							opacity: 1;
						}
					}
				}
				strong {
					color:$ts-customer-sidebar-item-active-color;
					font-weight: $ts-customer-sidebar-font-weight-active;
					display: block;
					padding: $ts-customer-sidebar-item-spacing-top $ts-customer-sidebar-item-spacing-side;
					background: $ts-customer-sidebar-active-bg;
					text-decoration: $ts-customer-sidebar-active-underline;
				}

				a {
					color:$ts-customer-sidebar-item-active-color;
					font-weight: $ts-customer-sidebar-font-weight-active;
					background: $ts-customer-sidebar-active-bg;
					text-decoration: $ts-customer-sidebar-active-underline;

					strong {
						padding: 0;
						background: none;
					}
				}
			}
		}
	}
}// .block.account-nav

.block-dashboard-info,
.block-dashboard-addresses {

	.block-title {
		.account & {
			margin-bottom: 1rem;
		}
	}

	.block-content {
		@include grid-row;
		@include grid-row-nest;

		.box {
			@include grid-col;
			margin-bottom: 2rem;

			@include breakpoint(medium) {
				@include grid-col-size(6);
			}
		}
	}

}// .block-dashboard-info, .block-dashboard-addresses



//
// Customer/account/edit/changepass
// Customer/address/edit
// Customer/address/new
//  ---------------------------------------------
.form-edit-account,
.form-address-edit {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.fieldset {
		@include grid-col;
		margin-bottom: 2rem;

		@include breakpoint(large) {
			@include grid-col-size(6);
			margin-top: -.25rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.field-column {
			@include grid-col;
			@include breakpoint(xlarge) {
				@include grid-col-size(10);
			}
		}
	}

	.field-name-firstname,
	.field-name-lastname {
		@include grid-col;
		@include breakpoint(xlarge) {
			@include grid-col-size(5);
			@include grid-column-end;
		}
	}

	.field.zip {
		@include grid-col;
		@include breakpoint(xlarge) {
			@include grid-col-size(4);
		}
	}
	.field.city {
		@include grid-col;
		@include breakpoint(xlarge) {
			@include grid-col-size(8);
		}
	}

	.field.choice {
		@include breakpoint(xlarge) {
			@include grid-col;
			@include get-gutter(.25,'padding-left');
			clear: both;
			margin-bottom: .75rem;
		}
	}

	.field-name-firstname,
	.field-name-lastname,
	.field.city,
	.field.zip {
		input {
			margin-bottom: 0;
		}

		.mage-error {
			margin-top: ($form-spacing / 3);
		}
	}

	.actions-toolbar {
		@include grid-col;
	}

	.message.info {
		margin-bottom: 1.25rem;
	}

}// .form-edit-account, .form-address-edit

.form-newsletter-manage {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.fieldset {
		margin-bottom: 2rem;
	}
	.fieldset,
	.actions-toolbar {
		@include grid-col;
		@include grid-col-end;
		clear: left;

		@include breakpoint(large) {
			@include grid-col-size(6);
		}
	}
}// .form-newsletter-manage

.form-wishlist-items {
	.actions-toolbar {
		.action.update {
			display: none;
		}
		.action {
			margin-bottom: .75rem;
			margin-right: 1.5rem;

			@include breakpoint(medium down) {
				margin-right: 0;
			}
		}
	}
	.block-title {
		display: none;
	}
	table.cart {
		.product-label,
		.convert-label-container {
			display: none !important;
		}
	}
}// .form-wishlist-items

.form-giftcard-redeem {
	.actions-toolbar {
		.action.check {
			display: none;
		}
	}
}// .form-wishlist-items


//
// Storecredit/info
//  ---------------------------------------------
.storecredit {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.block {
		@include grid-col;
		margin-bottom: 2rem;

		@include breakpoint(medium) {
			@include grid-col-size(6);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}// .storecredit


//
// Giftcard/customer
//  ---------------------------------------------
.form-giftcard-redeem {

	.fieldset {
		@include grid-col;

		@include breakpoint(medium) {
			@include grid-col-size(6);
		}
	}

	.actions-toolbar {
		@include grid-col;

		.primary,
		.secondary {
			display: inline-block;
			vertical-align: top;
			margin-right: .5rem;
		}
	}
}// .form-giftcard-redeem


//
// Giftregistry/index/edit
//  ---------------------------------------------
.form-giftregistry-edit {
	.fieldset {
		margin-bottom: 2rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}// .form-giftregistry-edit


//
// Review/customer, Sales/order/history
//  ---------------------------------------------
.table-wrapper.order-items {
	+ .actions-toolbar {
		display: none;
	}
}
.table-order-items {
	td {
		&.actions {
			@include breakpoint(medium) {
				width: 1%;
				white-space: nowrap;
			}
		}
	}

	.product-label,
	.convert-label-container {
		display: none !important;
	}
}
.pager {
	.account & {
		@include grid-row;
		@include grid-row-nest;
		display: flex;
		align-items: flex-end;
			flex-flow: row wrap;

		.toolbar-amount {
			@include grid-col;
			text-align: center;

			@include breakpoint(medium) {
				@include grid-col-size(6);
				text-align: left;
			}
		}
		.limiter {
			@include grid-col;
			text-align: center;

			@include breakpoint(medium) {
				@include grid-col-size(6);
				text-align: right;
			}

			.limiter-options {
				display: inline-block;
				width: auto;
				margin-right: .25rem;
				margin-left: .25rem;
			}
		}

		.pages {
			order:3;
			width: 100%;
			text-align: center;
			margin-top: 1rem;

			.pages-label {
				@include at;
			}

			.pages-items {
				@include lsn;
				.item {
					display: inline-block;
					vertical-align: middle;
					margin: 0 .25rem;
				}
				.label {
					@include at;
				}
			}

			a {
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}// .pager


//
// Reward/customer/info
//  ---------------------------------------------
.block-reward-info,
.block-reward-history {
	margin-bottom: 2rem;
}

.block-addresses-list {
	.block-title {
		.account & {
			margin-bottom: 1rem;
		}
	}
	.addresses {
		@include lsn;
		@include grid-row;
		@include grid-row-nest;

		> .item {
			@include grid-col;
			@include grid-column-end;
			margin-bottom: 2rem;
			@include breakpoint(large) {
				@include grid-col-size(6);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(4);
			}
		}
	}
}

.block-addresses-default {
	.block-title {
		.account & {
			margin-bottom: 1rem;
		}
	}
	.block-content {
		@include grid-row;
		@include grid-row-nest;

		.box {
			@include grid-col;
			margin-bottom: 2rem;

			@include breakpoint(xlarge) {
				@include grid-col-size(6);
			}
		}
	}
}// .block-addresses-default


//
// Sales/order/view/order_id/#
//  ---------------------------------------------
.order-actions-toolbar {
	margin-bottom: 1rem;
}

.sales-order-before-links {
	position: relative;
	z-index: 2;

	.actions-toolbar {
		margin-top: 0;
		.action {
			display: inline-block;
			vertical-align: middle;
			width: auto;
		}
	}

	@include breakpoint(xlarge) {
		float: right;
		padding-top: 0.625rem;
		text-align: right;

		.order-date,
		.order-actions-toolbar {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
		}

		.order-date {
			margin-right: 1rem;
		}
	}

	@include breakpoint(xlarge only) {
		.sales-order-shipment & {
			width: 100%;
			float: none;
			text-align: left;
			margin-bottom: 1rem;
			padding-top: 0;
		}
	}

	@include breakpoint(xxlarge only) {
		.sales-order-shipment & {
			width: 42%;
			padding-top: 0;
			margin-top: -1rem;

			.order-date {
				display: block;
				margin-right: 0;
			}

			.order-actions-toolbar {
				.action {
					&:after {
						margin: 0 .1rem;
					}
				}
			}
		}
	}

	.label {
		@include at;
	}
}

.order-links {
	@include lsn;
	position: relative;
	z-index: 1;

	@include breakpoint(xlarge only) {
		.sales-order-shipment & {
			width: 100%;
		}
	}

	@include breakpoint(xxlarge only) {
		.sales-order-shipment & {
			width: 58%;
		}
	}

	li {
		display: inline-block;
		vertical-align: top;
		border: 1px solid $ts-main-dark-border-color;
		margin-right: -1px;
		margin-bottom: -1px;

		&.current {
			border-bottom-color: $body-background;
		}

		a,
		strong {
			display: block;
			text-decoration: none;
			padding: 0.625rem;

			@include breakpoint(xlarge) {
				padding: 0.625rem 1.25rem;
			}
		}
	}
}

.block-order-details-comments {
	margin-bottom: 1.5rem;
	.block-title {
		@include at;
	}
}// .block-order-details-comments

.order-details-items {
	width: 100%;
	clear: both;
	margin-bottom: 3rem;
	overflow-x: auto;
	border: 1px solid $ts-main-dark-border-color;

	.actions-toolbar {
		margin-left: 2rem;
		margin-top: 1rem;
	}

	.order-tracking {
		margin:1rem 2rem 0;
	}

	.order-title {
		margin:1rem 2rem;

		strong {
			display: block;
		}
	}

	.product-item-name {
		display: block;
		margin-bottom: .25rem;
	}

	.item-options {
		font-size: 85%;
		line-height: 1.4;
	}

	.items-qty {
		@include lsn;

		li {
			white-space: nowrap;
		}
	}

	table {
		margin-bottom: 0;

		tr {
			td,
			th {
				vertical-align: top;
				font-size: rem-calc(14);
				font-weight: 400;

				strong {
					font-weight: inherit;
				}

				&:first-child {
					padding-left: 2rem;

					@include breakpoint(large down) {
						padding-left: .75rem;
						.product-image-container {
							display: none;
						}
					}
				}
				&:last-child {
					padding-right: 2rem;
				}
			}
		}

		thead {
			border:0;
			background: none;

			tr {
				&:first-child {
					th {
						border-top: 0;
					}
				}
				th {
					font-size: 1rem;
					font-weight: 700;
					@include header-font-family($ts-subtitle-font-family);
				}
			}
		}
		tfoot {
			border: 0;
			td,
			th {
				border:0;
				padding-top: 0;
				padding-bottom: 0;
			}
			th {
				text-align: right;
			}
			td {
				text-align: left !important;
			}
			tr {
				&:first-child {
					th,
					td {
						padding-top: 2rem;
					}
				}
				&:last-child {
					th,
					td {
						padding-bottom: 2rem;
					}
				}
			}

			.mark-inner {
				display: inline-block;
				vertical-align: middle;
				text-align: left;
				width: rem-calc(150);
				@include label-colon;
				&:after {
					margin-left: -3px;
				}
			}
		}
	}

	.table-order-items {
		.product-image-container {
			display: block;
		}
		.product-image-wrapper {
			padding-bottom: 0 !important;
		}
	}
}// .order-details-items

.block-order-details-view {
	.block-title {
		@extend .box-title;
		padding-bottom: .75rem !important;
		margin-bottom: 1.5rem !important;
	}
	.block-content {
		@include grid-row;
		@include grid-row-nest;

		.box {
			@include grid-col;
			@include grid-col-end;
			margin-bottom: 2rem;

			address {
				font-style: normal;
			}

			dt {
				font-weight: normal;
			}

			.box-title {
				display: block;
				margin-bottom: .5rem;
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: .5rem;

				&:before {
					display: none;
				}

				@include breakpoint(xlarge) {
					margin-bottom: 1.25rem;
				}
			}

			@include breakpoint(medium) {
				@include grid-col-size(6);

				&:nth-child(odd) {
					clear: left;
				}
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(3);

				&:nth-child(odd) {
					clear: none;
				}
			}
		}
	}

	.box-order-billing-method {
		table {
			border-top: 1px solid $ts-main-dark-border-color;
			tr {
				th,
				td {
					display: inline-block;
					vertical-align: middle;
					padding: 0;
					border:0;
					text-align: left;
				}
				th {
					@include label-colon;
					margin-right: 3px;
				}
			}
		}
	}
}// .block-order-details-view

.form.contact {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.legend,
	br {
		display: none;
	}

	.no-label {
		margin-bottom: 1rem;
	}

	.fieldset,
	.actions-toolbar {
		@include grid-col;
	}

	.fieldset {
		@include breakpoint(large) {
			@include grid-col-size(8);
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(6);
		}
	}
}// .form.contact

.shipping-tracking-popup {
	.algolia-instant-selector-results,
	#launcher,
	.to-top {
		display: none !important;
	}

	.subtitle {
		margin-bottom: 1rem;
		font-size: 18px;
		font-family: $header-font-family;
		font-weight: $header-font-weight;
	}
	.message {
		margin-bottom: 1rem;
	}
}


//
// Print Order
//  ---------------------------------------------
.page-print {
	.algolia-instant-selector-results {
		display: none !important;
	}
	.copyright,
	.cookies,
	.logo {
		display: block;
		@include get-gutter(.5,'padding-left');
		@include get-gutter(.5,'padding-right');
	}
	.logo {
		margin-bottom: 20px;
	}
	.order-status {
		margin-top: 10px;
	}

	.order-details-items {
		overflow-x: hidden;
	}
	.block-order-details-view {
		padding: 0 2rem;
		overflow: hidden;
	}
}
@media print {
	.page-print {
		.product-label,
		.convert-label-container {
			display: none !important;
		}
	}
}

