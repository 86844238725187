//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
	.action-close {
		@include close-button;
		@extend .no-outline;
		font-size: ($ts-modal-action-close-font-size * .5);
		top: $closebutton-offset-vertical;
		right: $closebutton-offset-horizontal;
		font-weight: $ts-modal-action-close-font-weight;
		color: $ts-modal-action-close-color;
		padding: $ts-modal-slide-action-close-padding;

		@include breakpoint(xlarge) {
			font-size: $ts-modal-action-close-font-size;
		}

		span {
			@include at;
		}

		&:before {
			content: '×';
		}

		&:hover,
		&:active,
		&:focus {
			&:before {
				color: $ts-modal-action-close-hover-color;
			}
		}
	}
}
.modal-custom {
	.action-close {
		margin: (map-get($grid-column-gutter, medium)/4);
	}
}// .modal-custom

.modal-popup {
	.modal-title {
		border-bottom: $ts-modal-title-border;
		padding-bottom: (map-get($grid-column-gutter, medium)/8);
		font-size: $ts-modal-popup-title-font-size;
		margin-bottom: 0;
		min-height: 1em;
		word-wrap: break-word;
	}
	.action-close {
		padding: (map-get($grid-column-gutter, medium)/8);
	}

	&.filters {
		.modal-footer,
		.modal-header {
			button:not(.action-close) {
				@include button-extended;
				@include button-expand;
				@include button-style($secondary-color, $button-background-secondary-hover);
				margin-bottom: 0;
			}
		}

		.modal-header {
			padding-bottom: ($ts-sidebar-main-filter-content-indent*2);
			button:not(.action-close) {
				margin-top: $ts-mobile-filter-subtitle-bottom-spacing;
			}
		}

		.modal-title,
		.sorter-title {
			border-bottom: 0;
			font-size: $ts-mobile-filter-title-font-size;
			color: $ts-mobile-filter-title-font-color;
			font-weight: $ts-mobile-filter-title-font-weight;
			text-transform: $ts-mobile-filter-title-text-transform;
			font-family: $ts-mobile-filter-title-font-family;
			letter-spacing: $ts-mobile-filter-title-indent;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.modal-sub-title {
			display: block;
			font-size: $ts-mobile-filter-subtitle-font-size;
			color: $ts-mobile-filter-subtitle-font-color;
			font-weight: $ts-mobile-filter-subtitle-font-weight;
			text-transform: $ts-mobile-filter-subtitle-text-transform;
			@include header-font-family($ts-mobile-filter-subtitle-font-family);
		}

	}// &.filters

	&.confirm {
		text-align: center;

		.modal-inner-wrap {
			@include breakpoint(medium) {
				width: ($ts-modal-popup-width / 2);
			}
		}


		.modal-footer {
			button {
				min-width: 5rem;
				@include button-extended;
				margin-bottom: 0;
				font-family: $button-font-family;
				font-weight: $button-weight;
				@include button-style($primary-color);

				&.action-secondary {
					@include button-style($secondary-color, $button-background-secondary-hover);
				}
			}
		}
	}// &.confirm
}// .modal-popup

.modal-slide {
	.action-close {
		padding: $ts-modal-slide-action-close-padding;
	}
	.page-main-actions {
		margin-bottom: $ts-modal-slide-header-padding-vertical - (map-get($grid-column-gutter, medium) / 2);
		margin-top: $ts-modal-slide-header-padding-vertical;
	}
}// .modal-slide

.modal-checkout-enter {
	text-align: center;

	.action-close {
		display: none;
	}

	.modal-content-row {
	    @include grid-row;
	    @include grid-row-nest;
	}

	.modal-content {
		padding-bottom: 2rem;

		.modal-content-column {
			padding-top: 2rem;
			margin-top: 2rem;
			@include border-line('top', 0, $ts-main-border-color);

			&:first-child {
				padding-top: 0;
				margin-top: 0;
				&:before {
					display: none;
				}
			}
		}
	}

	.modal-title {
		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 1.25rem;
		text-transform: $ts-checkout-authmodal-title-text-transform;
		font-size: $ts-checkout-authmodal-title-font-size;
		font-weight: $ts-checkout-authmodal-title-font-weight;
	}

	p {
		margin-bottom: 1.5rem;
		color: $ts-checkout-authmodal-text-color;
	}

	.label {
		@include label-colon;
		text-align: left;
	}

	.action {
		min-width: rem-calc(200);
		margin-bottom: .5rem;
	}

	.block-customer-login {

		@include breakpoint(xxxlarge) {
			max-width: 85%;
			margin-left: auto;
			margin-right: auto;
		}

		.fieldset {
			@include grid-row;

			@include breakpoint(large) {
				@include grid-row-nest;
			}

			.label {
				color: $ts-checkout-authmodal-text-color;
			}

			.field {
				@include grid-col;

				@include breakpoint(xxlarge) {
					@include grid-col-size(6);
				}
			}
		}

		.actions-toolbar {
			margin-top: 1rem;
		}
	}

	.button-group-modal {
		position: relative;
		z-index: 1;
	}

	@include breakpoint(large) {
		.modal-content {
			.modal-content-column {
				@include grid-col;
				@include grid-col-size(6);
				position: relative;
				padding-top: 0;
				margin-top: 0;
				@include border-line('left', 0, $ts-main-border-color);

				&:before {
					top: -1rem;
					bottom: -1rem;
				}
			}
		}
	}

	@include breakpoint(xxlarge) {
		.modal-header {
			padding-bottom: 2.25rem;
		}
		.modal-content {
			padding-bottom: 3.5rem;

			.button-group-modal {
				margin-right: 0;
				margin-bottom: 0;
				max-width: rem-calc(415);
				@include button-group;
				@include button-group-expand;
				margin: 0 auto;

				.action {
					min-width: inherit;
					margin-right: .5rem;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}// .modal-checkout-enter

//
//  Mobile
//  _____________________________________________

@include breakpoint(medium down) {
	body {
		&._has-modal-custom {
			height: 100vh;
			overflow: hidden;
			width: 100vw;
		}
	}

	.custom-slide {
		@include lib-modal();
		@include lib-modal-slide();
		&._show {
			-webkit-overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: auto;
		}
		.modal-inner-wrap {
			background-color: $ts-modal-slide-mobile-background-color;
			box-sizing: border-box;
			height: auto;
			min-height: 100%;
		}
	}
	.modal-popup {
		.modal-title {
			font-size: $ts-modal-popup-title-mobile-font-size;
		}
		&.modal-slide {
			.modal-inner-wrap[class] {
				background-color: $ts-modal-slide-mobile-background-color;
			}
			&._inner-scroll {
				&._show {
					-webkit-overflow-scrolling: touch;
					overflow-y: auto;
				}
				.modal-inner-wrap {
					height: auto;
					min-height: 100%;
				}
			}
		}
	}
}

//
//  Desktop
//  _____________________________________________

@include breakpoint(medium) {
	.modal-popup {
		&.modal-slide {
			.modal-footer {
				border-top: $ts-modal-title-border;
				text-align: right;
			}
		}
	}
}
