.product-info-sidebar-configurable {
    .product-info-bundle-row {
        @include grid-row();
        @include grid-row-nest();
        display: flex;
        flex-direction: column;
    }

    .product-form-row-bundle {
        @include grid-col;
        @include grid-col(12);

        @include breakpoint(xxlarge) {
            @include grid-col(8);
        }
        @include breakpoint(large) {
            align-items: flex-end;
            margin-top: 15px;
        }

        .product-info-stock-sku {
            order: 1;
            align-self: flex-start;
            @include breakpoint(xlarge) {
                @include get-gutter(.25, 'margin-top');
                align-self: auto;
                order: 2;
            }
        }

        .product-bundle-addto-container {
            @include get-gutter(.5, 'margin-top');
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            order: 2;
            @include breakpoint(xlarge) {
                flex-direction: row;
                width: auto;
                order: 1;
                margin-top: 0;
            }

            .bundle-actions {
                order: 1;
                @include breakpoint(xlarge) {
                    order: 2;
                }

                .action.primary.customize {
                    @include breakpoint(xlarge) {
                        width: auto;
                    }
                }
            }//.bundle-actions

            .product-social-links {
                @include get-gutter(.5, 'margin-right');
                order: 2;
                @include breakpoint(xlarge) {
                    order: 1;
                }
            }
        }//.product-bundle-addto-container
    }//.product-form-row-bundle

    .product-info-price {
        @include grid-col;
        @include grid-col(12);
        @include breakpoint(xxlarge) {
            @include grid-col(4);
        }

        .price-box {
            .price-container {
                line-height: 1.1;

                .price-label {
                    display: block;
                    font-weight: $ts-product-bundle-price-label-font-weight;
                    font-size: $ts-product-bundle-price-label-font-size;
                    color: $ts-product-bundle-price-label-font-color;
                }

                .price-wrapper {
                    font-size: $ts-product-bundle-price-font-size;
                    font-weight: $ts-product-bundle-price-font-weight;
                    color: $ts-product-bundle-price-font-color;
                }
            }
        }//.price-box
    }//.product-info-price
}//.product-info-sidebar-configurable

.bundle-options-container {
    @include clearfix;
    clear: both;
    @include grid-row();
    @include grid-row-nest();

    .bundle-options-wrapper {
        @include grid-col();
        @include grid-col(12);
        @include breakpoint(large) {
            @include grid-col(6);
        }
    }


    .legend.title {
        @include header-font-family($header-font-family);
        @each $size, $headers in $header-styles {
            @include breakpoint($size) {
                font-size: rem-calc(map-deep-get($headers, 'h2', font-size));
            }
        }
        font-weight: $header-font-weight;
        border: 0;
        padding: 0;
    }//.legend.title

    .product-add-form {
    	@if not ($ts-product-bundle-product-form__show) {
        	display: none;
    	}
    }

    .product-options-wrapper {
        @include breakpoint(xlarge) {
            @include grid-col-size(10);
        }

        .fieldset {
            @extend .no-outline;
            @include no-fieldset;
            @include disable-mouse-outline;

            > .field {
                border-top: $ts-product-bundle-option-container-border;
                margin: 1.15rem 0;
                padding-top: .6rem;

                &:first-of-type {
                    border-top: 0;
                    padding-top: 0;
                }

                > .label {
                    font-size: $ts-product-bundle-option-label-font-size;
                }

                .control {
                    @include breakpoint(large) {
                        @include grid-col-size(10);
                    }

                    .multiselect {
                        padding: 0;
                    }
                }

                .price-notice {
                    font-size: $ts-product-bundle-option-price-font-size;
                    font-weight: $ts-product-bundle-option-price-font-weight;
                }

                &.choice {

                    input {
                        float: left;
                        margin-top: 4px;
                    }

                    .label {
                        @include clearfix;
                        display: block;
                    }

                    .price-excluding-tax {
                        display: inline-block;
                    }
                }
            }
        }//.fieldset
    }//.product-options-wrapper

    .action.back {
        @include button-extended();

        &:before {
            content: none;
        }

        &:hover {
            span {
                text-decoration: none;
            }
        }
    }//.action.back

    .block-bundle-summary {
        @include grid-col();
        @include grid-col(12);

        @include breakpoint(large) {
            @include grid-col(6);
            position: relative;
        }

        > .title {
            @include header-font-family($header-font-family);
            @each $size, $headers in $header-styles {
                @include breakpoint($size) {
                    font-size: rem-calc(map-deep-get($headers, 'h2', font-size));
                }
            }
            font-weight: $header-font-weight;

            > strong {
                font-weight: inherit;
            }
        }//.title

        .bundle-info {
            margin-top: 2px;
            @include get-gutter(.25, 'margin-bottom');
            position: relative;
            padding: map-get($ts-product-info-sidebar-indent, small);
            padding-left: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
            padding-right: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
            margin-left: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
            margin-right: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
            background-color: $ts-product-info-sidebar-background-color;
            @include breakpoint(medium) {
                margin-left: 0;
                margin-right: 0;
                padding: map-get($ts-product-info-sidebar-indent, large);
                margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, large)/2;
            }
            @include breakpoint(xlarge) {
                padding: map-get($ts-product-info-sidebar-indent, xlarge);
                margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, xlarge)/2;
            }
            @include breakpoint(xxlarge) {
                padding: map-get($ts-product-info-sidebar-indent, xxlarge);
                margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, xxlarge)/2;
            }

            .fieldset {
                @include no-fieldset;
                @include disable-mouse-outline;
                @extend .no-outline;
                -webkit-tap-highlight-color: transparent;
                display: flex;

                .field.qty {
                    flex: 1 0 auto;
                    max-width: 70px;
                }

                .actions {
                    @include get-gutter(.5, 'margin-left');
                    @include get-gutter(.5, 'margin-right');
                    flex: 1 0 auto;
                    margin-top: $form-label-font-size * $form-label-line-height;
                }
            }
        }//.bundle-info

        > .title {
            padding-bottom: 1rem;
        }

        .bundle-summary {
            @include get-gutter(1, 'margin-bottom');
            @include breakpoint(large) {
                margin-bottom: 0;
            }

            &.empty {
                display: none;
            }

            .subtitle {
                @include get-gutter(.25, 'margin-bottom');
                @include header-font-family($header-font-family);
                display: block;
                border-bottom: 1px $medium-gray solid;
                padding-bottom: 5px;
                font-weight: $header-font-weight;
                @each $size, $headers in $header-styles {
                    @include breakpoint($size) {
                        font-size: rem-calc(map-deep-get($headers, 'h3', font-size));
                    }
                }
                @include breakpoint(large) {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

                > strong {
                    font-weight: inherit;
                }
            }//.subtitle

            .bundle.items {
                @include get-gutter(.25, 'margin-top');
                @include lsn;

                > li {
                    margin: rem-calc(5 0);

                    .label {
                        background-color: $primary-color;
                    }
                }
            }//.bundle.items
        }//.bundle-summary

        .price-box {
            .price-label {
                display: block;
            }
        }//.price-box

        .box-tocart {
            .action.tocart {
                font-size: $ts-product-addtocart-btn-font-size;
                font-weight: $ts-product-addtocart-btn-font-weight;
                font-family: $ts-product-addtocart-btn-font-family;
                text-transform: $ts-product-addtocart-text-transform;
                @if $ts-product-addtocart-btn-icon-view {
                    span {
                        display: block;
                        padding: $ts-product-addtocart-btn-padding;
                        background-image: url("#{$ts-path-to-images}/#{$ts-product-addtocart-btn-icon}.#{$ts-product-addtocart-btn-icon-type}");
                        background-repeat: no-repeat;
                        background-size: $ts-product-addtocart-btn-icon-size;
                        background-position: $ts-product-addtocart-btn-icon-pos;
                    }
                } @else {
                    padding: $ts-product-addtocart-btn-padding;
                }
            }
        }//.box-tocart

        .product-addto-links {
            text-align: center;
            @include breakpoint(large) {
                text-align: left;
            }

            > .action {
                margin-right: 5%;
            }
        }//.product-addto-links

        .product-image-container,
        .product.name,
        .stock {
            @include at;
        }

        .price-container {
            font-weight: $ts-product-detail-price-font-weight-mobile;
            font-size: ($ts-product-detail-price-font-size * .75);
            @include breakpoint(large) {
                font-weight: $ts-product-detail-price-font-weight;
            }
            @include breakpoint(xlarge) {
                margin-top: ($ts-product-detail-price-line-top-indent*.75);
            }
            @include breakpoint(xxlarge) {
                margin-top: $ts-product-detail-price-line-top-indent;
                font-size: $ts-product-detail-price-font-size;
            }

            .weee {
                .price {
                    font-weight: $ts-product-detail-price-font-weight-mobile;
                    @include breakpoint(large) {
                        font-weight: $ts-product-detail-price-font-weight;
                    }
                }
            }
        }//.price-container
    }//.block-bundle-summary

    p.required {
        color: $alert-color
    }

    .nested {
        .choice {
            @include radio()
        }

        .field.qty {

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                font-weight: 400;
            }

            .control {
                width: 15%;
                min-width: 60px;

                .qty {
                    @include remove-number-appearance;
                }
            }
        }//.field.qty
    }//.nested

    .product-options-bottom {
        clear: left;
        margin: 0;
        padding-top: 0;
    }
}//.bundle-options-container
