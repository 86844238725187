//
//  Columns & Column
//  _____________________________________________
.page-main {
	@include grid-row; // main centered content part
	margin-bottom: 2rem;

	.algolia-instant-selector-results & {
		.cms-home & {
			margin-top: 0;
		}
	}
	.cms-home & {
		margin-top: 1rem;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 5rem;
		.algolia-instant-selector-results & {
			.cms-home & {
				margin-top: 0;
			}
		}
		.cms-home & {
			margin-top: 2rem;
		}
	}

	&.page-main-details {
		margin: 0;
	}
}// .page-main

.column {
	&.main {
		min-height: 300px;
		@include grid-col;
		position: relative;

		.page-main-details & {
			min-height: inherit;
			padding: 0;
		}

		.page-layout-0column & {
			@include get-gutter(0,'padding-left');
			@include get-gutter(0,'padding-right');
		}

		.page-layout-1column & {
			width: 100%;
		}

		.page-layout-3columns & {
			@include breakpoint(medium) {
				@include grid-col-size(4);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(6);
			}
		}
		.page-layout-2columns-left & {
			@extend .float-right;

			@include breakpoint(medium) {
				@include grid-col-size(8);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(9);
			}
		}
		.page-layout-2columns-right & {
			@include breakpoint(medium) {
				@include grid-col-size(8);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(9);
			}
		}

		> .category-cms {
			padding-left: 0;
			padding-right: 0;
		}
	}
}// .column.main

.page-container:not(.algolia-instant-selector-results) {
	> .page-main {
		margin-top: $ts-shipping-bar-top-indent;

		.cms-index-index & {
			margin-top: 0;
		}

		@include breakpoint(large) {
			margin-top: ($ts-shipping-bar-top-indent*2);
		}
	}
	> .top-controls-wrapper,
	> .category-header,
	> .category-cms {
		+ .page-main {
			margin-top: 0;
		}
	}
	.page-layout-0column & {
		@include get-gutter(0,'padding-left');
		@include get-gutter(0,'padding-right');
	}
}// .page-container:not(.algolia-instant-selector-results)


.page-container,
.algolia-instant-selector-results,
.footer-content,
.footer-bottom,
.page-header,
.header .tab-bar,
.category-header.has-bg,
.header.is-stuck .sticky-header-row,
.magellan-bar.is-stuck .magellan-row,
.header .header-row.is-stuck .sticky-header-row,
.is-stuck .center-header-top-panel,
.category-cms,
.page-layout-0column .top-controls-wrapper,
.page-layout-0column .usp-section,
.usp-section ul,
.full-page-content .column.main > .convert-grid-row:not(.expanded),
body.preview > .convert-grid-row {
	padding-left: map-get($ts-global-space-indent, small);
	padding-right: map-get($ts-global-space-indent, small);

	@include breakpoint(large) {
		padding-left: map-get($ts-global-space-indent, large);
		padding-right: map-get($ts-global-space-indent, large);
	}

	@include breakpoint(xxxlarge) {
		padding-left: 0;
		padding-right: 0;
	}
}

//
//  Full page view for Pimcore pages
//  _____________________________________________
.full-page-content {
	@include clearfix;
	> .columns:not(.convert-column) {
		margin-right: auto;
		margin-left: auto;
		> .column {
			@include get-gutter(0, 'padding-left');
			@include get-gutter(0, 'padding-right');
		}
	}

	.page-container {
		@include get-gutter(0, 'padding-left');
		@include get-gutter(0, 'padding-right');
	}
}// .full-page-content


//
//  Override default grid structure due to current Magento html-structure
//  _____________________________________________
.columns:not(.convert-column) {
	@include get-gutter(0, 'padding-left');
	@include get-gutter(0, 'padding-right');

	@include breakpoint(medium down) {
		body.account & {
			display: flex;
			flex-wrap: wrap;
			.column.main {
				flex-basis: 100%;
				flex-grow: 1;
				order: 2;
			}
			.sidebar-main {
				flex-grow: 1;
				flex-basis: 100%;
				order: 1;
			}
			.sidebar-additional {
				flex-grow: 1;
				flex-basis: 100%;
				order: 3;
			}
		}// body.account

		body.checkout-cart-index & {
			.cart-container {
				display: flex;
				flex-wrap: wrap;
			}
			.cart-form {
				flex-grow: 1;
				flex-basis: 100%;
				order: 1;
			}
			.cart-summary {
				flex-grow: 1;
				flex-basis: 100%;
				order: 3;
			}
		}// body.checkout-cart-index
	}// breakpoint(medium down)

	@include breakpoint(medium) {
		display: block;
	}

	.page-layout-1column &,
	.page-layout-2columns-right &,
	.page-layout-2columns-left main > & {
		float: none !important;
		@include clearfix;
	}
}// .columns:not(.convert-column)


// Extend columns for 5 and 6 items
@include -zf-each-breakpoint {
	// Column width
	.#{$-zf-size}-25 {
		@include grid-col-size(2.4);
	}
}
