figure[data-appearance="full-width"] {
    @include breakpoint(large) {
        margin-left: -20px;
        margin-right: -20px;
    }

    @include breakpoint(medium down) {
        margin-left: -5px;
        margin-right: -5px;
    }

    > img {
        width: 100%;
    }
}

.top-container figure[data-appearance="full-width"] {
    @include breakpoint(large) {
        margin-left: 0;
        margin-right: 0;
    }

    @include breakpoint(medium down) {
        margin-left: 0;
        margin-right: 0;
    }
}

.pagebuilder-column {
    figure[data-appearance="full-width"] {
        margin-left: 0;
        margin-right: 0;
    }
}
