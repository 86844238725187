//
//  Pagination - Algolia and Magento
//  _____________________________________________
@if $ts-pagination-enable {
	.pages-label {
		@include at;
	}

	.ais-pagination,
	.ais-Pagination-list,
	.convert-paging .pages-items {
		@include lsn;
		position: relative;
		padding: map-get($ts-pagination-inner-indent,small);
		margin: map-get($ts-pagination-outside-indent, small);
		text-align: $ts-pagination-text-align;

        @if $ts-pagination-centered {
            padding: 0;
        }

		// page-wrapper item
		.ais-pagination--item,
		.ais-Pagination-item,
		.item {
			display: inline-block;
			margin: map-get($ts-pagination-item-outside-indent,small);

			@if $ts-pagination-centered {
				vertical-align: middle;
			} @else {
				vertical-align: top;
			}

			.label {
				@include at;
			}

			// page-item
			.ais-pagination--link,
			.ais-Pagination-link,
			.page {
				display: block;
				width: map-get($ts-pagination-item-width,small);
				height: map-get($ts-pagination-item-height,small);
				line-height: map-get($ts-pagination-item-line-height,small);
				font-size: $ts-pagination-item-font-size;
				font-family: $ts-pagination-item-font-family;
				font-weight: $ts-pagination-item-font-weight;
				text-align: center;
				border-radius: $ts-pagination-item-border-radius;
				color: $ts-pagination-item-color;
				text-decoration: none;
				border: $ts-pagination-item-border;
				background-color: $ts-pagination-item-bg;
				cursor: pointer;

				&:hover,
				&:focus {
					text-decoration: none;
					color: $ts-pagination-item-color-active;
					border-color: $ts-pagination-item-border-color-active;
					background-color: $ts-pagination-item-bg-active;
					box-shadow: $ts-pagination-item-box-shadow-active;
				}
			}

			// page item - active state
			&.ais-pagination--item__active,
			&.ais-Pagination-item--selected,
			&.current {
				.ais-pagination--link,
				.ais-Pagination-link,
				.page {
					text-decoration: none;
					color: $ts-pagination-item-color-active;
					border-color: $ts-pagination-item-border-color-active;
					background-color: $ts-pagination-item-bg-active;
					box-shadow: $ts-pagination-item-box-shadow-active;
				}
			}

			// next-prev wrapper
			&.ais-Pagination-item--previousPage,
			&.ais-pagination--item__previous,
			&.ais-Pagination-item--nextPage,
			&.ais-pagination--item__next,
			&.pages-item-next,
			&.pages-item-previous {
				@if $ts-pagination-centered {
					position: relative;
				} @else {
					position: absolute;
					bottom: 0;
					left: 0;
				}

				// next-prev has additional arrows
				@if $ts-pagination-item-next-prev-has-arrow {
					&:before {
						content:'';
						width: $ts-pagination-item-next-prev-arrow-width;
						height: $ts-pagination-item-next-prev-arrow-height;
						display: block;
						position: absolute;
						top: 50%;
						transform: translateY(-50%) rotate(180deg);
						left: $ts-pagination-item-next-prev-arrow-hz-indent;
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
				}

				// next-prev buttons
				.ais-pagination--link,
				.ais-Pagination-link,
				.link,
				.action {

					// has button-view
					@if $ts-pagination-item-next-prev-button {

						// has button-view only starting from specified breakpoint
						@if $ts-pagination-item-next-prev-button-simple-mobile {
							font-size: 0;
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
							background-size: $ts-pagination-item-next-prev-button-arrow-size;
							background-repeat: no-repeat;
							background-position: 50%;

							@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
								@include element-invisible-off;
								min-width: map-get($ts-pagination-item-next-prev-button-min-width,small);
								max-width: 49%;
								height: auto;
								@include button-extended;
								padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,small);
								padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,small);
								font-size: map-get($ts-pagination-item-next-prev-button-font-size,small);
								text-decoration: none;
								color: $ts-pagination-item-next-prev-button-color !important;
								letter-spacing: $ts-pagination-item-next-prev-button-letter-spacing;
								text-transform: $ts-pagination-item-next-prev-button-text-transform;
								background-image: none;
								background-color: $ts-pagination-item-next-prev-button-bg-color !important;
								cursor: pointer;

								// next-prev - active states
								&:hover {
									background-color: $ts-pagination-item-next-prev-button-bg-color-hover !important;
									background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
								}
								&:active,
								&:focus {
									background-color: $ts-pagination-item-next-prev-button-bg-color-active !important;
									background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
								}
							}
						} @else {
							@include element-invisible-off;
							min-width: map-get($ts-pagination-item-next-prev-button-min-width,small);
							max-width: 49%;
							height: auto;
							@include button-extended;
							padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,small);
							padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,small);
							font-size: map-get($ts-pagination-item-next-prev-button-font-size,small);
							text-decoration: none;
							color: $ts-pagination-item-next-prev-button-color !important;
							letter-spacing: $ts-pagination-item-next-prev-button-letter-spacing;
							text-transform: $ts-pagination-item-next-prev-button-text-transform;
							background-color: $ts-pagination-item-next-prev-button-bg-color !important;
							cursor: pointer;

							// next-prev - active states
							&:hover {
								background-color: $ts-pagination-item-next-prev-button-bg-color-hover !important;
							}
							&:active,
							&:focus {
								background-color: $ts-pagination-item-next-prev-button-bg-color-active !important;
							}
						}

					// has simple page-view with arrow
					} @else {
						display: block;
						width: map-get($ts-pagination-item-width,small);
						height: map-get($ts-pagination-item-height,small);
						line-height: map-get($ts-pagination-item-line-height,small);
						font-size: 0;
						text-align: center;
						border-radius: $ts-pagination-item-border-radius;
						color: $ts-pagination-item-color;
						text-decoration: none;
						border: $ts-pagination-item-border;
						background-color: $ts-pagination-item-bg;
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
						background-size: $ts-pagination-item-next-prev-button-arrow-size;
						background-repeat: no-repeat;
						background-position: 50%;
						cursor: pointer;

						// next-prev - active states
						&:hover,
						&:focus {
							text-decoration: none;
							color: $ts-pagination-item-color-active;
							border-color: $ts-pagination-item-border-color-active;
							background-color: $ts-pagination-item-bg-active;
							box-shadow: $ts-pagination-item-box-shadow-active;
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
						}
					}
				}

				// next-prev buttons - disabled state
				&.disabled,
				&.ais-Pagination-item--disabled,
				&.ais-pagination--item__disabled {
					@if $ts-pagination-centered {
						display: none;
					}

					.ais-pagination--link,
					.ais-Pagination-link,
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							cursor: default !important;

							// has disabled button-view only starting from specified breakpoint
							@if $ts-pagination-item-next-prev-button-simple-mobile {
								@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									&:hover {
										color: $ts-pagination-item-next-prev-button-color-disabled !important;
										background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
										box-shadow: none;
									}
									&:active,
									&:focus {
										color: $ts-pagination-item-next-prev-button-color-disabled !important;
										background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
										box-shadow: none;
									}
								}
							} @else {
								color: $ts-pagination-item-next-prev-button-color-disabled !important;
								background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
								&:hover {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									box-shadow: none;
								}
								&:active,
								&:focus {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									box-shadow: none;
								}
							}
						} @else {
							opacity: $ts-pagination-item-next-prev-opacity-disabled;
							cursor: default !important;
							&:hover,
							&:focus {
								color: $ts-pagination-item-next-prev-button-color-disabled;
								border-color: $ts-pagination-item-next-prev-button-color-disabled;
								background-color: $ts-pagination-item-next-prev-button-bg-color-disabled;
								box-shadow: none;
							}
						}
					}

					@if $ts-pagination-item-next-prev-has-arrow {
						&:before {
							opacity: $ts-pagination-item-next-prev-opacity-disabled;
						}
					}
				}
			}

			&.ais-pagination--item__next,
			&.ais-Pagination-item--nextPage,
			&.pages-item-next {
				left: auto;
				right: 0;
				@if $ts-pagination-item-next-prev-has-arrow {
					&:before {
						transform: translateY(-50%);
						left: auto;
						right: $ts-pagination-item-next-prev-arrow-hz-indent;
					}
				}
			}

			&.ais-pagination--item__previous,
			&.ais-Pagination-item--previousPage,
			&.pages-item-previous {
				.ais-pagination--link,
				.ais-Pagination-link,
				.link,
				.action {
					// has button-view
					@if $ts-pagination-item-next-prev-button {
						// has button-view only starting from specified breakpoint
						@if $ts-pagination-item-next-prev-button-simple-mobile {
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev}.svg");

							&:hover,
							&:active,
							&:focus {
								background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev-hover}.svg");
							}

							@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
								background-image: none;
							}
						}
					} @else {
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev}.svg");

						&:hover,
						&:active,
						&:focus {
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev-hover}.svg");
						}
					}
				}
			}
		}

		// Landscape view
		@include breakpoint(xlarge) {
			margin:map-get($ts-pagination-outside-indent, xlarge);
			padding: map-get($ts-pagination-inner-indent, xlarge);

            @if $ts-pagination-centered {
                padding: 0;
            }

			.ais-pagination--item,
			.ais-Pagination-item,
			.item {
				margin: map-get($ts-pagination-item-outside-indent,xlarge);

				.ais-pagination--link,
				.ais-Pagination-link,
				.link,
				.page {
					width: map-get($ts-pagination-item-width,xlarge);
					height: map-get($ts-pagination-item-height,xlarge);
					line-height: map-get($ts-pagination-item-line-height,xlarge);
				}

				&.ais-pagination--item__previous,
				&.ais-Pagination-item--previousPage,
				&.ais-pagination--item__next,
				&.ais-Pagination-item--nextPage,
				&.pages-item-next,
				&.pages-item-previous {
					bottom: auto;
					top: 0;
					.ais-pagination--link,
					.ais-Pagination-link,
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							min-width: map-get($ts-pagination-item-next-prev-button-min-width,xlarge);
							font-size: map-get($ts-pagination-item-next-prev-button-font-size,xlarge);
							padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,xlarge);
							padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,xlarge);
						}
					}
				}
			}
		}// breakpoint

		// Desktop view
		@include breakpoint(xxlarge) {
			margin:map-get($ts-pagination-outside-indent, xxlarge);
			padding: map-get($ts-pagination-inner-indent, xxlarge);

            @if $ts-pagination-centered {
                padding: 0;
            }

			.ais-pagination--item,
			.ais-Pagination-item,
			.item {
				margin: map-get($ts-pagination-item-outside-indent,xxlarge);

				.ais-pagination--link,
				.ais-Pagination-link,
				.link,
				.page {
					width: map-get($ts-pagination-item-width,xxlarge);
					height: map-get($ts-pagination-item-height,xxlarge);
					line-height: map-get($ts-pagination-item-line-height,xxlarge);
				}

				&.ais-pagination--item__previous,
				&.ais-Pagination-item--previousPage,
				&.ais-pagination--item__next,
				&.ais-Pagination-item--nextPage,
				&.pages-item-next,
				&.pages-item-previous {
					.ais-pagination--link,
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							min-width: map-get($ts-pagination-item-next-prev-button-min-width,xxlarge);
							font-size: map-get($ts-pagination-item-next-prev-button-font-size,xxlarge);
							padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,xxlarge);
							padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,xxlarge);
						}
					}
				}
			}
		}// breakpoint

		// iPhone4 smaller view
		@media all and (max-width: 374px) {
			.ais-pagination--item,
			.ais-Pagination-item,
			.item {
				&.ais-pagination--item__previous,
				&.ais-Pagination-item--previousPage,
				&.ais-pagination--item__next,
				&.ais-Pagination-item--nextPage,
				&.pages-item-next,
				&.pages-item-previous {
					&:before {
						@if $ts-pagination-item-next-prev-has-arrow {
							display: none;
						}
					}
					.ais-pagination--link,
					.ais-Pagination-link,
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							@if not $ts-pagination-item-next-prev-button-simple-mobile {
								font-size: $ts-pagination-item-next-prev-button-font-size-iphone4;
								min-width: $ts-pagination-item-next-prev-button-min-width-iphone4;
								padding-left: $ts-pagination-item-next-prev-button-hz-paddings-iphone4;
								padding-right: $ts-pagination-item-next-prev-button-hz-paddings-iphone4;
							}
						}
					}
				}
			}
		}// breakpoint
	}
}
