.catalog-category-view {
    .category-header {
       &.category-header--cms-block {
            .top-controls-wrapper {
                @include breakpoint(large down) {
                    margin-bottom: rem-calc(40);
                }
            }
        }
    }
}

.category-header {
    &--cms-block {
        padding: rem-calc(0 20);
        max-width: 91.25rem;
        margin-right: auto;
        margin-left: auto;
        @include breakpoint(large) {
            position: relative;
        }

        &.has-bg,
        &.has-image {
            padding: rem-calc(0 20);
            @include breakpoint(large) {
                background-image: none !important;
            }
        }

        .top-controls-wrapper {
            left: 20px;
            width: calc(100% - 40px);

            @include breakpoint(large) {
                position: absolute;
                width: 100%;
            }

            @include breakpoint(xxxlarge) {
                left: calc((100% - 1460px) / 2);
                padding: 0;
            }

            .breadcrumbs {
                margin-top: 1px;
                @include breakpoint(medium down) {
                    padding-left: 0;
                }
            }
        }
    }
}

#hr-pages .product-item-details .tier-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium down) {
        flex-direction: column;
    }

    .price {
        color: #F7465B;
        font-weight: 600;
        font-size: 1.25rem;
    }

    .original-price {
        color: black;
        font-weight: 600;
        font-size: 1.25rem;
        margin-right: 12px;
    }

    .special-price-container {
        @include breakpoint(large) {
            display: flex;
        }

        .special-price {
            color: #F7465B;
        }

        .old-price {
            font-size: 0.9375rem;
            margin-right: 12px;
        }
    }
}
