//
//  Messages
//  _____________________________________________
@if ($ts-main-message-style == 'fixed') {
	.page {
		&.messages {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding-left: 0;
			padding-right: 0;
			z-index: $ts-main-message-zindex;
			box-shadow: $ts-main-message-box-shadow;

			.message-wrap {
				@include grid-row;
				position: relative;
			}

			.message {
				@include get-gutter(.5,'padding-left');
				@include get-gutter(.5,'padding-right');
				border-left: 0;
				border-right: 0;
				text-align: $ts-main-message-text-align;
				background-image: none;
				margin-bottom: 0;

				&:first-child {
					border-top: 0;
				}

				&:last-child {
					border-bottom: 0;
				}

				+ .message {
					margin-top: -1px;
				}

				.message-text {
					display: inline-block;
					vertical-align: middle;
					padding-left: ($ts-main-message-text-padding*.75);
					padding-right: $ts-main-message-text-padding;
					background-position: $ts-main-message-icon-position;
					background-repeat: no-repeat;
					background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-info}.#{$ts-main-message-icon-info-type}");
					background-size: $ts-main-message-icon-info-size;

					@include breakpoint(large) {
						padding-left: $ts-main-message-text-padding;
					}
				}

				.message-close {
					display: block;
					width: $ts-main-message-close-size;
					height: $ts-main-message-close-size;
					padding: ($ts-main-message-close-size - $ts-main-message-close-icon-size);
					position: absolute;
					top: $ts-main-message-close-icon-position-top;
					right: 0;
					opacity: .75;
					background-image: url("#{$ts-path-to-images}/#{$ts-main-message-info-close-icon}.#{$ts-main-message-info-close-icon-type}");
					background-repeat: no-repeat;
					background-position: $ts-main-message-close-icon-position;
					background-size: $ts-main-message-close-icon-size auto;
					transition: opacity .5s ease;
					cursor: pointer;
					z-index: 1;

					&:hover,
					&:active {
						opacity: 1;
					}
				}

				&.success {
					border-color: $ts-main-message-border-color-success;
					background-color: $ts-main-message-bg-success;

					.message-text {
						background-size: $ts-main-message-icon-success-size;
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-success}.#{$ts-main-message-icon-success-type}");
					}
					.message-close {
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-success-close-icon}.#{$ts-main-message-success-close-icon-type}");
					}
				}
				&.error {
					border-color: $ts-main-message-border-color-error;
					background-color: $ts-main-message-bg-error;

					.message-text {
						background-size: $ts-main-message-icon-error-size;
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-error}.#{$ts-main-message-icon-error-type}");
					}

					.message-close {
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-error-close-icon}.#{$ts-main-message-error-close-icon-type}");
					}
				}
				&.warning {
					border-color: $ts-main-message-border-color-warning;
					background-color: $ts-main-message-bg-warning;

					.message-text {
						background-size: $ts-main-message-icon-warning-size;
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-warning}.#{$ts-main-message-icon-warning-type}");
					}
					.message-close {
						background-image: url("#{$ts-path-to-images}/#{$ts-main-message-warning-close-icon}.#{$ts-main-message-warning-close-icon-type}");
					}
				}
			}
		}
	}
} @else {
	.page {
		&.messages {
			@include grid-col;

			.message-close {
				display: none;
			}
			.message {
				margin-bottom: 1rem;
			}
		}
	}
}

.message {
	display: block;
	font-size: ($ts-main-message-font-size*.85);
	font-weight: $ts-main-message-font-weight;
	line-height: $ts-main-message-line-height;
	@include header-font-family($ts-main-message-font-family);
	clear: both;
	padding: $ts-main-message-main-padding;
	color: $ts-main-message-text-color-info;
	border: $ts-main-message-border-style $ts-main-message-border-color-info;
	background-color: $ts-main-message-bg-info;
	background-repeat: no-repeat;
	background-position: $ts-main-message-main-icon-position;
	background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-info}.#{$ts-main-message-icon-info-type}");
	background-size: $ts-main-message-icon-info-size;

	@include breakpoint(large) {
		font-size: $ts-main-message-font-size;
	}

	&.success {
		color: $ts-main-message-text-color-success;
		border-color: $ts-main-message-border-color-success;
		background-size: $ts-main-message-icon-success-size;
		background-color: $ts-main-message-bg-success;
		background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-success}.#{$ts-main-message-icon-success-type}");
	}
	&.error {
		color: $ts-main-message-text-color-error;
		border-color: $ts-main-message-border-color-error;
		background-size: $ts-main-message-icon-error-size;
		background-color: $ts-main-message-bg-error;
		background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-error}.#{$ts-main-message-icon-error-type}");
	}
	&.warning {
		color: $ts-main-message-text-color-warning;
		border-color: $ts-main-message-border-color-warning;
		background-size: $ts-main-message-icon-warning-size;
		background-color: $ts-main-message-bg-warning;
		background-image: url("#{$ts-path-to-images}/#{$ts-main-message-icon-warning}.#{$ts-main-message-icon-warning-type}");
	}

	&.success,
	&.error {
		a {
			color: $anchor-color;
			&:hover,
			&:focus,
			&:active {
				color: $anchor-color;
			}
		}
	}

	a {
		color: $ts-main-message-text-color-info;
		&:hover,
		&:focus,
		&:active {
			color: $ts-main-message-text-color-info;
		}
	}
}
