.page-product-giftcard {

    .product-info-sidebar {

        .product-add-form {
            .price-box {
                display: flex;
            }

            .product-options-bottom {
                .price-over-box {
                }
            }
        }//.product-add-form

        .giftcard-amount {
            flex: 1 0 100%;
            @extend .no-outline;
            @include no-fieldset;
            @include disable-mouse-outline;
            @include breakpoint(large) {
                @include grid-row-nest();
            }

            .field {

                &.open-amount {
                    @include breakpoint(large) {
                        @include grid-col(6);
                    }

                    .control {
                        white-space: normal;

                        .note {
                            color: rgba($body-font-color, .8);
                            font-size: 11px;
                            line-height: 1.4;
                            letter-spacing: normal;
                            margin: -6px 0 0;
                        }
                    }
                }

                &.amount {
                    @include breakpoint(large) {
                        @include grid-col(6);
                    }
                }
            }
        }//.giftcard-amount

        .giftcard.form {
            @include get-gutter(.5, 'margin-top');
            @include get-gutter(.5, 'margin-bottom');

            .fieldset.giftcard {
                min-inline-size: auto;

                .legend {
                    @include header-font-family($header-font-family);
                    @each $size, $headers in $header-styles {
                        @include breakpoint($size) {
                            font-size: rem-calc(map-deep-get($headers, 'h2', font-size));
                        }
                    }
                    font-weight: $header-font-weight;
                    border: 0;
                    padding: 0;
                }

                > br {
                    display: none;
                }
            }//.fieldset.giftcard
        }//.giftcard.form
    }//.product-info-sidebar
}//.page-product-giftcard