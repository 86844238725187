.header {
	.minisearch {
		position: relative;

		.control {
			.input-text {
				@extend %search-input-desktop-control;
			}
		}// .control

		.actions {
			position: absolute;
			top: 0;
			right: ($ts-header-search-input-icon-right-indent/2);
			line-height: 1;

			button.action {
				width: ($ts-header-search-input-indent - $ts-header-search-input-icon-right-indent);
				height: $ts-header-search-input-height;
				padding: $ts-header-search-input-icon-padding-indent;
				background-position: 50%;

				&:hover,
				&:focus {
					background-color: transparent;
				}
			}
		}// .actions
	} // .minisearch
} // .header

.search-autocomplete {
	text-align: left;
	margin-top: -1px;
	z-index: 4;

	ul {
		@include lsn;
		border:1px solid $ts-main-dark-border-color;
		box-shadow: 2px 3px 3px $ts-main-dark-border-color;
		background: $white;

		&:empty {
			display: none;
		}

		li {
			padding: .25rem 1rem;
			border-bottom: 1px solid $ts-main-dark-border-color;

			&.selected {
				color: $white;
				background: $anchor-color;
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}// .search-autocomplete