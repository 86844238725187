//
//  Forms
//  _____________________________________________
select,
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
	height: $input-select-height;
	margin-bottom: ($form-spacing / 3);
	background-repeat: no-repeat;
	background-size: $input-state-icon-size;
	background-position: $input-state-icon-position;
	padding: $input-padding;

	&.mage-error,
	&.invalid {
		padding-right: $input-state-right-space;
		background-image: url("#{$ts-path-to-images}/#{$input-invalid-icon}.#{$input-invalid-icon-type}");
		background-color: lighten(desaturate(map-get($foundation-palette, alert), 2.1680), 48.2353);
	}
	&.mage-success,
	&.valid {
		padding-right: $input-state-right-space;
		background-image: url("#{$ts-path-to-images}/#{$input-valid-icon}.#{$input-valid-icon-type}");
		background-size: $input-state-valid-icon-size;
		background-position: $input-state-icon-valid-position;
	}
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 ($form-spacing / 3);
}

textarea {
	resize: none;
}

select {
	@if $select-triangle-color = transparent {
		padding-right: $select-triangle-right-space;
		background:$input-background url("#{$ts-path-to-images}/#{$select-triangle-icon}.#{$select-triangle-icon-type}") $select-triangle-icon-position no-repeat;
		background-size: $select-triangle-icon-size auto;
	}
}

// Validation
.input-text {
	&.mage-error {
		border-color: $ts-main-message-border-color-error;
		margin-bottom: 0;

		+ .mage-error,
		+ .field-error {
			margin-top:($form-spacing / 3);
		}
	}
}

div.mage-error,
div.field-error {
	display: block;
	margin-bottom: .5rem;
	color: $ts-main-message-border-color-error;
}

//  No Foundation Label(Blue Badge View) for Form Label
label,
span {
	&.label {
		@include no-label;
	}
}
label {
	&.label {
		@include form-label;
		&.middle {
			@include form-label-middle;
		}
	}
}

//  No Foundation Fieldset(Borderd View) for Fieldset
.fieldset {
	@include no-fieldset;
} // .fieldset
