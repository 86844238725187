.algolia-instant-selector {
    @include grid-row;
}// .algolia-instant-selector

.algolia-instant-selector-results {
    display: none;

    @include breakpoint(medium down) {
        margin-top: 1rem;
        .category-header {
            .top-controls-wrapper,
            .page-title-wrapper,
            .sorter-label {
                @include at;
            }
        }
        .page-main {
            position: relative;

            .category-header {
                width: 48%;
                position: absolute;
                top: 0;
                right: 0;

                .sorter {
                    margin: 0;
                    select {
                        height: $input-select-height;
                    }
                }

                .category-view {
                    display: none;
                }
            }
            .refine-toggle {
                width: 48%;
                position: absolute;
                top: 0;
                height: $input-select-height;
                @include get-gutter(.5,'left');
            }

            .sidebar.algolia-left-container {
                min-height: 3rem;
                margin-bottom: 0;

                .button.expanded {
                    margin-bottom: 0;
                }

                .algolia-current-refinements {
                    margin-top: 1rem;
                }
            }

            .instant-search-facets-container {
                margin-top: 4rem;
            }
        }
    }// @include breakpoint(medium down)
}// .algolia-instant-selector-results

.algolia-instant-results-wrapper {
    @include clearfix;
    margin-top: 0;
    transition: margin-top .3s ease;

    .mobile-search-bar-open & {
        margin-top: 5.5rem;

        .page-container {
            margin-top: 0 !important;
        }
    }
}// .algolia-instant-results-wrapper

.algolia-right-container {
    margin-top: 1rem;

    @include breakpoint(large) {
        margin-top: $ts-category-content-top-indent;
    }
}// .algolia-right-container

.page-layout-0column,
.page-layout-1column {
    .column.algolia-right-container {
        @extend .float-right;
        @include get-gutter(.5,'padding-left');
        @include get-gutter(.5,'padding-right');

        @include breakpoint(medium) {
            @include grid-col-size(8);
        }
        @include breakpoint(xlarge) {
            @include grid-col-size(9);
        }
    }// .column.algolia-right-container
}// .page-layout-1column

.page-layout-2columns-right {
    .algolia-right-container {
        @extend .float-right;
    }
    .sidebar-main.algolia-left-container {
        @extend .float-left;
    }
}// .page-layout-2columns-right

.algolia-instant-replaced-content {
    &.columns {
        padding-left: 0;
        padding-right: 0;
    }
}// .algolia-instant-replaced-content