.page-header {
	background-color: $ts-headbar-bg-color;
	position: relative;
	z-index: $ts-headbar-zindex;
	@if ($ts-headbar-bg-image != none) {
		background-image: url("#{$ts-path-to-images}/#{$ts-headbar-bg-image}.#{$ts-headbar-bg-image-type}");
		background-repeat: $ts-headbar-bg-repeat;
		background-position: $ts-headbar-bg-position;
		background-size: $ts-headbar-bg-size;
	}
	@if $ts-headbar-top-border {
		@include top-header-line($ts-headbar-top-border-height);
	}
}// .page-header

.action.nav-toggle {
	display: none;
}// .action.nav-toggle


.is-not-sticky {
	.header {
		@include breakpoint(large) {
			height: auto !important;
		}
	}
}
.header {
	.header-row {
		.logo {
			text-align: $ts-headbar-logo-align;
			img {
				display: inline-block;
				height: auto;
				max-height: 100%;
				width: auto;

				@if ( $ts-headbar-logo-width-top != 0 ) {
					margin-top: $ts-headbar-logo-width-top;

					@include breakpoint(xxlarge only) {
						margin-top: 0;
					}
				}

				@if ($ts-headbar-style == logo-left) {
					@include breakpoint(xxxlarge) {
						max-width: $ts-headbar-logo-width;
					}
				} @else {
					@include breakpoint(xlarge) {
						max-width: $ts-headbar-logo-width;
					}
				}
			}// img
		}
		.field {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: none;
		color: scale-color(white,$lightness: -3%); // for WCAG
	}// a
}