//
//  Utilities
//  _____________________________________________
.reveal-overlay {
	bottom: auto;
	min-height: 100%;
	overflow: visible;
	background: $ts-modal-overlay-bg;
}// .reveal-overlay

.modals-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: auto;
	left: 0;
	min-height: 100%;
	overflow: visible;
	background: $ts-modal-overlay-bg;
	z-index: $ts-modal-overlay-zindex;
}// .modals-overlay

@mixin lib-modal() {
	bottom: 0;
	left: 0;
	min-width: 0;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
	&._show {
		visibility: visible;
		.modal-inner-wrap {
			// transform: translate(0, 0);
			opacity: 1;
		}
	}
	.modal-inner-wrap {
		background-color: $ts-modal-background-color;
		box-shadow: $ts-modal-box-shadow;
		opacity: 1;
		pointer-events: auto;
	}
}
@mixin lib-modal-slide() {
	left: $ts-modal-slide-first-indent-left;
	z-index: $ts-modal-slide-zindex;
	&._show {
		.modal-inner-wrap {
			transform: translateX(0);
            opacity: 1;
		}
	}
	.modal-inner-wrap {
		height: 100%;
		overflow-y: auto;
		position: static;
		opacity: 0;
		// transform: translateX(100%);
		transition-duration: 0.5s;
		transition-timing-function: ease;
		width: auto;
	}
}
@mixin lib-modal-popup() {
	left: 0;
	overflow-y: auto;
	._has-modal & {
		z-index: $ts-modal-popup-zindex;
	}
	&._show {
		z-index: $ts-modal-popup-zindex;
		.modal-inner-wrap {
			// transform: translateY(0);
			opacity: 1;
		}
	}
	.modal-inner-wrap {
		margin: ($ts-modal-popup-indent-vertical / 4) auto;
		width: $ts-modal-popup-width-mobile;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		opacity: 0;
		// transform: translateY(-200%);
		transition-duration: 0.5s;
		transition-timing-function: ease;

		@include breakpoint(medium) {
			width: $ts-modal-popup-width;
			margin: $ts-modal-popup-indent-vertical auto;
		}
	}

	&.modal-xs {
		.modal-inner-wrap {
			@include breakpoint(xlarge) {
				width: $ts-modal-popup-width/1.75;
			}
			@include breakpoint(xxlarge) {
				width: $ts-modal-popup-width/2;
			}
			@include breakpoint(xxxlarge) {
				width: $ts-modal-popup-width/3;
			}
		}
	}

	&.modal-md {
		.modal-inner-wrap {
			@include breakpoint(xlarge) {
				width: $ts-modal-popup-width/1.25;
			}
			@include breakpoint(xxlarge) {
				width: $ts-modal-popup-width/1.5;
			}
			@include breakpoint(xxxlarge) {
				width: $ts-modal-popup-width/1.9;
			}
		}
	}

	&.modal-xmd {
		.modal-inner-wrap {
			@include breakpoint(xlarge) {
				width: 65%;
			}
		}
	}

	&.modal-add-adress {
		.modal-inner-wrap {
			@include breakpoint(large) {
				width: rem-calc(600);
			}
		}
	}
}

//
//  Styles
//  _____________________________________________


body {
	&._has-modal {
		height: 100%;
		overflow: hidden;
		width: 100%;
		position: fixed;
	}
}
//  Modals overlay
.modals-overlay {
	z-index: $ts-modal-overlay-zindex;
}
.modal-popup,
.modal-slide {
	@include lib-modal();
}
.modal-slide {
	@include lib-modal-slide();
	&._inner-scroll {
		.modal-inner-wrap {
			overflow-y: visible;
			display: flex;
			flex-direction: column;
		}
		.modal-footer,
		.modal-header {
			flex-grow: 0;
			flex-shrink: 0;
		}
		.modal-content {
			overflow-y: auto;
		}
		.modal-footer {
			margin-top: auto;
		}
	}
	.modal-content,
	.modal-footer,
	.modal-header {
		padding: 0 ($ts-modal-slide-padding $ts-modal-slide-padding / 2);

		@include breakpoint(medium) {
			padding: 0 $ts-modal-slide-padding $ts-modal-slide-padding;
		}
	}
	.modal-header {
		padding-bottom: ($ts-modal-slide-header-padding-vertical / 2);
		padding-top: ($ts-modal-slide-header-padding-vertical / 2);

		@include breakpoint(medium) {
			padding-bottom: $ts-modal-slide-header-padding-vertical;
			padding-top: $ts-modal-slide-header-padding-vertical;
		}
	}
}
.modal-popup {
	@include lib-modal-popup();
	//  If applied, switching outer popup scroll to inner
	&._inner-scroll {
		overflow-y: visible;
		.ie10 &,
		.ie9 & {
			overflow-y: auto;
		}
		.modal-inner-wrap {
			max-height: 90%;
			.ie10 &,
			.ie9 & {
				max-height: none;
			}
		}
		.modal-content {
			overflow-y: auto;
		}
	}
	.modal-content,
	.modal-footer,
	.modal-header {
		padding-left: ($ts-modal-popup-padding / 2 );
		padding-right: ($ts-modal-popup-padding / 2 );

		@include breakpoint(medium) {
			padding-left: $ts-modal-popup-padding;
			padding-right: $ts-modal-popup-padding;
		}
	}
	.modal-footer,
	.modal-header {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.modal-header {
		padding-bottom: $ts-modal-popup-padding / 2.5;
		padding-top: ($ts-modal-popup-padding / 1.5);

		@include breakpoint(medium) {
			padding-top: $ts-modal-popup-padding;
		}
	}
	.modal-footer {
		margin-top: auto;
		padding-bottom: ($ts-modal-popup-padding / 2);
		padding-top: ($ts-modal-popup-padding / 2);

		@include breakpoint(medium) {
			padding-bottom: $ts-modal-popup-padding;
			padding-top: $ts-modal-popup-padding;
		}
	}
	.modal-footer-actions {
		text-align: right;
	}
}

//
//  Mobile
//  _____________________________________________
//  Mobile transform to modal-slide
@include breakpoint(medium down) {
	.modal-popup {
		&.modal-slide {
			@include lib-modal-slide();
			.modal-inner-wrap {
				margin: 0;
				max-height: none;
			}
		}
	}
}
