.header {
	.navigation {
		// position: relative;

		> .ui-menu {
			> .ui-menu-item {

				&.menu-type-complex-vertical {
					position: static;

					&.is-clickable {
						&.parent {
							&:not(.is-open) {
								> a {
									+ .submenu {
										display: none !important;
									}
								}
							}
						}

						&:not(.is-open) {
							> a {
								+ .submenu {
									display: none !important;
								}
							}
						}

						> .submenu {
							left: 0 !important;

							&:after {
								z-index: -1 !important;
								width: (rem-calc(190)*3) !important;

								@include breakpoint(xlarge) {
									width: (rem-calc(263)*3) !important;
								}
							}
						}

						.i-close-submenu {
							display: block;
							position: absolute;
							width: 1rem;
							height: 1rem;
							top: .25rem;
							color: $black;
							z-index: 10;
							background: url("#{$ts-path-to-images}/icon-close-bold.svg") no-repeat;
							background-size: 100% auto;
							cursor: pointer;
							left: (rem-calc(190)*3) - 1.25rem;

							@include breakpoint(xlarge) {
								left: (rem-calc(263)*3) - 1.25rem;
							}
						}
					}

					.subcategories-wrap {
						.subcategories-over {
							position: static !important;
						}
					}

					.submenu {
						padding: rem-calc(20 0 14);
						position: relative;
						min-width: inherit;
						width: rem-calc(190);
						box-shadow: none;

						@include breakpoint(xlarge) {
							width: rem-calc(263);
						}

						&:before {
							content: '';
							display: block;
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							background: $white;
							z-index: 2;
						}

						&:after {
							content: '';
							display: block;
							position: absolute;
							width: 100%;
							left: 0;
							top: 0;
							bottom: 0;
							background: $white;
							z-index: 0;
							box-shadow: $ts-header-menu-dropdown-box-shadow;
						}

						.ui-menu-item {
							position: static;

							> a {
								position: relative;
								font-weight: 700;
								padding: rem-calc(0 15);
								height: 36px;
								font-size: rem-calc(12);
								line-height: 36px;
								background-color: $white;
								background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray-light.svg");
								background-repeat: no-repeat;
								background-position: calc(100% - 15px) 50%;
								background-size: 8px auto;
								z-index: 2;
								color: $body-font-color;
								@extend .no-outline;

								@include breakpoint(xlarge) {
									height: 50px;
									font-size: rem-calc(15);
									line-height: 46px;
									padding: rem-calc(0 28 0 23);
									background-position: calc(100% - 28px) 50%;
								}

								span:not(.ui-menu-icon) {
									display: inline-block;
									line-height: 1.2;
									vertical-align: middle;
									padding-right: 10px;
									white-space: nowrap;
									max-width: 100%;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								&:after {
									display: block;
									content: '';
									position: absolute;
									top: 0;
									left: calc(100% + 1px);
									opacity: 0;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 18px 0 18px 8px;
									border-color: transparent transparent transparent $primary-color;

									@include breakpoint(xlarge) {
										border-width: 25px 0 25px 10px;
									}
								}


								&:before {
									display: block;
									content: '';
									position: absolute;
									top: 0;
									left: 15px;
									right: 15px;
									opacity: 1;
									border-bottom: 1px solid rgba($black, .1);

									@include breakpoint(xlarge) {
										left: 20px;
										right: 28px;
									}
								}
							}// > a

							&:hover {
								> a {
									&:after {
										opacity: 1;
										// left: 100%;
									}
									&:before {
										display: block !important;
										content: '';
										top: 0;
										right: -1px;
										bottom: 0;
										left: auto;
										opacity: 1;
										border-right: 1px solid $primary-color;
									}
								}
							}

							&.parent {
								&:hover {
									> a {
										&:after {
											opacity: 1;
										}
										&:before {
											display: block;
											top: 0;
											right: -1px;
											bottom: 0;
											left: auto;
											opacity: 1;
											border-right: 1px solid $primary-color;
										}
									}
								}
							}// &.parent

							&:first-child {
								> a {
									&:before {
										display: none;
									}
								}
							}

							&:hover {
								> a {
									color: $white;
									text-decoration: none;
									background: $primary-color;
									// &:before {
									// 	opacity: 0;
									// }
								}

								// + .ui-menu-item {
								// 	> a {
								// 		&:before {
								// 			opacity: 0;
								// 		}
								// 	}
								// }

								> .submenu {
									&.level0,
									&.level1,
									&.level2 {
										display: block !important;
									}
								}
							} // is-open

							&:hover {
								> a {
									color: $white;
									text-decoration: none;
									background: $primary-color;
									// &:before {
									// 	opacity: 0;
									// }
								}

								// + .ui-menu-item {
								// 	> a {
								// 		&:before {
								// 			opacity: 0;
								// 		}
								// 	}
								// }
							} // is-open

							.submenu {

								&.level0,
								&.level1,
								&.level2 {
									display: none !important;
									position: absolute !important;
									top: 0 !important;
									left: 100% !important;
									min-height: 100%;
									box-shadow: none;
									border-left: 1px solid rgba($black, .05);
									z-index: 1;
								} // &.level0-2

								&.level2 {
									.ui-menu-item {
										&.parent {
											&:hover {
												> a {
													color: $body-font-color;
													background-color: $white;
													background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray-light.svg");
													background-repeat: no-repeat;
													background-position: calc(100% - 15px) 50%;
													background-size: 8px auto;
													@include breakpoint(xlarge) {
														background-position: calc(100% - 28px) 50%;
													}
													&:after,
													&:before {
														opacity: 0;
													}
												}
											}
											> a {
												&:hover,
												&:active,
												&:focus {
													color: $white;
													text-decoration: none;
													background: $primary-color;
													&:before {
														display: block;
														content: '';
														opacity: 1 !important;
														top: 0;
														right: -1px;
														bottom: 0;
														left: auto;
														opacity: 1;
														border-right: 1px solid $primary-color;
														opacity: 1 !important;
													}
													&:after {
														opacity: 1;
													}
												}
											}
										}
										+ .ui-menu-item {
											> a {
												&:before {
													opacity: 1;
												}
											}
										}
									}// .ui-menu-item
									.submenu {
										display: block !important;
										position: static !important;
										padding-top: 0;
										padding-bottom: 0;
										width: 100% !important;
										border-left: 0;

										&:before,
										&:after {
											display: none;
										}

										.ui-menu-item {
											> a {
												&:before {
													display: block;
													content: '';
													position: absolute;
													top: 0;
													left: 15px;
													right: 15px;
													opacity: 1;
													border-bottom: 1px solid rgba($black, .1);

													@include breakpoint(xlarge) {
														left: 20px;
														right: 28px;
													}
												}
											}// > a
											&.parent {
												&:hover,
												&:active,
												&:focus {
													> a {
														color: $body-font-color;
														background-color: $white;
														&:after,
														&:before {
															opacity: 0;
														}
													}
												}
												> a {
													&:hover,
													&:active,
													&:focus {
														color: $white;
														text-decoration: none;
														background: $primary-color;

														&:after {
															opacity: 1;
														}
														&:before {
															display: block;
															top: 0;
															right: -1px;
															bottom: 0;
															left: auto;
															opacity: 1;
															border-right: 1px solid $primary-color;
														}
													}
												}
											}
											&:hover {
												> a {
													&:after {
														opacity: 1;
													}
													&:before {
														display: block !important;
														content: '';
														top: 0;
														right: -1px;
														bottom: 0;
														left: auto;
														opacity: 1;
														border-right: 1px solid $primary-color;
													}
												}
												// + .ui-menu-item {
												// 	> a {
												// 		&:before {
												// 			top: 0;
												// 			right: -1px;
												// 			bottom: 0;
												// 			left: auto;
												// 			opacity: 1;
												// 			border-right: 1px solid $primary-color;
												// 			opacity: 1 !important;
												// 		}
												// 	}
												// }
											}
											&:hover {
												> a {
													&:before {
														top: 0;
														right: -1px;
														bottom: 0;
														left: auto;
														opacity: 1;
														border-right: 1px solid $primary-color;
													}
												}
											}
										}// .ui-menu-item
									}// .submenu
								}// &.level2

							}// .submenu .submenu
						}// .ui-menu-item
					}//.submenu


					.i-close-submenu {
						display: none;
					}

				}
			}
		}
	}
}
