//  Product Stock View
//  _____________________________________________
.stock {
	white-space: nowrap;
	color: $ts-stock-label-font-color;
	font-size: $ts-stock-label-font-size;
	font-weight: $ts-stock-label-font-weight;
	text-transform: $ts-stock-label-uppercase;
	@include header-font-family($ts-stock-label-font-family);
	margin: $ts-stock-label-icon-vt-spacing;

	span {
		display: inline-block;
		padding: 0 0 0 $ts-stock-label-icon-left-indent;
		background-size: $ts-stock-label-icon-bg-size;
	}

	&.available {
		span {
			color: $ts-stock-label-icon-available-color;
			background: url("#{$ts-path-to-images}/#{$ts-stock-label-icon-available}.#{$ts-stock-label-icon-available-type}") 0 50% no-repeat;
			background-size: $ts-stock-label-icon-available-bg-size;
		}
	}

	&.unavailable {
		span {
			color: $ts-stock-label-icon-unavailable-color;
			background: url("#{$ts-path-to-images}/#{$ts-stock-label-icon-unavailable}.#{$ts-stock-label-icon-unavailable-type}") 0 50% no-repeat;
			background-size: $ts-stock-label-icon-unavailable-bg-size;
		}
	}

	&.backorder {
		span {
			white-space: normal;
			text-align: left;
			min-height: 17px;
			color: $ts-stock-label-icon-backorder-color;
			padding-left: $ts-stock-label-icon-left-indent + 2px;
			background: url("#{$ts-path-to-images}/#{$ts-stock-label-icon-backorder}.#{$ts-stock-label-icon-backorder-type}") 0 50% no-repeat;
			background-size: $ts-stock-label-icon-backorder-bg-size;
		}
	}
}