.header {

	.sticky-mm {
		position: fixed;
		width: 100%;
		z-index: 102;
		left: 0;
		top: 0;
		box-shadow: $ts-sticky-headbar-box-shadow;

		&.in-scroll {
			box-shadow: $ts-sticky-scroll-headbar-box-shadow;
		}

		&.has-up-animation {
			transition: top .5s ease-in-out;
		}

		&.has-up-animation.nav-up {
			top: -200px;
		}

		@if $ts-headbar-top-border {
			&:after {
				display: block;
				visibility: visible;
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: $ts-headbar-top-border-height-mobile;
				background: $ts-headbar-top-border-bg;
			}
		}

	}// .sticky-mm

	.tab-bar {
		@include clearfix;
		background-color: $ts-tabbar-menu-bg-color;
		@if ($ts-mobile-header-border-bottom) {
			border-bottom: $ts-mobile-header-border-bottom-width $ts-mobile-header-border-bottom-style $ts-mobile-header-border-bottom-color;
		}

		.nav-toggle {
			display: inline-block;
			vertical-align: top;
			position: relative;
			height: map-get($ts-headbar-height, small);
			min-width: $ts-mobile-toggle-button-width;
			margin-right: (map_get($grid-column-gutter,small))/3;
			@if ( $ts-mobile-toggle-button-type != none ) {
				background: url("#{$ts-path-to-images}/#{$ts-mobile-toggle-button-image}.#{$ts-mobile-toggle-button-type}") 50% 50% no-repeat;
				background-size: 100% auto;

				span {
					@include at;
				}

			} @else {
				width: $ts-mobile-toggle-button-width;
				height: $ts-mobile-toggle-button-width;
				vertical-align: middle;
				transform: rotate(0deg);
				transition: .5s ease-in-out;

				span {
					width: 100%;
					height: 2px;
					display: block;
					position: absolute;
					left: 0;
					border-radius: none;
					opacity: 1;
					font-size: 0;
					transform: rotate(0deg);
					transition: .5s ease-in-out;
					background: $primary-color;
					&:nth-child(1) {
						top: 0;
						transform-origin: left center;
					}

					&:nth-child(2) {
						top: 7px;
						transform-origin: left center;
					}

					&:nth-child(3) {
						top: 14px;
						transform-origin: left center;
					}
				}

				&[aria-expanded="true"] {
					span {
						&:nth-child(1) {
							transform: rotate(45deg);
							top: 1px;
							left: 2px;
						}
						&:nth-child(2) {
							width: 0%;
							opacity: 0;
						}

						&:nth-child(3) {
							transform: rotate(-45deg);
							top: 12px;
							left: 2px;
						}
					}
				}
			}

			@include breakpoint(large) {
				display: none;
			}
		}// .nav-toggle

		.mobile-search-icon {
			display: inline-block;
			vertical-align: top;
			height: map-get($ts-headbar-height, small);
			min-width: $ts-mobile-search-button-width;
			@if ( $ts-mobile-toggle-button-type == none ) {
				vertical-align: middle;
			}
			@if ( $ts-mobile-search-button-type == image ) {
				background: url("#{$ts-path-to-images}/#{$ts-mobile-search-button-image}.#{$ts-mobile-search-button-image-type}") 50% 50% no-repeat;
				background-size: 100% auto;
			}

			&.is-open {
				opacity: $ts-mobile-search-button-active-opacity;
			}

			span {
				@include at;
			}
		}// .mobile-search-icon

		.mobile-account-icon {
			height: map-get($ts-headbar-height, small);
		}

		.middle-side {
			display: block;
			@include grid-col;
			@include grid-col-size(4);
			@include get-gutter(.25,'padding-left');
			@include get-gutter(.25,'padding-right');
			height: map-get($ts-headbar-height, small);
			line-height: (map-get($ts-headbar-height, small) - (map-get($ts-headbar-height, small))/4);
			text-align: center;
			padding-top: (map-get($ts-headbar-height, small))/8;
			padding-bottom: (map-get($ts-headbar-height, small))/8;

			@if ( $ts-headbar-logo-mobile-height != auto ) {
				padding-top: (map-get($ts-headbar-height, small))/5.5;
			}

			.logo {
				height: 100%;
				display: inline-block;
				float: none;
				padding-left: 0;
				padding-right: 0;

				img {
					width: $ts-headbar-logo-width;
					max-width: 100%;
				}

				@if ( $ts-headbar-logo-mobile-height != auto ) {
					img {
						height: $ts-headbar-logo-mobile-height;
						max-height: inherit;
						width: auto;
					}
				}
			}// .logo
		}// .middle-side

		.left-side {
			@include grid-col;
			@include grid-col-size(4);
		}//.left-side

		.right-side {
			@include grid-col;
			@include grid-col-size(4);
			text-align: right;

			.mobile-search-icon {
				margin-right: .5rem;
				opacity: .7;

				&.is-open {
					opacity: $ts-mobile-search-button-active-opacity;
				}
			}

			.minicart-wrapper {
				text-align: right;
				padding: 0;
				height: map-get($ts-headbar-height, small);
				line-height: map-get($ts-headbar-height, small);
				margin-top: 0;
				opacity: .7;

				&:after {
					@include breakpoint(medium down) {
						display: none;
					}
				}
			}// .minicart-wrapper
		}// .right-side
	} // .tab-bar


}// .header

.page-container {
	margin-top: 0;
	transition: margin-top .3s ease;

	.mobile-search-bar-open & {
		margin-top: 5.5rem;
	}
}
