footer.page-footer {

	.cookies {
		color: $ts-footer-cookies-color;
		text-align: $ts-footer-cookies-text-align;
		font-size: $ts-footer-cookies-font-size;
		font-family: $ts-footer-font-family;

		p {
			font-size: $ts-footer-cookies-font-size;
			margin: 0;

			@if ($ts-footer-cookies-icon-type != none) {
				&:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: $ts-footer-cookies-icon-size;
					height: $ts-footer-cookies-icon-size;
					margin-right: 5px;
					background: url("#{$ts-path-to-images}/icon-checkmark-circle.#{$ts-footer-cookies-icon-type}") no-repeat;
					background-size: $ts-footer-cookies-icon-bg-size auto;
				}
			}
		} // p

		a {
			color: $ts-footer-cookies-anchor-color;
			text-decoration: $ts-footer-cookies-anchor-text-decoration;

			&:hover,
			&:active {
				text-decoration: $ts-footer-cookies-anchor-text-decoration-hover;
			}
		} // a

		.cookies-magento {
			a {
				color: $ts-footer-cookies-magento-anchor-color;
			}
		}
	}// .footer-cookies
}