@media screen and (max-width: 767px) {
    .modal-popup.modal-slide {
        left: 0;

        .modal-header {
            padding-top: 0;
        }

        .action-close {
            position: relative;
            padding: 8px 25px;
        }

        .modal-content div {
            overflow-x: auto;
            width: max-content;
        }
    }
}
