//
//  Loaders
//  _____________________________________________
.loading-mask {
	@include loading-overlay;
	display: block;
	.loader,
	img,
	p,
	span {
		display: none;
	}
}

body > .loading-mask {
	@include loading-overlay(fixed, $ts-loader-overlay-z-index);
	.loader {
		display: none;
	}
}

.load.indicator {
	@include loading-overlay(fixed, $ts-loader-overlay-z-index);
	span,
	div {
		display: none;
	}
}

._block-content-loading,
[data-bind="blockLoader: isLoading"] {
	position: relative;
}