.magellan-bar {
	&.is-stuck {
		width: 100%;
		max-width: inherit !important;
		left: 0 !important;
		padding: $ts-brands-navbar-padding;
		background: $ts-brands-navbar-bg;
		box-shadow: $ts-brands-navbar-box-shadow;

		.magellan-col {
			@include grid-col;
		}

		.magellan-container {
			margin-bottom: 0;
		}
	}
	.magellan-row {
		@include grid-row;
	}
}// .magellan-bar

.magellan-container {
	margin-bottom: 1.5rem;

	@include breakpoint(xlarge) {
		margin-bottom: 3.5rem;
	}

	.letter-nav-label {
		display: none;
		vertical-align: middle;
		line-height: 1;
		font-weight: $ts-brands-navbar-label-font-weight;
		@include header-font-family($ts-brands-navbar-label-font-family);
		font-size: $ts-brands-navbar-label-font-size;
		padding-right: 1rem;

		@include breakpoint(large) {
			display: inline-block;
		}
	}
	.letter-nav-tabs {
		@include lsn;
		display: inline-block;
		vertical-align: middle;
		font-weight: $ts-brands-navbar-alphabet-font-weight;
		@include header-font-family($ts-brands-navbar-alphabet-font-family);
		font-size: $ts-brands-navbar-alphabet-font-size;

		li {
			display: inline-block;
			margin-right: .2rem;
		}

		a {
			display: block;
			text-decoration: none;
			line-height: 1;
			color: $ts-brands-navbar-alphabet-color;
			border-bottom: 1px solid $ts-brands-navbar-alphabet-color;

			&:hover,
			&:active {
				border-bottom: 0;
				padding-bottom: 1px;
			}
		}
	}
}// .magellan-container

.brand-logo-list {
	@include lsn;
	text-align: center;
	margin-bottom: 1.5rem;

	@include breakpoint(xlarge) {
		margin-bottom: 5.5rem;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		width: 31%;
		padding: 0 1rem 1rem;

		@include breakpoint(large) {
			width: auto;
			padding: 0;
			margin: 0 1.75rem 1rem;
		}
	}
}// .brand-logo-list

.item-brands-wrapper {
	@include breakpoint(medium) {
		@include grid-row;
		@include grid-row-nest;
	}

	.item-brands-single {
		margin-bottom: 1rem;

		@include breakpoint(medium) {
			@include grid-col;
			@include grid-col-end;
			@include grid-col-size(6);
			margin-bottom: 1.5rem;

			&:nth-child(odd) {
				clear: left;
			}
		}
	}

	.item-brands {
		padding: $ts-brands-section-padding;
		background: $ts-brands-section-bg;

		ul {
			@include lsn;
		}

		.brand-letter {
			@include grid-col;
			@include grid-col-size(3);
			font-weight: $ts-brands-section-capital-letter-font-weight;
			line-height: 1;
			@include header-font-family($ts-brands-section-capital-letter-font-family);
			font-size: $ts-brands-section-capital-letter-font-size;
			color: $ts-brands-section-capital-letter-color;

			@include breakpoint(large) {
				@include grid-col-size(3);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(2);
			}
			@include breakpoint(xxlarge) {
				@include grid-col-size(1.5);
			}
		}

		.brand-list-contianer {
			@include grid-col;
			@include grid-col-size(9);
			@include get-gutter(0,'padding-left');
			padding-top: .25rem;

			a {
				color: $ts-brands-section-text-color;
			}

			@include breakpoint(large) {
				@include grid-col-size(9);
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(10);
			}
			@include breakpoint(xxlarge) {
				@include grid-col-size(10.5);
			}
		}

		.bare-list {
			@include grid-row;
			@include grid-row-nest;

			li {
				@include grid-col;
				@include grid-col-end;
				@include grid-col-size(6);
				@include get-gutter(0,'padding-right');
			}
		}
	}

}// .item-brands-wrapper
