//
// Customer/account/forgotpassword
//  ---------------------------------------------
.form.password.forget,
.form.password.reset {

	padding: map-get($ts-login-section-padding, small);
	background: $ts-login-section-bg;

	@include breakpoint(medium) {
		width: 75%;
	}

	@include breakpoint(xlarge) {
		width: 50%;
		padding: map-get($ts-login-section-padding, xlarge);
	}

	.field {
		margin-bottom: .25rem;

		@include breakpoint(xlarge) {
			margin-bottom: .75rem;
		}
	}// .field

	.label {
		@include label-colon;
	}

	.actions-toolbar {
		@include grid-row;
		@include grid-row-nest;

		div.primary {
			@include grid-col;

			.action {
				@include button-expand;
			}

			@include breakpoint(large) {
				@include grid-col-size(6);

				.action {
					width: auto;
					display: inline-block;
				}
			}
		}// div.primary

		.secondary {
			@include grid-col;
			text-align: center;
			margin-top: 1rem;

			.action {
				display: inline-block;
				vertical-align: middle;
				line-height: 1.2;
			}

			@include breakpoint(large) {
				@include grid-col-size(6);
				text-align: right;
				line-height: $input-select-height;
				margin-top: 0;
			}
		}// .secondary
	}// .actions-toolbar
}// .form.password.forget