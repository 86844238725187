.nav-toolbar-sorter-mobile {
	.sorter-title,
	.sorter-action {
		@include at;
	}
	ol,
	ul {
		@include lsn;
		border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

		.item {
			border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

			&:last-child {
				border-bottom: 0;
			}

			a {
				display: block;
				position: relative;
				padding: $ts-sidebar-mobile-category-padding;
				padding-left: $ts-mobile-sorting-left-indent;
				color: $ts-sidebar-mobile-category-color;
				font-size: $ts-sidebar-mobile-category-font-size;
				font-weight: $ts-sidebar-mobile-category-font-weight;
				font-family: $ts-sidebar-mobile-category-font-family;
				text-transform: $ts-sidebar-main-filter-text-transform;

				@if ( $ts-sidebar-mobile-category-image != false ) {
					background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
					background-size: $ts-sidebar-mobile-category-image-size;
				}
			}

			&.active {
				font-weight: $ts-sidebar-mobile-category-active-state;
				a {
					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						@if ( $ts-mobile-filter-image != false ) {
							width: $ts-mobile-filter-image-width;
							height: $ts-mobile-filter-image-height;
							background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
							background-size: 100% auto;
						}
					}
				}
			}
		}
	}
}// .nav-toolbar-sorter-mobile

.mobile-filter-btn {
	width: 43%;
	@include button-extended;
	@include button-style($secondary-color, $button-background-secondary-hover);
	margin-bottom: 0;
	margin-right: 2%;

	&.disabled {
		opacity: .15;
		cursor: not-allowed;
	}

	&.open-filter-modal {
		width: 55%;
	}
}// .mobile-filter-btn
