@if ($ts-primary-nav-home-url-icon-replace != hide) {
	.menu-item-home {
		overflow: hidden;
		a {
			position: relative;

			@include breakpoint(xxlarge) {
				margin-right: .3rem;
			}

			span {
				@include at;
				color: scale-color(white,$lightness: -3%);
			}

			&:before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				color: $anchor-color;
				width: $ts-primary-nav-home-url-icon-width;
				height: $ts-primary-nav-home-url-icon-height;
				background: url("#{$ts-path-to-images}/#{$ts-primary-nav-home-url-icon}.#{$ts-primary-nav-home-url-icon-type}") 0 0 no-repeat;
				background-size: $ts-primary-nav-home-url-icon-width auto;

				.is-stuck & {
					margin-top: -2px;
				}
			}
		} // a
	}// .menu-item-home
} @else {
	.menu-item-home {
		@include at;
	}
}
