.add-to-cart-popup {
	@include grid-row;
	@include disable-mouse-outline;
	display: none;

	.add-popup-container {
		position: absolute;

		@include breakpoint(medium down) {
			left: 0 !important;
			right: 0;
		}// @include breakpoint(medium down)

		@include breakpoint(large) {
			width: map-get($ts-addtocart-popup-width, large);
			.close-button {
				display: none;
			}
		}// @include breakpoint(large)
	}// .add-popup-container

	.add-popup-showcart {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		padding: $ts-header-cart-dropdown-showcart-icon-spacing;
		padding-right: 0 !important;

		@include breakpoint(large) {
			display: block;
		}
	}// .add-popup-showcart

	.add-popup-block {
		position: relative;
		padding: map-get($ts-addtocart-popup-padding, small);
		text-align: center;
		background: $white;

		@include breakpoint(large) {
			margin-top: ($ts-header-cart-dropdown-showcart-icon-height * 1.75);
			padding: map-get($ts-addtocart-popup-padding, large);
		}
	}// .add-popup-block

	.add-popup-message {
		@include header-font-family($ts-addtocart-popup-mssg-font-family);
		font-weight: $ts-addtocart-popup-mssg-font-weight;
		line-height: $ts-addtocart-popup-mssg-line-height;
		letter-spacing: $ts-addtocart-popup-mssg-letter-spacing;
		font-size: map-get($ts-addtocart-popup-mssg-font-size,small);
		margin-bottom: $ts-addtocart-popup-mssg-bottom-indent;

		a {
			display: inline-block;
			text-decoration: none;
			max-width: $ts-addtocart-popup-mssg-img-maxwidth;
		}

		span {
			display: block;
			margin-top: 1.5rem;
		}

		@include breakpoint(large) {
			span {
				display: inline-block;
				margin-top: 0;
				margin-left: 1rem;
			}
		}// @include breakpoint(large)

		@include breakpoint(xxlarge) {
			font-size: map-get($ts-addtocart-popup-mssg-font-size,xxlarge);
			span {
				margin-left: 2.5rem;
			}
		}// @include breakpoint(xxlarge)
	}// .add-popup-message

	.add-popup-product-image {
		position: relative;
		@if $ts-product-photo-overlay {
			@extend %product-layer-overlay;
		}
	}

	.add-popup-actions {
		overflow: hidden;

		.button {
			@include button-expand;
			margin-bottom: .5rem;
		}

		.btn-viewcart {
			@include button-style($primary-color);
		}

		@include breakpoint(large) {
			padding: $ts-addtocart-popup-footer-padding;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: $ts-addtocart-popup-footer-bg;

			.button {
				display: inline-block;
				width: auto;
				min-width: 45%;
				padding: 0.85em 2em 0.9em;
				margin-bottom: 0;
				position: relative;

				&:after {
					display: block;
					content: '';
					width: $ts-addtocart-popup-footer-btn-arrow-width;
					height: $ts-addtocart-popup-footer-btn-arrow-height;
					position: absolute;
					top: 50%;
					right: 1rem;
					transform: translateY(-50%);
					background-image: url("#{$ts-path-to-images}/#{$ts-addtocart-popup-footer-btn-arrow}.#{$ts-addtocart-popup-footer-btn-arrow-type}");
					background-repeat: no-repeat;
					background-size: 100% auto;
				}

				&.btn-continue {
					&:after {
						opacity: .4;
						right: auto;
						left: 1rem;
						transform: translateY(-50%) rotate(180deg);
					}
				}
			}

			.btn-viewcart {
				float: right;
			}

			.btn-continue {
				float: left;
			}
		}// @include breakpoint(large)

		@include breakpoint(xxlarge) {
			.button {
				min-width: rem-calc(220);
			}
		}// @include breakpoint(xxlarge)
	}// .add-popup-actions

	.block.grid {
		margin:0;
		.block-title {
			strong {
				font-size: ($ts-pro-block-title-font-size*.8);
			}
		}
	}// .block.grid

	.product-items {
		@include breakpoint(large) {
			display: flex;
		}

		.product-item {
			@include grid-col-size(6);
			margin: 1rem 0;

			@include breakpoint(large) {
				@include grid-col-size(4);
				margin: 0;

				&:last-child {
					display: none;
				}
			}

			&:before {
				top:0;
				bottom: 0;
			}

			.product-item-photo {
				margin-bottom: .5rem;

				.product-image-wrapper {
					display: block;
					height: rem-calc(120);
					line-height: rem-calc(120);

					img {
						max-height: 100%;
					}
				}
			}

			.product-item-name {
				font-weight: 400;
				line-height: 1.2;
				font-size: 1rem;
				margin-bottom: .5rem;
			}
		}// .product-item

		.product-item-details {
			padding-left: .75rem;
			padding-right: .75rem;
		}// .product-item-details

		.product-price-stock {
			margin-bottom: 0;
		}

		.product-price-stock .stock,
		.product-item-actions {
			@include at;
		}
	}// .product-items
}

@if ($ts-addtocart-popup-view == 'default') {
	.reveal-overlay {
		background: transparent;

		.add-popup-container {
			width: 100%;
			@include breakpoint(large) {
				width: rem-calc(395);
			}
			.add-popup-actions {
				display: none;
			}
		}

		.add-popup-block {
			padding: 0;
			background: transparent;
		}

		.add-popup-message {
			margin-bottom: 0;
			font-size: 0;
			text-align: left;
			padding: rem-calc(12);
			position: relative;
			box-shadow: 0 0 25px rgba($black, .15);
			background: $white;

			&:after {
				display: none;
			    content: '';
			    width: 0;
			    height: 0;
			    border-style: solid;
			    border-width: 0 1rem 1rem 1rem;
			    border-color: transparent transparent $white transparent;
			    position: absolute;
			    bottom: 100%;
			    right: 2.75rem;
			    transform: translateX(-50%);

			    @if ($ts-header-cart-dropdown-showcart-type == 'image-only') {
			    	right: 0;
			    	transform: translateX(0);
			    }

			    body.is-sticky & {
			    	right: 0;
			    	transform: translateX(0);
			    }

			    @include breakpoint(large) {
			    	display: block;
			    }
			}
			.add-popup-product-image {
				display: inline-block;
				vertical-align: middle;
				width: 33%;
				max-width: inherit;
				text-align: center;
				height: rem-calc(150);

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
					margin-right: -.3em;
				}

				img {
					max-width: 98%;
					display: inline-block;
					vertical-align: middle;
				}
			}
			> .txt {
				width: 67%;
				display: inline-block;
				vertical-align: middle;
				padding-left: 1rem;
				padding-right: 1rem;
				text-transform: uppercase;
				font-weight: 400;
				line-height: 1.3;
				margin: 0;
				text-align: center;
				font-size: rem-calc(16);

				a {
					display: inline;
					text-decoration: underline;

					&:hover,
					&:active {
						text-decoration: none;
					}
				}
			}
		}
	}// .reveal-overlay
}


