.cart-summary {
    .progress-text {
        color: $black;
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: rem-calc(18);
        margin-bottom: rem-calc(10);
        padding: 0;
        text-align: center;
        width: 100%;
        @include breakpoint(large) {
            font-size: rem-calc(18);
        }
    }

    .progress-graph {
        border-radius: rem-calc(50);
        width: 100%;
        margin-bottom: rem-calc(15);
        @include breakpoint(medium up) {
            margin-bottom: rem-calc(20);
        }

        .progress-bar {
            border-radius: rem-calc(50);
        }
    }

    .cart-totals {
        padding-top: 0;
    }

    table.totals {
        tbody {
            display: flex;
            flex-wrap: wrap;
        }

        .totals.sub,
        .totals-tax {
            display: none;
        }

        .grand.totals,
        .saved-amount,
        .totals.shipping {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .grand.totals {
            border: 0;
            order: 1;

            th,
            td {
                font-size: rem-calc(16);
                font-weight: 400;
                line-height: rem-calc(23);
                padding: 0;
                width: auto;

                strong {
                    font-weight: 500;
                    font-size: rem-calc(18);
                    @include breakpoint(large) {
                        font-size: rem-calc(24);
                    }
                }
            }

            th {
                padding-right: rem-calc(6);
            }

            td {
                text-align: left;
                white-space: nowrap;
            }

            .include-tax {
                margin-left: rem-calc(5);
            }
        }

        .saved-amount {
            margin-top: rem-calc(4);
            order: 2;

            th,
            td {
                color: map-get($foundation-palette, alert);
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(23);
                padding: 0;
                width: auto;

                span {
                    color: inherit;
                    vertical-align: middle;
                }
            }

            th {
                padding-right: rem-calc(5);
            }
        }

        .totals.shipping {
            order: 3;

            th,
            td {
                font-size: rem-calc(12);
                line-height: rem-calc(15);
                padding: rem-calc(3 0 0 0);
                width: auto;

                span {
                    vertical-align: middle;
                }
            }

            th {
                padding-right: rem-calc(4);
            }
        }
    }
}

.checkout-klarna-index .page-main {
    margin: 0;
    max-width: 100%;

    .column.main {
        padding: 0;
    }
}

.checkout-top-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .hr-cart-box {
        max-width: 91.25rem;
        margin: 0 auto rem-calc(55) auto;
        @include breakpoint(large) {
            margin: 0 auto rem-calc(80) auto;
        }
    }

    .cart {
        &-container {
            margin-left: 0;
            margin-right: 0;
            order: 1;
            width: 100%;
            display: flex;
            flex-direction: column;

            .cart-summary {
                float: none;
            }

            .page-title-wrapper, .cart-form, #gift-options-cart {
                margin: 0 auto;
                max-width: 86.25rem;
                @include breakpoint(large) {
                    width: 66%;
                }

                @include breakpoint(xlarge) {
                    width: 60%;
                }
            }
        }

        &-form {
            width: 100%;
            @include breakpoint(large) {
                padding-left: 0;
            }
        }

        &-summary {
            max-width: rem-calc(500);
            margin-left: auto;
            margin-right: auto;
            @include breakpoint(large) {
                min-width: rem-calc(320);
            }
        }

        &-totals {
            padding: 0;
            width: 100%;
            margin: rem-calc(0 0 30);
            @include breakpoint(large up) {
                margin: rem-calc(0 0 50);
            }
        }
    }

    .table-wrapper {
        .totals {
            width: calc(100% - 10px);
            display: flex;
            align-items: center;

            &.totals--coupon,
            &.aw-giftcard-totals {
                @include breakpoint(large up) {
                    width: calc(100% + 20px);
                    margin-left: -20px;
                }
            }

            &.totals--coupon {
                @include breakpoint(small down) {
                    margin-bottom: rem-calc(3);
                }
            }

            &.grand {
                margin: rem-calc(15 0 0);
                @include breakpoint(medium up) {
                    margin: rem-calc(20 0 0);
                }
            }
        }

        .mark {
            width: calc(100% - 55px);
            display: inline-block;
            padding: 0;

            form {
                position: relative;
            }

            .title {
                font-size: rem-calc(14);
                font-weight: 500;
            }

            .action-delete {
                position: absolute;
                right: -75px;
                top: rem-calc(4);
                @include breakpoint(medium up) {
                    right: -85px;
                }

                .delete-icon {
                    background-size: rem-calc(10);
                    height: rem-calc(13);
                    width: rem-calc(10);
                    display: block;
                    background-image: url("#{$ts-path-to-images}/icon-delete.svg");
                    background-repeat: no-repeat;
                    background-position: center;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .amount {
            display: inline-block;
            width: rem-calc(55);
            padding: 0;
            font-weight: 500;
        }
    }

    .hr-cart-box {
        order: 2;
        width: 100%;
        padding: 30px 0;
        min-height: 410px;

        @media screen and (min-width: 1023px) {
            width: 80%;
            min-height: 450px;
        }

        @media screen and (min-width: 1200px) {
            width: 65%;
            min-height: 460px;
        }

        @media screen and (max-width: 767px) {
            padding: 20px 0 0 16px;
        }

        .title {
            font-size: rem-calc(18);
            font-weight: normal;
        }
    }
}

.checkout-custom-header {
    background-color: #046C66;

    .checkout-custom-header-content {
        display: flex;
        height: rem-calc(71);
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        max-width: 91.25rem;
        margin-right: auto;
        margin-left: auto;

        @include breakpoint(large) {
            justify-content: flex-start;
        }
    }

    .checkout-return-container {
        @include breakpoint(large) {
            position: absolute;
        }

        a {
            font-size: 16px;
            color: white;
            background-image: url("#{$ts-path-to-images}/checkout-icon-arrow.svg");
            background-repeat: no-repeat;
            background-position-y: center;
            padding-left: 24px;
        }
    }

    .checkout-shop-name-container {
        @include breakpoint(large) {
            text-align: center;
            width: 100%;
        }

        .logo {
            img {
                width: rem-calc(155);
                height: rem-calc(23);

                @include breakpoint(large) {
                    width: rem-calc(268);
                    height: rem-calc(36);
                }
            }
        }
    }
}

.checkout-banner-container {
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: rem-calc(150);
    justify-content: space-evenly;
    padding: 10px 20px;
    margin-bottom: 2.6rem;

    .checkout-banner-block {
        max-width: 940px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        @include breakpoint(medium down) {
            flex-direction: column;
            gap: 20px;
        }
    }

    .banner-text {
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;

        @include breakpoint(large) {
            font-size: 20px;
            line-height: 25px;
        }

        .total {
            color: #046C66;
        }
    }

    .primary {
        height: rem-calc(40);
        display: flex;
        align-items: center;

        span {
            font-size: 16px;
            font-weight: 400;
        }
    }

    @include breakpoint(large) {
        flex-direction: row;
        justify-content: space-around;
        height: rem-calc(115);
    }
}

.checkout-tier-price {
    .line-through {
        text-decoration: line-through;
        font-size: 14px;
        font-weight: 400;
    }

    .normal-price,
    .member-price,
    .special-price {
        margin-bottom: 5px;
        text-wrap: nowrap;
    }

    .normal-price:not(.line-through),
    .member-price:not(.line-through),
    .special-price:not(.line-through){
        color: #F7465B;
        font-weight: 700;
        font-size: 20px;
    }
}
