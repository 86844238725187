//
//  Foundation Button
//  _____________________________________________
@mixin button-extended {
	@include button;
	@include header-font-family($button-font-family);
	font-weight: $button-weight;
	text-transform: $button-text-transform;
	margin-bottom: 0;

	&:active {
		color: scale-color($button-color, $alpha: -20%) !important;
	}
}

@mixin button-hollow-style-extend($bcolor: $primary-color, $color: $primary-color) {
	$color-hover: scale-color($color, $lightness: -50%);
	$bcolor-hover: scale-color($bcolor, $lightness: -50%);

	border: 1px solid $bcolor;
	color: $color;
	box-shadow: inset 0 0 0 1px $bcolor;
	transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out, color 0.25s ease-out;

	&:hover, &:focus {
		border-color: $bcolor-hover;
		color: $color-hover;
		box-shadow: inset 0 0 0 1px $bcolor-hover;
	}
}

.button {
	@include button-style($secondary-color, $button-background-secondary-hover);
	font-family: $button-font-family;
	font-weight: $button-weight;

	&.primary {
		@include button-style($primary-color);

		@if $button-gradient {
			@include linear-gradient(transparent, $button-shadow-color);
		}

		&.hover,
		&.active {
			background-color: $button-background-hover !important;
		}
	}// .primary

	&.secondary {
		@include button-style($secondary-color, $button-background-secondary-hover,$button-color);

		@if $button-gradient {
			@include linear-gradient($secondary-color, $button-shadow-color);
		}

		&.hover,
		&.active {
			background-color: darken(map-get($foundation-palette, secondary), 18.8235) !important;
		}
	}// .secondary

	&.active {
		color: scale-color($button-color, $alpha: -20%) !important;
	}

	&.light,
	&.hollow {
		@include button-hollow;
		@include button-hollow-style-extend(lighten($secondary-color, 72.9412), $secondary-color);

		&.arrow {
			background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray.svg");
		}
	}// .hollow

	&.hollow.primary,
	&.primary-light {
		@include button-hollow;
		@include button-hollow-style-extend(lighten($primary-color, 45), $primary-color);

		&.arrow {
			background-image: url("#{$ts-path-to-images}/icon-arrow-next-light-blue.svg");
		}
	}// .primary.hollow

	&.expand {
		@include button-expand;
	}

	&.arrow {
		text-align: left;
		padding-right: 2em;
		background-color: transparent;
		background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg");
		background-repeat: no-repeat;
		background-position: calc(100% - 13px) 50%;
		background-size: 8px auto;
	}// .arrow
}

button {
	&.action {
		@include button-extended;

		@if $button-gradient {
			@include linear-gradient(transparent, $button-shadow-color);
		}
	}
}

a.button {
	text-decoration: none;
}

a.action {
  &.create,
  &.add {
  		@include button-extended;
		text-decoration: none;

		@if $button-gradient {
			@include linear-gradient(transparent, $button-shadow-color);
		}
	}
}

// Back button
.action {
	&.back {
		display: inline-block;
		margin-top: 1rem;
		text-decoration: none;
		background: none;

		&:before {
			display: inline-block;
			vertical-align: middle;
			content: '';
			width: $ts-goback-button-icon-width;
			height: $ts-goback-button-icon-height;
			background-image: url("#{$ts-path-to-images}/#{$ts-goback-button-icon}.#{$ts-goback-button-icon-type}");
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: rem-calc(5);
			@if $ts-goback-button-icon-rotate {
				transform: rotate(180deg);
			}
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		&:hover {
			span {
				text-decoration: underline;
			}

			&:before {
				text-decoration: none;
			}
		}
	}
}

// Button link
.button-link {
	display: inline-block;
	vertical-align: middle;
	font-family: $body-font-family;
	font-size: $body-font-size;
	color: $anchor-color;
	padding: 0;
	line-height: 1.2;
	border:0;
	background-color: transparent;
	border-bottom: 1px solid $ts-main-border-color;
	cursor: pointer;

	&:disabled {
		background-color: transparent;
		opacity: .5;
	}
}
