.customer-account-logoutsuccess,
.checkout-onepage-success {
	.page-main {
        text-align: center;
        padding: 1rem 0;

        @include breakpoint(xlarge) {
            padding: 5rem 0;
        }

        @include breakpoint(xxxlarge) {
            padding: 6.7rem 0;
        }

        .page-title-wrapper {
            padding-bottom: 0;
            margin-bottom: 1rem;

            &:before {
                display: none;
            }
        }

        .action {
            text-decoration: none;
            &.primary {
                @include button-extended;
                @include button-style($primary-color);
            }
            &.secondary {
                @include button-extended;
                @include button-style($secondary-color, $button-background-secondary-hover,$button-color);
            }
        }

        .checkout-success,
        #registration {
            margin-bottom: 1rem;
        }

        h1 {
            text-align: center;
            font-size: rem-calc(19);
            @include header-font-family($header-font-family);

            @include breakpoint(xlarge) {
                font-size: rem-calc(25);
            }

            @include breakpoint(xxxlarge) {
                font-size: rem-calc(40);
            }
        }

        .note-msg {
            text-align: center;
            font-size: rem-calc(15);

            @include breakpoint(xlarge) {
                font-size: rem-calc(17);
            }
        }
    }
} //.s-no-search-result

.cart-empty {
    text-align: center;
    padding: 1rem 0;

    @include breakpoint(xlarge) {
        padding: 5rem 0;
    }

    @include breakpoint(xxxlarge) {
        padding: 6.7rem 0;
    }

    h2 {
        text-align: center;
        font-size: rem-calc(19);
        @include header-font-family($header-font-family);

        @include breakpoint(xlarge) {
            font-size: rem-calc(25);
        }

        @include breakpoint(xxxlarge) {
            font-size: rem-calc(40);
        }
    }

    p {
        text-align: center;
        font-size: rem-calc(15);

        @include breakpoint(xlarge) {
            font-size: rem-calc(17);
        }
    }
}// .cart-empty
