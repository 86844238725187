// 1. Global
// ---------
$global-width: rem-calc(1460);
$body-font-size : rem-calc(17);
$body-font-family: ('cerebri-sans', sans-serif);
$global-weight-normal: normal;
$paragraph-font-weight: normal;

$foundation-palette: (
  primary: #046C66,
  secondary: #F45D5D,
  success: #07AAA1,
  warning: #F7D98A,
  alert: #F7465B
);
$primary-color : get-color(primary);
$alt-color : get-color(secondary);
$black: #222222;
$light-black: #353539;
$box-color: #F9F7F6;
$panel-color: #f1f0eb;
$light-background: #F5F4F3;
$body-background: #fff;
$body-font-color: #444;
$body-font-color-alt: #555555;

// 3. The Grid
// -----------
$grid-row-width: $global-width;

// 4. Base Typography
// ------------------
$header-font-family: ('cerebri-sans', sans-serif);
$font-family-alt: ('noto-sans', sans-serif);

$header-color: $black;
$header-text-transform: none;
$header-font-weight: 400;

$header-styles: (
  small: (
    'h1': ('font-size': 24),
    'h2': ('font-size': 28),
    'h3': ('font-size': 18),
    'h4': ('font-size': 17),
    'h5': ('font-size': 14),
    'h6': ('font-size': 12),
  ),
  medium: (
    'h1': ('font-size': 36),
    'h2': ('font-size': 28),
    'h3': ('font-size': 18),
    'h4': ('font-size': 17),
    'h5': ('font-size': 14),
    'h6': ('font-size': 12),
  ),
  xlarge: (
    'h1': ('font-size': 50),
    'h2': ('font-size': 40),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 17),
  ),
);
$anchor-color: $primary-color;
//$anchor-color-hover: inherit;
$paragraph-lineheight: 1.59;
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: none;

// 7. Accordion
// ------------
$accordion-background: $body-background;
$accordion-plusminus: false;
$accordion-item-color: $body-font-color;
$accordion-title-font-size: rem-calc(18);
$accordion-item-padding: 21px 25px 21px 60px;
$accordion-content-padding: 20px 40px 20px 58px;

// 10. Breadcrumbs
// ---------------
$breadcrumbs-margin: 0;
$breadcrumbs-item-font-size: rem-calc(12);
$breadcrumbs-font-weight: normal;
$breadcrumbs-item-color: rgba($black,.5);
$breadcrumbs-item-color-current: rgba($black,.5);
$breadcrumbs-item-color-disabled: rgba($black,.7);
$breadcrumbs-item-slash-color: rgba($black,.5);
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-margin: 0.5rem;

// 11. Button
// ----------
$button-weight: 600;
$button-margin: 0;
$button-padding: 1.25em 2.9em;
$button-sizes: (
  tiny: 10px,
  small: 12px,
  default: 14px,
  xlarge: 18px,
);
$button-palette: map-remove($foundation-palette, success, alert, warning);
$button-background: map-get($button-palette, primary);
$button-background-hover: darken(map-get($button-palette, primary),15);
$button-background-active: $button-background;
$button-background-secondary : $white;
$button-background-secondary-hover: darken($button-background-secondary,10);
$button-background-secondary-active: $button-background-secondary-hover;
$button-color: $white;
$button-color-alt: $secondary-color;
$button-radius: 30px;
$button-opacity-disabled: .3;

// // 19. Forms
// // ---------
$input-font-family: $body-font-family;
$input-border: 1px solid lighten($black,70);
$input-placeholder-color: scale-color($black, $alpha: -50%);

$select-radius: 50px;
$input-radius: 50px;
$input-font-size: rem-calc(15);
$form-spacing: rem-calc(25);
$input-select-height: ($input-font-size + $form-spacing);
$input-padding: rem-calc(8) rem-calc(16);

// 22. Menu
// --------
$menu-margin:  0 0 0 -.5rem;
$menu-item-padding-hz  : ( medium : ( padding-left: rem-calc(15), padding-right: rem-calc(15)),
                           xlarge  : ( padding-left: rem-calc(15), padding-right: rem-calc(15)),
                           xxlarge : ( padding-left: rem-calc(16), padding-right: rem-calc(16)),
                           xxxlarge : ( padding-left: rem-calc(16), padding-right: rem-calc(16)));
$menu-item-padding-vt  : ( medium : ( padding-top: rem-calc(11), padding-bottom: rem-calc(11)),
                           xlarge  : ( padding-top: rem-calc(11), padding-bottom: rem-calc(11)),
                           xxlarge : ( padding-top: rem-calc(11), padding-bottom: rem-calc(11)),
                           xxxlarge : ( padding-top: rem-calc(11), padding-bottom: rem-calc(11)));
$menu-item-color-active: $white;
$menu-item-background-active: transparent;

// 24. Off-canvas
// --------------
$offcanvas-shadow: none;
$offcanvas-exit-background: rgba($black, 0.2);

// 27. Progress Bar
// ----------------
$progress-height: rem-calc(8);
$progress-padding: 0;
$progress-background: #7E7E7E;
$progress-border: none;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: 10px;


//MMenu
$mm_closeWidth: 32px;
$mm_closeHeight: 32px;
$mm_closeIconWidth: 12px;
$mm_closeIconPosition: center;
$mm_closeTop: 20px;
$mm_closeRight: 15px;
$mm_closeIcon: 'icon-cross-green';

$mm_backgroundColor: $white;

$mm_panelTitleColor: $primary-color;
$mm_panelTitleSize: 26px;
$mm_panelPadding: 25px;

$mm_navbarSize: 72px;

$mm_textColor: $primary-color;
$mm_LinksMenu_titleColor: $primary-color;
$mm_LinksMenu_inner-spacing: 0 25px;
$mm_LinksMenu_itemColor: $body-font-color-alt;
$mm_LinksMenu_itemFontSize: rem-calc(14);

$mm_listitemFontSize: rem-calc(17);
$mm_dimmedTextColor: $primary-color;

$mm_arrowIndent: 2px;
$mm_arrowOpacity: 1;
$mm_arrowWidth: 7px;
$mm_arrowHeight: 7px;

$mm_arrowPrevWidth: 8px;
$mm_arrowPrevHeight: 8px;

$mm_borderColor: rgba(34, 34, 34, 0.1);
