.no-search-results {
    @include grid-col;
    font-size: 1rem;
}// .no-search-results

.products-grid {
    .no-search-results {
        padding-left: 0;
        padding-right: 0;
    }
}// .products-grid
