//
// Customer/account/login
//  ---------------------------------------------
.customer-account-login {
	.page-title-wrapper {
		@if not ($ts-login-show-page-title) {
			@include at;
		}
	}
}// .customer-account-login

.login-container {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.block-customer-login {
		@include grid-col;
		margin-bottom: 2rem;

		@include breakpoint(medium) {
			@include grid-col-size(6);
		}
	}// .block-customer-login

	.block-new-customer,
	.block-new-company {
		@include grid-col;

		@include breakpoint(medium) {
			@include grid-col-size(6);
		}

		.action {
			@include button-expand;

			@include breakpoint(large) {
				width: auto;
				display: inline-block;
			}
		}
		+ .block-new-company {
			margin-top: 2rem;
			padding-top: 1.5rem;
			@include border-line('top',0);
		}
	}// .block-new-customer

	.block-title {
		margin-bottom: 1rem;
		line-height: 1;
		text-align: center;
		font-weight: $ts-login-main-title-font-weight;
		@include header-font-family($ts-login-main-title-font-family);
		font-style: $header-font-style;
		text-transform: $ts-login-main-title-text-transform;

		@each $size, $headers in $header-styles {
			@include breakpoint($size) {
				font-size: rem-calc(map-deep-get($headers, 'h1', font-size));
			}
		}
		@include breakpoint(xxlarge) {
			margin-bottom: 1.5rem;
		}

		strong {
			line-height: inherit;
			font-weight: inherit;
		}
	}// .block-title

	.note {
		@include at;
	}

}// .login-container

.form-login {
	padding: map-get($ts-login-section-padding, small);
	background: $ts-login-section-bg;
	box-shadow: $ts-login-section-box-shadow;

	@include breakpoint(xlarge) {
		padding: map-get($ts-login-section-padding, xlarge);
	}

	.fieldset {
		@include grid-row;
		@include grid-row-nest;
	}

	.field {
		@include grid-col;
		margin-bottom: .25rem;

		&.note {
			margin-bottom: 0;
		}
		&:not(.choice) {
			.label {
				@include label-colon;
			}
		}
		@include breakpoint(xlarge) {
			@include grid-col-size(6);
			margin-bottom: .75rem;
		}
	}// .field


	.actions-toolbar {
		clear: both;
		@include clearfix;

		div.primary {
			@include grid-col;

			.action {
				@include button-expand;
			}

			@include breakpoint(large) {
				@include grid-col-size(6);

				.action {
					width: auto;
					display: inline-block;
				}
			}
		}// div.primary

		div.secondary {
			@include grid-col;
			text-align: center;
			margin-top: 1rem;

			.action {
				display: inline-block;
				vertical-align: middle;
				line-height: 1.2;
			}

			@include breakpoint(large) {
				@include grid-col-size(6);
				text-align: right;
				line-height: $input-select-height;
				margin-top: 0;
			}
		}// div.secondary
	}// .actions-toolbar

}// .form-login