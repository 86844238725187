// Rewrite Algolia Search styles, had to use ids like in Algolia to prevent change of default templates and styles
#search_mini_form {
	label {
		color: $white; // for WCAG
	}
}

#algolia-searchbox {
	position: relative;

	label,
	.clear-cross {
		display: none;
	}

	input.algolia-search-input {
		@extend %search-input-desktop-control;
	}// .algolia-search-input

	#algolia-glass,
	.ais-search-box--magnifier-wrapper {
		position: absolute;
		top: 0;
		right: ($ts-header-search-input-icon-right-indent/2);
		width: ($ts-header-search-input-indent - $ts-header-search-input-icon-right-indent);
		height: $ts-header-search-input-height;
		padding: $ts-header-search-input-icon-padding-indent;
		@extend .action.search;
		background-position: 50%;

		&:hover,
		&:focus {
			background-color: transparent;
		}

		svg {
			display: none;
		}
	}// #algolia-glass

	.ais-search-box--magnifier-wrapper {
		top: -1px;
	}

	span.clear-query-autocomplete,
	.ais-search-box--reset-wrapper {
		height: 100%;
		top: 0;
		right: 0;
		width: 3rem;
		background-size: 1rem auto;
		z-index: 1;

		svg {
			display: none;
		}
	}// span.clear-query-autocomplete

	.ais-search-box--reset-wrapper {
		@extend .clear-cross;
		top: -1px;
	}
}