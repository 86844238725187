@import "../../two/styles/settings.scss";
@import "fonts";
@import "settings";
@import "../../two/styles/app.scss";
@import "../../two/styles/theme-settings.scss";
@import "theme-settings";
@include foundation-accordion;
@import "../../two/styles/imports.scss";

@import "imports";
@import "golfshopen";
@import "lipscore";
@import "slick";
@import "slick-theme";
@import "similar-products";
@import "product-page";
@import "bundle";
@import "modal";
@import "catalog-search";

.point-checker-wrapper{
    margin: 2.25rem 0 1rem;
}

#cancel-membership{
    text-decoration: underline;
    color: #f7455b;
}

.cancel-customerclub-wrapper .actions{
    display: none;
}
.consent-wrapper ul li{
    background-image: url(../images/icon-usp.svg);
    background-repeat: no-repeat;
    background-position: 0 10px;
}

@media screen and (max-width: 767px) {
    .header-hide-only-on-mobile {
        display: none !important; } }

.points-wrapper .points{
    font-weight: bold;
    font-size: 25px;
}

.cancel-customerclub-wrapper .actions{
    max-width: 500px;
    margin-top: 20px;
}

.cancel-customerclub-wrapper .actions .cancel.secondary{
    margin-left: 50px;
}

#cancel-membership{
    cursor: pointer;
}
.rewart-total-wrap{
    position: relative;
}

.checkout-top-container .table-wrapper .totals.rewardpoints{
    width: calc(100% + 20px);
    margin-left: -20px;
}
.club-points{
    padding-left: 15px;
}
#use-poits-form .field .points{
    font-size: 1.125rem;
    font-weight: 700;
    color: #222222;
    float: right;
}

.club-points .red {
    color: #F7465B;
}
.club-points p.text{
    width: 80%;
    margin: 10px auto;
}

.club-points p.text a{
    color: #222;
}

.customer-account-create .customer-dob .v-middle{
    background-color: #222;
    -webkit-mask: url(../images/icon-calendar.svg) no-repeat 50% 50%;
    mask: url(../images/icon-calendar.svg) no-repeat 50% 50%;
}

body .breadcrumbs li{
    text-transform: capitalize;
}

.accordion-title::before{
    display: none;
}

table.totals tbody tr:nth-child(even){
    background: #fff;
}

.quantity-value-number input.qty {
    width: 30px;
    background: none;
    border: 0;
    text-align: center;
    padding: 0;
    padding-top: 6px;
    -moz-appearance: textfield;
}

.quantity-value-number input.qty::-webkit-outer-spin-button,
.quantity-value-number input.qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.stock.available.config{
    display: none !important;
}


.checkout-klarna-index .product-addtocart-quantity-control-desc {
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile-only figure[data-appearance=full-width]{
        margin: 0;
    }
}

body .header .navigation ul li, .header .navigation .ui-menu li{
    order: 1;
}
body .header .navigation ul li, .header .navigation li.ambrands-menu-item{
    order: 3;
}
body .header .navigation ul li, .header .navigation li.giftcard-menu-item{
    order: 2;
}

body .mm-menu .mm-listview{
    display: flex;
    flex-direction: column;
}

body .mm-menu .mm-listview li{
    order: 1;
}

body .mm-menu .mm-listview li.ambrands-menu-item{
    order: 3;
}
body .mm-menu .mm-listview li.giftcard-menu-item {
    order: 2;
}

@media only screen and (max-width: 768px) {
    .mobile-only figure[data-appearance=full-width]{
        margin: 0;
    }
}
