.product-items,
.ais-infinite-hits {

    .product-item,
    .ais-hits--item,
    .ais-infinite-hits--item {

        .price-box {
            .price-from,
            .price-to {
                > .price-container {
                    font-size: map-get($ts-product-item-product-price-font-size, small);
                    @include header-font-family($ts-product-item-product-price-font-family);
                    font-weight: $ts-product-item-product-price-font-weight;
                    color: $ts-product-item-product-price-font-color;
                    line-height: 1;

                    @include breakpoint(xlarge) {
                        font-size: map-get($ts-product-item-product-price-font-size, xlarge);
                    }

                    @include breakpoint(xxlarge) {
                        font-size: map-get($ts-product-item-product-price-font-size, xxlarge);
                    }
                }
            }//.price-from
        }// .price-box
    }// .product-item
}// .product-items


