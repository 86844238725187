//
// Customer/sales/guest/form/
//  ---------------------------------------------
.form-orders-search {
	padding: map-get($ts-login-section-padding, small);
	background: $ts-login-section-bg;
	margin-bottom: 2rem;

	@include breakpoint(xlarge) {
		width: 75%;
		padding: map-get($ts-login-section-padding, xlarge);
	}

	@include breakpoint(xxlarge) {
		width: 50%;
	}

	legend {
		@include at;
	}

	br {
		display: none;
	}

	.fieldset {
		@include grid-row;
		@include grid-row-nest;
	}

	.field {
		@include grid-col;
		margin-bottom: .25rem;

		&.note {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			@include grid-col-size(6);
			margin-bottom: .75rem;
		}
	}// .field

	.label {
		@include label-colon;
	}


	.action {
		@include button-expand;

		@include breakpoint(large) {
			width: auto;
			display: inline-block;
		}
	}

}