footer.page-footer {
	overflow: hidden;
	position: relative;
	font-size: ($ts-footer-font-size*.9);

	@include breakpoint(medium) {
		font-size: $ts-footer-font-size;
	}

	@if $ts-footer-bottom-border {
		&:after {
			display: block;
			visibility: visible;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $ts-footer-bottom-border-height;
			background: $ts-footer-bottom-border-bg;
		}
	}

	@if $ts-footer-bg-has-image {
		transition: background-image .3s ease, background-position .3s ease;

		@if ( $ts-footer-bg-custom-set != true ) {
			@if ( $ts-footer-bg-type != none ) {
				background:$ts-footer-bg-color url("#{$ts-path-to-images}/#{map-get($ts-footer-bg-src,small)}.#{map-get($ts-footer-bg-type,small)}") map-get($ts-footer-bg-position, small) no-repeat;
				background-size: map-get($ts-footer-bg-size,small);

				@include breakpoint(xlarge) {
					background:$ts-footer-bg-color url("#{$ts-path-to-images}/#{map-get($ts-footer-bg-src,xlarge)}.#{map-get($ts-footer-bg-type,xlarge)}") map-get($ts-footer-bg-position, xlarge) no-repeat;
					background-size: map-get($ts-footer-bg-size,xlarge);
				}
			} @else {
				background-color: $ts-footer-bg-color;
			}
		} @else {
			background:$ts-footer-bg-color url("#{$ts-path-to-images}/#{map-get($ts-footer-bg-src,small)}.#{map-get($ts-footer-bg-type,small)}") map-get($ts-footer-bg-position, small) no-repeat;
			background-size: map-get($ts-footer-bg-size,small);

			@media screen and (min-width: 1580px) {
				background:$ts-footer-bg-color url("#{$ts-path-to-images}/#{map-get($ts-footer-bg-src,xlarge)}.#{map-get($ts-footer-bg-type,xlarge)}") map-get($ts-footer-bg-position, xlarge) no-repeat;
				background-size: map-get($ts-footer-bg-size,xlarge);
			}
		}
	} @else {
		background:$ts-footer-bg-color;
	}

	.footer-content {
		@include grid-row;
		@include get-gutter(map-get($ts-footer-indents-vt,small),'padding-top');

		@include breakpoint(medium) {
			@include get-gutter(map-get($ts-footer-indents-vt,medium),'padding-top');
		}

		@include breakpoint(xxlarge) {
			@include get-gutter(map-get($ts-footer-indents-vt,xxlarge),'padding-top');
		}

		@if ( $ts-footer-view == 'half-width' ) {

			.footer-list {
				@include grid-row;
				display: flex;
				flex-wrap: wrap;
				position: relative;

				@include breakpoint(xlarge) {
					max-width: 75%;
					margin-left: 0;
				}

				@include breakpoint(xxlarge) {
					max-width: 50%;
				}


				.footer-block {
					@include grid-col;
					@include get-gutter(map-get($ts-footer-block-indents-vt,small),'padding-top');
					@include get-gutter(map-get($ts-footer-block-indents-vt,small),'padding-bottom');
					position: relative;

					@include breakpoint(medium) {
						@include get-gutter(map-get($ts-footer-block-indents-vt,medium),'padding-top');
						@include get-gutter(map-get($ts-footer-block-indents-vt,medium),'padding-bottom');
					}

					@include breakpoint(xxlarge) {
						@include get-gutter(map-get($ts-footer-block-indents-vt,xxlarge),'padding-top');
						@include get-gutter(map-get($ts-footer-block-indents-vt,xxlarge),'padding-bottom');
					}

					@if ($ts-footer-border) {
						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 0;
							@include get-gutter(.5,'left');
							@include get-gutter(.5,'right');
							border-top: $ts-footer-border-width $ts-footer-border-style $ts-footer-border-color;
						}

						&:first-child {
							&:after {
								display: none;
							}
						}

					}

					&.text {
						clear: both;

						h3,
						h3.convert-block:not(:empty),
						.title {
							font-size: $ts-footer-header-font-size;

							@include breakpoint(medium) {
								font-size: ($ts-footer-header-font-size*1.5);
							}
						}

						p {
							margin-bottom: 0;
						}
					}

					&.social,
					&.newsletter {
						h3,
						h3.convert-block:not(:empty),
						.title {
							font-size: ($ts-footer-header-font-size*.75);
						}
					}

					&.convert-links,
					&.contact {
						width: 50%;
					}

					&.convert-links {
						&:after {
							right: 0;
						}
					}

					&.contact {
						&:after {
							left: 0;
						}
					}

					@include breakpoint(medium) {

						&.social {
							clear: left;
						}

						&.social,
						&.newsletter {
							width: 50%;
						}

						&.social {
							&:after {
								right: 0;
							}
						}

						&.newsletter {
							&:after {
								left: 0;
							}
						}
					}
				}// .footer-block

			}// .footer-list
		} @elseif ($ts-footer-view == 'full-width') {
			.footer-list {
				@include grid-row;
				position: relative;

				.footer-block {
					@include grid-col;
					position: relative;
					@include get-gutter(map-get($ts-footer-block-indents-vt,small),'padding-top');
					@include get-gutter(map-get($ts-footer-block-indents-vt,small),'padding-bottom');
					border-bottom: $ts-footer-border-width $ts-footer-border-style $ts-footer-border-color;

					&:last-child {
						border-bottom: 0;
					}

					@include breakpoint(medium) {
						@include grid-col-size(3);
						@include get-gutter(map-get($ts-footer-block-indents-vt,medium),'padding-top');
						@include get-gutter(map-get($ts-footer-block-indents-vt,medium),'padding-bottom');
						border-bottom: 0;

						&:nth-child(3) {
							clear: right;
						}

						&:nth-child(4) {
							clear: left;
						}

						&:nth-child(4),
						&:nth-child(5) {
							@include grid-col-size(6);
						}
					}

					@include breakpoint(xlarge) {
						@include grid-col-size(3);
						@include get-gutter(1.5,'padding-bottom');

						&:nth-child(4),
						&:nth-child(5) {
							@if ($ts-footer-full-width-block-alignment == 'non-uniform') {
								@include get-gutter(2.4,'padding-bottom');
							}

							@if ($ts-footer-header-last-decrease) {
								h3,
								.newsletter .title {
									margin-bottom: ($ts-footer-header-margin-bottom / 1.25);
								}
								p,
								.description {
									margin-bottom: ($ts-footer-header-margin-bottom * 1.3);
								}
							}

						}
					}

					@include breakpoint(xxlarge) {
						@include get-gutter(map-get($ts-footer-block-indents-vt,xxlarge),'padding-top');
					}
				}// .footer-block


				@if ($ts-footer-full-width-block-alignment == 'uniform') {
					.footer-block {
						@include grid-col-end();

						@include breakpoint(medium) {
							@include grid-col-size(6);

							&:nth-child(4) {
								clear: none;
							}
							&:nth-child(3) {
								clear: both;
							}
							&:nth-child(5) {
								clear: left;
							}
						}
						@include breakpoint(large) {
							@include grid-col-size(3);

							&:nth-child(3),
							&:nth-child(4) {
								clear: none;
							}
							&:nth-child(4),
							&:nth-child(5) {
								@include grid-col-size(3);
							}
						}
						@include breakpoint(xxlarge) {
							@include get-gutter(map-get($ts-footer-block-indents-vt,xxlarge),'padding-top');
							@include get-gutter(map-get($ts-footer-block-indents-vt,xxlarge),'padding-bottom');
						}

						&.social {
							@include breakpoint(large down) {
								text-align: left;
							}
						}
					}
				}// $ts-footer-full-width-block-alignment

			}// .footer-list
		}
	}// .footer-content

	.footer-bottom {
		background-color: $ts-footer-bottom-bg-color;

		.footer-wrap {
			@include grid-row;
		}

		.footer-list {
			@include grid-row;

			@include breakpoint(medium) {
				padding-left: 0;
				padding-right: 0;
			}

			.footer-block {
				@include grid-col;
				@include grid-col-size(12);
				@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, small),'padding-top');
				@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, small),'padding-bottom');
				position: relative;
				margin-bottom: 0;
				z-index: 1;

				+ .footer-block {
					padding-top: 0;
				}

				p {
					margin-bottom: 0;
				}

				@include breakpoint(medium) {
					@include grid-col-size(6);
					@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, medium),'padding-bottom');
					@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, medium),'padding-top');

					+ .footer-block {
						@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, medium),'padding-top');
						text-align: right;
					}
				}

				@include breakpoint(xxlarge) {
					@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, xxlarge),'padding-bottom');
					@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, xxlarge-top),'padding-top');

					+ .footer-block {
						@include get-gutter(map-get($ts-footer-bottom-block-indents-vt, xxlarge-top),'padding-top');
					}
				}
			}// .footer-block
		}// .footer-list
	}// .footer-bottom

	.footer-block.social {
		text-align: $ts-footer-social-text-align;
	}

	ul {
		@include lsn;
	}

	p,
	ul {
		font-family: $ts-footer-font-family;
		color: $ts-footer-font-color;
	}

	p {
		font-size: ($ts-footer-font-size*.9);

		@include breakpoint(medium) {
			font-size: $ts-footer-font-size;
		}
	}

	a {
		color: $ts-footer-nav-anchor-color;
		text-decoration: $ts-footer-nav-anchor-underline;
	}

	h3,
	h3.convert-block:not(:empty),
	.newsletter .title {
		@include header-font-family($ts-footer-header-font-family);
		font-size: ($ts-footer-header-font-size*.8);
		color: $ts-footer-header-font-color;
		font-weight: $ts-footer-header-font-weight;
		font-style: $ts-footer-header-font-style;
		margin-bottom: $ts-footer-header-margin-bottom;
		text-transform: $ts-footer-header-text-transform;

		@include breakpoint(medium) {
			font-size: $ts-footer-header-font-size;
		}

		strong {
			font-weight: inherit;
		}

		@include breakpoint(xlarge) {
			margin-bottom: ($ts-footer-header-margin-bottom * 1.7);
		}
	}// titles

	.convert-block:not(:empty) {
		margin-bottom: 0;
	}

	ul.links,
	.pimcore_area_document-menu ul,
	.footer-block .convert-wysiwyg ul {
		li {
			padding: $ts-footer-nav-anchor-padding;
		}
		a {
			display: block;
			text-transform: $ts-footer-nav-text-transform;
			font-family: $ts-footer-nav-font-family;
			color: $ts-footer-nav-anchor-color;
			font-weight: $ts-footer-nav-font-weight;
			padding: 0;

			&:hover {
				color: $ts-footer-nav-anchor-color-hover;
				text-decoration: $ts-footer-nav-anchor-underline-hover;
			}
		}// a
	}// ul.links


	.copyright {
		display: block;
		color: $ts-footer-copyright-color;
		font-size: $ts-footer-copyright-font-size;
		font-family: $ts-footer-font-family;

		p {
			margin-bottom: 0;
		}
	}// .copyright


	.newsletter {
		text-align: $ts-footer-suscribe-text-align;

		input,
		button,
		.action {
			margin-bottom: 0;
		}

		.label {
			@include at;
		}

		.description {
			line-height: $paragraph-lineheight;
			margin-bottom: $ts-footer-desc-bottom-indent;
			font-size: $ts-footer-desc-font-size;
			font-weight: $ts-footer-desc-font-weight;
			font-family: $ts-footer-desc-font-family;
			line-height: $ts-footer-desc-line-height;
		}

		@if ($ts-footer-input-place == inline) {

			.form.subscribe {
				@extend .input-group;
				max-width: $ts-footer-subscribe-max-width;
				position: relative;
				margin-bottom: .5rem;

				@if ($ts-footer-subscribe-center) {
					margin-left: auto;
					margin-right: auto;
				}

				.field.newsletter {
					@extend .input-group-field;
					height: map-get($ts-footer-subscribe-input-height, small);

					@include breakpoint(xlarge) {
						height: map-get($ts-footer-subscribe-input-height, xlarge);

						.control {
							margin-right: .5rem;

							@if ($ts-footer-button-view == icon) {
								margin-right: 0;
							}
						}
					}
				}// .field.newsletter

				.actions {
					@extend .input-group-button;

					.action {
						height: map-get($ts-footer-subscribe-input-height, small);
						@include button-style($ts-footer-button-bg, $ts-footer-button-bg-hover);
						color: $ts-footer-button-color;
						font-size: $ts-footer-button-font-size;
						font-weight: $ts-footer-button-font-weight;
						font-style: $ts-footer-button-font-style;
						text-transform: $ts-footer-button-text-transform;
						&:hover,
						&:active,
						&:focus {
							color: $ts-footer-button-color-hover;
						}

						@include breakpoint(xlarge) {
							height: map-get($ts-footer-subscribe-input-height, xlarge);
						}

						@if ($ts-footer-button-view == icon) {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							padding-left: $ts-footer-button-icon-indent;
							padding-right: $ts-footer-button-icon-indent;

							span {
								font-size: 0;
								display: inline-block;
								vertical-align: middle;
								width: $ts-footer-button-icon-width;
								height: $ts-footer-button-icon-height;
								background: url("#{$ts-path-to-images}/#{$ts-footer-button-icon}.#{$ts-footer-button-icon-type}") no-repeat;
								background-size: 100% auto;
							}
						}
					}
				}// .actions

				input {
					height: map-get($ts-footer-subscribe-input-height, small);
					padding: $ts-footer-subscribe-input-spacing;
					font-size: $ts-footer-subscribe-input-font-size;
					font-family: $ts-footer-subscribe-input-font-family;
					font-weight: $ts-footer-subscribe-input-font-weight;
					color: $ts-footer-subscribe-input-color;
					border-color: $ts-footer-subscribe-input-border-color;
					border-right: 1px solid $ts-footer-subscribe-input-border-color;
					background-color: $ts-footer-subscribe-input-bg;
					transition: all .3s ease;
					text-align: $ts-footer-subscribe-input-text-align;
					text-transform: $ts-footer-subscribe-input-text-transform;
					@include placeholder($ts-footer-subscribe-input-placeholder-color);

					&:focus {
						color: $ts-footer-subscribe-input-focus-color;
						background-color: $ts-footer-subscribe-input-focus-bg;
						&::placeholder {
						  color: $ts-footer-subscribe-input-focus-color;
						}
					}

					&.mage-error {
						border-color: map-get($foundation-palette, alert);
					}
					&.mage-error,
					&.valid {
						@if ($ts-footer-button-view == icon) {
							background-position: calc(100% - #{$ts-footer-button-icon-indent*2.5 + $ts-footer-button-icon-width}) 50%;
						}
					}

					@include breakpoint(xlarge) {
						height: map-get($ts-footer-subscribe-input-height, xlarge);
					}

					@if ($ts-footer-button-view == icon) {
						padding-right: ($ts-footer-button-icon-indent*2.5 + $ts-footer-button-icon-width);
					}
				}// input

				div.mage-error {
					position: absolute;
					top: 100%;
					left: 0;
					min-width: 100%;
				}// div.mage-error

			}// .form.subscribe

		} @else {
			.form.subscribe {
				margin-bottom: .5rem;
				max-width: $ts-footer-subscribe-max-width;

				@if ($ts-footer-subscribe-center) {
					margin-left: auto;
					margin-right: auto;
				}

				.field.newsletter {
					position: relative;
					margin-bottom: $ts-footer-subscribe-input-bottom-indent;

					input {
						height: map-get($ts-footer-subscribe-input-height, small);
						padding: $ts-footer-subscribe-input-spacing;
						font-size: $ts-footer-subscribe-input-font-size;
						font-family: $ts-footer-subscribe-input-font-family;
						font-weight: $ts-footer-subscribe-input-font-weight;
						color: $ts-footer-subscribe-input-color;
						border-color: $ts-footer-subscribe-input-border-color;
						background-color: $ts-footer-subscribe-input-bg;
						text-align: $ts-footer-subscribe-input-text-align;
						text-transform: $ts-footer-subscribe-input-text-transform;
						transition: all .3s ease;
						@include placeholder($ts-footer-subscribe-input-placeholder-color);

						@if ($ts-footer-input-border == only-bottom) {
							border:0;
							border-bottom: 1px solid $ts-footer-subscribe-input-border-bottom-color;
							&:active,
							&:focus {
								border-bottom-color: darken($ts-footer-subscribe-input-border-bottom-color, 25);
							}
						}

						@include breakpoint(xlarge) {
							height: map-get($ts-footer-subscribe-input-height, xlarge);
						}
					}

				}// .field.newsletter
				.field.terms {
					@include checkbox($ts-terms-checkbox-position);

					.label {
						@include element-invisible-off;
						color: $ts-terms-label-color;
						font-size: $ts-terms-label-font-size;
						font-weight: $ts-terms-label-font-weight;
						line-height: $ts-terms-label-line-height;
						cursor: $ts-terms-label-cursor;

						a {
							color: $ts-terms-link-color;
							text-decoration: $ts-terms-link-decoration;

							&:hover {
								text-decoration: $ts-terms-link-decoration-hover;
							}
						}
					}
				}
				.actions {
					.action {
						@include button-expand;
						height: map-get($ts-footer-subscribe-input-height, small);
						@include breakpoint(xlarge) {
							height: map-get($ts-footer-subscribe-input-height, xlarge);
						}

						@if ($ts-footer-button-bg-view == 'hollow') {
							@include button-hollow;
							@extend .button.light;
							@extend .button.small;
							text-transform: $ts-footer-button-text-transform;
							font-style: $ts-footer-button-font-style;
							color: $ts-footer-button-color;
							font-size: $ts-footer-button-font-size;
							font-weight: $ts-footer-button-font-weight;
							font-style: $ts-footer-button-font-style;
							border: $ts-footer-button-border;
							box-shadow: none;

							&:hover {
								box-shadow: none;
							}

						} @else {
							@include button-style($ts-footer-button-bg, $ts-footer-button-bg-hover);
							color: $ts-footer-button-color;
							font-size: $ts-footer-button-font-size;
							font-weight: $ts-footer-button-font-weight;
							font-style: $ts-footer-button-font-style;
							text-transform: $ts-footer-button-text-transform;
							background-image: none;

							&:hover,
							&:active,
							&:focus {
								color: $ts-footer-button-color-hover;
							}
						}

						@if ($ts-footer-button-view == with-icon) {
							padding-right: 3.5em;
							padding-left: 3.5em;
							background-repeat: no-repeat;
							background-position: calc(100% - 13px) 50%;
							background-image: url("#{$ts-path-to-images}/icon-arrow-light.png");
						}
					}
				}// .actions
			}
		}

		.response {
			color: $ts-footer-response-color;
			font-size: $ts-footer-response-font-size;
			border: 1px solid $ts-footer-response-border-color;
			max-width: $ts-footer-subscribe-max-width;
			padding: 1rem 1rem 1.25rem 2.9rem;
			background: url("#{$ts-path-to-images}/#{$ts-footer-response-icon}.#{$ts-footer-response-icon-type}") no-repeat 1rem 1.5rem;
			background-size: 17px auto;
			background-color: $ts-footer-response-bg;

			.ttl {
				display: none;
				@include header-font-family($ts-footer-header-font-family);
				font-size: $ts-footer-header-font-size;
				color: $ts-footer-header-font-color;
				font-weight: $ts-footer-header-font-weight;
				margin-bottom: ($ts-footer-header-margin-bottom/3);
			}

			.message-text {
				margin-bottom: 0 !important;
			}

			&.success {
				.error-ttl {
					display: none;
				}

				.success-ttl {
					display: block;
				}
			}

			&.error {
				border-color: $ts-footer-response-error-border-color;
				background-image: url("#{$ts-path-to-images}/#{$ts-footer-response-error-icon}.#{$ts-footer-response-error-icon-type}");
				background-color: $ts-footer-response-bg-error;

				.ttl,
				.message-text {
					color: map-get($foundation-palette, alert);
				}

				.error-ttl {
					display: block;
				}

				.success-ttl {
					display: none;
				}
			}
		}

		div.mage-error {
			white-space: normal;
			font-size: .75rem;
		}// div.mage-error

	} // .newsletter

	.footer-payment {
		text-align: $ts-footer-payment-text-align-small;

		@include breakpoint(medium) {
			text-align: $ts-footer-cookies-text-align;
		}

		p {
			margin-bottom: rem-calc(10);
		}
	} // .footer-payment

	.footer-contacts {
		li {
			padding: $ts-footer-nav-anchor-padding;
		}
	}

}// .page-footer

.footer.links {
	.phone {
		display: block;
		font-size: ($ts-footer-contact-tel-font-size*.9);
		@include header-font-family($ts-footer-contact-tel-font-family);
		color: $ts-footer-contact-tel-color;
		line-height: 1;
		font-weight: $ts-footer-contact-tel-font-weight;
		margin-top: .25rem;
		margin-bottom: .25rem;
		@if ( $ts-footer-contact-tel-icon-type != none) {
			padding: 0 0 ($ts-footer-contact-tel-icon-height / 4) $ts-footer-contact-tel-padding-left;
			background: url("#{$ts-path-to-images}/#{$ts-footer-contact-tel-icon}.#{$ts-footer-contact-tel-icon-type}") $ts-footer-contact-tel-icon-position no-repeat;
			background-size: $ts-footer-contact-tel-icon-height auto;
		}

		@include breakpoint(medium) {
			font-size: $ts-footer-contact-tel-font-size;
		}
	} // .tel

	.email {
		color: $ts-footer-contact-email-color;
		font-weight: $ts-footer-contact-email-font-weight;
		text-decoration: $ts-footer-contact-email-text-decoration;

		&:hover {
			text-decoration: $ts-footer-contact-email-text-decoration-hover;
		}
	} // .email
} // .footer-contacts