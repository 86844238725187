.usp-section {
	ul {
		display: flex;
        flex-wrap: wrap;
		@include lsn;
		padding: 0;
		line-height: 1.23;
        justify-content: space-between;
		font-size: 14px;
		@include header-font-family($ts-usp-font-family);
		font-weight: $ts-usp-font-weight;
		color: $ts-usp-font-color;
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
		@include breakpoint(xxlarge) {
			font-size: $ts-usp-font-size;
		}

		li {
            margin: 0;
            padding: 6px 20px 5px 30px;
            background-image: url("#{$ts-path-to-images}/#{$ts-usp-item-icon}.#{$ts-usp-item-icon-type}");
            background-size: 14px 14px;
            background-repeat: no-repeat;
            background-position: 0 50%;
            width: 100%;

            @include breakpoint(xlarge) {
                padding: 7px 20px 7px 40px;
                width: auto;
                background-size: $ts-usp-item-icon-width $ts-usp-item-icon-height;
                background-position: $ts-usp-item-icon-indent 50%;
            }
		}
	}
}//.usp-section

.product-info-main {
	.usp-section {
		@include hide-for(large);
		ul {
			display: flex;
			font-size: 15px;
			justify-content: space-between;
			margin-bottom: 35px;
			li {
				display: none;
				width: 48%;
				margin: 0;
				text-align: center;
				padding: 7px 20px 7px 33px;
				background-image: url("#{$ts-path-to-images}/#{$ts-usp-item-icon}.#{$ts-usp-item-icon-type}");
				background-size: $ts-usp-item-icon-width $ts-usp-item-icon-height;
				background-repeat: no-repeat;
				background-position: 8px 50%;

				&.usp-delivery,
				&.usp-return {
					display: block;
				}
			}
		}
	}
}
