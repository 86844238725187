//
//  Main Page Title in different layouts
//  _____________________________________________
.page-title-wrapper {
	@include grid-row;

	.column.main & {
		@include grid-row-nest;
		max-width: initial;
		width: auto;
	}
	.page-title {
		@include grid-col;
		text-align: $ts-product-detail-title-text-align;
	}

	.price-box,
	.order-status,
	.order-date,
	.actions-toolbar {
		@include grid-col;
	}

	@if ($ts-product-detail-title-text-align != center) {
		&.product {
			.page-title {
				@include breakpoint(xlarge) {
					@include grid-col-size(8);
				}
			}
			.price-revert-wrapper {
				@include breakpoint(xlarge) {
					@include grid-col-size(4);
					text-align: right;
				}
			}
		}
	}

	&.toolbar-amount {
		.page-title {
			@include grid-col-collapse;
		}
	}

	.page-layout-0column &,
	.page-layout-1column &,
	.cms-page-view & {
		padding-bottom: map-get($ts-common-titles-padding-bottom, small);
		margin-top: map-get($ts-common-titles-margin-top, small);
		margin-bottom: map-get($ts-common-titles-margin-bottom, small);

		.page-title {
			margin-bottom: 0;
			span {
				margin: 0;
				padding: 0;
				&:before {
					display: none;
				}
			}
		}

		@include breakpoint(large) {
			padding-bottom: map-get($ts-common-titles-product-padding-bottom, large);
			margin-top: map-get($ts-common-titles-margin-top, large);
			margin-bottom: map-get($ts-common-titles-margin-bottom, large);

			&:not(.product) {
				padding-bottom: map-get($ts-common-titles-padding-bottom, large);
			}

			@if $ts-common-titles-border-bottom {
				@include border-line(bottom, 0, $ts-main-dark-border-color);
			}
		}
	}

} //.page-title-wrapper