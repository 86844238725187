$mobile-breakpoint: 767px;
$laptop-breakpoint: 1224px;
$padding-standard: 1.25rem;
$action-padding: 0 1.9em;
$selected-item-font-size: 32px;

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: $laptop-breakpoint) {
        @content;
    }
}
.page-product-bundle {
    .product-info-wrapper {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    #lipscore-rating,
    videoly-slider {
        display: none;
    }
}
.bundle-product-full-width {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    padding-left: 1.25rem;

    @include mobile {
        width: 100%;
    }

    .page-title-wrapper {
        margin-bottom: 18px;
    }

    .product-info-usps {
        order: 2;

        .pagebuilder-column-group {
            width: 100%;
            padding: 10px 0 10px 48px;
            margin-top: 21px;

            @include mobile {
                width: 100%;
            }

            .pagebuilder-column {
                width: fit-content;

                .product-info-usps p {
                    width: 300px;
                }
            }
        }
    }

    .product-info-sidebar.product-info-sidebar-configurable {
        order: 2;
        display: flex;
        flex-direction: column;
    }

    .bundle-how-to-buy-row {
        background: #0000000A;
        border-radius: 15px;
        width: 65%;

        @include mobile {
            width: 100%;
        }

        .bundle-how-to-buy-container {
            margin: 26px;

            .bundle-how-to-buy-title {
                font-weight: 600;
                padding-bottom: 10px;
            }

            .bundle-how-to-buy-items {
                margin-left: 4px;

                .bundle-how-to-buy-item {
                    padding: 0 0 0 1.2em;
                    font-weight: 600;
                }
            }
        }
    }

    .product-info-bundle-row {
        @include mobile {
            margin: 0;
        }

        .price-final_price .price {
            color: #F7465B;
            font-weight: 600;
            font-size: 32px;
        }

        .old-price {
            text-decoration: none;

            .price {
                font-weight: 400;
                font-size: 16px;
                color: #555555;
                text-decoration: line-through;
            }
        }

        .product-info-price .price-box .price-container {
            line-height: 0.6;
            margin-right: 12px;
        }

        .product-info-price .price-box .price-container .price-label {
            display: none;
            font-size: 14px;
        }

         .product-info-price, .product-form-row-bundle {
            @include mobile {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .product-form-row-bundle {
            width: 100%;
            padding-top: 13px;

            .product-bundle-addto-container {
                display: block;

                .bundle-actions .action.primary.customize {
                    width: 100%;

                    @include mobile {
                        width: 100%;
                    }

                    padding: 0.8em 2.9em;
                }
            }
        }
    }

    .product.info.short_description.teaser.b-decription-toggle {
        order: 1;
        width: 100%;

        @include mobile {
            width: 100%;
        }
    }

    .stock.available {
        display: none;
    }

    .product-info-bundle-row .product-info-price {
        margin-top: 18px;
    }

    .product-bulletin {
        order: 1;
    }

    .product-info-bundle-row .product-form-row-bundle {
        align-items: flex-end;
        margin-top: 6px;
    }
    .page-title-wrapper {
        display: flex;
        flex-direction: column;

        .page-title {
            order: 2;
        }

        .amshopby-option-link {
            order: 1;
        }
    }
}

.bundle-options-container {
    padding-bottom: 40px;

    .bundle-options-wrapper,
    .product-options-wrapper {
        width: 100%;
    }

    .option-tier-prices {
        display: none;
    }

    p.required {
        display: none;
    }

    .block-bundle-summary {
        top: 0 !important;
    }

    #product_addtocart_form {
        display: flex;
        flex-direction: column;
    }

    .control {
        display: flex;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }
    }

    .bundle-option-container {
        width: 40%;

        @include mobile {
            width: 98%;
        }
    }

    .image-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;

        @include mobile {
            flex-direction: column;
        }
    }

    .slider-for {
        flex: 1;
        margin-left: 20px;
        max-width: 675px;
        text-align: -webkit-center;

        @include laptop {
            max-width: 450px;
        }

        @include mobile {
            max-width: 360px;
        }
    }

    .slider-nav {
        width: 100px; /* Adjust as needed */
        flex: none;
        display: flex;
        flex-direction: column;

        @include mobile {
            flex-direction: row;
            width: 92vw;
        }

        .slick-next, .slick-next:hover, .slick-next:focus {
            margin-top: 30px;

            @include mobile {
                margin-top: 0;
            }
        }

        .slick-prev:before {
            transform: rotate(270deg);

            @include mobile {
                transform: rotate(180deg);
            }
        }

        .slick-next:before {
            transform: rotate(90deg);

            @include mobile {
                transform: rotate(0deg);
            }
        }

        .slick-dots {
            display: none !important;
        }

        .slick-slide {
            @include mobile {
                text-align: -webkit-center;
            }
        }

        .slick-current img {
            border: 1px solid black;

            @include mobile {
                border: none;
            }
        }
    }

    .slider-nav img {
        width: 100px; /* Same as the container width */
        height: 100px; /* Ensure square thumbnails */
        object-fit: cover;
        margin-bottom: 10px; /* Space between thumbnails */
    }

    .slick-slide img {
        max-width: 100%;
        height: auto;
        max-height: 600px;
    }

    .description-container {
        margin-top: 10px;
    }

    .box-tocart .fieldset .actions {
        display: flex;

        .product-quantity-control {
            display: flex;

            .product-addtocart-quantity-control-desc {
                display: none;
            }
        }
    }

    .product-info-form-wrapper {
        order: 2;
        padding: 0 $padding-standard;
        margin-top: 26px;

        button.action {
            padding: $action-padding;
            width: 37em;
            margin-right: 11px;

            @include mobile {
                width: 12em;
                height: 75%;
            }
        }
    }

    .product-options-wrapper .fieldset>.field {
        padding-top: 2.6rem;
    }

    .selected-brand .bundle-option-link {
        padding-bottom: 25px;
    }

    .selected-brand .bundle-option-link img {
        width: 120px;
        height: auto;
    }

    .selected-item-name {
        font-weight: 600;
        font-size: $selected-item-font-size;
        margin: 0 0 16px 0;
        color: #222222;
    }

    .description-container {
        margin-bottom: 30px;
    }

    .action.back,
    .legend.title {
        display: none;
    }

    .next-button {
        display: inline-block;
        vertical-align: middle;
        margin: 10px 0 40px 0;
        padding: 0.75em 2.9em;
        -webkit-appearance: none;
        border: 1px solid transparent;
        border-radius: 30px;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        background-color: #046C66;
        color: #fff;
        font-family: "Roboto", sans-serif;
        letter-spacing: normal;
        font-weight: 600;
        text-transform: none;
        width: -webkit-fill-available;
    }

    .product-options-wrapper .fieldset > .field > .label {
        display: none;
    }

    .product-options-wrapper .fieldset > .field .control {
        width: 100%;
    }

    .bundle-info .convert-label-container {
        display: none;
    }

    .product-info-form-wrapper .product-bulletin {
        display: none;
    }
}

.bundle-product-full-width .toggle-description, .bundle-options-container .toggle-description {
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 5px;
    text-decoration: underline;
}
