// DESKTOP HEADER
.header {
	.block-search {
		.block-title,
		.action.advanced,
		.block-content .search .label,
		.minisearch .search > span {
			@include at;
			color: $white; // for WCAG
		}
	} // .block-search

	.top-search-wrapper {
		margin-bottom: 0;
		text-align: right;

		@include breakpoint(xxlarge) {
			display: table;
			width: 100%;
 		}

 		.block-search {
			height: $ts-header-search-input-height;

			@include breakpoint(xxlarge) {
				width: 100%;
			}
 		}

		.block-search,
		.minicart-wrapper {
			@include breakpoint(xxlarge) {
				margin: 0;
				white-space: nowrap;
				display: table-cell;
				vertical-align: middle;
			}
		}

		@if ($ts-header-cart-dropdown-showcart-type == image-only) {
			.minicart-wrapper {
				margin-top: 1rem;
				margin-right: .5rem;

				@include breakpoint(xxlarge) {
					width: 1%;
					height: 100%;
					padding: $ts-header-cart-dropdown-showcart-icon-spacing;
					margin-top: 0;
					margin-right: 0;
				}
			}
		}
	} // .top-search-wrapper
} // .header

%search-input-desktop-control {
	height: $ts-header-search-input-height;
	padding-right: $ts-header-search-input-indent;
	padding-left: $ts-header-search-input-left-indent;
	margin-bottom: 0;
	font-family: $ts-header-search-font-family;
	font-size: $ts-header-search-font-size;
	font-weight: $ts-header-search-font-weight;
	color: $ts-header-search-input-color;
	text-transform: $ts-header-search-input-text-transform;
	border-color: $ts-header-search-input-background-color;
	border-bottom-color: $ts-header-search-input-border-bottom-color;
	background-color: $ts-header-search-input-background-color;
	background-position: $ts-header-search-input-icon-bg-position;
	transition: all .3s ease;
	@include placeholder($ts-header-search-input-color);

	@if not($ts-header-search-input-focus-one-line-border) {
		border: $ts-header-search-input-focus-border;
	}

	.mobile-search-bar & {
		padding-left: $ts-header-search-input-left-indent-mobile;
	}

	@include breakpoint(large down) {
		padding-left: $ts-header-search-input-left-indent-mobile;
	}

	&:hover,
	&:active,
	&:focus,
	&:focus:not([value=""]),
	&.focus {
		@if $ts-header-search-input-focus-one-line-border {
			border:1px solid $ts-headbar-bg-color;
			border-bottom: 1px solid $primary-color;
		} @else {
			border-color: $ts-header-search-input-focus-border-color;
			border-bottom-color: $ts-header-search-input-focus-border-bottom-color;
			background-color: lighten($ts-header-search-input-background-color, 14.5098) !important;
		}
	}

	&::-ms-clear {
		display: none;
	}
}

%search-input-mobile-control {
	height: $ts-mobile-search-field-height;
	color: $ts-mobile-search-field-color;
	font-size: $ts-mobile-search-field-font-size;
	padding: $ts-mobile-search-field-padding;
	border-width: $ts-mobile-search-field-border-width;
	border-style: $ts-mobile-search-field-border-style;
	border-color: $ts-mobile-search-field-border-color;
	background-color: $ts-mobile-search-field-bg;
	text-transform: $ts-mobile-search-field-text-transform;
	@include placeholder($ts-mobile-search-field-placeholder-color);

	@if ($ts-mobile-search-button-position == normal) {
		width: $ts-mobile-search-field-width;
		display: inline-block;
		vertical-align: middle;
	} @else {
		width: 100%;
	}

	&:hover,
	&:active,
	&:focus,
	&:focus:not([value=""]),
	&.focus {
		border-color: darken($ts-mobile-search-field-border-color, 30);
		background-color: $ts-mobile-search-field-bg !important;
	}
}

%search-button-mobile-control {
	height: $ts-mobile-search-field-height;
	@if ( $ts-mobile-search-button-position == inside ) {
		position: absolute;
		top: $ts-mobile-search-button-position-top;
		right: $ts-mobile-search-button-position-right;
		left: auto;
	} @else {
		display: inline-block;
		vertical-align: middle;
	}
}

%search-button-mobile {
	height: $ts-mobile-search-field-height;
	width: $ts-mobile-search-button-width;
	min-width: ($ts-mobile-search-button-width * 2);
	line-height: $ts-mobile-search-field-height;
	padding: $ts-header-search-input-icon-padding-indent ($ts-header-search-input-icon-padding-indent * 2);
	background-image: url("#{$ts-path-to-images}/#{$ts-mobile-search-toggle-button-image}.#{$ts-mobile-search-toggle-button-type}");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: $ts-mobile-search-button-image-size auto;
}

// MOBILE SEARCHBAR VIEW
@if ($ts-header-search-mobile-parent == 'sticky-mm') {
	.#{$ts-header-search-mobile-parent} {
		.mobile-search-bar {
			display: none;
			&.open {
				display: block;
			}
		}
	}
} @elseif ($ts-header-search-mobile-parent == 'mobile-search-wrapper') {
	.#{$ts-header-search-mobile-parent} {
		@include hide-for($ts-header-search-mobile-sticky-hide-from);
		.mobile-search-bar {
			body:not(.page-layout-0column) & {
				padding-left:(map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
				padding-right:(map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
				@include breakpoint(large) {
					padding-left:(map-get($grid-column-gutter, medium)/2) + map-get($ts-global-space-indent, large);
					padding-right:(map-get($grid-column-gutter, medium)/2) + map-get($ts-global-space-indent, large);
				}
			}
		}

		@if $ts-header-search-mobile-sticky {
			transition: all .3s ease;
			&.in-scroll {
				position: fixed;
				top: $ts-header-search-mobile-sticky-top;
				left: $ts-header-search-mobile-sticky-left;
				right: $ts-header-search-mobile-sticky-right;
				z-index: $ts-headbar-zindex;
				.mobile-search-bar {
					padding: 0 !important;
				}
			}
		}// @if
	}
}

.mobile-search-bar {
	padding-top: (map-get($grid-column-gutter, small)/2);
	padding-bottom: (map-get($grid-column-gutter, small)/2);
	padding-left: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
	padding-right: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
	background-color: $ts-mobile-search-bar-bg-color;
	@if ( $ts-mobile-search-bar-box-shadow != none ) {
		box-shadow: $ts-mobile-search-bar-box-shadow;
	}

	#algolia-searchbox {
		.algolia-search-input {
			@extend %search-input-mobile-control;
		}
		#algolia-glass {
			@extend %search-button-mobile-control;
			@extend %search-button-mobile;
		}
		.clear-cross {
			height: $ts-mobile-search-field-height;
			width: $ts-mobile-search-button-width;
			min-width: ($ts-mobile-search-button-width * 2);
			line-height: $ts-mobile-search-field-height;
			padding: $ts-header-search-input-icon-padding-indent ($ts-header-search-input-icon-padding-indent * 2);
			@extend .clear-cross;
			background-size: $ts-mobile-search-button-image-size auto;
			top: -1px;

			@if ( $ts-mobile-search-button-position == inside ) {
				position: absolute;
				top: $ts-mobile-search-button-position-top;
				right: $ts-mobile-search-button-position-right;
				left: auto;
			} @else {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}// #algolia-searchbox

	.minisearch {
		position: relative;
		.input-text {
			@extend %search-input-mobile-control;
		}
		.label,
		.nested {
			@include at;
		}
		.actions {
			@extend %search-button-mobile-control;
		}
		.action.search {
			@extend %search-button-mobile;
		}
	}// .minisearch

	.le-search-side.autocomplete {
		left: 0;
		right: 0;
		z-index: 103;
	}// .le-search-side.autocomplete
} // .mobile-search-bar