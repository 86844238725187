//
//  Tables
//  _____________________________________________
table {
	border-collapse: collapse;
	border:0;
	margin-bottom: $table-margin;
	tr {
		td,
		th {
			border-top: $table-border;
			border-bottom: $table-border;
			text-align: #{$global-left};
		}
		td {
			font-size: $table-body-font-size;
		}
	}
	thead {
		th {
			border-bottom: 0;
			padding: $table-head-padding;
			font-size: $table-head-font-size;
			font-weight: $table-head-font-weight;
			@include header-font-family($table-head-font-family);
		}
	}
	thead,
	tfoot,
	tbody {
		border: 0;
	}
	tfoot,
	tbody {
		tr {
			&:last-child {
				td,
				th {
					border-bottom: 0;
				}
			}
		}
	}
}

.table-wrapper {
	@include breakpoint(medium down) {
		overflow-x: auto;
		overflow-y: hidden;
	}
}