.header {
	.sticky-search {
		display: none !important;
	}// .sticky-search

	.sticky {
		width: 100%; // fix for FF
		position: relative;
	}// .sticky

	.header-row {
		&.is-stuck {
			max-width: 100% !important;
			height: $ts-sticky-headbar-height;
			position: fixed !important;
			left: 0 !important;
			right: 0 !important;
			margin: 0 !important;
			padding-top: $ts-sticky-headbar-height-spacing-top;
			padding-bottom: $ts-sticky-headbar-height-spacing-bottom;
			box-shadow: $ts-sticky-scroll-headbar-box-shadow;
			background: $ts-headbar-bg-color;

			@if $ts-headbar-top-border {
				@include top-header-line($ts-headbar-top-border-height-stuck);
			}

			.nav-sections {
				height: $ts-sticky-headbar-height - $ts-sticky-headbar-height-spacing-top - $ts-sticky-headbar-height-spacing-bottom;

				.navigation {
					height: 100%;
					margin-top: 0;
				}
			}

			.logo {
				img {
					.ie11 & {
						width: 100%;
						max-height: inherit;
					}
				}
			}// .logo
		}// &.is-stuck
	}// .header-row
} // .header